import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Notifications = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
			<path d="M12 5.333a4 4 0 0 0-8 0c0 4.667-2 6-2 6h12s-2-1.333-2-6ZM9.153 14a1.334 1.334 0 0 1-2.307 0" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Notifications;
