import * as React from 'react';
type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Earth = (props: Props) => {
	const { width = 24, height = 24, color = '#016DEA', ...rest } = props;

	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g clipPath="url(#clip0_82_76263)">
				<path
					d="M12 23.6248C18.4203 23.6248 23.625 18.4201 23.625 11.9998C23.625 5.57945 18.4203 0.374756 12 0.374756C5.57969 0.374756 0.375 5.57945 0.375 11.9998C0.375 18.4201 5.57969 23.6248 12 23.6248Z"
					stroke={color}
					strokeWidth={0.75}
				/>
				<path
					d="M12.3362 0.448975C12.3362 0.448975 11.6237 1.12397 11.2487 1.49897C10.8737 1.87397 10.1237 1.87397 10.4987 2.24897C10.8737 2.62397 10.8737 2.99897 10.8737 2.99897L10.4987 3.37397C10.4987 3.37397 10.4987 3.74897 9.7487 4.12397C8.9987 4.49897 8.9987 4.12397 8.6237 4.49897C8.2487 4.87397 8.2487 4.87397 7.8737 4.87397C7.4987 4.87397 7.4987 5.24897 7.1237 5.24897C6.7487 5.24897 7.1237 3.74897 7.1237 3.74897L7.8737 3.37397L8.6237 2.99897C8.6237 2.99897 8.9987 2.24897 8.6237 2.24897H8.2487C8.2487 2.24897 8.2487 1.87397 7.8737 1.49897L7.4987 1.12397"
					stroke={color}
					strokeWidth={0.75}
				/>
				<path
					d="M4.3125 3.33667L4.875 5.62417L4.5 6.37417C4.5 6.37417 3.75 6.37417 3.75 7.12417C3.75 7.87417 3.375 7.12417 3.75 7.87417C4.125 8.62417 3.75 8.99917 3.75 8.99917V9.37417V10.1242H2.625C2.625 10.1242 2.625 10.1242 2.25 10.1242C1.875 10.1242 1.875 10.1242 1.5 10.4992C1.125 10.8742 1.125 10.8742 1.125 11.2492V11.6242L0.75 12.3742L0.375 12.7492"
					stroke={color}
					strokeWidth={0.75}
				/>
				<path
					d="M0.5625 14.0242C0.9375 14.3992 1.3125 14.3992 1.3125 14.3992C1.3125 14.3992 3 13.8742 3.375 14.2492C3.75 14.6242 3.75 15.3742 3.75 15.3742V15.7492C3.75 15.7492 4.5 16.8742 4.875 16.8742H5.25C5.25 16.8742 5.625 17.2492 6 17.2492C6.375 17.2492 6.75 17.6242 6.75 17.6242C6.75 17.6242 7.125 18.7492 6.75 18.7492C6.375 18.7492 6.375 19.1242 6.375 19.1242C6.375 19.1242 6.375 20.6242 7.125 20.9992C7.875 21.3742 8.625 21.7492 9 22.1242C9.375 22.4992 9.75 22.1242 9.75 22.4992C9.75 22.8742 10.5 23.6242 12 23.6242"
					stroke={color}
					strokeWidth={0.75}
				/>
				<path
					d="M23.625 13.1248C23.625 13.1248 22.5 11.9998 22.5 12.3748C22.5 12.7498 22.125 13.1248 22.125 13.8748C22.125 14.6248 21.75 15.7498 21.75 15.7498L21 14.9998L21.375 13.1248L20.25 11.9998H19.5C19.5 11.9998 19.875 12.7498 19.875 13.1248V14.6248C19.875 14.6248 19.125 16.1248 19.125 16.4998C19.125 16.8748 18.75 16.4998 18.375 17.2498C18 17.9998 17.625 17.9998 17.25 18.7498C16.875 19.4998 16.125 19.8748 15.75 20.6248C15.375 21.3748 15 20.9998 15 20.6248C15 20.2498 14.25 19.4998 14.625 19.1248C15 18.7498 14.625 18.3748 15 17.9998C15.375 17.6248 15.75 17.6248 15.375 17.2498C15 16.8748 14.625 16.8748 14.625 16.4998V14.9998C14.625 14.9998 13.875 13.8748 13.5 14.2498L12.75 14.9998L11.25 13.8748L12 11.9998L11.625 10.4998C11.625 10.4998 12.75 9.37476 12.75 8.99976C12.75 8.62476 13.125 8.24976 13.125 8.24976L12.75 6.74976C12.75 6.74976 12.375 6.37476 13.125 6.74976C13.875 7.12476 14.25 7.87476 14.25 7.87476L15.375 7.49976C15.375 7.49976 15.375 7.49976 15.375 7.12476C15.375 6.74976 15.75 5.99976 16.125 5.99976H16.875L18.375 4.49976C18.375 4.49976 16.875 4.12476 16.875 3.74976C16.875 3.37476 16.875 2.99976 17.25 2.62476C17.625 2.24976 18.375 2.24976 18.375 2.24976"
					stroke={color}
					strokeWidth={0.75}
				/>
			</g>
			<defs>
				<clipPath id="clip0_82_76263">
					<rect width={24} height={24} fill="white" transform="translate(0 -0.000244141)" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default Earth;
