import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const PlanIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.75 1.55937C6.15313 1.75 5.675 2.25313 5.54688 2.82187L5.5125 2.98438L5.05938 3.00313C4.75 3.01562 4.56563 3.0375 4.46875 3.075C4.04063 3.2375 3.675 3.62813 3.55 4.05937C3.50625 4.2125 3.5 4.71562 3.5 8.75312C3.5 13.1031 3.50313 13.2844 3.55937 13.4656C3.70312 13.925 4.09688 14.3156 4.55938 14.45C4.70625 14.4937 4.97188 14.5 6.45 14.5C8.1125 14.5 8.175 14.4969 8.275 14.4375C8.57188 14.2562 8.58437 13.7625 8.29063 13.5719C8.2125 13.5188 8.07812 13.5156 6.54688 13.5C4.9625 13.4844 4.8875 13.4812 4.78438 13.4219C4.64688 13.3406 4.56563 13.2281 4.53125 13.075C4.49375 12.9125 4.49375 4.5875 4.53125 4.425C4.56563 4.27187 4.64688 4.15938 4.78438 4.07812C4.86875 4.03125 4.9625 4.01562 5.26875 4.00625L5.65 3.99375L5.71563 4.12187C5.89688 4.475 6.375 4.84062 6.8 4.95312C7.06562 5.02187 9.93125 5.02187 10.1969 4.95312C10.6156 4.84375 11.0125 4.55 11.2438 4.17812L11.3563 3.99375L11.7344 4.00625C12.0375 4.01562 12.1344 4.03125 12.2156 4.07812C12.3531 4.15938 12.4344 4.27187 12.4688 4.425C12.4844 4.49062 12.5 5.1375 12.5 5.85938C12.5 7.1125 12.5031 7.175 12.5625 7.275C12.6562 7.425 12.7969 7.5 13 7.5C13.2031 7.5 13.3438 7.425 13.4375 7.275C13.4969 7.175 13.5 7.1125 13.5 5.69687C13.5 4.34062 13.4969 4.20937 13.4406 4.03438C13.2969 3.575 12.9031 3.18437 12.4406 3.05C12.325 3.01562 12.1438 3 11.8844 3H11.5V2.92188C11.5 2.79688 11.3563 2.44063 11.2313 2.25937C10.9969 1.91875 10.6188 1.64687 10.2344 1.54688C10.0781 1.50937 9.79375 1.5 8.48438 1.50313C7.07812 1.50313 6.90313 1.50937 6.75 1.55937ZM10.0469 2.58125C10.3219 2.73125 10.4688 2.9625 10.4688 3.25C10.4688 3.46562 10.3906 3.6375 10.2281 3.78438C10.0062 3.98438 10.0062 3.98438 8.48438 3.98438C7.15313 3.98438 7.10625 3.98125 6.96875 3.91875C6.30625 3.60938 6.42188 2.65625 7.14062 2.52812C7.20938 2.51562 7.8625 2.50937 8.59375 2.50937C9.8875 2.51562 9.925 2.51875 10.0469 2.58125Z"
			fill="white"
		/>
		<path
			d="M5.725 6.0625C5.575 6.15625 5.5 6.29688 5.5 6.5C5.5 6.70312 5.575 6.84375 5.725 6.9375C5.825 6.99687 5.8875 7 8.5 7C11.1125 7 11.175 6.99687 11.275 6.9375C11.425 6.84375 11.5 6.70312 11.5 6.5C11.5 6.29688 11.425 6.15625 11.275 6.0625C11.175 6.00313 11.1125 6 8.5 6C5.8875 6 5.825 6.00313 5.725 6.0625Z"
			fill="white"
		/>
		<path
			d="M5.725 8.0625C5.575 8.15625 5.5 8.29688 5.5 8.5C5.5 8.70312 5.575 8.84375 5.725 8.9375C5.825 8.99687 5.8875 9 7.75 9C9.6125 9 9.675 8.99687 9.775 8.9375C9.925 8.84375 10 8.70312 10 8.5C10 8.29688 9.925 8.15625 9.775 8.0625C9.675 8.00313 9.6125 8 7.75 8C5.8875 8 5.825 8.00313 5.725 8.0625Z"
			fill="white"
		/>
		<path
			d="M11.5625 8.5594C10.9281 8.6969 10.4281 8.9719 9.95001 9.45003C9.46251 9.93753 9.19376 10.4313 9.05626 11.0938C8.96563 11.525 8.99063 12.2407 9.10626 12.6344C9.27813 13.2157 9.55313 13.6719 9.97501 14.0813C10.3875 14.4844 10.8031 14.725 11.3656 14.8938C11.7594 15.0094 12.475 15.0344 12.9063 14.9438C13.5688 14.8063 14.0625 14.5375 14.55 14.05C15.2 13.4 15.5 12.675 15.5 11.75C15.5 10.825 15.2 10.1 14.55 9.45003C14.0656 8.96565 13.5656 8.69378 12.9219 8.5594C12.55 8.48128 11.9313 8.48128 11.5625 8.5594ZM12.7 9.56565C13.4438 9.7094 14.1094 10.3063 14.3656 11.0594C14.475 11.3782 14.5 11.8875 14.4219 12.2344C14.2469 13.0282 13.5906 13.7032 12.7906 13.9094C11.4063 14.2657 10.0281 13.1969 10.0313 11.7657C10.0313 11.5938 10.0531 11.3657 10.0781 11.2563C10.3563 10.0782 11.5094 9.3344 12.7 9.56565Z"
			fill="white"
		/>
		<path
			d="M13.2969 10.4906C13.2625 10.4969 13.2 10.5094 13.1562 10.5187C13.1125 10.5281 12.825 10.7906 12.4625 11.15L11.8406 11.7625L11.6125 11.5312C11.3562 11.275 11.3 11.2437 11.0812 11.2469C10.7281 11.2469 10.4937 11.6094 10.6281 11.9594C10.6656 12.0594 11.4344 12.8469 11.5875 12.9437C11.7437 13.0406 11.9969 13.0344 12.15 12.9281C12.3625 12.7781 13.775 11.3344 13.8281 11.2094C13.9156 10.9969 13.8687 10.7781 13.7 10.6187C13.6094 10.5344 13.4125 10.4719 13.2969 10.4906Z"
			fill="white"
		/>
		<path
			d="M5.725 10.0625C5.575 10.1562 5.5 10.2969 5.5 10.5C5.5 10.7031 5.575 10.8438 5.725 10.9375C5.82188 10.9969 5.88438 11 7 11C8.11563 11 8.17813 10.9969 8.275 10.9375C8.425 10.8438 8.5 10.7031 8.5 10.5C8.5 10.2969 8.425 10.1562 8.275 10.0625C8.17813 10.0031 8.11563 10 7 10C5.88438 10 5.82188 10.0031 5.725 10.0625Z"
			fill="white"
		/>
	</svg>
);
export default PlanIcon;
