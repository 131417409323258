import Assets from '@/components/icons/Assets';
import Connections from '@/components/icons/Connections';
import Earth from '@/components/icons/Earth';
import Facebook from '@/components/icons/Facebook';
import Instagram from '@/components/icons/Instagram';
import Linkedin from '@/components/icons/Linkedin';
import MainPage from '@/components/icons/MainPage';
import OnlyMe from '@/components/icons/OnlyMe';
import Pinterest from '@/components/icons/Pinterest';
import Profile from '@/components/icons/Profile';
import SelectedProfiles from '@/components/icons/SelectedProfiles';
import TikTok from '@/components/icons/TikTok';
import Blog from '@/components/icons/VV_Assets/Blog';
import Books from '@/components/icons/VV_Assets/Books';
import Channel from '@/components/icons/VV_Assets/Channel';
import Consultation from '@/components/icons/VV_Assets/Consultation';
import Courses from '@/components/icons/VV_Assets/Courses';
import DigitalProd from '@/components/icons/VV_Assets/DigitalProd';
import Episodes from '@/components/icons/VV_Assets/Episodes';
import Events from '@/components/icons/VV_Assets/Events';
import Jobs from '@/components/icons/VV_Assets/Jobs';
import PhysicalProd from '@/components/icons/VV_Assets/PhysicalProd';
import Podcasts from '@/components/icons/VV_Assets/Podcasts';
import Project from '@/components/icons/VV_Assets/Project';
import Shorts from '@/components/icons/VV_Assets/Shorts';
import SocialGroup from '@/components/icons/VV_Assets/SocialGroup';
import SocialPage from '@/components/icons/VV_Assets/SocialPage';
import Sponsorship from '@/components/icons/VV_Assets/Sponsorship';
import Stores from '@/components/icons/VV_Assets/Stores';
import Videos from '@/components/icons/VV_Assets/Videos';
import Webinars from '@/components/icons/VV_Assets/Webinars';
import EntityProfile from '@/components/icons/VV_Profiles/EntityProfile';
import PersonalProfile from '@/components/icons/VV_Profiles/PersonalProfile';
import VBlogAuthor from '@/components/icons/VV_Profiles/VBlogAuthor';
import VBooksAuthor from '@/components/icons/VV_Profiles/VBooksAuthor';
import VCandidate from '@/components/icons/VV_Profiles/VCandidate';
import VCastAuthor from '@/components/icons/VV_Profiles/VCastAuthor';
import VClient from '@/components/icons/VV_Profiles/VClient';
import VEmployer from '@/components/icons/VV_Profiles/VEmployer';
import VExpert from '@/components/icons/VV_Profiles/VExpert';
import VHosts from '@/components/icons/VV_Profiles/VHosts';
import VInfluencer from '@/components/icons/VV_Profiles/VInfluencer';
import VInstructor from '@/components/icons/VV_Profiles/VInstructor';
import VLancer from '@/components/icons/VV_Profiles/VLancer';
import VNationAuthor from '@/components/icons/VV_Profiles/VNationAuthor';
import VOrganizer from '@/components/icons/VV_Profiles/VOrganizer';
import VSeller from '@/components/icons/VV_Profiles/VSeller';
import VTubeAuthor from '@/components/icons/VV_Profiles/VTubeAuthor';
import X from '@/components/icons/X';
import Youtube from '@/components/icons/Youtube';
import PlanIcon from '@/components/icons/collectives/PersonalProfile/PlanIcon';
import AdsManager from '@/components/icons/megamenu_account_office/AdsManager';
import Analytics from '@/components/icons/megamenu_account_office/Analytics';
import Badges from '@/components/icons/megamenu_account_office/Badges';
import Builders from '@/components/icons/megamenu_account_office/Builders';
import Chat from '@/components/icons/megamenu_account_office/Chat';
import Contracts from '@/components/icons/megamenu_account_office/Contracts';
import Credits from '@/components/icons/megamenu_account_office/Credits';
import Notifications from '@/components/icons/megamenu_account_office/Notifications';
import Orders from '@/components/icons/megamenu_account_office/Orders';
import Planner from '@/components/icons/megamenu_account_office/Planner';
import Profiles from '@/components/icons/megamenu_account_office/Profiles';
import Referrals from '@/components/icons/megamenu_account_office/Referrals';
import Services from '@/components/icons/megamenu_account_office/Services';
import ShoppingCart from '@/components/icons/megamenu_account_office/ShoppingCart';
import Storage from '@/components/icons/megamenu_account_office/Storage';
import Wallet from '@/components/icons/megamenu_account_office/Wallet';
import WishList from '@/components/icons/megamenu_account_office/Wishlist';
import StarDefault from '@/components/icons/megamenu_home/StarDefault';
import AllCustomers from '@/components/icons/v-buzz/AllCustomers';
import Endorsement from '@/components/icons/v-guide/Endorsment';
import GeneralInfoIcon from '@/components/icons/v-guide/General-Info-Icon';
import Likes from '@/components/icons/v-guide/Likes';
import Share from '@/components/icons/v-guide/Shares';
import WebinarWhite from '@/components/icons/v-webinar/WebinarWhite';
import { CountsType } from '@/lib/slice/counts';
import { AssetType, BreakPointCounts } from '@/utils/types';
import { FormType } from '@/utils/types/personal-profile-builder';

export const militaryStatusOptions = [
	{ id: 1, name: 'Completed', value: 'Completed' },
	{ id: 2, name: 'Exempted', value: 'Exempted' },
	{ id: 3, name: 'Expecting', value: 'Expecting' },
];

export const privacyOptions = [
	{ id: 1, name: 'Private', value: 'Private', value_two: 'only_me' },
	{ id: 2, name: 'Public', value: 'Public', value_two: 'public' },
];

export const FeaturedOptions = [
	{ id: 1, name: 'Featured', value: 1 },
	{ id: 2, name: 'Not Featured', value: 0 },
];

export const localizedFeaturedOptions = (t: (s: string) => string) => [
	{ id: 1, name: t('common.buttons.featured'), value: 1 },
	{ id: 2, name: t('common.buttons.notFeatured'), value: 0 },
];

export const activitiesOptions = [
	{ id: 1, name: 'V-Blog Author', value: 'V-Blog Author' },
	{ id: 2, name: 'V-Nation Author', value: 'V-Nation Author' },
	{ id: 3, name: 'V-Tube Author', value: 'V-Tube Author' },
	{ id: 4, name: 'V-Employer', value: 'V-Employer' },
	{ id: 5, name: 'V-Candidate', value: 'V-Candidate' },
	{ id: 6, name: 'V-Instructor', value: 'V-Instructor' },
	{ id: 7, name: 'V-Customer', value: 'V-Customer' },
	{ id: 8, name: 'V-Lancer', value: 'V-Lancer' },
	{ id: 9, name: 'V-Client', value: 'V-Client' },
	{ id: 10, name: 'V-Expert', value: 'V-Expert' },
	{ id: 11, name: 'V-Seller', value: 'V-Seller' },
	{ id: 12, name: 'V-Host', value: 'V-Host' },
	{ id: 13, name: 'V-Organizer', value: 'V-Organizer' },
	{ id: 14, name: 'V-Influencer', value: 'V-Influencer' },
	{ id: 15, name: 'V-Cast Author', value: 'V-Cast Author' },
	{ id: 16, name: 'V-Books Author', value: 'V-Books Author' },
];

export const socialStatusOptions = [
	{ id: 1, name: 'Single', value: 'Single' },
	{ id: 2, name: 'Married', value: 'Married' },
	{ id: 3, name: 'Engaged', value: 'Engaged' },
	{
		id: 4,
		name: 'Not Disclosed',
		value: 'Not Disclosed',
	},
];

export const openingOptions = [
	{ id: 1, name: 'Open Now', value: 'open' },
	{ id: 2, name: 'Closed', value: 'closed' },
];

export const profileTypeOptions = [
	{ id: 1, name: 'Personal Profiles', value: 'personal' },
	{ id: 2, name: 'Entity Profiles', value: 'business' },
];

export const localizedProfileTypeOptions = (t: (s: string) => string) => [
	{ id: 1, name: t('footer.personalProfiles'), value: 'personal' },
	{ id: 2, name: t('footer.entityProfiles'), value: 'business' },
];

export const connectedOptions = [
	{ id: 1, name: 'Connected', value: 'connected', value_two: 'connected' },
	{
		id: 2,
		name: 'Disconnected',
		value: 'not connected',
		value_two: 'not connected',
	}, //Do not change
];

export const localizedConnectedOptions = (t: (s: string) => string) => [
	{ id: 1, name: t('common.tooltips.connected'), value: 'connected', value_two: 'connected' },
	{
		id: 2,
		name: t('common.tooltips.notConnected'),
		value: 'not connected',
		value_two: 'not connected',
	}, //Do not change
];

export const webinarStatusOptions = (t: (s: string) => string) => [
	{ id: 1, name: t('common.labels.coming'), value: 'coming' },
	{ id: 2, name: t('common.labels.happened'), value: 'happened' },
];

export const statusOptions = [
	{ id: 1, name: 'Online', value: 'online', value_two: 'yes', valueBool: 1 },
	{ id: 2, name: 'Offline', value: 'offline', value_two: 'no', valueBool: 0 },
];

export const yesNoOptions = [
	{ id: 1, name: 'Yes', value: true, valueBool: 1 },
	{ id: 2, name: 'No', value: false, valueBool: 0 },
];

export const localizedYesNoOptions = (t: (s: string) => string) => [
	{ id: 1, name: t('common.buttons.yes'), value: true, valueBool: 1 },
	{ id: 2, name: t('common.buttons.no'), value: false, valueBool: 0 },
];

export const openingStatus = [
	{ id: 1, name: 'Open Now', value: 'open' },
	{ id: 2, name: 'Closed', value: 'close' },
];

export const placeOptions = [
	{ id: 1, name: 'All', value: 'all' },
	{ id: 2, name: 'Clinic', value: 'clinic' },
	{ id: 3, name: 'Hospital', value: 'hospital' },
	{ id: 4, name: 'College', value: 'college' },
	{ id: 5, name: 'Farm', value: 'farm' },
	{ id: 6, name: 'Factory', value: 'factory' },
	{ id: 7, name: 'Laboratory', value: 'laboratory' },
	{ id: 8, name: 'Retail Shop', value: 'retail_shop' },
	{ id: 9, name: 'Pharma Company', value: 'pharma_company' },
	{ id: 10, name: 'Zoo', value: 'zoo' },
	{ id: 11, name: 'Other', value: 'other' },
];

export const menuItems = (t: (s: string) => string, counts?: CountsType, totalUnread?: string | number) => {
	return {
		col1: [
			{
				header: t('theStory'),
				links: [
					{
						icon: <StarDefault />,
						name: t('theDream'),
						notifs: '',
						href: '/the-story/the-dream',
						verified: false,
					},
					{
						icon: <StarDefault />,
						name: t('thePeople'),
						notifs: '',
						href: '/the-story/the-people',
						verified: false,
					},
					{
						icon: <StarDefault />,
						name: t('theTimeline'),
						notifs: '',
						href: '/the-story/the-timeline',
						verified: false,
					},
				],
			},

			{
				header: t('theOpportunities'),
				links: [
					{
						icon: <StarDefault />,
						name: t('branding'),
						notifs: '',
						href: '/the-opportunities/branding',
						verified: false,
					},
					{
						icon: <StarDefault />,
						name: t('incomeStreams'),
						notifs: '',
						href: '/the-opportunities/income-streams',
						verified: false,
					},
					{
						icon: <StarDefault />,
						name: t('networking'),
						notifs: '',
						href: '/the-opportunities/networking',
						verified: false,
					},
				],
			},
			{
				header: t('theLegalities'),
				links: [
					{
						icon: <StarDefault />,
						name: t('privacyPolicy'),
						notifs: '',
						href: '/the-legalities/privacy-policy',
						verified: false,
					},
					{
						icon: <StarDefault />,
						name: t('termsAndConditions'),
						notifs: '',
						href: '/the-legalities/terms-and-conditions',
						verified: false,
					},
				],
			},
		],
		col2: [
			{
				header: t('myAccount'),
				links: [
					{
						icon: <Analytics />,
						name: t('myAnalytics'),
						notifs: '',
						href: '/my-account/my-analytics',
						verified: true,
					},
					{
						icon: <Wallet />,
						name: t('myWallet'),
						notifs: '',
						href: '/my-account/my-wallet/funding-sources',
						verified: true,
					},
					{
						icon: <PlanIcon />,
						name: t('myPlan'),
						notifs: '',
						href: '/my-plan',
						verified: true,
					},
					{
						icon: <Credits />,
						name: t('myCredits'),
						notifs: '',
						href: '/my-account/my-credits/summary',
						verified: true,
					},
					{
						icon: <Profiles />,
						name: t('myProfiles'),
						notifs: '',
						href: '/my-account/my-profiles',
						verified: true,
					},
					{
						icon: <Storage />,
						name: t('myStorage'),
						notifs: '',
						href: '/my-account/my-storage',
						verified: true,
					},
					{
						icon: <Badges />,
						name: t('myBadges'),
						notifs: '',
						href: '/my-account/my-badges',
						verified: true,
					},
					{
						icon: <Referrals />,
						name: t('myReferrals'),
						notifs: '',
						href: '/my-account/my-referrals',
						verified: true,
					},
				],
			},

			{
				header: t('myOffice'),
				links: [
					{
						icon: <Builders />,
						name: t('assetBuilders'),
						notifs: '',
						href: '/my-office/builders',
						verified: true,
					},
					{
						icon: <Notifications />,
						name: t('notifications'),
						notifs: counts?.notifications,
						href: '/my-office/notifications',
						verified: true,
					},
					{
						icon: <Chat />,
						name: t('chat'),
						notifs: totalUnread,
						href: '/my-office/new-chat',
						verified: true,
					},
					{
						icon: <Planner />,
						name: t('planner'),
						notifs: '',
						href: '/my-office/planner',
						verified: true,
					},
					{
						icon: <WishList />,
						name: t('wishlist'),
						notifs: counts?.wishlists,
						href: '/my-office/wishlist',
						verified: true,
					},
					{
						icon: <ShoppingCart />,
						name: 'shoppingCart',
						notifs: counts?.cart,
						href: '/my-office/shopping-cart',
						verified: true,
					},
					{
						icon: <Contracts />,
						name: t('contracts'),
						notifs: '',
						href: '/my-office/contracts',
						verified: true,
					},
					{
						icon: <Orders />,
						name: t('orders'),
						notifs: '',
						href: '/my-office/orders',
						verified: true,
					},
					{
						icon: <AdsManager />,
						name: t('adsManager'),
						notifs: '',
						href: '/my-office/ads-manager',
						verified: true,
					},
				],
			},
		],
	};
};

export const menuItems2 = (t: (s: string) => string) => [
	[
		{
			header: t('vGuide'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-guide',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-guide/my-profile/general-info',
					verified: true,
				},
			],
		},
		{
			header: t('vLance'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-lance',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-lance/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-lance/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vExperts'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-experts',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-experts/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-experts/my-assets',
					verified: true,
				},
			],
		},
	],
	[
		{
			header: t('vBlog'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-blog',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-blog/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-blog/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vCollege'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-college',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-college/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-college/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vBuzz'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-buzz',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-buzz/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-buzz/my-assets',
					verified: true,
				},
			],
		},
	],
	[
		{
			header: t('vNation'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-nation/main-page',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-nation/my-profile',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-nation/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vMarket'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-mart',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-mart/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-mart/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vCast'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-cast',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-cast/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-cast/my-assets',
					verified: true,
				},
			],
		},
	],
	[
		{
			header: t('vTube'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-tube/main-page',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-tube/my-profile',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-tube/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vEvents'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-events',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-events/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-events/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vBooks'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-books',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-books/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-books/my-assets',
					verified: true,
				},
			],
		},
	],
	[
		{
			header: t('vJobs'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-job',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-job/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-job/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vWebinars'),
			links: [
				{
					icon: <MainPage />,
					name: t('mainPage'),
					notifs: '',
					href: '/v-webinars',
					verified: true,
				},
				{
					icon: <Profile />,
					name: t('myProfile'),
					notifs: '',
					href: '/v-webinars/my-profile/general-info',
					verified: true,
				},
				{
					icon: <Assets />,
					name: t('myAssets'),
					notifs: '',
					href: '/v-webinars/my-assets',
					verified: true,
				},
			],
		},
		{
			header: t('vSupport'),
			links: [
				{
					icon: <StarDefault />,
					name: t('helpDesk'),
					notifs: '',
					href: '',
					verified: true,
				},
				{
					icon: <StarDefault />,
					name: t('knowledgeBase'),
					notifs: '',
					href: '',
					verified: true,
				},
			],
		},
	],
];

export const SocialMedia = [
	{
		name: 'Facebook',
		icon: <Facebook />,
		href: 'https://www.facebook.com/',
	},
	{
		name: 'Instagram',
		icon: <Instagram />,
		href: 'https://www.instagram.com/',
	},
	{ name: 'X', icon: <X />, href: 'https://www.x.com/', verified: true },
	{
		name: 'Linkedin',
		icon: <Linkedin />,
		href: 'https://www.linkedin.com/',
	},
	{
		name: 'Youtube',
		icon: <Youtube />,
		href: 'https://www.youtube.com/',
	},
	{
		name: 'TikTok',
		icon: <TikTok />,
		href: 'https://www.tiktok.com/',
	},
	// {
	// 	name: 'Snapchat',
	// 	icon: <Snapchat />,
	// 	href: 'https://www.snapchat.com/',
	// },
	{
		name: 'Pinterest',
		icon: <Pinterest />,
		href: 'https://www.pinterest.com/',
	},
];

export const V_list = (t: (s: string) => string) => [
	{ name: t('vGuide'), href: '/v-guide', verified: true },
	{ name: t('vBlog'), href: '/v-blog', verified: true },
	{ name: t('vNation'), href: '/v-nation', verified: true },
	{ name: t('vTube'), href: '/v-tube/main-page', verified: true },
	{ name: t('vJobs'), href: '/v-job', verified: true },
	{ name: t('vLance'), href: '/v-lance', verified: true },
	{ name: t('vCollege'), href: '/v-college', verified: true },
	{ name: t('vMarket'), href: '/v-mart', verified: true },
	{ name: t('vEvents'), href: '/v-events', verified: true },
	{ name: t('vWebinars'), href: '/v-webinars', verified: true },
	{ name: t('vExperts'), href: '/v-experts', verified: true },
	{ name: t('vBuzz'), href: '/v-buzz', verified: true },
	{ name: t('vCast'), href: '/v-cast', verified: true },
	{ name: t('vBooks'), href: '/v-books', verified: true },
	{ name: t('vSupport'), href: '#', verified: true },
];

export const VV_Assets = (t: (s: string) => string) => [
	{ icon: <Blog />, name: t('blogPosts'), href: '/vv-search/posts', verified: true },
	{ icon: <SocialPage />, name: t('socialPage'), href: '/vv-search/social-page', verified: true },
	{ icon: <SocialGroup />, name: t('socialGroups'), href: '/vv-search/social-group', verified: true },
	{ icon: <Channel />, name: t('channels'), href: '/vv-search/channels', verified: true },
	{ icon: <Videos />, name: t('videos'), href: '/vv-search/videos', verified: true },
	{ icon: <Shorts />, name: t('shorts'), href: '/vv-search/shorts', verified: true },
	{ icon: <Jobs />, name: t('jobs'), href: '/vv-search/jobs', verified: true },
	{ icon: <Services />, name: t('services'), href: '/vv-search/services', verified: true },
	{ icon: <Project stroke="white" />, name: t('projects'), href: '/vv-search/projects', verified: true },
	{ icon: <Courses />, name: t('courses'), href: '/vv-search/courses', verified: true },
	{ icon: <Stores />, name: t('stores'), href: '/vv-search/stores', verified: true },
	{ icon: <PhysicalProd />, name: t('physicalProducts'), href: '/vv-search/physical-products', verified: true },
	{
		icon: <DigitalProd width={16} height={16} />,
		name: t('digitalProducts'),
		href: '/vv-search/digital-products',
		verified: true,
	},
	{ icon: <Events />, name: t('events'), href: '/vv-search/v-events', verified: true },
	{ icon: <Webinars />, name: t('webinars'), href: '/vv-search/webinars', verified: true },
	{ icon: <Consultation />, name: t('consultations'), href: '/vv-search/consultations', verified: true },
	{ icon: <Sponsorship />, name: t('sponsorships'), href: '/vv-search/sponsorships', verified: true },
	{ icon: <Podcasts />, name: t('podcasts'), href: '/vv-search/podcasts', verified: true },
	{ icon: <Episodes />, name: t('episodes'), href: '/vv-search/episodes', verified: true },
	{ icon: <Books />, name: t('books'), href: '/vv-search/books', verified: true },
	// {
	//   icon: <Shops />,
	//   name: 'Shops',
	//   href: '#',
	//   verified: true,
	// },
	// {
	//   icon: <PODS />,
	//   name: 'PODs',
	//   href: '#',
	//   verified: true,
	// },
];

export const VV_Profiles = (t: (s: string) => string) => [
	{
		icon: <PersonalProfile />,
		name: t('personalProfiles'),
		href: '/vv-search/personal-profile',
		verified: true,
	},
	{
		icon: <EntityProfile />,
		name: t('entityProfiles'),
		href: '/vv-search/entity-profile',
		verified: true,
	},
	{
		icon: <VBlogAuthor />,
		name: t('vBlogAuthors'),
		href: '/vv-search/v-blog-author',
		verified: true,
	},
	{
		icon: <VNationAuthor />,
		name: t('vNationAuthors'),
		href: '/vv-search/v-nation-author',
		verified: true,
	},
	{
		icon: <VTubeAuthor />,
		name: t('vTubeAuthors'),
		href: '/vv-search/v-tube-author',
		verified: true,
	},
	{
		icon: <VCandidate />,
		name: t('vCandidates'),
		href: '/vv-search/v-candidate',
		verified: true,
	},
	{
		icon: <VEmployer />,
		name: t('vEmployers'),
		href: '/vv-search/v-employer',
		verified: true,
	},
	{
		icon: <VLancer />,
		name: t('vLancers'),
		href: '/vv-search/v-lancers',
		verified: true,
	},
	{
		icon: <VClient />,
		name: t('vClients'),
		href: '/vv-search/v-clients',
		verified: true,
	},
	{
		icon: <VInstructor />,
		name: t('vInstructors'),
		href: '/vv-search/v-instructors',
		verified: true,
	},
	{
		icon: <VSeller />,
		name: t('vSellers'),
		href: '/vv-search/v-sellers',
		verified: true,
	},
	{
		icon: <VOrganizer />,
		name: t('vOrganizers'),
		href: '/vv-search/organizers',
		verified: true,
	},
	{
		icon: <VHosts />,
		name: t('vHosts'),
		href: '/vv-search/v-hosts',
		verified: true,
	},
	{
		icon: <VExpert />,
		name: t('vExperts'),
		href: '/vv-search/v-experts',
		verified: true,
	},
	{
		icon: <VInfluencer />,
		name: t('vInfluencers'),
		href: '/vv-search/v-influencers',
		verified: true,
	},
	{
		icon: <VCastAuthor />,
		name: t('vCastAuthors'),
		href: '/vv-search/v-podcasts-author',
		verified: true,
	},
	{
		icon: <VBooksAuthor />,
		name: t('vBooksAuthors'),
		href: '/vv-search/v-author',
		verified: true,
	},
];

export const eductionLevels = [
	{
		id: 2,
		name: 'High School Degree Holders',
		value: 'High School Degree Holders',
	},
	{
		id: 3,
		name: 'University Degree Holders',
		value: 'University Degree Holders',
	},
	{ id: 4, name: 'MBA Degree Holders', value: 'MBA Degree Holders' },
	{ id: 5, name: 'PhD Degree Holders', value: 'PhD Degree Holders' },
	{ id: 6, name: 'Any Degree Holders', value: 'Any Degree Holders' },
];

export const gender = [
	{ id: 0, name: 'All', value: 'all' },
	{ id: 1, name: 'Male', value: 'male' },
	{ id: 2, name: 'Female', value: 'female' },
	// { id: 3, name: 'Other', value: 'other' },
];

export const employeeStatus = [
	{ id: 1, name: 'Employed', value: 'Employed' },
	{ id: 2, name: 'Unemployed', value: 'Unemployed' },
	{ id: 3, name: 'Self Employed', value: 'Self Employed' },
	{ id: 3, name: 'Career Break', value: 'Career Break' },
];

export const horoscopes = [
	{
		id: 1,
		value: 'Aries',
		name: '♈ Aries: March 21–April 19',
	},
	{
		id: 2,
		value: 'Taurus',
		name: '♉ Taurus: April 20–May 20',
	},
	{
		id: 3,
		value: 'Gemini',
		name: '♊ Gemini: May 21–June 21',
	},
	{
		id: 4,
		value: 'Cancer',
		name: '♋ Cancer: June 22–July 22',
	},
	{
		id: 5,
		value: 'Leo',
		name: '♌ Leo: July 23–Aug. 22',
	},
	{
		id: 6,
		value: 'Virgo',
		name: '♍ Virgo: Aug. 23–Sep. 22',
	},
	{
		id: 7,
		value: 'Libra',
		name: '♎ Libra: Sep. 23–Oct. 23',
	},
	{
		id: 8,
		value: 'Scorpio',
		name: '♏ Scorpio: Oct. 24–Nov. 21',
	},
	{
		id: 9,
		value: 'Sagittarius',
		name: '♐ Sagittarius: Nov. 22–Dec. 21',
	},
	{
		id: 10,
		value: 'Capricorn',
		name: '♑ Capricorn: Dec. 22–Jan. 19',
	},
	{
		id: 11,
		value: 'Aquarius',
		name: '♒ Aquarius: Jan. 20–Feb. 18',
	},
	{
		id: 12,
		value: 'Pisces',
		name: '♓ Pisces: Feb. 19–Mar. 20',
	},
];

export const religions = [
	{ id: 1, name: 'Muslim', value: 'Muslim' },
	{ id: 2, name: 'Christian', value: 'Christian' },
	{
		id: 3,
		name: 'Not Disclosed',
		value: 'Not Disclosed',
	},
];

export const grades = [
	{
		id: 1,
		name: 'Excellent',
		value: 'Excellent',
	},
	{
		id: 2,
		name: 'Very Good',
		value: 'Very Good',
	},
	{
		id: 3,
		name: 'Good',
		value: 'Good',
	},
	{
		id: 3,
		name: 'Satisfactory',
		value: 'Satisfactory',
	},
];

export const paymentOptions = (t?: (s: string) => string) => {
	return [
		{ id: 1, name: t ? t('common.subscription.free') : 'Free', value: 'free' },
		{ id: 2, name: t ? t('common.buttons.paid') : 'Paid', value: 'paid' },
	];
};

export const priceOptions = (t?: (s: string) => string) => {
	return [
		{ id: 1, name: t ? t('vEvent.common.vEventFiltering.regular') : 'Regular Price', value: 'regular' },
		{ id: 2, name: t ? t('vEvent.common.vEventFiltering.disCounted') : 'Discounted Price', value: 'discounted' },
	];
};

export const offerOptions = [
	{ id: 1, name: 'BOGOF', value: 'bogof' },
	{ id: 2, name: '3FOR2', value: '3for2' },
	{ id: 3, name: '4FOR2', value: '4for2' },
	{ id: 4, name: 'FreePlusShipping', value: 'FreePlusShipping' },
];

export const MyAssetsGroupsSortingOptions = [
	{ name: 'By No Of Likes', value: 'likes' },
	{ name: 'By No Of Members', value: 'members' },
	{ name: 'By No Of Comments', value: 'comments' },
	{ name: 'By No Of Shares', value: 'shares' },
	{ name: 'By Rating', value: 'rating' },
	{ name: 'By No Of Reviews', value: 'reviews' },
	{ name: 'By Date', value: 'date' },
];

export const VVSearchSocialGroupsPostOrderBy = [
	{ name: 'Published Date', value: 'created_at' },
	{ name: 'By No Of Comments', value: 'comments' },
	{ name: 'By No Of Likes', value: 'likes' },
	{ name: 'By No Of Shares', value: 'shares' },
	{ name: 'By No Of Views', value: 'views' },
];

export const VVSearchSocialPagesSortingOptions = [
	{ name: 'By No Of Likes', value: 'likes' },
	{ name: 'By No Of Followers', value: 'followers' },
	{ name: 'By No Of Comments', value: 'comments' },
	{ name: 'By No Of Shares', value: 'shares' },
	{ name: 'By Rating', value: 'rating' },
	{ name: 'By No Of Reviews', value: 'reviews' },
	{ name: 'By Date', value: 'date' },
];

export const assetTableHeaders: Record<AssetType | string, string[]> = {
	[AssetType.PERSONAL]: [
		'Actions',
		'Profile ID',
		'Referral Code',
		'Image',
		'Official Name',
		'Nationality',
		'Date Of Birth',
		'Gender',
		'Nationality ID No.',
		'Nationality ID File',
		'Referring Member Code',
		'Public Name',
		'Location Country',
		'Location City',
		'Local Time',
		'Join Date',
		'Last Seen',
		'Mobile No.',
		'WhatsApp No.',
		'Email Address',
		'Website',
		'Plan Type',
		'V1K',
		'Profile Likes',
		'Profile Shares',
		'Profile Endorsements',
		'No. of Assets',
		'Sales',
		'Earnings',
		'Investments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Connections',
		'Followers',
		'Members',
		'Subscribers',
		'Customers',
		'Hiring',
		'Open Vacancies',
		'Open To Work',
		'Social Status',
		'Military Status',
		'Employment Status',
		'Yrs. Of Experience',
		'Expected Salary',
		'Notice Period',
		'Own A Car',
		'Willing to Relocate',
		'Weekly Hours Availability',
		'Hourly Rate',
		'Success Rate',
		'Hiring Rate',
		'Open Projects',
	],
	[AssetType.ENTITY]: [
		'Actions',
		'Profile ID',
		'Referral Code',
		'Image',
		'Official Name',
		'Entity Type',
		'Establishment Date',
		'Location Country',
		'Location City',
		'License No.',
		'License File',
		'Referring Member Code',
		'Public Name',
		'Local Time',
		'Join Date',
		'Last Seen',
		'Address',
		'Total Employees',
		'Inside VV',
		'Outside VV',
		'Mobile No.',
		'WhatsApp No.',
		'Email Address',
		'Website',
		'Plan Type',
		'V1K',
		'Profile Likes',
		'Profile Shares',
		'Profile Endorsements',
		'No. of Assets',
		'Sales',
		'Earnings',
		'Investments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Connections',
		'Followers',
		'Members',
		'Subscribers',
		'Customers',
		'Hiring',
		'Open Vacancies',
		'Weekly Hours Availability',
		'Hourly Rate',
		'Success Rate',
		'Hiring Rate',
		'Open Projects',
	],
	[AssetType.BLOG_POST]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.SOCIAL_POST]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
	],
	[AssetType.HIGHLIGHT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
	],
	[AssetType.REEL]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
	],
	[AssetType.STORY]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
	],
	[AssetType.SOCIAL_PAGE]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Social Posts',
		'Followers',
		'Rating',
		'Reviews',
		'Shares',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.SOCIAL_GROUP]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Social Posts',
		'Members',
		'Rating',
		'Reviews',
		'Shares',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.CHANNEL]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Videos',
		'Subscribers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.VIDEO]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.SHORT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
	],
	[AssetType.JOB]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Hiring',
		'Applying Deadline',
		'Department',
		'Role Level',
		'Salary',
		'Payment Cycle',
		'Contract Type',
		'Work Type',
		'Applicants',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.SERVICE]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Starting Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.PROJECT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Hiring',
		'Applying Deadline',
		'Budget Type',
		'Budget Value',
		'Preferred Rate',
		'Delivery Time',
		'Work Type',
		'Applicants',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.COURSE]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.STORE]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Products',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.PHYSICAL_PRODUCT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.DIGITAL_PRODUCT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.EVENT]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Coming',
		'Happening',
		'Happened',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.WEBINAR]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Coming',
		'Happening',
		'Happened',
		'Live',
		'Hybrid',
		'Automated',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.CONSULTATION]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.SPONSORSHIP]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Starting Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.PODCAST]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.EPISODE]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.HARDCOVER_BOOK]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
	[AssetType.E_BOOK]: [
		'Actions',
		'Asset ID',
		'Asset Owner',
		'Publishing Date',
		'Last Updated',
		'Price',
		'Sales',
		'Orders',
		'Customers',
		'Views',
		'Comments',
		'Reactions',
		'Shares',
		'Rating',
		'Reviews',
		'Featured',
		'VV Exclusive',
	],
};
export const OrderBoughtTableFields = (t: (key: string) => string) => [
	{ label: t('myOffice.orders.orderTableField.actions'), key: 'actions' },
	{ label: t('myOffice.orders.orderTableField.code'), key: 'code', sortable: true },
	{ label: t('myOffice.orders.orderTableField.date'), key: 'date', sortable: true },
	{ label: t('myOffice.orders.orderTableField.totalNoOfItems'), key: 'total_no_of_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfPaidItems'), key: 'no_of_paid_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfFreeItems'), key: 'no_of_free_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfDigitalItems'), key: 'no_of_digital_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfPhysicalItems'), key: 'no_of_physical_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfInProgressItems'), key: 'no_of_in_progress_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfOnTheWayItems'), key: 'no_of_on_the_way_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfDeliveredItems'), key: 'no_of_delivered_items', sortable: true },
	{ label: t('myOffice.common.refundedItems'), key: 'no_of_returned_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.grossValue'), key: 'gross_value', sortable: true },
	{ label: t('myOffice.shoppingCart.discount'), key: 'discount', sortable: true },
	{ label: t('myOffice.orders.orderTableField.discountValue'), key: 'discount_value', sortable: true },
	{ label: t('myOffice.shoppingCart.shippingFees'), key: 'shipping_fees', sortable: true },
	{ label: t('myOffice.orders.orderTableField.subTotalValue'), key: 'sub_total_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vatPercentage'), key: 'vat_percentage', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vatValue'), key: 'vat_value', sortable: true },
	{ label: t('myOffice.common.vvFeesPercent'), key: 'shipping_fees', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vvFeesValue'), key: 'vv_fees_value', sortable: true },
	{ label: t('myOffice.common.otherFeesPercent'), key: 'other_tax_percentage', sortable: true },
	{ label: t('myOffice.common.otherFeesValue'), key: 'other_tax_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.netPaidValue'), key: 'net_paid_value', sortable: true },
];

export const OrderSoldTableFields = (t: (key: string) => string) => [
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.actions'), key: 'actions' },
	{ label: t('myOffice.orders.orderTableField.code'), key: 'code', sortable: true },
	{ label: t('admin.common.date'), key: 'date', sortable: true },
	{ label: t('myOffice.orders.orderTableField.buyer'), key: 'buyer', sortable: true },
	{ label: t('myOffice.orders.orderTableField.totalNoOfItems'), key: 'total_no_of_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.totalNoOfItems'), key: 'no_of_paid_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfFreeItems'), key: 'no_of_free_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfDigitalItems'), key: 'no_of_digital_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfPhysicalItems'), key: 'no_of_physical_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.grossValue'), key: 'gross_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.discountValue'), key: 'discount_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.shippingFees'), key: 'shipping_fees', sortable: true },
	{ label: t('myOffice.orders.orderTableField.subTotalValue'), key: 'sub_total_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.netPaidValue'), key: 'net_paid_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vvFeesValue'), key: 'vv_fees_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.netReceivedValue'), key: 'net_received_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vatValue'), key: 'vat_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.vatPercentage'), key: 'vat_percentage', sortable: true },
	{ label: t('myOffice.orders.orderTableField.otherTaxValue'), key: 'other_tax_value', sortable: true },
	{ label: t('myOffice.orders.orderTableField.otherTaxPercentage'), key: 'other_tax_percentage', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfInProgressItems'), key: 'no_of_in_progress_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfOnTheWayItems'), key: 'no_of_on_the_way_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfDeliveredItems'), key: 'no_of_delivered_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfCancelledItems'), key: 'no_of_cancelled_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.noOfReturnedItems'), key: 'no_of_returned_items', sortable: true },
	{ label: t('myOffice.orders.orderTableField.status'), key: 'status', sortable: true },
];

export const CreditsGuideTableFields = (t: (s: string) => string) => [
	{ label: t('myAccount.myCredits.category'), key: 'category' },
	{ label: t('myAccount.myCredits.doneBy'), key: 'done_by' },
	{ label: t('myAccount.myCredits.actionsDescription'), key: 'description' },
	{ label: t('myAccount.myCredits.actionCredits'), key: 'signature' },
];

export const PETabs = [
	{ label: 'Plans', value: 'plans' },
	{ label: 'Credits Guide', value: 'credits' },
	{ label: 'Badges Guide', value: 'badges' },
	{ label: 'Ads Guide', value: 'ads' },
	{ label: 'Privacy Policy', value: 'privacy' },
	{ label: 'Terms & Conditions', value: 'terms' },
	{ label: 'Support', value: 'support' },
];

export const headerAdminPanelPersonalProfiels = [
	{ header: 'Actions' },
	{ header: 'Profile ID' },
	{ header: 'Referral Code' },
	{ header: 'Image' },
	{ header: 'Official Name' },
	{ header: 'Nationality' },
	{ header: 'Date Of Birth' },
	{ header: 'Gender' },
	{ header: 'Nationality ID No.' },
	{ header: 'Nationality ID File' },
	{ header: 'Referring Member Code' },
	{ header: 'Public Name' },
	{ header: 'Location Country' },
	{ header: 'Location City' },
	{ header: 'Local Time' },
	{ header: 'Join Date' },
	{ header: 'Last Seen' },
	{ header: 'Mobile No.' },
	{ header: 'WhatsApp No.' },
	{ header: 'Email Address' },
	{ header: 'Website' },
	{ header: 'Plane Type' },
	{ header: 'V1K' },
	{ header: 'Profile Likes' },
	{ header: 'Profile Shares' },
	{ header: 'Profile Endorsement' },
	{ header: 'No. of Assets' },
	{ header: 'Sales' },
	{ header: 'Earnings' },
	{ header: 'Investments' },
	{ header: 'Reactions' },
	{ header: 'Shares' },
	{ header: 'Rating' },
	{ header: 'Reviews' },
	{ header: 'Connections' },
	{ header: 'Followers' },
	{ header: 'Members' },
	{ header: 'Subscriber' },
	{ header: 'Customers' },
	{ header: 'Hiring' },
	{ header: 'Open Vacancies' },
	{ header: 'Open To Work' },
	{ header: 'Social Status' },
	{ header: 'Military Status' },
	{ header: 'Years of Experience' },
	{ header: 'Expected Salary' },
	{ header: 'Notice Paid' },
	{ header: 'Willing to Relocate' },
	{ header: 'Weekly Hours Availability' },
	{ header: 'Hourly Rate' },
	{ header: 'Success Rate' },
	{ header: 'Hiring Rate' },
	{ header: 'Open Project' },
];

export const headersAdminPanelEntityProfiels = [
	{ header: 'Actions' },
	{ header: 'Profile ID' },
	{ header: 'Referral Code' },
	{ header: 'Image' },
	{ header: 'Official Name' },
	{ header: 'Entity Type' },
	{ header: 'Establishment Date' },
	{ header: 'Location Country' },
	{ header: 'Location City' },
	{ header: 'License No.' },
	{ header: 'License File' },
	{ header: 'Referring Member Code' },
	{ header: 'Public Name' },
	{ header: 'Local Time' },
	{ header: 'Join Date' },
	{ header: 'Last Seen' },
	{ header: 'Address' },
	{ header: 'Total Employes' },
	{ header: 'Outside VV' },
	{ header: 'Mobile No.' },
	{ header: 'WhatsApp No.' },
	{ header: 'Email Address' },
	{ header: 'Website' },
	{ header: 'Plane Type' },
	{ header: 'V1K' },
	{ header: 'Profile Likes' },
	{ header: 'Profile Shares' },
	{ header: 'Profile Endorsement' },
	{ header: 'No. of Assets' },
	{ header: 'Sales' },
	{ header: 'Earnings' },
	{ header: 'Investments' },
	{ header: 'Reactions' },
	{ header: 'Shares' },
	{ header: 'Rating' },
	{ header: 'Reviews' },
	{ header: 'Connections' },
	{ header: 'Followers' },
	{ header: 'Members' },
	{ header: 'Subscriber' },
	{ header: 'Customers' },
	{ header: 'Hiring' },
	{ header: 'Open Vacancies' },
	{ header: 'Weekly Hours Availability' },
	{ header: 'Hourly Rate' },
	{ header: 'Success Rate' },
	{ header: 'Hiring Rate' },
	{ header: 'Open Project' },
];

export const headersAdminPanelAssets = [
	'Actions',
	'Asset ID',
	'Asset Owner',
	'Publishing Date',
	'Last Updated',
	'Views',
	'Comments',
	'Reactions',
	'Shares',
];

export const dropDownOptionsInAdminAssets = (t: (s: string) => string) => [
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.blogPosts'), value: AssetType.BLOG_POST },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.socialPosts'), value: AssetType.SOCIAL_POST },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.highlights'), value: AssetType.HIGHLIGHT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.reels'), value: AssetType.REEL },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.stories'), value: AssetType.STORY },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.socialPages'), value: AssetType.SOCIAL_PAGE },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.socialGroups'), value: AssetType.SOCIAL_GROUP },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.channels'), value: AssetType.CHANNEL },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.videos'), value: AssetType.VIDEO },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.shorts'), value: AssetType.SHORT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.jobs'), value: AssetType.JOB },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.services'), value: AssetType.SERVICE },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.projects'), value: AssetType.PROJECT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.courses'), value: AssetType.COURSE },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.store'), value: AssetType.STORE },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.physicalProducts'), value: AssetType.PHYSICAL_PRODUCT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.digitalProducts'), value: AssetType.DIGITAL_PRODUCT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.events'), value: AssetType.EVENT },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.webinars'), value: AssetType.WEBINAR },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.consultations'), value: AssetType.CONSULTATION },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.sponsorships'), value: AssetType.SPONSORSHIP },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.podcasts'), value: AssetType.PODCAST },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.episodes'), value: AssetType.EPISODE },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.hardcoverBooks'), value: AssetType.HARDCOVER_BOOK },
	{ label: t('admin.assets.dropDownOptionsInAdminAssets.eBooks'), value: AssetType.E_BOOK },
];
export const adminTabsMemberPage = (tCommon: (s: string) => string) => [
	{ label: tCommon('tabs.personalProfiles'), value: AssetType.PERSONAL },
	{ label: tCommon('tabs.entityProfiles'), value: AssetType.ENTITY },
];
export const adminTabsReqoustsPage = [
	{ label: 'Verification Requests', value: 'Verification Requests' },
	{ label: 'Withdraw Funds Requests', value: 'Withdraw Funds Requests' },
	{ label: 'Credits Redemption Requests', value: 'Credits Redemption Requests' },
	{ label: 'Ads Renting Requests', value: 'Ads Renting Requests' },
];
export const adminTabsFE_pagesPage = [
	{ label: 'Plans', value: 'Plans' },
	{ label: 'Credits Guide', value: 'Credits Guide' },
	{ label: 'Badges Guide', value: 'Badges Guide' },
	{ label: 'Ads Guide', value: 'Ads Guide' },
	{ label: 'Privacy Policy', value: 'Privacy Policy' },
	{ label: 'Terms & Conditions', value: 'Terms & Conditions' },
];

export const ReportsTableTabs = (t: (s: string) => string) => [
	{
		key: 'users',
		title: t('userPanel.admin.common.reportedProfiles'),
	},
	{
		key: 'assets',
		title: t('userPanel.admin.common.reportedAssets'),
	},
	{
		key: 'interactions',
		title: t('userPanel.admin.common.reportedEngagements'),
	},
];

export const AdminReportsProfilesTableColumns = (t: (s: string) => string) => [
	{ key: 'actions', label: t('userPanel.admin.common.MemberTableHeaders.actions'), sortable: false },
	{ key: 'profile_id', label: t('userPanel.admin.common.reportedProfile'), sortable: true },
	{ key: 'profile_type', label: t('userPanel.admin.common.reportedProfileType'), sortable: true },
	{ key: 'official_name', label: t('userPanel.admin.common.reportedProfileOfficialName'), sortable: true },
	{ key: 'public_name', label: t('userPanel.admin.common.reportedProfilePublicName'), sortable: true },
	{ key: 'user', label: t('userPanel.admin.common.reportedBy'), sortable: true },
	{ key: 'date', label: t('myOffice.orders.orderTableField.code'), sortable: true },
];

export const AdminReportsAssetsTableColumns = (t: (s: string) => string) => [
	{ key: 'actions', label: t('userPanel.admin.common.MemberTableHeaders.actions'), sortable: false },
	{ key: 'asset_id', label: t('userPanel.admin.common.reportedAsset'), sortable: true },
	{ key: 'asset_type', label: t('userPanel.admin.common.reportedAssetType'), sortable: true },
	{ key: 'asset_title', label: t('userPanel.admin.common.reportedAssetTitle'), sortable: true },
	{ key: 'user', label: t('userPanel.admin.common.reportedBy'), sortable: true },
	{ key: 'date', label: t('myOffice.orders.orderTableField.code'), sortable: true },
];

export const AdminReportsEngagementsTableColumns = (t: (s: string) => string) => [
	{ key: 'actions', label: t('userPanel.admin.common.MemberTableHeaders.actions'), sortable: false },
	{ key: 'profile_id', label: t('userPanel.admin.common.reportedProfile'), sortable: true },
	{ key: 'user', label: t('userPanel.admin.common.reportedBy'), sortable: true },
	{ key: 'date', label: t('myOffice.orders.orderTableField.code'), sortable: true },
];

export const myStorageSummaryFields = (t: (s: string) => string) => [
	{ label: t('myAccount.myCredits.category'), key: 'type', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields.totalNo'), key: 'total', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.sizeMB'), key: 'size', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields.usage'), key: 'used', sortable: true },
];

export const myStorageSummaryFields2 = (t: (s: string) => string) => [
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.actions'), key: 'actions' },
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.name'),
		key: 'file_name',
		sortKey: 'name',
		sortable: true,
	},
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields.type'),
		key: 'file_type',
		sortKey: 'type',
		sortable: true,
	},
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.sizeMB'),
		key: 'file_size',
		sortKey: 'size',
		sortable: true,
	},
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.lastUsed'), key: 'updated_at', sortable: true },
];

export const myStorageFolderFields = (t: (s: string) => string) => [
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.actions'), key: 'actions' },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.name'), key: 'folder_name', sortable: true },
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.parentFolder'),
		key: 'parent_folder_name',
		sortable: true,
	},
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.nestedFolders'),
		key: 'nested_folders_count',
		sortable: true,
	},
	{ label: t('myAccount.common.files'), key: 'files_count', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields.size'), key: 'size', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.creationDate'), key: 'created_at', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.lastUsed'), key: 'updated_at', sortable: true },
];

export const myStorageFolderLocationFields = (t: (s: string) => string) => [
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.actions'), key: 'actions' },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.name'), key: 'folder_name', sortable: true },
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.parentFolder'),
		key: 'parent_folder_name',
		sortable: true,
	},
	{
		label: t('myAccount.myStorage.summary.myStorageSummaryFields2.nestedFolders'),
		key: 'nested_folders_count',
		sortable: true,
	},
	{ label: t('myAccount.common.files'), key: 'files_count', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields.size'), key: 'size', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.creationDate'), key: 'created_at', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.lastUsed'), key: 'updated_at', sortable: true },
];

export const myStorageImagesFields = (t: (s: string) => string) => [
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.actions'), key: 'actions' },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.name'), key: 'name', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields.type'), key: 'type', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.sizeMB'), key: 'size', sortable: true },
	{ label: t('myAccount.myStorage.summary.myStorageSummaryFields2.uploadDate'), key: 'created_at', sortable: true },
];

export enum TabsOption {
	V_LANCER = 'v-lancer',
	V_CLIENT = 'v-client',
}

export const dummy_data = [
	{
		id: 1,
		image_url: '/images/squareImage.jpeg',
		title: '<This Will be Project Name & It Can be Over 2 Lines based on your Needs >',
		order_id: '#ORDPRJ-123',
		v_lancer: '<V-Lancer Name>',
		v_client: '<V-Client Name>',
		budget_type: 'Fixed',
		budget_value: '500',
		delivery_date: '15-Sep-2023 05:45 PM',
		contract_status: 'In Progress',
	},
	{
		id: 2,
		image_url: '/images/squareImage.jpeg',
		title: '<This Will be Project Name & It Can be Over 2 Lines based on your Needs >',
		order_id: '#ORDPRJ-123',
		v_lancer: '<V-Lancer Name>',
		v_client: '<V-Client Name>',
		budget_type: 'Fixed',
		budget_value: '500',
		delivery_date: '15-Sep-2023 05:45 PM',
		contract_status: 'In Progress',
	},
	{
		id: 3,
		image_url: '/images/squareImage.jpeg',
		title: '<This Will be Project Name & It Can be Over 2 Lines based on your Needs >',
		order_id: '#ORDPRJ-123',
		v_lancer: '<V-Lancer Name>',
		v_client: '<V-Client Name>',
		budget_type: 'Fixed',
		budget_value: '500',
		delivery_date: '15-Sep-2023 05:45 PM',
		contract_status: 'In Progress',
	},
];

export const _privacyOptions = (t: (s: string) => string, tCommon: (s: string) => string) => [
	{
		id: 1,
		name: tCommon('buttons.public'),
		description: t('myOffice.assetBuilder.common.privacyOption.publicDes'),
		icon: <Earth />,
		value: 'public',
	},
	{
		id: 2,
		name: t('myOffice.assetBuilder.common.privacyOption.connections'),
		description: t('myOffice.assetBuilder.common.privacyOption.connectionsDes'),
		icon: <Connections />,
		value: 'connections',
	},
	{
		id: 3,
		name: t('myOffice.assetBuilder.common.privacyOption.selectedProfiles'),
		description: t('myOffice.assetBuilder.common.privacyOption.selectedProfilesDes'),
		icon: <SelectedProfiles />,
		value: 'selected_users',
	},
	{
		id: 4,
		name: t('myOffice.assetBuilder.common.privacyOption.onlyMe'),
		description: t('myOffice.assetBuilder.common.privacyOption.onlyMeDes'),
		icon: <OnlyMe />,
		value: 'only_me',
	},
];
// Array of order status options
export const OrderStatusOptions = (t: (key: string) => string) => [
	{ id: 'pending', name: t('myOffice.orders.orderTableField.pending') },
	{ id: 'processing', name: t('myOffice.orders.orderTableField.processing') },
	{ id: 'on_the_way', name: t('myOffice.orders.orderTableField.onTheWay') },
	{ id: 'delivered', name: t('myOffice.orders.orderTableField.delivered') },
	{ id: 'refunded', name: t('myOffice.orders.orderTableField.refunded') },
	{ id: 'inprogress', name: t('myOffice.orders.orderTableField.inProgress') },
	{ id: 'approved', name: t('myOffice.orders.orderTableField.approved') },
	{ id: 'declined', name: t('myOffice.orders.orderTableField.declined') },
];

export const AdsManagerTableFields = [
	{ label: 'Actions', key: 'actions' },
	{ label: 'Ad Owner', key: 'ad_owner', sortable: true },
	{ label: 'Performance', key: 'performance', sortable: true },
	{ label: 'Ad Status', key: 'ad_status', sortable: true },
	{ label: 'Order ID', key: 'order_iD', sortable: true },
	{ label: 'Ad Spot Id', key: 'ad_spot_id', sortable: true },
	{ label: 'Ad Slot Id', key: 'ad_slot_id', sortable: true },
	{ label: 'Ad Id', key: 'ad_id', sortable: true },
	{ label: 'From', key: 'from', sortable: true },
	{ label: 'To', key: 'to', sortable: true },
	{ label: 'Duration', key: 'duration', sortable: true },
	{ label: 'Value', key: 'value', sortable: true },
	{ label: 'Art Work', key: 'art_work', sortable: true },
	{ label: 'Action Button', key: 'action_button', sortable: true },
	{ label: 'Destination Link', key: 'destination_link', sortable: true },
];

export const transactionPlanOptions = (t: (s: string) => string) => [
	{ value: 'credits_redemption', name: t('myAccount.My Wallet.transactions.transactionPlanOption.creditsRedemption') },
	{
		value: 'affiliate_commission',
		name: t('myAccount.My Wallet.transactions.transactionPlanOption.affiliateCommission'),
	},
	{ value: 'projects', name: t('myAccount.My Wallet.transactions.transactionPlanOption.projects') },
	{ value: 'services', name: t('myAccount.My Wallet.transactions.transactionPlanOption.services') },
	{ value: 'courses', name: t('myAccount.My Wallet.transactions.transactionPlanOption.courses') },
	{ value: 'stores', name: t('myAccount.My Wallet.transactions.transactionPlanOption.stores') },
	{ value: 'events', name: t('myAccount.My Wallet.transactions.transactionPlanOption.events') },
	{ value: 'webinars', name: t('myAccount.My Wallet.transactions.transactionPlanOption.webinars') },
	{ value: 'consultations', name: t('myAccount.My Wallet.transactions.transactionPlanOption.consultations') },
	{ value: 'sponsorships', name: t('myAccount.My Wallet.transactions.transactionPlanOption.sponsorships') },
	{ value: 'podcast', name: t('myAccount.My Wallet.transactions.transactionPlanOption.podcasts') },
	{ value: 'books', name: t('myAccount.My Wallet.transactions.transactionPlanOption.books') },
];
export const transactionExpensePlanOptions = [
	{ value: 'credits_redemption', name: 'Plan Fees' },
	{ value: 'affiliate_commission', name: 'Ads Orders' },
	{ value: 'projects', name: 'Projects Orders' },
	{ value: 'services', name: 'Services Orders' },
	{ value: 'courses', name: 'Courses Orders' },
	{ value: 'stores', name: 'E-Commerce Stores Orders' },
	{ value: 'events', name: 'Events Orders' },
	{ value: 'webinars', name: 'Webinar Orders' },
	{ value: 'consultations', name: 'Consultation Orders' },
	{ value: 'sponsorships', name: 'Sponsorships Orders' },
	{ value: 'podcasts', name: 'Podcasts Orders' },
	{ value: 'books', name: 'Books Orders' },
];
export const transactionFieldObject: { [key: string]: { key: string; label: string }[] } = {
	credits_redemption: [
		{ key: 'order_id', label: 'Request ID' },
		{ key: 'created_at', label: 'Request Date' },
		{ key: 'total_credits', label: 'No. Of Redeemed Credits' },
		{ key: 'discounted_price', label: 'Redemption Value' },
		{ key: 'order_status', label: 'Request Status' },
		{ key: 'net_value', label: 'Net Received Value' },
		{ key: 'order_date', label: 'Transaction Data' },
		{ key: 'amount', label: 'Invoice' },
	],
	affiliate_commission: [
		{ key: 'order_id', label: 'Commission ID' },
		{ key: 'user_id', label: 'Referred Member ID' },
		{ key: 'customer', label: 'Referred Member Name' },
		{ key: 'created_at', label: 'Joining Data' },
		{ key: 'plan', label: 'Referred Member Plan' },
		{ key: 'net_value', label: 'Plan Value' },
		{ key: 'member_status', label: 'Membership Status' },
		{ key: 'commission_percentage', label: 'Commission %' },
		{ key: 'commission', label: 'Commission Value' },
		{ key: 'order_date', label: 'Transaction Data' },
		{ key: 'amount', label: 'Invoice' },
	],
	projects: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'milestone_name', label: 'Milestone Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	services: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	courses: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	stores: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'store_name', label: 'Store Name' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'asset_type', label: 'Asset Type' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'shipping_fees', label: 'Shipping Fees' },
		{ key: 'net_value', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	events: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	webinars: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	consultations: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'order_date', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	sponsorships: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'milestone_name', label: 'Milestone Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'order_date', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	podcasts: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'order_date', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	books: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'asset_type', label: 'Asset Type' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'shipping_fees', label: 'Shipping Fees' },
		{ key: 'order_date', label: 'Net Escrow/Received Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
};

export const getTransactionExpenseFieldObject = (
	t: (key: string) => string
): { [key: string]: { key: string; label: string }[] } => ({
	credits_redemption: [
		{ key: 'order_id', label: t('admin.activeAds.ActiveAdsTableFields.orderId') },
		{ key: 'created_at', label: t('myAccount.common.orderDate') },
		{ key: '', label: t('myAccount.myPlan.heroSection.planName') },
		{ key: 'total', label: t('myAccount.common.orderValue') },
		{ key: 'applied_discount', label: t('myAccount.common.appliedDiscounts') },
		{ key: 'taxes', label: t('myAccount.myPlan.heroSection.taxes') },
		{ key: 'net_value', label: t('myOffice.orders.orderTableField.netPaidValue') },
		{ key: 'order_date', label: t('myAccount.common.transactionDate') },
		{ key: '', label: t('myAccount.common.invoice') },
	],
	affiliate_commission: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid Value' },
		{ key: 'order_date', label: 'Transaction Date' },
	],
	projects: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'milestone_name', label: 'Milestone Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	services: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	courses: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	stores: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'store_name', label: 'Store Name' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'asset_type', label: 'Asset Type' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'shipping_fees', label: 'Shipping Fees' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	events: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	webinars: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	consultations: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	sponsorships: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'milestone_name', label: 'Milestone Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	podcasts: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
	books: [
		{ key: 'order_id', label: 'Order ID' },
		{ key: 'created_at', label: 'Order Date' },
		{ key: 'vendor', label: 'Seller' },
		{ key: 'customer', label: 'Customer' },
		{ key: 'module_enum', label: 'Asset Name' },
		{ key: 'asset_type', label: 'Asset Type' },
		{ key: 'item_price', label: 'Item Price' },
		{ key: 'no_of_paid_items', label: 'No. Of Paid Items' },
		{ key: 'no_of_free_items', label: 'No. Of Free Items' },
		{ key: 'order_status', label: 'Order Status' },
		{ key: 'total', label: 'Order Value' },
		{ key: 'applied_discount', label: 'Applied Discounts' },
		{ key: 'vv_fees', label: 'VV Fees' },
		{ key: 'taxes', label: 'Taxes' },
		{ key: 'shipping_fees', label: 'Shipping Fees' },
		{ key: 'net_value', label: 'Net Paid/Refunded Value' },
		{ key: 'updated_at', label: 'Transaction Data' },
	],
});

export const ActiveAdsTableFields = (t: (s: string) => string) => [
	{
		key: 'actions',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.actions'),
	},
	{
		key: 'ad_Owner',
		label: t('admin.activeAds.ActiveAdsTableFields.adOwner'),
	},
	{
		key: 'performance',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.performance'),
	},
	{
		key: 'status',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.status'),
	},
	{
		key: 'order_id',
		label: t('myAccount.common.orderId'),
	},
	{
		key: 'ad_spot_id',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.adSpotID'),
	},
	{
		key: 'ad_slot_id',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.adSlotID'),
	},
	{
		key: 'id',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.id'),
	},
	{
		key: 'from',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.from'),
	},
	{
		key: 'to',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.to'),
	},
	{
		key: 'duration',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.duration'),
	},
	{
		key: 'cost',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.cost'),
	},
	{
		key: 'artwork',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.artwork'),
	},
	{
		key: 'destionans_link',
		label: t('myOffice.adsManager.tabs.adManagement.tableFields.destionansLink'),
	},
];
export enum SOCIAL_PLATFORMS {
	INSTAGRAM = 'instagram',
	FACEBOOK = 'facebook',
	TWITTER = 'twitter',
	LINKEDIN = 'linkedin',
	TIKTOK = 'tiktok',
	YOUTUBE = 'youtube',
	SNAPCHAT = 'snapchat',
	PINTEREST = 'pinterest',
}

export const filedsAdsRequestsAdmin = (t: (s: string) => string) => [
	{ label: t('admin.common.MemberTableHeaders.actions'), key: 'Action' },
	{ label: t('admin.requests.tableHeaders.requestId'), key: 'request_id' },
	{ label: t('admin.requests.tableHeaders.requestDate'), key: 'request_date' },
	{ label: t('admin.requests.tableHeaders.adSpotId'), key: 'ad_spot' },
	{ label: t('admin.requests.tableHeaders.adSlotId'), key: 'ad_slot' },
	{ label: t('admin.requests.tableHeaders.adId'), key: 'ad_id' },
	{ label: t('admin.requests.tableHeaders.from'), key: 'from' },
	{ label: t('admin.requests.tableHeaders.to'), key: 'to' },
	{ label: t('admin.requests.tableHeaders.duration'), key: 'duration' },
	{ label: t('admin.requests.tableHeaders.value'), key: 'value' },
	{ label: t('admin.requests.tableHeaders.artwork'), key: 'artwork' },
	{ label: t('admin.requests.tableHeaders.actionButton'), key: 'action_button' },
	{ label: t('admin.requests.tableHeaders.destinationLink'), key: 'destination_link' },
];
export const filedsAdsRequestsCreditsAdmin = (t: (s: string) => string) => [
	{ label: t('admin.common.MemberTableHeaders.actions'), key: 'action' },
	{ label: t('admin.requests.tableHeaders.requestId'), key: 'requestid' },
	{ label: t('admin.requests.tableHeaders.requestDate'), key: 'request_date' },
	{ label: t('admin.requests.tableHeaders.requestedBy'), key: 'requested_by' },
	{ label: t('admin.requests.tableHeaders.redeemedCredits'), key: 'redeemed_credits' },
	{ label: t('admin.requests.tableHeaders.redemptionValue'), key: 'redemption_value' },
	{ label: t('admin.requests.tableHeaders.processingFeesPercentage'), key: 'processing_fees' },
	{ label: t('admin.requests.tableHeaders.processingFeesValue'), key: 'processing_fees_value' },
	{ label: t('admin.requests.tableHeaders.netTransferValue'), key: 'net_transfer_value' },
];

export const adminNavLinks = (t: (s: string) => string) => [
	{
		id: 1,
		label: t('admin.analytics.Analytics'),
		href: '/admin/analytics',
	},
	{
		id: 2,
		label: t('admin.members.members'),
		href: '/admin/members',
	},
	{
		id: 3,
		label: t('admin.assets.assets'),
		href: '/admin/assets',
	},
	{
		id: 4,
		label: t('admin.reports.reports'),
		href: '/admin/reports',
	},
	{
		id: 5,
		label: t('admin.tab.requests'),
		href: '/admin/requests',
	},
	{
		id: 6,
		label: t('admin.tab.FEPages'),
		href: '/admin/fe-pages',
	},
	{
		id: 7,
		label: t('admin.tab.activeAds'),
		href: '/admin/active-ads',
	},
	{
		id: 8,
		label: t('admin.tab.Categories'),
		href: '/admin/categories',
	},
	{
		id: 9,
		label: t('admin.tab.settings'),
		href: '/admin/settings',
	},
];

export const MODULE_SHARE_LINKS: { [key: string]: string } = {
	General: '',
	'V-Guide': 'personal-profile/user-profile/{id}/general-info',
	'V-Blog': 'v-blog-author/{id}/general-info',
	'V-Nation': 'v-nation-author/{id}/general-info',
	'V-Tube': 'v-tube-author/{id}/general-info',
	'V-Jobs': 'v-employer/{id}/general-info',
	'V-College': 'v-instructors/{id}/general-info',
	'V-Market': 'v-sellers/{id}/general-info',
	'V-Lance': 'v-lancers/{id}/general-info',
	'V-Events': 'organizers/{id}/general-info',
	'V-Webinars': 'v-hosts/{id}/general-info',
	'V-Experts': 'v-experts/{id}/general-info',
	'V-Buzz': 'v-influencers/{id}/general-info',
	'V-Cast': 'v-podcasts-author/{id}/general-info',
	'V-Book': 'v-author/{id}/general-info',
};

export const LANDLINE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;

export const MOBILE_PHONE_REGEX = /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{3,6}$/;

export const INVALID_STRING = 'Please enter a valid string';

export const INVALID_NUMBER = 'Please enter a valid number';

export const PersonalProfileBuilderInitialValues: FormType = {
	public_name: '',
	residence_country_id: '',
	residence_city_id: '',
	hobbies: [],
	activity_mood: false,
	employment_status: '',
	total_years_of_experience: '',
	notice_period: '',
	address: '',
	driving_license_enum: false,
	social_status_enum: '',
	military_status_enum: '',
	excepected_gross_monthly_salary: '',
	website: '',
	driving_license_issuing_country_id: '',
	is_open_to_work: false,
	no_of_dependents: 0,
	hourly_rate: 0,
	whatsapp: '',
	horoscope: '',
	own_a_car: false,
	religion: '',
	willing_to_relocate: false,

	work_experiences: [],
	educations: [],
	skill_sets: [],
	achievements: [],

	resume: {
		cover_letter: '',
		attachments: [],
		deleted_attachments: [],
	},

	availability: [],
	gap_between_consultations: 0,

	module_array: {
		VGuide: {
			module_enum: 'V-Guide',
			files: [],
			attachments: [],
			custom_tagline: '',
			activity_mood: false,
			profile_message: '',
			social_media_links: [],
			billing_address: '',
			shipping_addresses: [],
			languages: [],
			related_entity_ids: undefined,
			outside_profile: [],
		},
		VBlog: {
			module_enum: 'V-Blog',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
		},
		VNation: {
			module_enum: 'V-Nation',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
		},
		VTube: {
			module_enum: 'V-Tube',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
		},
		VJob_candidate: {
			module_enum: 'V-Jobs',
			profile_type: 'candidate',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
			languages: [],
		},
		VJob_Employer: {
			module_enum: 'V-Jobs',
			profile_type: 'employer',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
			languages: [],

			hiring_for_entity_ids: undefined,
			outside_profile: [],

			profile_hiring_preference: {
				hiring_status: false,
				gender_hiring_preference: 'all',
				nationality_hiring_preference: [],
				education_level_hiring_preference: ['all'],
				residence_country_hiring_preference: [],
				residence_city_hiring_preference: [],
				prefered_hourly_rate: 0,
			},
		},
		VLance_lancer: {
			module_enum: 'V-Lance',
			profile_type: 'freelancer',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
			languages: [],
			weekly_hours_availability: '',
			prefered_hourly_rate: 0,
		},
		VLance_client: {
			module_enum: 'V-Lance',
			profile_type: 'client',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
			languages: [],

			hiring_for_entity_ids: undefined,
			outside_profile: [],

			profile_hiring_preference: {
				hiring_status: false,
				gender_hiring_preference: 'all',
				nationality_hiring_preference: [],
				residence_country_hiring_preference: [],
				residence_city_hiring_preference: [],
				education_level_hiring_preference: ['all'],
				prefered_hourly_rate: 0,
			},
		},
		VCollege: {
			module_enum: 'V-College',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			social_media_links: [],
			languages: [],
		},
		VMarket: {
			module_enum: 'V-Market',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VEvents: {
			module_enum: 'V-Events',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VWebinars: {
			module_enum: 'V-Webinars',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VExperts: {
			module_enum: 'V-Experts',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			gap_between_consultations: 0,
			availability: [],
			social_media_links: [],
		},
		VBuzz: {
			module_enum: 'V-Buzz',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VCast: {
			module_enum: 'V-Cast',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VBook: {
			module_enum: 'V-Book',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
		VMood: {
			module_enum: 'V-Mood',
			files: [],
			attachments: [],
			custom_tagline: '',
			profile_message: '',
			languages: [],
			social_media_links: [],
		},
	},
};

export const PersonalProfileLegalVerificationValues: {
	country_id: number | null | any;
	official_full_name: string;
	gender: 'male' | 'female' | 'Other' | undefined | any;
	date_of_birth: string | any;
	national_id_number: string | number | any;
	attachments: any;
	email: string;
	phone: string;
	email_otp: string;
	phone_otp: string;
} = {
	country_id: null,
	official_full_name: '',
	gender: undefined,
	date_of_birth: '',
	national_id_number: '',
	attachments: undefined,
	email: '',
	phone: '',
	email_otp: '',
	phone_otp: '',
};

export const eventCategories = [
	{ name: 'General', key: 'general' },
	{ name: 'Category1 Name', key: '1 ' },
	{ name: 'Category2 Name', key: '2 ' },
	{ name: 'Category3 Name', key: '3 ' },
	{ name: 'Category4 Name', key: '4 ' },
	{ name: 'Category5 Name', key: '5 ' },
	{ name: 'Category6 Name', key: '6 ' },
	{ name: 'Category7 Name', key: '7 ' },
	{ name: 'Category8 Name', key: '8 ' },
	{ name: 'Category9 Name', key: '9 ' },
	{ name: 'Category10 Name', key: '10 ' },
];

export const eventSubCategories = [
	{ name: 'Sub-Category1 Name', key: '1 ' },
	{ name: 'Sub-Category2 Name', key: '2 ' },
	{ name: 'Sub-Category3 Name', key: '3 ' },
	{ name: 'Sub-Category4 Name', key: '4 ' },
	{ name: 'Sub-Category5 Name', key: '5 ' },
	{ name: 'Sub-Category6 Name', key: '6 ' },
	{ name: 'Sub-Category7 Name', key: '7 ' },
	{ name: 'Sub-Category8 Name', key: '8 ' },
	{ name: 'Sub-Category9 Name', key: '9 ' },
	{ name: 'Sub-Category10 Name', key: '10 ' },
];

export const eventLevels = [
	{ name: 'Level1 Name', key: '1 ' },
	{ name: 'Level2 Name', key: '2 ' },
	{ name: 'Level3 Name', key: '3 ' },
	{ name: 'Level4 Name', key: '4 ' },
	{ name: 'Level5 Name', key: '5 ' },
	{ name: 'Level6 Name', key: '6 ' },
	{ name: 'Level7 Name', key: '7 ' },
	{ name: 'Level8 Name', key: '8 ' },
	{ name: 'Level9 Name', key: '9 ' },
	{ name: 'Level10 Name', key: '10 ' },
];

// WEBINARS
export const webinarRoomTabs = (t: (s: string) => string) => [
	{
		title: t('buttons.polls'),
	},
	{
		title: t('buttons.offers'),
	},
	{
		title: t('buttons.handouts'),
	},
];

export const WebinarStatusesEnum = {
	started: 'started',
	ended: 'ended',
};

export const votersPreferencesOptions = [
	{
		id: 1,
		name: 'Share No. Of Voters Only With Attendees',
		value: 'number_only',
	},
	{
		id: 2,
		name: 'Share % Of Voters Only With Attendees',
		value: 'percentage_only',
	},
	{
		id: 3,
		name: 'Share Both No. & %  Of Voters Only With Attendees',
		value: 'both',
	},
];

export const myStorageModalTableHeaders = (t: (s: string) => string) => [
	{
		key: 'action',
		label: t('panelCommon.modals.myStorage.fields.action'),
	},
	{
		key: 'name',
		label: t('buttons.name'),
		sortable: true,
	},
	{
		key: 'parent_folder_name',
		label: t('panelCommon.modals.myStorage.fields.parentFolder'),
		sortable: true,
	},
	{
		key: 'nested_folders_count',
		label: t('panelCommon.modals.myStorage.fields.nestedFolders'),
		sortable: true,
	},
	{
		key: 'files_count',
		label: t('panelCommon.files'),
		sortable: true,
	},
	{
		key: 'size',
		label: t('panelCommon.modals.myStorage.fields.size'),
		sortable: true,
	},
];

// ADs CONSTANTS
export const sidebarAdsSpotsIds = [2, 3, 4, 5, 6];

// Tailwind Breakpoints
export const TAILWIND_BREAKPOINTS: BreakPointCounts = {
	'2xl': 1536,
	xl: 1280,
	lg: 1024,
	md: 768,
	sm: 640,
};

// COURSE CONSTANTS
export const HTML2PDFOptions = {
	margin: 0.5,
	filename: 'CourseCertificate.pdf',
	image: {
		type: 'jpeg',
		quality: 1,
	},
	html2canvas: {
		scale: 3,
		useCORS: true,
		width: 1094,
		height: 800,
	},
	jsPDF: {
		unit: 'in',
		format: 'letter',
		orientation: 'landscape',
	},
};

export const horoscopeEmojies = {
	aries: '♈️',
	taurus: '♉️',
	gemini: '♊️',
	cancer: '♋️',
	leo: '♌️',
	virgo: '♍️',
	libra: '♎️',
	scorpio: '♏️',
	sagittarius: '♐️',
	capricorn: '♑️',
	aquarius: '♒️',
	pisces: '♓️',
};

export const VVSearchVHostsNavItems = (t: (s: string) => string, id: string) => [
	{
		icon: <GeneralInfoIcon />,
		name: t('headers.general_info'),
		link: `/vv-search/v-hosts/${id}/general-info`,
		active: true,
	},
	{
		icon: <WebinarWhite />,
		name: t('labels.webinars'),
		link: `/vv-search/v-hosts/${id}/webinars`,
		active: true,
	},
	{
		icon: <AllCustomers />,
		name: t('headers.allCustomers'),
		link: `/vv-search/v-hosts/${id}/all-customers`,
		active: true,
	},
	{
		icon: <Endorsement />,
		name: t('statBoxsTitle.endorsements'),
		link: `/vv-search/v-hosts/${id}/endorsements`,
		active: false,
	},
	{
		icon: <Likes />,
		name: t('statBoxsTitle.likes'),
		link: `/vv-search/v-hosts/${id}/likes`,
		active: false,
	},
	{
		icon: <Share />,
		name: t('statBoxsTitle.shares'),
		link: `/vv-search/v-hosts/${id}/shares`,
		active: false,
	},
];
