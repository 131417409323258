import { SVGProps } from 'react';

const ImageIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M5.58017 2.02031C4.99892 2.13281 4.43642 2.59219 4.15986 3.17344L4.00986 3.49219L3.9958 9.88594C3.98642 15.6516 3.99111 16.3078 4.06142 16.5422C4.23486 17.1375 4.56299 17.5359 5.10205 17.7984L5.4583 17.9766H14.013H22.5724L22.8536 17.8406C23.2427 17.6484 23.627 17.2687 23.8192 16.8844L23.9786 16.5703V10.0078V3.44531L23.8192 3.11719C23.5427 2.55469 23.0224 2.15156 22.4177 2.02031C22.113 1.95937 5.9083 1.95469 5.58017 2.02031ZM22.4317 3.14531C22.5724 3.21562 22.7224 3.34219 22.8067 3.46406L22.9474 3.66562L22.9614 8.35781C22.9708 12.9562 22.9708 13.05 22.8864 12.9609C22.8349 12.9094 21.8458 11.7609 20.688 10.4062C19.5302 9.05156 18.5364 7.90781 18.4895 7.86562C18.438 7.82344 18.2739 7.72969 18.1239 7.65937C17.8802 7.54688 17.8099 7.5375 17.4442 7.55625C16.7317 7.58906 16.8208 7.50937 14.5661 10.2C13.488 11.4844 12.5786 12.5812 12.5411 12.6328C12.4755 12.7172 12.4333 12.6844 11.688 11.9437C11.238 11.5031 10.8114 11.1187 10.6895 11.0578C10.5067 10.9594 10.413 10.9453 10.0099 10.9453C9.61142 10.9453 9.5083 10.9641 9.30674 11.0578C9.12861 11.1469 8.60361 11.6391 7.04736 13.1906L5.01767 15.2062V9.5625C5.01767 5.82187 5.03642 3.85312 5.06455 3.74062C5.1583 3.41719 5.48174 3.11719 5.82392 3.05156C5.93642 3.02812 9.66299 3.01406 14.1114 3.01875L22.202 3.02344L22.4317 3.14531ZM17.838 8.72812C17.9224 8.8125 19.1177 10.1906 20.4817 11.7891L22.9708 14.6953V15.4359C22.9708 16.2422 22.9333 16.4062 22.6989 16.6594C22.6286 16.7344 22.488 16.8328 22.3849 16.8797C22.2067 16.9641 21.8786 16.9688 13.9989 16.9688C5.88017 16.9688 5.7958 16.9688 5.60361 16.875C5.4958 16.8234 5.36455 16.7344 5.3083 16.6734L5.20517 16.5656L7.51611 14.2594C9.77549 12.0047 9.82705 11.9531 10.0099 11.9531C10.1833 11.9531 10.2302 11.9953 11.2286 12.9844C12.2552 14.0016 12.2692 14.0156 12.4661 14.0156C12.5739 14.0156 12.7052 13.9922 12.7567 13.9641C12.8083 13.9406 13.8067 12.7641 14.9833 11.3531C16.1552 9.94219 17.177 8.74219 17.2614 8.68125C17.4677 8.53125 17.6411 8.55 17.838 8.72812Z"
			fill={props.color || 'white'}
		/>
		<path
			d="M8.57707 5.02031C8.0005 5.13281 7.42863 5.59219 7.16144 6.16406C7.05363 6.40313 7.01613 6.55781 6.99738 6.85781C6.94113 7.72031 7.34425 8.42813 8.09894 8.79844C8.4505 8.97188 8.46457 8.97656 8.99894 8.97656C9.53332 8.97656 9.54738 8.97188 9.89894 8.79844C10.6536 8.42813 11.0568 7.72031 11.0005 6.85781C10.9255 5.63906 9.79582 4.78125 8.57707 5.02031ZM9.54738 6.21563C10.0349 6.60938 10.1099 7.1625 9.73488 7.61719C9.35519 8.08125 8.638 8.07656 8.25363 7.6125C7.93957 7.22813 7.94894 6.74063 8.27707 6.37969C8.51613 6.1125 8.68488 6.04219 9.05519 6.05625C9.32238 6.07031 9.39269 6.09375 9.54738 6.21563Z"
			fill={props.color || 'white'}
		/>
		<path
			d="M2.39062 9.69844C2.25469 9.73125 2.15625 9.79219 2.07656 9.89531C2.04375 9.93281 1.57031 11.6203 1.02188 13.6453L0.028125 17.3203L0.0234375 17.8594C0.0234375 18.3703 0.0328125 18.4125 0.173438 18.7172C0.351562 19.0922 0.721875 19.4859 1.05938 19.6547C1.19063 19.7203 4.6125 20.6578 9.1875 21.8859C16.5234 23.85 17.1141 24 17.4656 24C17.8781 24 18.2297 23.9063 18.5438 23.7047C18.8391 23.5219 19.2 23.0906 19.3078 22.8047C19.4578 22.4063 20.2031 19.6078 20.2031 19.4484C20.2031 19.2375 19.9406 18.9844 19.7156 18.9844C19.4953 18.9844 19.3172 19.0688 19.2422 19.2188C19.2094 19.2844 18.9984 20.0109 18.7734 20.8359C18.5531 21.6563 18.3422 22.3828 18.3047 22.4484C18.1547 22.7344 17.7797 22.9688 17.4703 22.9688C17.3813 22.9688 14.0766 22.1016 10.1203 21.0422C0.9 18.5766 1.59375 18.7688 1.39219 18.6047C1.19063 18.4359 1.03125 18.1219 1.03125 17.8875C1.03125 17.7938 1.47188 16.0594 2.01563 14.0391C2.55938 12.0234 3 10.2844 3 10.1813C3 9.8625 2.70469 9.62813 2.39062 9.69844Z"
			fill={props.color || 'white'}
		/>
	</svg>
);
export default ImageIcon;
