import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Podcasts = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<path stroke="#fff" d="M6.75 6.666a2 2 0 1 1 4 0v2a2 2 0 1 1-4 0v-2Z" />
		<path
			stroke="#fff"
			strokeLinecap="round"
			d="M9.417 6.666h1.333M9.417 8.666h1.333M6.75 6.666h.667M6.75 8.666h.667"
		/>
		<path
			stroke="#fff"
			d="M2.083 8A1.333 1.333 0 1 1 4.75 8v1.333a1.333 1.333 0 1 1-2.667 0V7.999ZM12.75 8a1.333 1.333 0 1 1 2.667 0v1.333a1.333 1.333 0 0 1-2.667 0V7.999Z"
		/>
		<path stroke="#fff" strokeLinecap="round" d="M8.75 10.666v2M12.75 5.508c-.333-1-1.6-3-4-3-3 0-4 3-4 3" />
	</svg>
);
export default Podcasts;
