import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const GeneralInfoIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.20544 17.8525V6.388C2.20544 5.553 2.88344 4.875 3.71844 4.875H21.0434C21.8774 4.875 22.5574 5.552 22.5574 6.388V17.8525C22.5574 18.688 21.8784 19.3655 21.0434 19.3655H3.71844C2.88344 19.3655 2.20544 18.688 2.20544 17.8525ZM21.5994 17.8525V6.388C21.5994 6.0825 21.3509 5.833 21.0434 5.833H3.71844C3.41194 5.833 3.16294 6.0815 3.16294 6.388V17.8525C3.16294 18.1585 3.41194 18.408 3.71844 18.408H21.0434C21.3499 18.408 21.5994 18.1585 21.5994 17.8525Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.88647 6.79446C3.88647 6.54096 4.09297 6.33496 4.34647 6.33496C4.59947 6.33496 4.80497 6.54096 4.80497 6.79446C4.80497 7.04646 4.59947 7.25296 4.34647 7.25296C4.09297 7.25296 3.88647 7.04646 3.88647 6.79446ZM5.18647 6.79446C5.18647 6.54096 5.39247 6.33496 5.64497 6.33496C5.89847 6.33496 6.10497 6.54096 6.10497 6.79446C6.10497 7.04646 5.89847 7.25296 5.64497 7.25296C5.39247 7.25296 5.18647 7.04646 5.18647 6.79446ZM6.48597 6.79446C6.48597 6.54096 6.69247 6.33496 6.94447 6.33496C7.19797 6.33496 7.40397 6.54096 7.40397 6.79446C7.40397 7.04646 7.19797 7.25296 6.94447 7.25296C6.69247 7.25296 6.48597 7.04646 6.48597 6.79446Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.0895 11.1982L17.013 10.8712C16.927 10.8457 16.845 10.8117 16.767 10.7707L16.448 10.9682C16.365 11.0202 16.2685 11.0362 16.198 10.9682L16.053 10.8212C15.984 10.7532 16.004 10.6607 16.053 10.5727L16.25 10.2522C16.197 10.1532 16.1555 10.0457 16.13 9.93169L15.8235 9.85869C15.728 9.83719 15.647 9.77969 15.647 9.68269V9.47669C15.647 9.37969 15.728 9.32869 15.8235 9.29969L16.1755 9.21819L16.2755 9.00869C16.218 8.91619 16.1605 8.82219 16.1045 8.72969C16.051 8.64669 16.035 8.54869 16.1045 8.48069L16.25 8.33469C16.318 8.26569 16.412 8.28719 16.499 8.33469L16.798 8.52019L17 8.43719C17.0275 8.32669 17.0575 8.21569 17.0895 8.10619C17.118 8.01069 17.168 7.92969 17.2665 7.92969H17.4725C17.5695 7.92969 17.6265 8.01069 17.649 8.10619L17.732 8.43619L17.9755 8.53669C18.0635 8.48469 18.1505 8.43519 18.239 8.38619C18.327 8.33819 18.4195 8.31719 18.4875 8.38619L18.6345 8.53169C18.7035 8.59969 18.6865 8.69769 18.6345 8.78069L18.48 9.03919L18.558 9.20419C18.6855 9.23619 18.807 9.26819 18.915 9.29969C19.011 9.32769 19.092 9.37869 19.092 9.47669V9.68269C19.092 9.77969 19.011 9.83619 18.915 9.85869L18.606 9.93719L18.509 10.2137C18.5695 10.3152 18.628 10.4182 18.6855 10.5217C18.7345 10.6087 18.755 10.7012 18.6855 10.7707L18.5395 10.9162C18.4705 10.9852 18.3735 10.9682 18.291 10.9162L18.009 10.7492C17.9205 10.8012 17.8255 10.8417 17.726 10.8712L17.649 11.1982C17.6265 11.2937 17.5705 11.3747 17.4725 11.3747H17.2665C17.169 11.3747 17.118 11.2937 17.0895 11.1982ZM18 9.65219C18 9.30419 17.717 9.02119 17.369 9.02119C17.0215 9.02119 16.7385 9.30419 16.7385 9.65219C16.7385 10.0002 17.0215 10.2827 17.369 10.2827C17.717 10.2827 18 10.0002 18 9.65219Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.8059 16.1848C15.8059 16.0393 15.9254 15.9193 16.0724 15.9193H18.6319C18.7784 15.9193 18.8984 16.0393 18.8984 16.1848C18.8984 16.3318 18.7784 16.4513 18.6319 16.4513H16.0724C15.9254 16.4513 15.8059 16.3318 15.8059 16.1848ZM15.7944 15.2208C15.7944 15.0753 15.9134 14.9548 16.0604 14.9548H19.6909C19.8369 14.9548 19.9569 15.0753 19.9569 15.2208C19.9569 15.3683 19.8369 15.4873 19.6909 15.4873H16.0604C15.9134 15.4873 15.7944 15.3683 15.7944 15.2208ZM15.8059 14.0308C15.8059 13.8848 15.9254 13.7643 16.0724 13.7643H18.6319C18.7784 13.7643 18.8984 13.8848 18.8984 14.0308C18.8984 14.1773 18.7784 14.2963 18.6319 14.2963H16.0724C15.9254 14.2963 15.8059 14.1773 15.8059 14.0308ZM15.7944 13.0663C15.7944 12.9208 15.9134 12.8008 16.0604 12.8008H19.6909C19.8369 12.8008 19.9569 12.9208 19.9569 13.0663C19.9569 13.2133 19.8369 13.3323 19.6909 13.3323H16.0604C15.9134 13.3323 15.7944 13.2133 15.7944 13.0663Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.15894 16.1854C4.15894 16.0399 4.27844 15.9199 4.42494 15.9199H12.9079C13.0539 15.9199 13.1739 16.0399 13.1739 16.1854C13.1739 16.3324 13.0539 16.4519 12.9079 16.4519H4.42494C4.27844 16.4519 4.15894 16.3324 4.15894 16.1854Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.72945 15.043C7.32245 15.043 7.80595 15.5265 7.80595 16.1195C7.80595 16.7125 7.32245 17.196 6.72945 17.196C6.13645 17.196 5.65295 16.7125 5.65295 16.1195C5.65295 15.5265 6.13645 15.043 6.72945 15.043Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.13599 13.3997V8.76172H13.1955V13.3997H4.13599ZM12.663 12.8677V9.29372H4.66749V12.8677H12.663Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.65198 12.3562V11.3742H6.63598V12.3562H5.65198ZM7.33398 12.3562V11.3742H8.31598V12.3562H7.33398ZM9.01648 12.3562V11.3742H9.99698V12.3562H9.01648ZM10.697 12.3562V11.3742H11.6815V12.3562H10.697ZM5.65198 10.7877V9.80469H6.63598V10.7877H5.65198ZM7.33398 10.7877V9.80469H8.31598V10.7877H7.33398ZM9.01648 10.7877V9.80469H9.99698V10.7877H9.01648ZM10.697 10.7877V9.80469H11.6815V10.7877H10.697Z"
			fill="white"
		/>
	</svg>
);
export default GeneralInfoIcon;
