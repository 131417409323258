import { SVGProps } from 'react';

const BorderedVideo = (props: SVGProps<SVGSVGElement>) => (
	<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.492188" width="45" height="45" rx="22.5" stroke="url(#paint0_linear_872_83914)" />
		<path
			d="M34 17.9922L27 22.9922L34 27.9922V17.9922Z"
			stroke="url(#paint1_linear_872_83914)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M25 15.9922H14C12.8954 15.9922 12 16.8876 12 17.9922V27.9922C12 29.0968 12.8954 29.9922 14 29.9922H25C26.1046 29.9922 27 29.0968 27 27.9922V17.9922C27 16.8876 26.1046 15.9922 25 15.9922Z"
			stroke="url(#paint2_linear_872_83914)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_872_83914"
				x1="17.9954"
				y1="43.727"
				x2="55.2003"
				y2="24.3385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_872_83914"
				x1="29.7384"
				y1="27.4998"
				x2="36.0921"
				y2="25.182"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_872_83914"
				x1="17.8681"
				y1="29.3028"
				x2="29.6285"
				y2="22.7364"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default BorderedVideo;
