import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const SocialPage = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.7} clipPath="url(#a)">
			<path
				strokeMiterlimit={10}
				d="M2.25 5.5h13M3.813 7.469h3.75M3.813 9.344h1.562M12.125 9.344h1.563M3.813 11.219h1.562M12.125 11.219h1.563M9.938 7.469h3.75M5.25 13.5h-3v-11h13v11h-3"
			/>
			<path d="M8.75 12.281a.812.812 0 1 0 0-1.625.812.812 0 0 0 0 1.625Z" />
			<path d="M9.563 10.656v1.016a.61.61 0 1 0 1.218 0v-.203a2.032 2.032 0 1 0-.796 1.612" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75 0h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default SocialPage;
