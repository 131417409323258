import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VExpert = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.66797 10.499H5.33464C4.62739 10.499 3.94911 10.78 3.44902 11.2801C2.94892 11.7802 2.66797 12.4584 2.66797 13.1657V14.499"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.0013 8.49902L11.7223 10.035L13.3346 10.2828L12.168 11.4776L12.4433 13.1657L11.0013 12.3683L9.5593 13.1657L9.83464 11.4776L8.66797 10.2828L10.2803 10.035L11.0013 8.49902Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.9987 7.83236C9.47146 7.83236 10.6654 6.63845 10.6654 5.16569C10.6654 3.69293 9.47146 2.49902 7.9987 2.49902C6.52594 2.49902 5.33203 3.69293 5.33203 5.16569C5.33203 6.63845 6.52594 7.83236 7.9987 7.83236Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default VExpert;
