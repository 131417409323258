import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Facebook = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} fill="none" {...props}>
		<path
			fill="#fff"
			d="m17.093 13.78.532-3.468h-3.328V8.06c0-.948.465-1.873 1.955-1.873h1.514V3.233S16.392 3 15.079 3c-2.74 0-4.532 1.661-4.532 4.669v2.644H7.5v3.468h3.047v8.386a12.097 12.097 0 0 0 3.75 0V13.78h2.796Z"
		/>
	</svg>
);
export default Facebook;
