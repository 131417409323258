import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Pintereset = (props: Props) => {
	const { width = 25, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M12.5 0C5.87284 0 0.5 5.36842 0.5 11.9901C0.5 17.0724 3.66049 21.4145 8.12469 23.1612C8.01605 22.2138 7.92716 20.7533 8.1642 19.7171C8.38148 18.7796 9.56667 13.7566 9.56667 13.7566C9.56667 13.7566 9.21111 13.0362 9.21111 11.9803C9.21111 10.3125 10.179 9.06908 11.384 9.06908C12.4111 9.06908 12.9049 9.83882 12.9049 10.7566C12.9049 11.7829 12.2531 13.3224 11.9074 14.7533C11.621 15.9474 12.5099 16.9243 13.6852 16.9243C15.8185 16.9243 17.458 14.6743 17.458 11.4375C17.458 8.56579 15.3938 6.5625 12.4407 6.5625C9.02346 6.5625 7.01852 9.11842 7.01852 11.7632C7.01852 12.7895 7.41358 13.8947 7.90741 14.4967C8.00617 14.6151 8.01605 14.7237 7.98642 14.8421C7.89753 15.2171 7.69012 16.0362 7.65062 16.2039C7.60123 16.4211 7.47284 16.4704 7.24568 16.3618C5.74444 15.6612 4.80617 13.4803 4.80617 11.7138C4.80617 7.93421 7.55185 4.46053 12.737 4.46053C16.8951 4.46053 20.1346 7.42105 20.1346 11.3882C20.1346 15.523 17.5272 18.8487 13.9123 18.8487C12.6975 18.8487 11.5519 18.2171 11.1667 17.4671C11.1667 17.4671 10.5642 19.7566 10.416 20.3191C10.1494 21.3651 9.41852 22.6678 8.92469 23.4671C10.0506 23.8125 11.2358 24 12.4802 24C19.1074 24 24.4802 18.6316 24.4802 12.0099C24.5 5.36842 19.1272 0 12.5 0Z"
				fill={color}
			/>
		</svg>
	);
};

export default Pintereset;
