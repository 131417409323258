import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Consultation = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#fff"
				d="M9.45-.001h-1.4a6.976 6.976 0 0 0-6.968 6.969v3.763c0 .215.175.39.39.39h.587v.275c0 .215.175.39.39.39h.709c.7 0 1.27-.57 1.27-1.27V7.464c0-.7-.57-1.27-1.27-1.27h-.709a.39.39 0 0 0-.39.39v.275h-.193A6.194 6.194 0 0 1 8.051.78h1.398a6.194 6.194 0 0 1 6.185 6.08h-.193v-.276a.39.39 0 0 0-.39-.39h-.709c-.7 0-1.27.57-1.27 1.27v3.052c0 .7.57 1.27 1.27 1.27h.709a.39.39 0 0 0 .276-.115v.843c0 1.13-.92 2.05-2.05 2.05h-2.321a1.046 1.046 0 0 0-.968-.654H8.933a1.046 1.046 0 0 0 0 2.09h1.055c.437 0 .813-.272.968-.655h2.321a2.834 2.834 0 0 0 2.831-2.83v-1.403a.39.39 0 0 0 .31-.382V6.968A6.977 6.977 0 0 0 9.449-.001Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75-.001h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Consultation;
