import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Shorts = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.66281 9.76077V6.22766L9.99594 7.99422L6.66281 9.76077ZM11.8425 6.87428C11.3292 6.66096 11.0425 6.54097 11.0425 6.54097L11.9958 6.03434C13.2224 5.39437 13.6824 3.88113 13.0358 2.66121C12.3891 1.44128 10.8759 0.974645 9.65596 1.62127L3.99631 4.62109C3.13636 5.07439 2.61639 5.98101 2.66305 6.94761C2.70972 7.89422 3.28302 8.7275 4.14296 9.11415C4.16296 9.12081 4.94292 9.44746 4.94292 9.44746L3.99631 9.94743C2.77638 10.5941 2.30974 12.1073 2.95637 13.3272C3.603 14.5471 5.11624 15.0138 6.33616 14.3672L12.0025 11.3673C12.8624 10.914 13.3757 10.0074 13.3291 9.04082C13.2824 8.09421 12.7024 7.25426 11.8425 6.87428ZM11.6892 10.7807L6.02285 13.7805C5.12957 14.2538 4.01631 13.9139 3.543 13.0206C3.0697 12.1273 3.40968 11.014 4.30295 10.5407L5.66287 9.82077V9.01415L5.2029 8.8275L4.46294 8.52085C3.80298 8.24753 3.36301 7.62091 3.32968 6.91428C3.29635 6.20766 3.67633 5.54103 4.30295 5.20772L9.96928 2.2079C10.8626 1.7346 11.9758 2.07458 12.4491 2.96786C12.9224 3.86114 12.5824 4.9744 11.6892 5.4477L10.3293 6.16766V6.97428L11.5292 7.46758C12.1891 7.7409 12.6291 8.36753 12.6624 9.07415C12.6958 9.78077 12.3158 10.4474 11.6892 10.7807Z"
			fill="#fff"
		/>
	</svg>
);
export default Shorts;
