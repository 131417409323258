import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VBooksAuthor = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.0001 9.17517H5.33341C4.62617 9.17517 3.94789 9.45612 3.4478 9.95622C2.9477 10.4563 2.66675 11.1346 2.66675 11.8418V14.8418"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M7.99748 7.17391C9.47024 7.17391 10.6641 5.98 10.6641 4.50724C10.6641 3.03448 9.47024 1.84058 7.99748 1.84058C6.52472 1.84058 5.33081 3.03448 5.33081 4.50724C5.33081 5.98 6.52472 7.17391 7.99748 7.17391Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M6.0511 11.9915H12.0511C12.5161 11.9915 12.7486 11.9915 12.9393 12.0426C13.457 12.1813 13.8613 12.5856 14 13.1032"
			stroke={props.stroke || 'white'}
			strokeWidth={'0.66667'}
		/>
		<path d="M12.5 11.9915V5.24146" stroke={props.stroke || 'white'} strokeLinecap="round" strokeWidth={'0.66667'} />
		<path
			d="M11 14.9915C12.4142 14.9915 13.1213 14.9915 13.5607 14.5521C14 14.1128 14 13.4057 14 11.9915V7.99146C14 6.57724 14 5.87014 13.5607 5.4308C13.24 5.11014 12.7767 5.0234 12 5M9 14.9915C7.5858 14.9915 6.8787 14.9915 6.43935 14.5521C6 14.1128 6 13.4057 6 11.9915V11"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeWidth={'0.66667'}
		/>
	</svg>
);
export default VBooksAuthor;
