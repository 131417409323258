import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Project = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_10692_135630)">
			<path
				d="M8.00004 2.28076L1.33337 5.61409L8.00004 8.94743L14.6667 5.61409L8.00004 2.28076Z"
				stroke={props.stroke || 'url(#paint0_linear_10692_135630)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.33337 12.2808L8.00004 15.6141L14.6667 12.2808"
				stroke={props.stroke || 'url(#paint1_linear_10692_135630)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.33337 8.94727L8.00004 12.2806L14.6667 8.94727"
				stroke={props.stroke || 'url(#paint2_linear_10692_135630)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_10692_135630"
				x1="6.54942"
				y1="8.61914"
				x2="13.1222"
				y2="1.76866"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_10692_135630"
				x1="6.54942"
				y1="15.45"
				x2="9.11485"
				y2="10.1023"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_10692_135630"
				x1="6.54942"
				y1="12.1165"
				x2="9.11485"
				y2="6.76879"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_10692_135630">
				<rect width="16" height="16" fill="white" transform="translate(0 0.947266)" />
			</clipPath>
		</defs>
	</svg>
);
export default Project;
