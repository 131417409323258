import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Contracts = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g clipPath="url(#a)">
			<path
				fill="#fff"
				d="M4.8 7.467h-.533v1.067H4.8V7.467Zm6.4 1.067h.533V7.467H11.2v1.067ZM4.8 4.267h-.533v1.067H4.8V4.267Zm2.133 1.067h.534V4.267h-.534v1.067ZM11.2.534l.377-.377L11.421 0h-.22v.534Zm3.2 3.2h.533v-.221l-.156-.156-.377.377ZM8 12.267l.239.477.043-.021.038-.03-.32-.426ZM4.8 8.534h6.4V7.467H4.8v1.067Zm0-3.2h2.133V4.267H4.8v1.067Zm8.533 9.6H2.667V16h10.666v-1.066Zm-11.2-.534V1.6H1.067v12.8h1.066Zm.534-13.333H11.2V0H2.667v1.067Zm11.2 2.667V14.4h1.066V3.734h-1.066ZM10.823.91l3.2 3.2.754-.754-3.2-3.2-.754.754ZM2.667 14.934a.533.533 0 0 1-.534-.534H1.067a1.6 1.6 0 0 0 1.6 1.6v-1.066ZM13.333 16a1.6 1.6 0 0 0 1.6-1.6h-1.066a.533.533 0 0 1-.534.534V16ZM2.133 1.6c0-.294.24-.533.534-.533V0a1.6 1.6 0 0 0-1.6 1.6h1.066ZM5.84 12.436c.119-.356.455-.685.886-.8.402-.108.919-.038 1.431.475l.755-.755c-.768-.767-1.673-.963-2.463-.75-.76.204-1.384.781-1.62 1.492l1.011.338Zm2.317-.325c.035.035.062.066.084.093l.842-.654a2.064 2.064 0 0 0-.171-.194l-.755.755Zm.084.093c.083.108.065.141.067.118.002-.015.007.009-.057.067-.065.059-.17.123-.31.18a1.906 1.906 0 0 1-.42.115.906.906 0 0 1-.266.01c-.019-.003.019 0 .073.04a.413.413 0 0 1 .14.46c-.01.03-.022.046-.024.049-.001.002.016-.022.077-.072.122-.098.345-.24.719-.427l-.477-.954c-.403.201-.704.384-.912.552a1.467 1.467 0 0 0-.277.284.753.753 0 0 0-.149.467c.01.23.135.4.271.5a.879.879 0 0 0 .352.148c.2.04.427.029.633-.002.406-.062.93-.236 1.288-.561a1.18 1.18 0 0 0 .399-.731c.038-.327-.08-.633-.285-.897l-.842.654Zm.08.49c.196-.147.38-.253.553-.328l-.424-.979c-.256.111-.512.26-.769.453l.64.854Zm.553-.328c.694-.3 1.313-.141 1.948.161.16.076.315.159.473.244.153.083.316.173.47.251.293.149.659.312 1.036.312v-1.067c-.1 0-.264-.05-.554-.197-.137-.07-.28-.148-.444-.237-.161-.087-.337-.18-.523-.269-.745-.355-1.718-.658-2.83-.177l.424.98Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Contracts;
