import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Events = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<path
			fill="#fff"
			d="M4.231 1.069a.804.804 0 0 0-.372.383c-.07.153-.068.457.003.613.05.11.05.118.005.134a5.442 5.442 0 0 0-.238.107c-.555.265-.98.785-1.135 1.392-.05.192-.055.304-.055 1.081v.868l-.134.019c-.512.068-.741.755-.377 1.132.175.184.26.208.736.22l.432.01.027.113c.14.541.572.98 1.09 1.097l.155.035.014 2.994.013 2.993.074.156c.096.208.26.378.465.49l.173.093h9.932l.158-.08c.186-.095.394-.306.487-.495l.066-.137V4.902l-.093-.172a1.074 1.074 0 0 0-.49-.465l-.156-.074-4.15-.008-4.15-.006-.017-.158a2.648 2.648 0 0 0-.208-.736c-.214-.45-.684-.884-1.127-1.043-.11-.038-.13-.055-.115-.101.09-.277.1-.359.07-.517a.772.772 0 0 0-.492-.586.93.93 0 0 0-.59.033Zm.531.44c.115.099.153.23.104.348-.104.244-.386.295-.55.101-.115-.137-.08-.366.071-.473.096-.069.277-.055.375.024Zm.208 1.068c.57.145 1.045.602 1.234 1.19.041.128.05.284.06 1.018l.011.87H2.877v-.837c0-.923.014-1.027.17-1.336a1.743 1.743 0 0 1 1.075-.894c.21-.06.63-.066.848-.011Zm9.981 2.074a.665.665 0 0 1 .323.306c.05.104.052.304.052 4.646 0 4.41-.003 4.54-.052 4.638a.72.72 0 0 1-.304.295c-.093.05-.265.052-4.87.052-4.547 0-4.78-.002-4.903-.049a.667.667 0 0 1-.34-.336c-.032-.077-.037-.597-.037-3.013V8.268l.172-.05a1.46 1.46 0 0 0 .89-.752c.06-.12.117-.27.128-.331l.019-.112h.388c.468 0 .6-.036.772-.208a.693.693 0 0 0-.008-.974c-.096-.09-.298-.183-.397-.186h-.077v-1.04h4.077c3.36 0 4.093.006 4.167.036ZM6.852 6.147a.233.233 0 0 1 .072.28c-.069.164.019.158-2.375.158H2.363l-.085-.071c-.132-.11-.112-.301.038-.389.041-.021.654-.03 2.26-.032 2.159 0 2.208 0 2.276.054Zm-1.31.99c-.099.285-.285.482-.577.616-.135.063-.197.074-.392.074-.298 0-.49-.07-.695-.26-.14-.126-.317-.413-.317-.509 0-.03.183-.035 1.012-.035h1.01l-.041.115Z"
		/>
		<path
			fill="#fff"
			d="M12.423 5.381V5.6h.438v-.438h-.438v.219ZM9.62 6.317c-.362.736-.466.928-.515.938-.033.009-.449.069-.922.137-.474.069-.906.132-.958.143l-.093.016.736.717.733.714-.156.93-.172 1.002c-.008.043-.003.076.011.076.016 0 .424-.21.908-.465l.881-.465.89.468c.49.257.894.462.905.454.008-.008-.063-.462-.156-1.007l-.167-.99.728-.712c.4-.39.723-.717.714-.722a29.293 29.293 0 0 0-.998-.156c-.545-.08-.994-.15-1.002-.159a32.965 32.965 0 0 1-.462-.927l-.452-.914-.454.922Zm1.06 1.324a48.81 48.81 0 0 0 1.363.203c.017 0-.197.219-.473.49l-.5.487.114.68c.063.373.112.682.11.688-.006.002-.274-.134-.602-.307a10.218 10.218 0 0 0-.619-.314c-.013 0-.292.142-.618.314-.328.173-.6.312-.605.31-.002-.006.047-.313.11-.685l.117-.673-.487-.487c-.268-.268-.484-.49-.481-.492.003-.003.306-.052.676-.107.366-.052.67-.101.673-.104.003-.003.142-.282.312-.621l.309-.616.298.616c.164.336.301.616.304.618ZM11.74 6.065v.22h.437v-.439h-.438v.22ZM13.111 6.065v.22h.438v-.439h-.438v.22ZM12.423 6.75v.218h.438V6.53h-.438v.22ZM6.654 9.075v.219h.437v-.438h-.438v.219ZM5.97 9.759v.219h.438V9.54H5.97v.219ZM7.337 9.759v.219h.438V9.54h-.438v.219ZM6.654 10.443v.219h.437v-.438h-.438v.219ZM5.5 12.823v1.04h1.314v-.438h-.876v-.383h.876v-.438h-.876v-.382h.876v-.438H5.5v1.04ZM7.439 11.838a1.39 1.39 0 0 0-.17.06c-.005.006.129.447.298.98l.31.972h.396l.31-.972c.213-.673.3-.977.278-.99-.052-.03-.374-.113-.388-.099-.008.008-.096.274-.197.591-.101.32-.192.58-.2.58-.008 0-.101-.265-.205-.588-.14-.44-.197-.588-.23-.586-.024 0-.115.022-.202.052ZM9.279 12.823v1.04h1.34v-.438h-.903v-.383h.903v-.437h-.903v-.383h.903v-.438H9.28v1.04ZM11.029 12.837v1.053h.437l.006-.574.008-.575.353.561.353.561h.375v-2.08h-.438l-.005.562-.009.56-.353-.56-.353-.561h-.374v1.053ZM12.997 12.003v.218h.602v1.67h.438v-1.67h.629v-.438h-1.669v.22Z"
		/>
	</svg>
);
export default Events;
