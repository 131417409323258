import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Youtube = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M22.5401 6.82137C22.4214 6.34678 22.1794 5.91194 21.8388 5.56077C21.4982 5.20961 21.0709 4.95455 20.6001 4.82137C18.8801 4.40137 12.0001 4.40137 12.0001 4.40137C12.0001 4.40137 5.12015 4.40137 3.40014 4.86137C2.92939 4.99455 2.50212 5.24961 2.16149 5.60077C1.82086 5.95194 1.57893 6.38678 1.46014 6.86137C1.14536 8.60692 0.99138 10.3777 1.00014 12.1514C0.988924 13.9384 1.14291 15.7227 1.46014 17.4814C1.5911 17.9412 1.83845 18.3595 2.17829 18.6958C2.51813 19.0322 2.93897 19.2752 3.40014 19.4014C5.12015 19.8614 12.0001 19.8614 12.0001 19.8614C12.0001 19.8614 18.8801 19.8614 20.6001 19.4014C21.0709 19.2682 21.4982 19.0131 21.8388 18.662C22.1794 18.3108 22.4214 17.876 22.5401 17.4014C22.8525 15.669 23.0065 13.9117 23.0001 12.1514C23.0114 10.3643 22.8574 8.58007 22.5401 6.82137Z"
				fill="white"
			/>
			<path
				d="M9.74957 15.4228L15.4996 12.1528L9.74957 8.88281V15.4228Z"
				fill="#6c727f"
				stroke="#6c727f"
				strokeWidth={2.4}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Youtube;
