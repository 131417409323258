import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Courses = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<rect width={14.044} height={12.912} x={0.74} y={0.555} stroke="#fff" strokeWidth={0.75} rx={1.348} />
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={0.75}
			d="m2.226 11.481.8.8.799-.8M7.41 4.22H2.719c-.37 0-.67.3-.67.67v4.69c0 .37.3.67.67.67h4.69c.37 0 .67-.3.67-.67V4.89c0-.37-.3-.67-.67-.67Z"
		/>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={0.75}
			d="M3.89 6.563a.502.502 0 1 0 0-1.004.502.502 0 0 0 0 1.004ZM8.075 8.236 6.4 6.562l-3.683 3.683"
		/>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeWidth={0.75}
			d="M11.424 1.43h1.834M9.208 4.236h3.804M9.208 5.713h3.804M9.208 7.19h3.804M9.208 11.867h1.835M5.516 11.867h1.835"
		/>
		<circle cx={2.236} cy={1.805} r={0.394} fill="#fff" />
		<circle cx={3.344} cy={1.805} r={0.394} fill="#fff" />
		<circle cx={4.452} cy={1.805} r={0.394} fill="#fff" />
		<path stroke="#fff" strokeWidth={0.75} d="M.365 2.882h14.794" />
	</svg>
);
export default Courses;
