import { SVGProps } from 'react';

const ExcelIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#20744A"
			fillRule="evenodd"
			d="M21.586 3.304h-7.598v-1.79L1.5 3.44v16.895l12.488 2.151v-2.653h7.598a.871.871 0 0 0 .914-.822V4.125a.872.872 0 0 0-.914-.821Zm.12 15.844h-7.743l-.013-1.416h1.865v-1.65h-1.88l-.008-.975h1.888v-1.65h-1.902l-.01-.975h1.912v-1.65h-1.917v-.975h1.917v-1.65h-1.917v-.975h1.917v-1.65h-1.917v-1.5h7.808v15.066Z"
			clipRule="evenodd"
		/>
		<path
			fill="#20744A"
			d="M20.108 5.58h-3.243v1.65h3.243V5.58ZM20.108 8.205h-3.243v1.65h3.243v-1.65ZM20.108 10.831h-3.243v1.65h3.243v-1.65ZM20.108 13.457h-3.243v1.65h3.243v-1.65ZM20.108 16.082h-3.243v1.65h3.243v-1.65Z"
		/>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="m4.76 8.005 1.609-.092 1.011 2.781 1.196-2.896 1.61-.092-1.955 3.95 1.954 3.958-1.701-.114-1.15-3.018-1.15 2.903-1.563-.138 1.817-3.497-1.679-3.745Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default ExcelIcon;
