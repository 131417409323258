import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Jobs = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M6.525.637c-.888.45-1.138 1.025-1.275 3.012a72.12 72.12 0 0 0-.125 3.188c0 1.45-.013 1.537-.25 1.537s-.25-.087-.25-1.437V5.499h-.75c-.663 0-.75.025-.75.25 0 .213.087.25.5.25h.5v1.8c0 1.8 0 1.813.325 2.063.287.224.513.262 1.75.262h1.425v.35c0 .425.2.65.588.65.2 0 .287.075.287.225 0 .188-.313.325-1.588.713-2.012.6-2.037.612-2.037 1.062 0 .237-.138.487-.388.75-.337.337-.387.45-.325.85.1.563.476.9 1.013.9 1-.013 1.413-1.25.612-1.863-.55-.437-.337-.637 1.15-1.087.763-.237 1.413-.425 1.475-.425.05 0 .088.313.088.688 0 .375-.05.687-.125.687-.3 0-.625.512-.625.987 0 .888.963 1.313 1.675.75.25-.2.325-.362.325-.75 0-.475-.325-.987-.625-.987-.063 0-.125-.313-.125-.7 0-.813-.113-.8 1.813-.188 1.237.4 1.412.613.9 1.025-.813.626-.388 1.85.637 1.863.95 0 1.35-1.075.662-1.763-.225-.225-.387-.525-.412-.775l-.037-.4-1.776-.55C9.362 11.687 9 11.524 9 11.35c0-.15.1-.225.287-.225.388 0 .588-.225.588-.65v-.35H11.3c1.237 0 1.462-.038 1.75-.262.325-.25.325-.263.325-2.063v-1.8h.5c.412 0 .5-.037.5-.25 0-.225-.088-.25-.75-.25h-.75v1.438c0 1.35-.012 1.437-.25 1.437s-.25-.087-.25-1.412c-.012-2.275-.213-4.9-.412-5.3-.25-.463-.813-.988-1.25-1.15-.2-.075-1.1-.138-2-.138-1.425 0-1.725.038-2.188.263Zm4.213.55c.837.487.925.862.987 4.125l.063 2.887-.738-.087c-.413-.05-1.438-.088-2.3-.088-.862 0-1.888.038-2.3.088l-.737.087.062-2.875c.075-3.55.162-3.85 1.313-4.275.737-.275 3.1-.187 3.65.138Zm-.488 7.437c2.375.213 2.7.3 2.55.663-.075.187-.45.212-4.05.212-3.6 0-3.975-.025-4.05-.212-.125-.3.075-.375 1.412-.525 2.363-.275 2.5-.288 4.138-.138Z" />
			<path d="M7.987 3.112c-.412.425-.5 1.012-.137 1.012.125 0 .275-.15.362-.375.113-.3.213-.375.525-.375.25 0 .425.088.513.238.175.325.187.3-.313.887-.512.613-.6 1.25-.187 1.25.162 0 .25-.087.25-.25 0-.125.2-.437.437-.675.35-.35.438-.537.438-.925 0-1.062-1.138-1.525-1.888-.787Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75-.001h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Jobs;
