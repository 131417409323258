import { firestore } from 'firebase-admin';
import { DocumentSnapshot } from 'firebase/firestore';
import { ReactNode } from 'react';
// API Types Section
export type AuthStateType = {
	v_job_type: 'candidate' | 'employer';
	v_lance_type: 'freelancer' | 'client';
	isLogin: boolean;
	token: string;
	expires_in: number;
	user: UserType;
};

export type LoginByPhoneRequestType = {
	phone: string;
	code: string;
};

export type LoginByPhoneResponseType = {
	user: UserType;
	token: string;
	expires_in: number;
};

export type LoginRelatedAccountResquestType = {
	id: string;
};

export type LoginRelatedAccountResponseType = {
	user: UserType;
	token: string;
	expires_in: number;
	message: string;
};

export type sendPhoneVerificationRequestType = {
	phone: string;
	type: 'register' | 'login' | 'verification';
};

export type sendPhoneVerificationResponseType = {
	message: string;
};

export type sendEmailVerificationRequestType = {
	email: string;
	type: 'register' | 'login' | 'forgot_password' | 'verification';
	code?: string;
};

export type sendEmailVerificationResponseType = {
	message: string;
};

export type LogoutResponseType = {
	message: string;
};

export type LoginByEmailResponseType = {
	user: UserType;
	token: string;
	expires_in: number;
};

export type CreateBlogRequestType = {
	title: string;
	details: string;
	date?: string;
	comments_enabled: boolean;
	privacy_enum: string; // PrivacyEnumTypes
	is_featured: boolean;
	is_exclusive: boolean;
	sources_copy_right?: string;
	viewable_by: number[];
	categories: number[];
	sub_categories?: number[];
	levels?: number[];
	hashtags?: number[];
	files: number[];
	gallery: number[];
	banners: number[];
	co_authors_inside_profiles_ids?: number[];
	co_authors_entity_outside_profiles?: EntityOutsideProfilesType[];
	co_authors_personal_outside_profiles?: PersonalOutsideProfilesType[];
	publish_enum: string;
};

type SocialLinksType = {
	id: number;
	platform: string;
	link: string;
};

export type CreateBookRequestType = {
	title: string;
	details: string;
	book_type_enum: 'physical' | 'digital';
	privacy_enum: string; // PrivacyEnumTypes
	publish_enum: string;
	payment_type_enum: string;
	price?: string;
	discount?: string;
	offer_type_enum: string;
	comments_enabled: boolean;
	is_scheduled?: boolean;
	scheduled_at: string;
	is_exclusive: boolean;
	sources_copy_right?: string;
	viewable_by: number[];
	categories: number[];
	sub_categories?: number[];
	levels?: number[];
	hashtags?: number[];
	downloadable?: number[];
	coupons: CouponsType[];
	files: number[];
	gallery: number[];
	banners: number[];
	co_authors_inside_profiles_ids?: number[];
	co_authors_entity_outside_profiles?: EntityOutsideProfilesType[];
	co_authors_personal_outside_profiles?: PersonalOutsideProfilesType[];
	social_links?: SocialLinksType[];
};

export type CreateJobRequestType = {
	title: string;
	description: string;
	job_type_enum: string;
	is_commentable: boolean;
	privacy_enum: string; // PrivacyEnumTypes
	is_exclusive: boolean;
	contract_type_enum?: string;
	work_type_enum: string;
	salary: string;
	payment_cycle_enum: number | null;
	department_id: number | null;
	job_branch_id: number | null;
	role_level_id: number | null;
	job_branch: string;
	application_deadline: string;
	job_personality_requirements: string;
	job_experience_requirements: string;
	job_skills_requirements: string;
	job_education_requirements: string;
	gender_requirement_enum: string;
	job_benefits: string;
	job_salary: string;
	job_salary_is_disclosed: boolean;
	requiring_cross_border_travel: boolean;
	job_hiring_date: string;
	publishing_status: string;
	longitude: number | null;
	latitude: number | null;
	website: string;
	address: string;
	email: string;
	publishing_date: string;
	country_id: number | null;
	city_id: number | null;
	age_from: number | null;
	age_to: number | null;
	military_status_enum: string;
	car_ownership: boolean;
	driving_license: boolean;
	// image: number | null;
	marital_status_enum: string[];
	required_languages: object[];
	nationality_country_ids: number[];
	residence_city_ids: number[];
	residence_country_ids: number[];
	screening_questions: object[];
	viewable_by: number[];
	categories: number[];
	sub_categories?: number[];
	levels?: number[];
	hashtags?: string[];
	files: number[];
	gallery: number[];
	banner: number[];
};

type EntityOutsideProfilesType = {
	id: string;
	logo?: number;
	name: string;
	business_type_id: string;
	establishment_date: string;
	head_office_country_id: string;
	head_office_city_id: string;
	no_of_employees: string;
	no_of_employees_inside: string;
	no_of_employees_outside: string;
	address: string;
	maps_url: string;
	website: string;
	phone: string;
	landine: string;
	whatsapp: string;
	email: string;
	social_links: UserPersonalProfileSocialMediaLinkType[];
};

type PersonalOutsideProfilesType = {
	id: string;
	name: string;
	nationality_country_id: number;
	nationality_city_id: number;
	image?: number;
	email: string;
	phone: string;
	title?: string;
	employer?: string;
	about?: string;
	gender: 'male' | 'female';
	socialLinks: UserPersonalProfileSocialMediaLinkType[];
};

export type CreateBlogResponseType = {
	data: BlogPostType;
};

export type CreateBookResponseType = {
	data: BookPostType;
};

export type CreateJobResponseType = {
	data: JobPostType;
};

export type WalletBalanceResponseType = {
	data: {
		balance: {
			current_balance: string;
			escrow_balance: string;
			grand_total_balance: string;
			withdraw_balance: string;
		};
		total_income: string;
		income: string[];
		total_expenses: string;
		expenses: string;
	};
};
export type DateFilterType = 'all_time' | 'last_7_days' | 'last_14_days' | 'last_30_days' | 'last_3_months' | 'custom';
export type WalletBalanceRequestType = {
	date_filter?: DateFilterType;
	end_date?: string;
	start_date?: string;
};

export type WalletFundResponseType = {
	message: string;
};

export type WalletFundRequestType = {
	amount: number;
};

export type WalletWithdrawResponseType = {
	message: string;
};

export type WalletWithdrawRequestType = {
	amount: number;
};

export type walletWithdrawRequestResponseType = {
	data: {
		id: string;
		user_id: string;
		wallet_id: string;
		amount: string;
		status: string;
		created_at: string;
		updated_at: string;
	}[];
};

export type walletWithdrawRequestRequestType = {
	sort_by: 'date' | 'value' | 'status';
	sort_order: 'asc' | 'desc';
};

export type walletStatementsResponseType = {
	data: StatmentType[];
};

export type walletStatementsRequestType = {
	sort_by: 'date' | 'value' | 'status';
	sort_order: 'asc' | 'desc';
};
export type walletStatementsWithdrawAndFundedRequestType = {
	type: 'funding' | 'withdrawal';
	end_date: string | null;
	start_date: string | null;
	limit?: number;
	page: number;
};

export type wallet_enum = 'payment' | 'payout';

//useing to delete and change primary
export type WalletSubActionsRequestType = {
	wallet_enum: wallet_enum;
	payment_wallet_id?: number;
	payout_wallet_id?: number;
};

export type DayScheduleType = {
	day: string;
	time_slots: { start_time: string; end_time: string }[];
};
export type updatePersonalProfileRequestType = {
	id: string;
	public_name?: string;
	residence_country_id?: string;
	residence_city_id?: string;
	hobbies?: string;
	employment_status?: string;
	total_years_of_experience?: string;
	notice_period?: string;
	address?: string;
	driving_license_enum?: 'yes' | 'no';
	social_status_enum?: string;
	military_status_enum?: string;
	excepected_gross_monthly_salary?: string;
	website?: string;
	driving_license_issuing_country_id?: string;
	is_open_to_work?: boolean;
	no_of_dependents?: number;
	whatsapp?: string;
	horoscope?: string;
	own_a_car?: boolean;
	religion?: string;
	willing_to_relocate?: 1 | 0;

	gap_between_consultations?: number;
	availability?: DayScheduleType[];
	hourly_rate: number;
	work_experiences?: {
		company_or_institution_name?: string;
		employer_entity_type?: 'inside_v_verse' | 'outside_v_verse';
		employer_inside_vv?: number | null;
		employer_outside_vv?: OutsideProfileType;
		job_department?: string;
		job_level?: string;
		country_id?: string;
		city_id?: string;
		start_date?: string;
		is_current?: boolean;
		end_date?: string;
		description?: string;
		related_entity_ids?: number[] | null;
		outside_profile?: OutsideProfileType | null;

		inside_reference_id?: number;
		reference_outside_vv?: {
			id?: number;
			name?: string;
			nationality_country_id?: number | null;
			country_id?: number | null;
			city_id?: number | null;
			website?: string | null;
			whatsapp?: string | null;
			image?: number | null;
			email: string;
			phone?: string | null;
			title?: string | null;
			employer?: string | null;
			about: string;
			gender?: 'male' | 'female' | null;
			social_links?: SocialMediaLink[];
		};
	}[];

	educations?: {
		company_or_institution_name?: string;
		entity_type?: 'inside_v_verse' | 'outside_v_verse';
		inside_related_entity_id?: number | null;
		outside_profile?: OutsideProfileType | null;
		start_date?: string;
		is_graduating?: 0 | 1;
		end_date?: string;
		grade?: string;
		description?: string;
		attachments?: number[];
	}[];

	skill_sets?: {
		skill_set_name?: string;
		skills?: {
			name?: string;
			percentage?: number;
		}[];
		attachments?: number[];
	}[];

	achievements?: {
		id?: string;
		title?: string;
		date?: string;
		entity_type?: 'inside_v_verse' | 'outside_v_verse';
		inside_related_entity_id?: number | null;
		outside_profile?: OutsideProfileType | null;
		description?: string;
		attachments?: number[];
	}[];

	resume?: {
		cover_letter?: string;
		attachments?: number[];
		deleted_attachments?: number[];
	};

	module_array?: {
		module_enum:
			| 'General'
			| 'V-Client'
			| 'V-Verse'
			| 'V-Guide'
			| 'V-Blog'
			| 'V-Nation'
			| 'V-Tube'
			| 'V-Jobs'
			| 'V-College'
			| 'V-Market'
			| 'V-Lance'
			| 'V-Events'
			| 'V-Marketing'
			| 'V-Agency'
			| 'V-Webinars'
			| 'V-Experts'
			| 'V-Cast'
			| 'V-Book'
			| 'V-Mood'
			| 'V-Buzz';
		profile_type?: 'user' | 'profile' | 'employer' | 'candidate' | 'freelancer' | 'client';
		attachment_type_enum?: string[];
		files?: number[];
		custom_tagline?: string;
		profile_message?: string;
		social_media_links?: UserPersonalProfileSocialMediaLinkType[];
		languages?: {
			language_id: number;
			language_level_id: number;
		}[];

		billing_address?: string;
		shipping_addresses?: {
			id?: string;
			title?: string;
			address?: string;
			maps_link?: string;
			delivery_time?: string;
			delivery_time_end?: string;
		}[];

		related_entity_ids?: number[] | null;
		hiring_for_entity_ids?: number[] | null;
		outside_profile?: OutsideProfileType | null;
		weekly_hours_availability?: string;

		profile_hiring_preference?: {
			inside_related_entity_id?: number;
			hiring_status?: boolean;
			gender_hiring_preference?: 'all' | 'male' | 'female';
			education_level_hiring_preference?: (
				| 'all'
				| 'High School Degree Holders'
				| 'University Degree Holders'
				| 'MBA Degree Holders'
				| 'PhD Degree Holders'
				| 'Any Degree Holders'
			)[];
			nationality_hiring_preference?: string[];
			residence_country_hiring_preference?: string[];
			residence_city_hiring_preference?: string[];
			prefered_hourly_rate?: number;
		};
	}[];
};

const xxxx = {
	public_name: 'string',
	hourly_rate: 1000000,
	weekly_hours_availability: 9999,
	no_of_employees: 2000000000,
	no_of_employees_inside_vv: 999999999,
	no_of_employees_outside_vv: 999999999,
	website: 'string',
	whatsapp: 'string',
	gap_between_consultations: 0,
	module_array: [
		{
			module_enum: 'General',
			profile_type: 'user',
			profile_message: 'string',
			custom_tagline: 'string',
			is_featured: true,
			official_full_name: 'string',
			social_media_links: [
				{
					id: 0,
					platform: 'string',
					link: 'string',
				},
			],
			files: [0],
			attachment_type_enum: ['profile'],
			languages: [
				{
					id: 'string',
					language_id: 0,
					language_level_id: 0,
				},
			],
			shipping_addresses: [
				{
					id: 0,
					title: 'string',
					address: 'string',
					maps_link: 'string',
					delivery_time: 'string',
					delivery_time_end: 'string',
				},
			],
			profile_hiring_preference: {
				hiring_status: true,
				gender_hiring_preference: 'all',
				education_level_hiring_preference: ['all'],
				nationality_hiring_preference: ['string'],
				residence_country_hiring_preference: ['string'],
				residence_city_hiring_preference: ['string'],
				prefered_hourly_rate: 0,
				inside_related_entity_id: 0,
				hiring_for_enum: 'inside_v_verse',
			},
			outside_profile: {
				id: 'string',
				name: 'string',
				business_type_id: 0,
				establishment_date: 'string',
				head_office_country_id: 0,
				head_office_city_id: 0,
				no_of_employees: 0,
				no_of_employees_inside: 0,
				no_of_employees_outside: 0,
				about: 'string',
				logo: 0,
				address: 'string',
				maps_url: 'string',
				website: 'string',
				phone: 'string',
				landline: 'string',
				whatsapp: 'string',
				email: 'user@example.com',
				social_links: [
					{
						id: 0,
						platform: 'string',
						link: 'string',
					},
				],
			},
			hiring_for_entity_ids: [0],
		},
	],
	shipping_addresses: [
		{
			module_enum: 'General',
		},
	],
	availability: [
		{
			day: 'Monday',
			time_slots: [
				{
					start_time: 'string',
					end_time: 'string',
				},
			],
		},
	],
	languages: [
		{
			language_id: 0,
			language_level_id: 0,
		},
	],
	branches: [
		{
			id: 0,
			is_head_office: true,
			official_full_name: 'string',
			country_id: 0,
			city_id: 0,
			phone_number: 'string',
			email: 'user@example.com',
			address: 'string',
			website: 'string',
			maps_url: 'string',
			landline: 'string',
			whatsapp: 'string',
			social_media_links: [
				{
					id: 'string',
					platform: 'string',
					link: 'string',
				},
			],
			opening_hours: [
				{
					id: 'string',
					day: 'string',
					starting_time: 'string',
					ending_time: 'string',
					status: '1',
				},
			],
		},
	],
};
export type updateEntityProfileRequestType = {
	id: string;
	public_name?: string;
	hourly_rate?: number;
	weekly_hours_availability?: number;
	no_of_employees?: number;
	no_of_employees_inside_vv?: number;
	no_of_employees_outside_vv?: number;
	website?: string;
	whatsapp?: string;

	gap_between_consultations?: number;
	availability?: DayScheduleType[];

	module_array?: {
		module_enum?:
			| 'General'
			| 'V-Verse'
			| 'V-Guide'
			| 'V-Blog'
			| 'V-Nation'
			| 'V-Tube'
			| 'V-Jobs'
			| 'V-College'
			| 'V-Market'
			| 'V-Lance'
			| 'V-Events'
			| 'V-Marketing'
			| 'V-Agency'
			| 'V-Webinars'
			| 'V-Experts'
			| 'V-Buzz'
			| 'V-Cast'
			| 'V-Book'
			| 'V-Mood'
			| 'V-Client';
		profile_type?: 'user' | 'profile' | 'candidate' | 'employer' | 'freelancer' | 'client';
		profile_message?: string;
		custom_tagline?: string;
		is_featured?: boolean;
		official_full_name?: string;
		social_media_links?: { platform?: string; link?: string }[];
		files?: number[];
		attachment_type_enum?: ('profile' | 'cover' | 'video')[];
		languages?: {
			id?: string;
			language_id?: number;
			language_level_id?: number;
		}[];
		shipping_addresses?: {
			id?: number;
			title?: string;
			address?: string;
			maps_link?: string;
			delivery_time?: string;
			delivery_time_end?: string;
		}[];
		profile_hiring_preference?: {
			hiring_status?: boolean;
			gender_hiring_preference?: 'all' | 'male' | 'female';
			education_level_hiring_preference?:
				| 'all'
				| 'High School Degree Holders'
				| 'University Degree Holders'
				| 'MBA Degree Holders'
				| 'PhD Degree Holders'
				| 'Any Degree Holders';
			nationality_hiring_preference?: string[];
			residence_country_hiring_preference?: string[];
			residence_city_hiring_preference?: string[];
			prefered_hourly_rate?: number;
			inside_related_entity_id?: number;
			hiring_for_enum?: 'inside_v_verse' | 'outside_v_verse';
			outside_profile?: {
				name?: string;
				business_type_id?: number;
				establishment_date?: string;
				head_office_country_id?: number;
				head_office_city_id?: number;
				no_of_employees?: number;
				no_of_employees_inside?: number;
				no_of_employees_outside?: number;
				about?: string;
				logo?: number;
				address?: string;
				maps_url?: string;
				website?: string;
				phone?: string;
				landline?: string;
				whatsapp?: string;
				email?: string;
				social_links?: SocialMediaLink[];
			};
			hiring_for_entity_ids?: number[];
		};
	};
	branches?: {
		is_head_office?: 1 | 0;
		official_full_name?: string;
		country_id?: number | undefined;
		city_id?: number | undefined;
		phone_number?: string;
		email?: string;
		address?: string;
		website?: string;
		maps_url?: string;
		landline?: string;
		whatsapp?: string;
		social_media_links?: SocialMediaLink[];
		opening_hours?: {
			day?: string;
			starting_time?: string;
			ending_time?: string;
			status?: '1' | '0';
		}[];
	}[];
};

export type deleteShippingAddressType = {
	addressId: string;
	userId: string;
};

export type deleteLanguageType = {
	id: string;
	languageId: string;
};

type OutsideProfileType = {
	name?: string;
	logo?: string;
	about?: string;
	business_type_id?: string;
	establishment_date?: string;
	head_office_country_id?: string;
	head_office_city_id?: string;
	no_of_employees?: number;
	no_of_employees_inside?: number;
	no_of_employees_outside?: number;
	head_office_address?: string;
	maps_url?: string;
	landline?: string;
	phone?: string;
	whatsapp?: string;
	email?: string;
	website?: string;
	social_links?: UserPersonalProfileSocialMediaLinkType[];
	index?: number;
};

export type userPersonalProfileResponseType = {
	data: UserPersonalProfileType;
	error: string;
};

export type userPersonalProfileRequestType = {
	id: string;
};

export type userBusinessProfileResponseType = {
	data: UserBusinessProfileType;
	error: string;
};

export type userBusinessProfileRequestType = {
	id: string;
};

export type updatePersonalProfileResponseType = {
	data: UserPersonalProfileType;
	module_enum: string;
	profile_type: string;
};

export type updateEntityProfileResponseType = {
	data: UserBusinessProfileType;
	module_enum: string;
	profile_type: string;
};

export type updatePersonalProfileLanguageRequestType = {
	id: string;
};

export type deletePersonalProfileLanguageRequestType = {
	id: string;
	languageId: string;
};

export type updatePersonalProfileSocialMediaResponseType = {
	data: UserPersonalProfileType;
};

export type updatePersonalProfileSocialMediaRequestType = {
	id: string;
	social_media_links: [
		{
			platform: string;
			link: string;
		},
	];
};

export type deletePersonalProfileSocialMediaResponseType = {
	error: string;
};

export type deletePersonalProfileSocialMediaRequestType = {
	userId: string;
	socialMediaId: string;
};

export type updatePersonalProfileStoreAttachmentsResponseType = {
	error: string;
};

export type updatePersonalProfileStoreAttachmentsRequestType = {
	id: string;
	module_enum:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz';
	profile_type: 'user' | 'profile' | 'employer' | 'candidate' | 'freelancer' | 'client';
	file: string;
	attachment_type_enum: 'profile' | 'cover' | 'video';
};

export type myRelatedAccountsResponseType = {
	data: ConnectedUserType[];
};

export type assetName =
	| 'user'
	| 'blog post'
	| 'book'
	| 'job posting'
	| 'endorsement'
	| 'social page'
	| 'social page post'
	| 'social group'
	| 'social group post'
	| 'social timeline post'
	| 'video channel'
	| 'video'
	| 'client'
	| 'freelancer'
	| 'room'
	| 'conversation'
	| 'room_message'
	| 'message'
	| 'sponsorship'
	| 'employer'
	| 'candidate';

export type myRelatedEntityProfilesResponseType = {
	data: ConnectedUserType[];
	meta: PaginationMetaType;
};

export type storeNewEntityProfileResponseType =
	| {
			message: string;
	  }
	| string;

export type storeNewEntityProfileRequestType = {
	full_official_name: string;
	business_type_id: number;
	establishment_date: string;
	entity_license_Number: string;
	head_office_country_id: number;
	head_office_city_id: number;
	head_office_address: string;
	phone: string;
	email: string;
	attachments: string[];
};

export type NotificationsType = {
	notifications: string;
};

export type LanguageResponseType = {
	data: {
		id: string;
		name: string;
	}[];
};

export type LanguageLevelResponseType = {
	data: {
		id: string;
		name: string;
	}[];
};

export type GeneralUserRequestType = {
	profile_type_enum: 'personal' | 'business' | 'all';
};

export type GeneralUserResponseType = {
	data: ConnectedUserType[];
};

export type UserConversationsRequestType = {
	filter?: 'all' | 'all_except_hidden' | 'unread' | 'favorites' | 'hidden';
	label?: 'personal' | 'business' | 'social' | 'interviews' | 'pre_orders' | 'open_orders' | 'post_orders' | '';
	page?: number;
	per_page?: number;
	search?: string;
};

export type UserConversationsResponseType = {
	data: UserConversationType[];
	meta: MetaType;
};

export type RoomsRequestType = {
	filter?: 'all' | 'all_except_hidden' | 'unread' | 'favorites' | 'hidden';
	label?: 'personal' | 'business' | 'social' | 'interviews' | 'pre_orders' | 'open_orders' | 'post_orders' | '';
	page?: number;
	per_page?: number;
	search?: string;
};

export type RoomsResponseType = {
	data: RoomType[];
	meta: MetaType;
};

export type createRoomRequestType = {
	name: string;
	tag_line: string;
	image: number | null;
};

export type createRoomResponseType = {
	data: RoomType;
};

export type UpdateRoomsRequestType = {
	flag: '1' | '0';
	flag_name: 'is_hidden' | 'is_favorite' | 'is_muted';
	conversation_id: string | number;
};

export type UpdateRoomsResponseType = {
	message: string;
};

interface meetingPlan {
	planable: {
		id: number;
		user_id: number;
		title: string;
		description: string;
		category: string;
		type: string;
		timezone: string;
		status: string;
		is_online: number;
		organizer_id: number;
		priority: string;
		start_time: string;
		starting_date?: string;
		end_time: string;
		ending_date?: string;
		reminder_time: string[];
		repeat: string;
		created_at: string;
		updated_at: string;
		address: string;
		maps_link: string;
		meeting_link: string;
		webinar_type: string;
	};
	id: number;
	type: string;
}

export type MeetingPlan = {
	data: meetingPlan[];
};

export type getUserDetailRequestType = {
	module_enum?:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-Book'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Cast'
		| 'V-Buzz';
	asset_name?: 'freelancer' | 'client' | 'employer' | 'candidate' | 'user' | 'profile';
	user_id?: number;
};

export type getUserDetailResponseType = {
	data: UserType;
	module_enum: string;
};

export type getmyJobsRequestType = {
	limit?: number;
	page?: number;
	status?: 'published' | 'expired' | 'draft' | 'scheduled' | 'archived';
	filters?: {
		search?: string;
		comments?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		job_branch: number[];
		payment_cycle: string[];
		hiring_status_enum: string[];
		job_contract_type: string[];
		job_department: number[];
		job_country: number[];
		featured_status: boolean;
		vv_exclusive_status: boolean;
		application_status: string;
		job_type_enum: string;
		role_level_ids: number[];
		salary: {
			min?: number;
			max?: number;
		}[];
		category_ids: number[];
		sub_category_ids: number[];
		level_ids: number[];
		publishing_date: string[];
		total_applicants: {
			min?: number;
			max?: number;
		}[];
		sorting?: {
			order_by?: string;
			order?: string;
		};
	};
};

export type getmyJobsResponseType = {
	data: JobPostingType[];
	meta: PaginationMetaType;
};

export type getBooksResponseType = {
	data: BookPostType[];
};

export type getAllJobPostingsRequestType = {
	filters?: {
		search?: string;
		comments?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		job_branch?: number[];
		payment_cycle?: string[];
		hiring_status_enum?: string[];
		job_contract_type?: string[];
		job_department?: number[];
		job_country?: number[];
		job_city?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me';
		featured_status?: boolean;
		vv_exclusive_status?: boolean;
		application_status?: string;
		job_type_enum?: string;
		role_level_ids?: number[];
		salary?: {
			min?: number;
			max?: number;
		};
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: string[];
		total_applicants?: {
			min?: number;
			max?: number;
		};
		sorting?: {
			order_by?: string;
			order?: string;
		};
		employer_endorsements?: {
			min: number;
			max: number;
		};
		employer_country_id?: number[];
		employer_city_id?: number[];
		employer_profile_type_enum?: string[];
		employer_hiring_rate?: {
			min: number;
			max: number;
		};
		employer_connection_status?: string;
		employer_is_online?: string;
	};
	limit?: number;
	page?: number;
};

// channels Filter vv-search
export type getAllChannelsRequestType = {
	filters?: {
		search?: string;
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me'; //channel privacy
		publishing_date?: {
			min?: string;
			max?: string;
		};
		videos?: {
			min?: number;
			max?: number;
		};
		subscribers?: {
			min?: number;
			max?: number;
		};
		views?: {
			min?: number;
			max?: number;
		};
		comments?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		reactions?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean;
		subscribing_status?: string[];
		author_profile_type_enum?: string[];
		author_country_id?: number[];
		author_city_id?: number[];
		author_connection_status?: string;
		author_is_online?: string;
		sorting?: {
			order_by?: string;
			order?: string;
		};
	};
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
};

export type getAllHashChannelsRequestType = {
	filters?: {
		search?: string;

		publishing_date?: {
			min?: string;
			max?: string;
		};
		videos?: {
			min?: number;
			max?: number;
		};
		subscribers?: {
			min?: number;
			max?: number;
		};
		views?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};

		featured_status?: boolean;
		subscribing_status?: string[];
		author_profile_type_enum?: string[];
		author_country_id?: number[];
		author_city_id?: number[];
		author_connection_status?: string;
		sorting?: {
			// Map the 'order_by' to match with the ones allowed in getAllChannelsRequestType
			order_by?:
				| 'name'
				| 'date'
				| 'no_of_videos'
				| 'no_of_subscribers'
				| 'no_of_views'
				| 'no_of_reactions'
				| 'no_of_shares'
				| 'rating'
				| 'no_of_reviews'; // Adjusted here for compatibility
			order?: 'asc' | 'desc';
		};
	};
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?:
		| 'name' // Changed from 'name' to 'title'
		| 'date'
		| 'no_of_videos'
		| 'no_of_subscribers'
		| 'no_of_views'
		| 'no_of_reactions'
		| 'no_of_shares'
		| 'rating'
		| 'no_of_reviews'; // Adjusted for consistency with the required types
};

export type getAllVidoesRequestType = {
	filters?: {
		search?: string;
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me'; //channel privacy
		publishing_date?: {
			min?: string;
			max?: string;
		};
		author_profile_type_enum?: string[];
		author_country_id?: number[];
		author_city_id?: number[];
		author_is_online?: string;
		featured_status?: boolean;
		vv_exclusive_stats?: string[];
		video_type?: string[];
		duration?: {
			min?: number;
			max?: number;
		};
		author_type?: string[]; // own\others\subscribedChannels
		comments?: {
			min?: number;
			max?: number;
		};
		reactions?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		views?: {
			min?: number;
			max?: number;
		};
		author_connection_status?: string;
		sorting?: {
			order_by?: string;
			order?: string;
		};
	};
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
};

export type getAllShortsRequestType = {
	filters?: {
		search?: string;
		publishing_date?: {
			min?: string;
			max?: string;
		};
		views?: {
			min?: number;
			max?: number;
		};
		comments?: {
			min?: number;
			max?: number;
		};
		reactions?: {
			min?: number;
			max?: number;
		};
		author_connection_status?: string;
		author_is_online?: string;
		shares?: {
			min?: number;
			max?: number;
		};
		author_profile_type_enum?: string[];
		author_country_id?: number[];
		author_city_id?: number[];
		sorting?: {
			order_by?: string;
			order?: string;
		};
	};
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
};

export type getAllConsultationsRequestType = {
	status?: 'published' | 'draft' | 'archived' | 'saved';
	filters?: {
		categories?: number[];
		sub_categories?: number[];
		levels?: number[];
		search?: string;
		comments?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		job_branch?: number[];
		payment_cycle?: string[];
		hiring_status_enum?: string[];
		job_contract_type?: string[];
		job_department?: number[];
		job_country?: number[];
		job_city?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me';
		featured_status?: boolean;
		vv_exclusive_status?: boolean;
		application_status?: string;
		job_type_enum?: string;
		role_level_ids?: number[];
		salary?: {
			min?: number;
			max?: number;
		};
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: string[];
		total_applicants?: {
			min?: number;
			max?: number;
		};
		employer_endorsements?: {
			min: number;
			max: number;
		};
		employer_country_id?: number[];
		employer_city_id?: number[];
		employer_profile_type_enum?: string[];
		employer_hiring_rate?: {
			min: number;
			max: number;
		};
		employer_connection_status?: string;
		employer_is_online?: string;
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'name' | 'date' | 'price' | 'title';
	page?: number;
};
export type getAllBooksRequestType = {
	order?: 'asc' | 'desc';
	order_by?:
		| 'name'
		| 'date'
		| 'comments'
		| 'likes'
		| 'shares'
		| 'rating'
		| 'reviews'
		| 'no_of_sales'
		| 'no_of_customers'
		| 'no_of_orders'
		| 'no_of_likes'
		| 'no_of_shares'
		| 'no_of_rating'
		| 'no_of_reviews'
		| 'title';
	filters?: {
		categories?: number[];
		sub_categories?: number[];
		levels?: number[];
		search?: string;
		comments?: {
			min?: number;
			max?: number;
		};
		customers?: {
			min?: number;
			max?: number;
		};
		sales?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		job_branch?: number[];
		payment_cycle?: string[];
		hiring_status_enum?: string[];
		job_contract_type?: string[];
		job_department?: number[];
		job_country?: number[];
		job_city?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me';
		featured_status?: boolean;
		profile_type?: 'personal' | 'business';
		vv_exclusive_status?: boolean;
		application_status?: string;
		job_type_enum?: string;
		role_level_ids?: number[];
		salary?: {
			min?: number;
			max?: number;
		};
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: {
			min?: string;
			max?: string;
		};
		total_applicants?: {
			min?: number;
			max?: number;
		};
		sorting?: {
			order_by?: string;
			order?: string;
		};
		employer_endorsements?: {
			min: number;
			max: number;
		};
		employer_country_id?: number[];
		employer_city_id?: number[];
		employer_profile_type_enum?: string[];
		employer_hiring_rate?: {
			min: number;
			max: number;
		};
		employer_connection_status?: string;
		employer_is_online?: string;

		v_author_profile_type?: ('personal' | 'business')[];
		book_type?: ('physical' | 'digital')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
	};
	limit?: number;
	page?: number;
};

export type getAllJobPostingsResponseType = {
	data: JobPostingType[];
	meta: {
		current_page: number;
		last_page: number;
	};
};

export type PodcastgetallRequestType = {
	limit: number; // Must be between 1 and 50
	order: 'asc' | 'desc'; // Sorting order
	order_by: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews'; // Sorting field
	filters: Filters; // Nested filters object
	page?: string | number;
};

interface Filters {
	categories?: number[]; // Array of category IDs
	sub_category?: number[]; // Array of subcategory IDs
	privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[] | null; // Privacy settings
	levels?: number[]; // Array of level IDs
	search?: string | null; // Search query
	publishing_date?: string | null; // Publishing date in string format
	comments?: CommentLikeShareFilter; // Array of objects for comments filter
	likes?: CommentLikeShareFilter; // Array of objects for likes filter
	shares?: CommentLikeShareFilter; // Array of objects for shares filter
	ratings?: RatingFilter; // Ratings filter object
	reviews?: RatingFilter; // Array of objects for reviews filter
	featured_status?: (boolean | null)[]; // Featured status
	vv_exclusive_status?: (boolean | null)[]; // VV exclusive status
	v_author_profile_type?: ('personal' | 'business')[] | null; // Author profile type
	v_author_city?: (number | null)[]; // Array of city IDs or null
	v_author_country?: (number | null)[] | undefined; // Array of country IDs or null
	v_author_connection_status?: ('connected' | 'not_connected')[] | null; // Connection status
	v_author_is_online?: ('yes' | 'no')[] | null; // Online status
	super_creator_status?: ('yes' | 'no')[] | null; // Super creator status
	super_seller_status?: ('yes' | 'no')[] | null; // Super seller status
	super_affiliate_status?: ('yes' | 'no')[] | null; // Super affiliate status
	no_of_sales_form?: number | null; // Minimum number of sales
	no_of_sales_to?: number | null; // Maximum number of sales
	no_of_order_form?: number | null; // Minimum number of orders
	no_of_order_to?: number | null; // Maximum number of orders
	no_of_subscribe_form?: number | null; // Minimum number of subscriptions
	no_of_subscribe_to?: number | null; // Maximum number of subscriptions
	no_of_plays_form?: number | null; // Minimum number of plays
	no_of_plays_to?: number | null; // Maximum number of plays
}

interface CommentLikeShareFilter {
	min?: number | null; // Minimum value for comments/likes/shares, must be >= 0 or null
	max?: number | null; // Maximum value for comments/likes/shares, must be >= 0 or null
}

interface RatingFilter {
	min: number | null; // Minimum rating, must be between 0 and 5 or null
	max: number | null; // Maximum rating, must be between 0 and 5 or null
}

export interface getAllposdcastsResponseType {
	data: PodcastType[];
	meta: {
		current_page: number;
		last_page: number;
	};
}

export interface PodcastType {
	id: string;
	custom_id: string;
	scheduled_date: string;
	is_scheduled: string;
	image: string;
	is_private: string;
	comments_enabled: string;
	title: string;
	publish_enum: string;
	is_featured: string;
	is_exclusive: string;
	details: string;
	category_tree: CastCategoryTreeResource;
	categories: CastCategoryResource[];
	likes_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	hashtags: string;
	sources_copy_right: string;
	files: CastAttachmentResource[];
	gallery: CastAttachmentResource[];
	banners: CastAttachmentResource[];
	user: UserpodcastType;
	co_authors_entity_outside: OutSideProfileResource[];
	co_authors_personal_outside: Record<any, any>;
	co_authors_inside: ConnectedUserResource[];
	is_liked: string;
	is_saved: string;
	is_reported: string;
}

interface UserPodcastCounts {
	sales_sum: number;
	earned_sum: number;
	rating_avg: number;
	rating_sum: number;
	likes_count: number;
	posts_count: number;
	invested_sum: number;
	orders_count: number;
	points_count: number;
	rating_count: number;
	shares_count: number;
	reviews_count: number;
	comments_count: number;
	episodes_count: number;
	podcasts_count: number;
	customers_count: number;
	endorsements_count: number;
	profile_likes_count: number;
	profile_shares_count: number;
}

interface UserpodcastType {
	id: number;
	full_name: string;
	email: string;
	role: string;
	phone: string;
	is_online: boolean;
	is_online_profile_state: boolean;
	current_title: string;
	custom_id: string;
	is_featured: boolean;
	email_verified_at: string; // You may consider using Date if converting to Date object
	phone_verified_at: string; // You may consider using Date if converting to Date object
	document_verified_at: string; // You may consider using Date if converting to Date object
	id_verified_at: string; // You may consider using Date if converting to Date object
	verification_status: string;
	is_hide_welcome: number; // Assuming this is a number (0 or 1)
	current_company: string;
	gender: string;
	banner_tags: string[];
	country: string;
	businessType: string | null;
	age: number;
	no_of_employees: string;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string; // You may consider using Date if converting to Date object
	joining_date: string; // You may consider using Date if converting to Date object
	is_connected: boolean;
	connection_status: string;
	profile_type: string;
	profile_id: number;
	image: string | null;
	counts_text: string;
	counts: UserPodcastCounts;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
	is_reported: boolean;
	v_guide_is_saved: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
	is_my_account: boolean;
	is_saved: boolean;
	is_liked: boolean;
}

interface CastCategoryTreeResource {
	categories: CastCategoryResource[]; // Array of categories
}

interface CastCategoryResource {
	id: string; // ID of the category
	name: string; // Name of the category
}

interface CastSubCategoryResource {
	id: string; // ID of the subcategory
	name: string; // Name of the subcategory
	category_id: string; // ID of the parent category
}

interface CastLevelResource {
	id: string; // ID of the level
	name: string; // Name of the level
	sub_category_id: string; // ID of the parent subcategory
}

interface CastCategoryResource {
	id: string;
	name: string;
	sub_categories: CastSubCategoryResource[];
}

interface CastSubCategoryResource {
	id: string;
	name: string;
	category_id: string;
	levels: CastLevelResource[];
}

interface CastLevelResource {
	id: string;
	name: string;
	sub_category_id: string;
}

interface CastAttachmentResource {
	id: string;
	pod_cast_id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	url: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
	storage_file_id: string;
}

interface OutSideProfileResource {
	id: string;
	name: string;
	logo: AttachmentResource;
	user_id: string;
	business_type_id: string;
	business_type: string;
	establishment_date: string;
	head_office_country_id: string;
	head_office_city_id: string;
	head_office_country: string;
	head_office_city: string;
	no_of_employees: string;
	no_of_employees_inside: string;
	no_of_employees_outside: string;
	address: string;
	maps_url: string;
	website: string;
	phone: string;
	landline: string;
	whatsapp: string;
	email: string;
	about: string;
	social_links: Record<any, any>;
}

// interface PersonalProfileOutSideResource {
// 	// Define the properties of Record<any, any> here
// }

interface AttachmentResource {
	id: string;
	pod_cast_id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	url: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
	storage_file_id: string;
	attachment_type_enum?: string;
}

export type getAllBooksResponseType = {
	data: BookPostType[];
	meta: {
		current_page: number;
		last_page: number;
	};
};

export type getJobCandidatesStatusRequestType = {
	hiring_status_enum:
		| 'accepted'
		| 'rejected'
		| 'shortlisted'
		| 'interviews'
		| 'offered'
		| 'hired'
		| 'archived'
		| 'withdraw'
		| 'remove';
	job_candidate_id: number;
	interview_date?: string;
};

export type getJobCandidatesStatusResponseType = {
	data: JobPostingType[];
};

export type getJobCandidateRequestType = {
	job_posting_id: string;
	cover_letter?: string;
	is_custom_application?: boolean;
	attachments?: number[];
	screening_questions?: {
		question_id?: number;
		answer?: string;
		is_required?: boolean;
		quesstion_type_enum?: 'text' | 'multiple_choice';
		bullets?: {
			bullet: string;
		}[];
	}[];
};

export type getbulkJobPostingResponseType = {
	message: string;
};

export type getbulkJobPostingRequestType = {
	details?: string;
	interaction_type_enum: 'like' | 'save' | 'unsave';
	asset_ids: number[];
};

export type getJobPostingInteractionRequestType = {
	job_posting_id: string;
	interaction_type_enum: 'like' | 'comment' | 'review' | 'share' | 'save';
	details?: string;
	attachments?: number[];
	parent_interaction_id?: string | number;
	review?: string;
	rating?: string | number;
};

export type getServicePostingInteractionRequestType = {
	service_id: string;
	interaction_type_enum: 'like' | 'comment' | 'review' | 'share' | 'save';
	details?: string;
	attachments?: number[];
	parent_interaction_id?: string | number;
	review?: string;
	rating?: string | number;
};

export type getBookInterationRequest = {
	book_id: string;
	interaction_type_enum: 'like' | 'comment' | 'review' | 'share' | 'save';
	details?: string;
	attachments?: number[];
	attachments_delete_ids?: number[];
	parent_interaction_id?: string | number;
	review?: string;
	rating?: string | number;
	voice_note?: string;
	voice_note_delete_id?: number;
};

export type getJobEmployersResponseType = {
	data: ConnectedUserResource[];
	pagination: {
		current_page: number;
		per_page: number;
		total: number;
		total_pages: number;
	};
};

export type getJobEmployersRequestType = {
	filters?: {
		profile_type_enum?: 'personal' | 'business';
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		search?: string;
		joining_date?: {
			min?: string;
			max?: string;
		};
		activities?: string;
		location_latitude?: number;
		location_longitude?: number;
		max_distance?: number;
		gender?: 'male' | 'female' | 'other';
		plan?: string[];
		no_of_employees?: {
			min?: string;
			max?: string;
		};
		no_of_employees_inside_vv?: {
			min?: string;
			max?: string;
		};
		no_of_employees_outside_vv?: {
			min?: string;
			max?: string;
		};
		no_of_published_jobs?: {
			min?: string;
			max?: string;
		};
		hiring_rate?: {
			min?: string;
			max?: string;
		};
		vacancies_range?: {
			min?: string;
			max?: string;
		};
		no_of_hiring?: {
			min?: string;
			max?: string;
		};
		vacancies_availability?: 'yes' | 'no';
		country_id?: number[];
		city_id?: number[];
		nationality_country_id?: number[];
		reviews?: {
			min?: string;
			max?: string;
		};
		likes?: {
			min?: string;
			max?: string;
		};
		endorsements?: {
			min?: string;
			max?: string;
		};
		shares?: {
			min?: string;
			max?: string;
		};
		ratings?: {
			min?: string;
			max?: string;
		};
		age?: {
			min?: string;
			max?: string;
		};
		is_online?: 'yes' | 'no';
		featured_status?: 'yes' | 'no';
		connection_status?: 'connected' | 'not connected';
		super_creator_status?: 'yes' | 'no';
		super_seller_status?: 'yes' | 'no';
		super_affiliate_status?: 'yes' | 'no';
		opening_status?: string;
		entity_type?: string;
	};
	per_page?: number;
};

export type getBookAuthorsRequestType = {
	profile_type_enum: 'personal' | 'business' | 'all';
	filters?: {
		personal?: {
			sorting: {
				name?:
					| 'By Name'
					| 'By Joining Date'
					| 'By Location'
					| 'By Featured'
					| 'By No Of Assets'
					| 'By Sales'
					| 'By Investment'
					| 'By Likes'
					| 'By Shares'
					| 'By No Of Endorsements'
					| 'By No Of Customers'
					| 'By No Of Orders'
					| 'By Rating'
					| 'By Reviews';
				order?: 'asc' | 'desc';
			};
			connection_status?: ('connected' | 'not connected')[];
			search?: string;
			earning?: { min: number; max: number }[];
			is_open_to_work?: boolean;
			languages?: { language_id: number; language_level_id: number }[];
			sales?: { min: number; max: number }[];
			investment?: { min: number; max: number }[];
			points?: { min: number; max: number }[];
			no_of_assets?: { min: number; max: number }[];
			no_of_sponsorships?: { min: number; max: number }[];
			likes?: { min: number; max: number }[];
			orders?: { min: number; max: number }[];
			shares?: { min: number; max: number }[];
			comments?: { min?: number; max?: number }[];
			reviews?: { min?: number; max?: number }[];
			rating?: { min?: number; max?: number }[];
			age?: { min: number; max: number }[];
			joining_date?: { min: string; max: string };
			gender?: ('male' | 'female')[];
			nationality_country_id?: number[];
			residence_country_id?: number[];
			residence_city_id?: number[];
			location_latitude?: number;
			location_longitude?: number;
			max_distance?: number;
			employment_status?: ('Employed' | 'Unemployed' | 'Self-Employed')[];
			current_title?: string[];
			current_employer?: string[];
			total_years_of_experience?: number[];
			notice_period?: number[];
			driving_license_enum?: ('yes' | 'no')[];
			no_of_connections?: { min: number; max: number }[];
			total_customers?: { min: number; max: number }[];
			service_rate_per_hour?: number[];
			social_status_enum?: ('single' | 'married' | 'engaged' | 'not disclosed')[];
			military_status_enum?: ('completed' | 'exempted' | 'expecting')[];
			excepected_gross_monthly_salary?: { min: number; max: number };
			is_online?: ('online' | 'offline')[];
			is_featured?: boolean[];
			profile_message?: string[];
			driving_license_issuing_country_id?: number[];
			total_endorsements?: { min: number; max: number }[];
			social_groups_members?: { min: number; max: number }[];
			social_pages_followers?: { min: number; max: number }[];
			video_channel_subscribers?: { min: number; max: number }[];
			vv_activities?: string[];
			super_creator_status?: ('yes' | 'no')[];
			super_seller_status?: ('yes' | 'no')[];
			super_affiliate_status?: ('yes' | 'no')[];
			hiring_rate?: { min: number; max: number };
			vacancies_range?: { min: number; max: number };
			no_of_books?: { min: number; max: number };
			no_of_hiring?: { min: number; max: number }[];
		};
		business?: {
			sorting?: {
				name?:
					| 'By Name'
					| 'By Joining Date'
					| 'By Location'
					| 'By Featured'
					| 'By No Of Assets'
					| 'By Sales'
					| 'By Investment'
					| 'By Likes'
					| 'By Shares'
					| 'By No Of Endorsements'
					| 'By No Of Sponsorships'
					| 'By No Of Customers'
					| 'By No Of Orders'
					| 'By Rating'
					| 'By Reviews';
				order?: 'asc' | 'desc';
			};
			search?: string;
			is_online?: boolean[];
			personal?: {
				plan?: string[];
			};
			connection_status?: ('connected' | 'not connected')[];
			joining_date?: {
				min: string;
				max: string;
			};
			profile_type?: ('individual' | 'business')[];
			total_sales?: { min: number; max: number }[];
			total_earnings?: { min: number; max: number }[];
			total_investments?: { min: number; max: number }[];
			no_of_connections?: { min: number; max: number }[];
			total_points?: { min: number; max: number }[];
			no_of_assets?: { min: number; max: number }[];
			languages?: {
				language_id: number;
				language_level_id: number;
			}[];
			total_likes?: { min: number; max: number }[];
			total_reviews?: { min: number; max: number }[];
			total_shares?: { min: number; max: number }[];
			total_comments?: { min?: number; max?: number }[];
			no_of_books?: {
				min?: number;
				max?: number;
			};
			total_rating?: {
				min?: number | null;
				max?: number | null;
			};
			total_endorsements?: {
				min?: number;
				max?: number;
			};
			vv_activities?: string[];
			no_of_employees_inside_vv?: {
				min?: number;
				max?: number;
			};
			no_of_employees_outside_vv?: {
				min?: number;
				max?: number;
			};
			no_of_sponsorships?: {
				min?: number;
				max?: number;
			};
			social_pages_followers?: {
				min?: number;
				max?: number;
			}[];
			social_groups_members?: {
				min?: number;
				max?: number;
			}[];
			video_channel_subscribers?: {
				min?: number;
				max?: number;
			}[];
			total_customers?: {
				min?: number;
				max?: number;
			}[];
			nationality_country_id?: number[];
			residence_country_id?: number[];
			residence_city_id?: number[];
			location_latitude?: number;
			location_longitude?: number;
			max_distance?: number;
			total_years_of_experience?: {
				min?: number;
				max?: number;
			}[];
			no_of_employees?: {
				min?: number;
				max?: number;
			}[];
			opening_status?: ('open' | 'closed')[];
			super_creator_status?: ('yes' | 'no' | null)[];
			super_seller_status?: ('yes' | 'no' | null)[];
			super_affiliate_status?: ('yes' | 'no' | null)[];
			hiring_rate?: {
				min?: number;
				max?: number;
			};
			vacancies_range?: {
				min?: number;
				max?: number;
			};
			no_of_hiring?: {
				min?: number;
				max?: number;
			}[];
			business_type_id?: number[];
			featured_status?: boolean[];
		};
	};
	limit?: number | null;
};

export type getBookAuthorRequestType = {
	profile_type_enum?: 'personal' | 'business';
	filters?: {
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		search?: string;
		nationality?: number[];
		joining_date?: {
			min?: string;
			max?: string;
		};
		location_latitude?: number;
		location_longitude?: number;
		max_distance?: number;
		activities?: string[];
		city?: number[];
		gender?: 'male' | 'female';
		plan?: string[];
		no_of_employees?: {
			min?: string;
			max?: string;
		};
		no_of_employees_inside_vv?: {
			min?: string;
			max?: string;
		};
		no_of_employees_outside_vv?: {
			min?: string;
			max?: string;
		};
		no_of_books?: {
			min?: string;
			max?: string;
		};
		total_books_points?: {
			min?: string;
			max?: string;
		};
		total_book_likes?: {
			min?: string;
			max?: string;
		};
		total_book_reviews?: {
			min?: string;
			max?: string;
		};
		total_book_shares?: {
			min?: string;
			max?: string;
		};
		total_book_ratings?: {
			min?: string;
			max?: string;
		};
		total_book_comments?: {
			min?: string;
			max?: string;
		};
		vacancies_availability?: 'yes' | 'no';
		country?: number[];
		age?: {
			min?: string;
			max?: string;
		};
		is_online?: 'yes' | 'no';
		featured_status?: 'yes' | 'no';
		connection_status?: 'connected' | 'not connected';
		super_creator_status?: 'yes' | 'no';
		super_seller_status?: 'yes' | 'no';
		super_affiliate_status?: 'yes' | 'no';
	};
	limit?: number;
	page?: number;
};

export type getBookAuthorResponseType = {
	pagination: any;
	data: ConnectedUserResource[];
};

export type getCandidatesResponseType = {
	data: ConnectedUserResource[];
	pagination: {
		current_page: number;
		per_page: number;
		total: number;
		total_pages: number;
	};
};

export type getCandidatesRequestType = {
	filters?: {
		profile_rank?: string[];
		connection_status?: 'connected' | 'not connected';
		age?: {
			min: number;
			max: number;
		};
		gender?: string[];
		nationality_country_id?: number[];
		residence_country_id?: number[];
		residence_city_id?: number[];
		employment_status?: string[];
		current_title?: string[];
		current_employer?: string[];
		total_years_of_experience?: {
			min: number;
			max: number;
		};
		driving_license_enum?: string[];
		service_rate_per_hour?: number[];
		social_status_enum?: string[];
		military_status_enum?: string[];
		excepected_gross_monthly_salary?: {
			min: number;
			max: number;
		};
		is_online?: string;
		is_featured?: string;
		driving_license_issuing_country_id?: number[];
		order_by?:
			| 'rating'
			| 'likes'
			| 'shares'
			| 'reviews'
			| 'full_name'
			| 'applying_date'
			| 'salary'
			| 'experience'
			| 'featured'
			| 'created_at'
			| 'updated_at'
			| 'endorsments'
			| 'notice_period';
		order?: 'asc' | 'desc';

		search?: string;
		is_open_to_work?: string;
		notice_period?: {
			min: string;
			max: string;
		};
		languages?: {
			language_id: number;
			language_level_id: number;
		}[];
		total_points?: {
			min: string;
			max: string;
		};
		likes?: {
			min: string;
			max: string;
		};
		shares?: {
			min: string;
			max: string;
		};
		endorsements?: {
			min: string;
			max: string;
		};
		joining_date?: {
			min: string;
			max: string;
		};
	};
	page?: number;
};

export type getSavedUsersResponseType = {
	pagination: any;
	data: ConnectedUserResource[];
};

export type getSavedUsersRequestType = {
	module_enum:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz'
		| 'V-Cast'
		| 'V-Book'
		| 'V-Mood'
		| 'V-Client';
	asset_enum?: 'user' | 'profile' | 'freelancer' | 'client' | 'employer' | 'candidate';
	profile_type_enum?: 'personal' | 'business';
	limit?: number;
	page?: number;
	filters?: {
		blog_author?: {
			profile_type?: 'personal' | 'business';
			country?: number[];
			no_of_posts?: { min?: number; max?: number }[];
			total_posts_points?: { min?: number; max?: number }[];
			total_post_reviews?: { min?: number; max?: number }[];
			total_post_shares?: { min?: number; max?: number }[];
			total_post_ratings?: { min?: number; max?: number }[];
			is_online?: 'yes' | 'no';
			connection_status?: ('connected' | 'not connected' | 'all')[];
			gender?: 'male' | 'female';
			online_status?: 'online' | 'offline';
			featured_status?: boolean;
			plan?: string[];
			order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
			order?: 'asc' | 'desc';
		};
		personal?: {
			is_online?: ('online' | 'offline')[];
			connection_status?: ('connected' | 'not connected' | 'all')[];
			sorting?: {
				name?:
					| 'By Name'
					| 'By Joining Date'
					| 'By Location'
					| 'By Featured'
					| 'By No Of Assets'
					| 'By Sales'
					| 'By Investment'
					| 'By Likes'
					| 'By Shares'
					| 'By No Of Endorsements'
					| 'By No Of Customers'
					| 'By No Of Orders'
					| 'By Rating'
					| 'By Reviews'
					| 'No Of Books';
				order?: 'asc' | 'desc';
			};
			search?: string;
			earning?: { min: number; max: number }[];
			is_open_to_work?: boolean;
			languages?: { language_id: number; language_level_id: number }[];
			sales?: { min: number; max: number }[];
			investment?: { min: number; max: number }[];
			points?: { min: number; max: number }[];
			no_of_assets?: { min: number; max: number }[];
			no_of_sponsorships?: { min: number; max: number };
			likes?: { min: number; max: number }[];
			shares?: { min: number; max: number }[];
			comments?: { min?: number; max?: number }[];
			reviews?: { min?: number; max?: number }[];
			rating?: { min?: number; max?: number }[];
			no_of_books?: { min?: number; max?: number }[];
			age?: { min: number; max: number }[];
			joining_date?: { min: string; max: string };
			gender?: ('male' | 'female')[];
			nationality_country_id?: number[];
			residence_country_id?: number[];
			residence_city_id?: number[];
			location_latitude?: number;
			location_longitude?: number;
			max_distance?: number;
			employment_status?: ('Employed' | 'Unemployed' | 'Self-Employed')[];
			current_title?: string[];
			current_employer?: string[];
			total_years_of_experience?: number[];
			notice_period?: number[];
			driving_license_enum?: ('yes' | 'no')[];
			no_of_connections?: { min: number; max: number }[];
			total_customers?: { min: number; max: number }[];
			service_rate_per_hour?: number[];
			social_status_enum?: ('single' | 'married' | 'engaged' | 'not disclosed')[];
			military_status_enum?: ('completed' | 'exempted' | 'expecting')[];
			excepected_gross_monthly_salary?: { min: number; max: number };
			is_featured?: boolean[];
			profile_message?: string[];
			driving_license_issuing_country_id?: number[];
			total_endorsements?: { min: number; max: number }[];
			social_groups_members?: { min: number; max: number }[];
			social_pages_followers?: { min: number; max: number }[];
			video_channel_subscribers?: { min: number; max: number }[];
			vv_activities?: string[];
			super_creator_status?: ('yes' | 'no')[];
			super_seller_status?: ('yes' | 'no')[];
			super_affiliate_status?: ('yes' | 'no')[];
			hiring_rate?: { min: number; max: number };
			vacancies_range?: { min: number; max: number };
			no_of_hiring?: { min: number; max: number }[];
		};
		business?: {
			connection_status?: ('connected' | 'not connected' | 'all')[];
			sorting?: {
				name?:
					| 'By Name'
					| 'By Joining Date'
					| 'By Location'
					| 'By Featured'
					| 'By No Of Assets'
					| 'By Sales'
					| 'By Investment'
					| 'By Likes'
					| 'By Shares'
					| 'By No Of Endorsements'
					| 'By No Of Sponsorships'
					| 'By No Of Customers'
					| 'By No Of Orders'
					| 'By Rating'
					| 'By Reviews'
					| 'No Of Books';
				order?: 'asc' | 'desc';
			};
			search?: string;
			is_online?: boolean[];
			personal?: {
				plan?: string[];
			};
			joining_date?: {
				min: string;
				max: string;
			};
			profile_type?: ('individual' | 'business')[];
			total_sales?: { min: number; max: number }[];
			total_earnings?: { min: number; max: number }[];
			total_investments?: { min: number; max: number }[];
			no_of_connections?: { min: number; max: number }[];
			total_points?: { min: number; max: number }[];
			no_of_assets?: { min: number; max: number }[];
			no_of_books?: { min?: number; max?: number }[];
			languages?: { language_id: number; language_level_id: number }[];
			total_likes?: { min: number; max: number }[];
			total_reviews?: { min: number; max: number }[];
			total_shares?: { min: number; max: number }[];
			total_comments?: { min?: number; max?: number }[];
			total_rating?: { min?: number; max?: number }[];
			total_endorsements?: { min: number; max: number }[];
			vv_activities?: string[];
			no_of_employees_inside_vv?: { min: number; max: number };
			no_of_employees_outside_vv?: { min: number; max: number };
			no_of_sponsorships?: { min: number; max: number };
			social_pages_followers?: { min: number; max: number }[];
			social_groups_members?: { min: number; max: number }[];
			video_channel_subscribers?: { min: number; max: number }[];
			total_customers?: { min: number; max: number }[];
			nationality_country_id?: number[];
			residence_country_id?: number[];
			residence_city_id?: number[];
			location_latitude?: number;
			location_longitude?: number;
			max_distance?: number;
			total_years_of_experience?: { min: number; max: number }[];
			no_of_employees?: { min: number; max: number }[];
			opening_status?: ('open' | 'closed')[];
			super_creator_status?: ('yes' | 'no')[];
			super_seller_status?: ('yes' | 'no')[];
			super_affiliate_status?: ('yes' | 'no')[];
			hiring_rate?: { min: number; max: number };
			vacancies_range?: { min: number; max: number };
			no_of_hiring?: { min: number; max: number }[];
			business_type_id?: number[];
			featured_status?: boolean[];
		};
	};
};

export type getSavedJobPostingsResponseType = {
	data: JobPostingType[];
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
};

export type getSavedBooksResponseType = {
	data: BookPostType[];
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
};

export type getSavedJobPostingsRequestType = {
	filters?: {
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me';
		search?: string;
		comments?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		job_branch?: number[];
		contract_type?: 'full_time' | 'part_time' | 'contract';
		payment_cycle?: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'project_based';
		hiring_status_enum?: 'hiring' | 'expired';
		job_contract_type?: 'full_time' | 'part_time' | 'contract';
		job_department?: number[];
		job_country?: number[];
		job_city?: number[];
		featured_status?: boolean;
		vv_exclusive_status?: boolean;
		application_status?: 'applied' | 'not applied';
		job_type_enum?: 'remote' | 'onsite';
		role_level_ids?: number[];
		salary?: {
			min?: number;
			max?: number;
		};
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: string[];
		total_applicants?: {
			min?: number;
			max?: number;
		};
		sorting?: {
			order_by?: 'title' | 'created_at' | 'applications' | 'likes' | 'shares' | 'rating' | 'reviews';
			order?: 'asc' | 'desc';
		};
		employer_endorsements?: {
			min: number;
			max: number;
		};
		employer_country_id?: number[];
		employer_city_id?: number[];
		employer_profile_type_enum?: 'personal' | 'business';
		employer_hiring_rate?: {
			min: number;
			max: number;
		};
		employer_connection_status?: string;
		employer_is_online?: string;
	};
	limit: number;
	page?: number;
	status?: 'published' | 'expired' | 'draft' | 'scheduled' | 'archived';
};

export type getSavedBooksRequestType = {
	limit: number;
	status: 'published' | 'draft' | 'scheduled' | 'archived' | 'saved';
	filters?: {
		privacy_enum?: (
			| 'public'
			| 'connections'
			| 'selected_users'
			| 'only_me'
			| 'followers'
			| 'members'
			| 'subscribers'
		)[];
		categories?: number[];
		sub_category?: number[];
		levels?: number[];
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: { min?: number; max?: number }[];
		featured_status?: boolean[];
		author_featured_status?: boolean[];
		author_country_id?: number[];
		author_city_id?: number[];
		vv_exclusive_status?: boolean[];
		book_type?: 'physical' | 'digital';
		v_author_profile_type?: 'personal' | 'business';
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: 'yes' | 'no';
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		search?: string;
		sales?: {
			min?: number;
			max?: number;
		};
		customers?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		employer_endorsements?: {
			min: number;
			max: number;
		};
	};
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type getUserIdDataResponse = {
	data: {
		id: number;
		country_id: number;
		official_full_name: string;
		gender: string;
		date_of_birth: string;
		national_id_number: string;
		status_enum: string;
		attachments: [
			{
				id: number;
				attachable_type: string;
				attachable_id: number;
				user_id: number;
				file_size: number;
				file_name: string;
				file_type_enum: string;
				file_extension_enum: string;
				file_path: string;
				url: string;
				file_id: string;
				created_at: string;
				updated_at: string;
			},
		];
	};
	message: string;
};

export type UserModulesResponseType = {
	data: {
		id: string;
		email: string;
		phone: string;
		response_date: string;
		custom_id: string;
		email_verified_at: string;
		phone_verified_at: string;
		document_verified_at: string;
		id_verified_at: string;
		verification_status: string;
		is_hide_welcome: string;
		counts: string;
		joining_date: string;
		time_zone: string;
		last_seen_at: string;
		profile_type: string;
		profile_id: string;
		profile: any;
		refferal_code: string;
	};
};

export type MyBlogPostsResponseType = {
	data: BlogPostType[];
	meta?: {
		total?: string;
		per_page?: string;
	};
};

export type MyBlogPostsRequestType = {
	limit?: number;
	status?: 'published' | 'draft' | 'scheduled' | 'archived' | 'saved';
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	filters?: {
		categories?: number[];
		sub_category?: number[];
		levels?: number[];
		publishing_date?: { min?: string; max?: string };
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		comments?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		featured_status?: string[];
		author_featured_status?: string[];
		super_creator_status?: 'yes' | 'no';
		super_seller_status?: 'yes' | 'no';
		vv_exclusive_status?: boolean;
		search?: string;
	};
	page?: number;
};

type ProfileTypeEnumVLance = 'personal' | 'business' | 'all';

type ConnectionStatusVlance = 'connected' | 'not connected';
type Gender = 'male' | 'female';
type EmploymentStatusVlance = 'Employed' | 'Unemployed' | 'Self-Employed';
type DrivingLicenseEnumVlance = 'yes' | 'no';
type SocialStatusEnumVlance = 'single' | 'married' | 'engaged' | 'not disclosed';
type MilitaryStatusEnumVlance = 'completed' | 'exempted' | 'expecting';
type OnlineStatusEnum = 'online' | 'offline';
type SuperStatusEnum = 'yes' | 'no';
type OpeningStatusEnum = 'open' | 'closed';
type ProfileTypeVlance = 'individual' | 'business';

interface PersonalFiltersVlance {
	sorting?: Record<string, any>; // Define specific type if known
	connection_status?: (ConnectionStatusVlance | null)[];
	search?: string;
	earning?: object[];
	is_open_to_work?: boolean | null;
	languages?: object[];
	sales?: object[];
	investment?: object[];
	points?: object[];
	no_of_assets?: object[];
	no_of_sponsorships?: object;
	likes?: object[];
	shares?: object[];
	comments?: object[];
	reviews?: object[];
	rating?: object[];
	age?: object[];
	joining_date?: object;
	gender?: Gender[];
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	employment_status?: EmploymentStatusVlance[];
	current_title?: string[];
	current_employer?: string[];
	total_years_of_experience?: number[];
	notice_period?: number[];
	driving_license_enum?: DrivingLicenseEnumVlance[];
	no_of_connections?: object[];
	total_customers?: object[];
	service_rate_per_hour?: number[];
	social_status_enum?: SocialStatusEnumVlance[];
	military_status_enum?: MilitaryStatusEnumVlance[];
	excepected_gross_monthly_salary?: object;
	is_online?: OnlineStatusEnum[];
	is_featured?: boolean[];
	profile_message?: string[];
	driving_license_issuing_country_id?: number[];
	total_endorsements?: object[];
	social_groups_members?: object[];
	social_pages_followers?: object[];
	video_channel_subscribers?: object[];
	vv_activities?: string[];
	super_creator_status?: (SuperStatusEnum | null)[];
	super_seller_status?: (SuperStatusEnum | null)[];
	super_affiliate_status?: (SuperStatusEnum | null)[];
	hiring_rate?: object[] | null;
	vacancies_range?: object;
	no_of_hiring?: object[];
	no_of_previous_projects?: object[] | null;
	no_of_open_projects?: object[] | null;
	preffered_hourly_rate?: object[] | null;
}

interface BusinessFiltersVlance {
	sorting?: Record<string, any>; // Define specific type if known
	search?: string;
	is_online?: boolean[];
	personal?: {
		connection_status?: ConnectionStatus | null;
		joining_date?: object;
		profile_type?: ProfileTypeVlance[];
	};
	total_sales?: object[];
	total_earnings?: object[];
	total_investments?: object[];
	no_of_connections?: object[];
	total_points?: object[];
	no_of_assets?: object[];
	languages?: object[];
	total_likes?: object[];
	total_reviews?: object[];
	total_shares?: object[];
	total_comments?: object[];
	total_rating?: object[];
	total_endorsements?: object[];
	vv_activities?: string[];
	no_of_employees_inside_vv?: object;
	no_of_employees_outside_vv?: object;
	no_of_sponsorships?: object;
	social_pages_followers?: object[];
	social_groups_members?: object[];
	video_channel_subscribers?: object[];
	total_customers?: object[];
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	total_years_of_experience?: object[];
	no_of_employees?: object[];
	opening_status?: OpeningStatusEnum[];
	super_creator_status?: (SuperStatusEnum | null)[];
	super_seller_status?: (SuperStatusEnum | null)[];
	super_affiliate_status?: (SuperStatusEnum | null)[];
	hiring_rate?: object[] | null;
	vacancies_range?: object;
	no_of_hiring?: object[];
	business_type_id?: number[];
	featured_status?: boolean[];
	no_of_previous_projects?: object[] | null;
	no_of_open_projects?: object[] | null;
	preffered_hourly_rate?: object[] | null;
}

interface FiltersVlance {
	personal?: PersonalFiltersVlance;
	business?: BusinessFiltersVlance;
}

export interface VlanceClientRequestType {
	profile_type_enum: ProfileTypeEnumVLance;
	filters?: FiltersVlance;
	limit: number | null;
}

export type MyEventsRequestType = {
	limit?: number; // Should be between 1 and 50
	order?: 'asc' | 'desc'; // Sorting order
	order_by?:
		| 'title'
		| 'starting_date'
		| 'ticket_value'
		| 'status'
		| 'tickets'
		| 'sales'
		| 'orders'
		| 'customers'
		| 'likes'
		| 'comments'
		| 'shares'
		| 'rating'
		| 'reviews'
		| 'created_at'; // Field to order by
	status_enum?: 'archived' | 'draft' | 'coming' | 'happened' | 'ongoing' | null; // Event status
	filters?: {
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me' | null)[];
		category_ids?: number[];
		search?: string;
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: {
			min?: string;
			max?: string;
		};
		duration?: {
			min?: number;
			max?: number;
		};
		start_date?: string;
		end_date?: string;
		no_of_tickets?: {
			min?: number;
			max?: number;
		};
		payment_type?: ('free' | 'paid')[];
		price_type?: ('regular' | 'discounted')[];
		offer_type?: ('BOGOF' | '3For2' | '4For2' | 'FreePlusShipping')[];
		price?: {
			min?: number;
			max?: number;
		};
		venue_country_id?: number;
		venue_city_id?: number;
		sales?: {
			min?: number;
			max?: number;
		};
		likes?: {
			min?: number;
			max?: number;
		}[];
		shares?: {
			min?: number;
			max?: number;
		}[];
		reviews?: {
			min?: number;
			max?: number;
		}[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		profile_type_enum?: ('personal' | 'business')[];
		organizer_country_id?: number[];
		organizer_city_id?: number[];
		connection_status?: ('connected' | 'not connected')[];
		is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no' | null)[];
		super_seller_status?: ('yes' | 'no' | null)[];
		super_affiliate_status?: ('yes' | 'no' | null)[];
	};
};

export type AllBlogsResponseType = {
	data: BlogPostType[];
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
};

export type AllBlogsRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		no_of_start_price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		no_of_sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type getAllUsersResponseType = {
	data: ConnectedUserResource[];
	pagination: {
		current_page: number;
		total_page: number;
		total_pages: number;
		per_page: number;
		total: number;
	};
};

export type getAllUsersRequestType = {
	profile_type_enum: 'personal' | 'business' | 'all';
	filters?: {
		personal?: PersonalFilters;
		business?: BusinessFilters;
	};
	limit?: number | null;
};

export type getAllBlogAuthorsResponseType = {
	meta: Meta;
	data: UserType[];
};

export type getAllBlogAuthorsRequestType = {
	filters?: {
		profile_type?: 'personal' | 'business';
		search?: string;
		page?: number;
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		nationality?: number[];
		joining_date?: {
			min?: string;
			max?: string;
		};
		city?: number[];
		gender?: 'male' | 'female' | null;
		is_online?: 'yes' | 'no';
		location_latitude?: number;
		location_longitude?: number;
		max_distance?: number | null;
		country?: number[];
		business_type?: number[];
		no_of_employees?: {
			min?: number;
			max?: number;
		};
		no_of_employees_inside_vv?: {
			min?: number;
			max?: number;
		};
		no_of_employees_outside_vv?: {
			min?: number;
			max?: number;
		};
		no_of_posts?: {
			min?: number;
			max?: number;
		}[];
		total_posts_points?: {
			min?: number;
			max?: number;
		}[];
		total_post_reviews?: {
			min?: number;
			max?: number;
		}[];
		total_post_likes?: {
			min?: number;
			max?: number;
		}[];
		total_post_shares?: {
			min?: number;
			max?: number;
		}[];
		total_post_ratings?: {
			min?: number;
			max?: number;
		}[];
		age?: {
			min?: number;
			max?: number;
		}[];
		connection_status?: 'connected' | 'not connected' | null;
		featured_status?: boolean;
		plan?: string[];
		activities?: string[];

		total_post_comments?: {
			min?: number;
			max?: number;
		}[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
	};
	limit?: number;
	page?: number;
};

export type GetPublishedJobsResponseType = {
	data: JobPostingType[];
};

export type getAllPodcastAuthorsRequestType = {
	filters?: {
		profile_type?: 'personal' | 'business' | null;
		search?: string | null;
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		nationality?: (number | null)[];
		joining_date?: DateRange;
		city?: (number | null)[];
		gender?: 'male' | 'female' | null;
		is_online?: 'yes' | 'no' | null;
		location_latitude?: number | null;
		location_longitude?: number | null;
		max_distance?: number | null; // Must be >= 0
		country?: (number | null)[];
		business_type?: (number | null)[];
		no_of_employees?: Range;
		no_of_employees_inside_vv?: Range;
		no_of_employees_outside_vv?: Range;
		no_of_casts?: ValueRange[];
		total_casts_points?: ValueRange[];
		total_pod_cast_likes?: ValueRange[];
		total_pod_cast_reviews?: ValueRange[];
		total_pod_cast_shares?: ValueRange[];
		total_pod_cast_ratings?: ValueRange[];
		total_pod_cast_comments?: ValueRange[];
		age?: ValueRange[];
		connection_status?: 'connected' | 'not connected' | null;
		featured_status?: boolean | null;
		super_creator_status?: ('yes' | 'no')[] | null;
		super_seller_status?: ('yes' | 'no')[] | null;
		super_affiliate_status?: ('yes' | 'no')[] | null;
		no_of_sales_form?: number | null; // Must be >= 0
		no_of_sales_to?: number | null; // Must be >= 0
		no_of_order_form?: number | null; // Must be >= 0
		no_of_order_to?: number | null; // Must be >= 0
		no_of_subscribe_form?: number | null; // Must be >= 0
		no_of_subscribe_to?: number | null; // Must be >= 0
		plan?: string[];
		activities?: (string | null)[];
	};
};

interface Range {
	min?: number | null;
	max?: number | null;
}

interface DateRange {
	start_date?: string | null; // You may consider using Date if converting to Date object
	end_date?: string | null; // You may consider using Date if converting to Date object
}

interface ValueRange {
	min?: number | null; // Minimum value, must be >= 0 or null
	max?: number | null; // Maximum value, must be >= 0 or null
}

export type GetPublishedJobsRequestType = {
	id?: string;
	publishing_status: 'published' | 'expired';
	filters?: {
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		search?: string;
		comments?: { min?: number; max?: number }[];
		reviews?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: { min?: number; max?: number }[];
		contract_type?: ('full_time' | 'part_time' | 'contract')[];
		payment_cycle?: ('hourly' | 'daily' | 'weekly' | 'monthly' | 'project_based')[];
		hiring_status_enum?: ('hiring' | 'expired')[];
		applying_status?: ('applied' | 'not_applied')[];
		job_contract_type?: ('full_time' | 'part_time' | 'contract')[];
		job_department?: number[];
		job_country?: number[];
		job_city?: number[];
		application_deadline?: { min: string; max: string };
		work_type_enum?: ('monthly' | 'daily' | 'hourly' | 'annually' | 'weekly')[];
		featured_status?: '0' | '1';
		vv_exclusive_status?: boolean;
		application_status?: string;
		job_type_enum?: 'remote' | 'onsite';
		role_level_ids?: number[];
		salary?: { min?: number; max?: number }[];
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		publishing_date?: { min: string; max: string };
		total_applicants?: { min?: number; max?: number }[];
		sorting?: {
			order_by?: 'title' | 'created_at' | 'applications' | 'likes' | 'shares' | 'rating' | 'reviews';
			order?: 'asc' | 'desc';
		};
		employer_endorsements?: { min?: number; max?: number }[];
		employer_country_id?: number[];
		employer_featured_status?: boolean[];
		employer_city_id?: number[];
		employer_profile_type_enum?: ('personal' | 'business')[];
		employer_hiring_rate?: { min?: number; max?: number };
		employer_connection_status?: 'connected' | 'not connected';
		employer_is_online?: ('yes' | 'no')[];
		limit?: number;
	};
	limit?: number;
};

export type FiltersPersonalType = {
	sorting?: {
		name?:
			| 'By Name'
			| 'By Joining Date'
			| 'By Location'
			| 'By Featured'
			| 'By No Of Assets'
			| 'By Sales'
			| 'By Investment'
			| 'By Likes'
			| 'By Shares'
			| 'By No Of Endorsements'
			| 'By No. Sponsorships'
			| 'By No. Orders'
			| 'By No. Customers'
			| 'By sales';
		order?: 'asc' | 'desc';
	};
	connection_status?: ('connected' | 'not connected')[];
	search?: string;
	earning?: { min: number; max: number }[];
	is_open_to_work?: boolean | null;
	languages?: { language_id: number; language_level_id: number }[];
	sales?: { min: number; max: number }[];
	investment?: { min: number; max: number }[];
	points?: { min: number; max: number }[];
	no_of_assets?: { min: number; max: number }[];
	no_of_sponsorships?: { min: number; max: number }[];
	no_of_orders?: { min: number; max: number }[];
	orders?: string[];
	likes?: { min: number; max: number }[];
	shares?: { min: number; max: number }[];
	comments?: { min?: number; max?: number }[];
	reviews?: { min?: number; max?: number }[];
	rating?: { min?: number; max?: number }[];
	age?: { min: number; max: number }[];
	joining_date?: { min: string; max: string };
	gender?: ('male' | 'female')[];
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	employment_status?: ('Employed' | 'Unemployed' | 'Self-Employed')[];
	current_title?: string[];
	current_employer?: string[];
	total_years_of_experience?: number[];
	notice_period?: number[];
	driving_license_enum?: ('yes' | 'no')[];
	no_of_connections?: { min: number; max: number }[];
	total_customers?: { min: number; max: number }[];
	service_rate_per_hour?: number[];
	social_status_enum?: ('single' | 'married' | 'engaged' | 'not disclosed')[];
	military_status_enum?: ('completed' | 'exempted' | 'expecting')[];
	excepected_gross_monthly_salary?: { min: number; max: number };
	is_online?: ('online' | 'offline')[];
	is_featured?: boolean[];
	profile_message?: string[];
	driving_license_issuing_country_id?: number[];
	total_endorsements?: { min: number; max: number }[];
	social_groups_members?: { min: number; max: number }[];
	social_pages_followers?: { min: number; max: number }[];
	video_channel_subscribers?: { min: number; max: number }[];
	vv_activities?: string[];
	super_creator_status?: ('yes' | 'no' | null)[];
	super_seller_status?: ('yes' | 'no' | null)[];
	super_affiliate_status?: ('yes' | 'no' | null)[];
	hiring_rate?: { min: number; max: number };
	vacancies_range?: { min: number; max: number };
	no_of_hiring?: { min: number; max: number }[];
};

export type FiltersBusinessType = {
	sorting?: {
		name?:
			| 'By Name'
			| 'By Joining Date'
			| 'By Location'
			| 'By Featured'
			| 'By No Of Assets'
			| 'By Sales'
			| 'By Investment'
			| 'By Likes'
			| 'By Shares'
			| 'By No Of Endorsements'
			| 'By No. Sponsorships'
			| 'By No. Orders'
			| 'By No. Customers'
			| 'By sales';
		order?: 'asc' | 'desc';
	};
	search?: string;
	is_online?: boolean[];
	personal?: {
		plan?: string[];
	};
	connection_status?: 'connected' | 'not connected' | null;
	joining_date?: { min: string; max: string };
	profile_type?: ('individual' | 'business')[];
	total_sales?: { min: number; max: number }[];
	total_earnings?: { min: number; max: number }[];
	total_investments?: { min: number; max: number }[];
	no_of_connections?: { min: number; max: number }[];
	total_points?: { min: number; max: number }[];
	no_of_assets?: { min: number; max: number }[];
	no_of_sponsorships?: { min: number; max: number }[];
	languages?: { language_id: number; language_level_id: number }[];
	total_likes?: { min: number; max: number }[];
	total_reviews?: { min: number; max: number }[];
	total_shares?: { min: number; max: number }[];
	total_comments?: { min?: number; max?: number }[];
	total_rating?: { min?: number; max?: number }[];
	total_endorsements?: { min: number; max: number }[];
	vv_activities?: string[];
	no_of_employees_inside_vv?: { min: number; max: number };
	no_of_employees_outside_vv?: { min: number; max: number };
	social_pages_followers?: { min: number; max: number }[];
	social_groups_members?: { min: number; max: number }[];
	video_channel_subscribers?: { min: number; max: number }[];
	total_customers?: { min: number; max: number }[];
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	total_years_of_experience?: number[];
	no_of_employees?: { min: number; max: number }[];
	opening_status?: ('open' | 'closed')[];
	super_creator_status?: ('yes' | 'no' | null)[];
	super_seller_status?: ('yes' | 'no' | null)[];
	super_affiliate_status?: ('yes' | 'no' | null)[];
	hiring_rate?: { min: number; max: number };
	vacancies_range?: { min: number; max: number };
	no_of_hiring?: { min: number; max: number }[];
	business_type_id?: number[];
	featured_status?: boolean[];
	limit?: number;
};

export type GetUsersRequestType = {
	profile_type_enum: ProfileTypeEnum;
	filters?: {
		personal?: FiltersPersonalType &
			any & {
				sorting?: {
					name?:
						| 'By Name'
						| 'By Joining Date'
						| 'By Location'
						| 'By Featured'
						| 'By No Of Assets'
						| 'By Sales'
						| 'By Investment'
						| 'By Likes'
						| 'By Shares'
						| 'By No Of Endorsements'
						| 'By No. Sponsorships'
						| 'By No. Orders'
						| 'By No. Customers'
						| 'By sales';
					order?: 'asc' | 'desc';
				};
			};
		business?: FiltersBusinessType &
			any & {
				sorting?: {
					name?:
						| 'By Name'
						| 'By Joining Date'
						| 'By No. Sponsorships'
						| 'BY sales'
						| 'By No. Orders'
						| 'By No. Customers'
						| 'By Likes'
						| 'By Shares'
						| 'By No Of Endorsements';
					order?: 'asc' | 'desc';
				};
			};
	};
	limit?: number;
	page?: number;
};

export type GetUsersResponseType = {
	data: any;
	pagination: {
		current_page: string;
		total_page: string;
		total_pages: string;
		per_page: string;
		total: string;
	};
	user_ids: number;
};

export type GetSponsorshipAllRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		no_of_start_price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		no_of_sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};
export type GetCourseAllRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		instructor_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		instructor_country_id?: number[];
		instructor_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type GetConsultationAllRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: string;
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		no_of_start_price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		no_of_sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
};

export type GetSponsorshipAllResponseType = {
	data: SponsorshipResource[];
	meta: Meta;
};

export type MySponsorsRequestType = {
	limit?: number;
	status: 'published' | 'draft' | 'archived' | 'saved';
	filters?: {
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		categories?: number[];
		sub_category?: number[];
		levels?: number[];
		publishing_date?: string | null;
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: { min?: number; max?: number }[];
		featured_status?: (boolean | null)[];
		author_featured_status?: (boolean | null)[];
		author_country_id?: number[];
		author_city_id?: number[];
		vv_exclusive_status?: (boolean | null)[];
		v_author_profile_type?: 'personal' | 'business' | null;
		v_author_city?: number | null;
		v_author_country?: number | null;
		v_author_connection_status?: 'connected' | 'not_connected' | null;
		v_author_is_online?: 'yes' | 'no' | null;
		super_creator_status?: ('yes' | 'no' | null)[];
		super_seller_status?: ('yes' | 'no' | null)[];
		super_affiliate_status?: ('yes' | 'no' | null)[];
		search?: string | null;
	};
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'created_at' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	meta?: PaginationMetaType;
	page?: number;
};

type PublishEnumVlance = 'draft' | 'published' | 'archived' | 'expired';

// Define enums and types for specific fields
type PrivacyEnum = 'public' | 'connections' | 'selected_users' | 'only_me' | null;
type HiringStatusEnum = 'open' | 'closed' | 'pending' | null;
type WorkTypeEnum = 'full_time' | 'part_time' | 'freelance' | 'contract' | null;
type BudgetTypeEnum = 'fixed' | 'hourly' | null;
type ConnectionStatusEnum = 'connected' | 'not connected' | null;
type YesNoEnum = 'yes' | 'no' | null;
type OrderByEnum =
	| 'title'
	| 'publishing_date'
	| 'delivery_time'
	| 'budget'
	| 'no_of_applicants'
	| 'no_of_reactions'
	| 'no_of_shares'
	| 'no_of_likes'
	| 'no_of_comments'
	| 'no_of_endorsements'
	| 'client_investments'
	| 'client_hiring_rate'
	| 'client_preferred_hourly_rate'
	| 'created_at'
	| null;
type OrderEnum = 'asc' | 'desc' | null;

// Define the range type
interface RangeObjectVlance {
	min?: number | null; // Minimum value
	max?: number | null; // Maximum value
}

// Define the filters object interface
export interface VLanceMyProjectsFilters {
	category_ids?: number[] | null; // Array of integers or null
	sub_category_ids?: number[] | null; // Array of integers or null
	level_ids?: number[] | null; // Array of integers or null
	privacy_enum?: PrivacyEnum[] | null; // Array of PrivacyEnum values or null
	publishing_date?: RangeObjectVlance; // Object defining a range for publishing date
	hiring_status?: HiringStatusEnum; // Hiring status
	skills_level?: number[] | null; // Array of integers or null
	work_type?: WorkTypeEnum; // Work type
	budget_type?: BudgetTypeEnum; // Budget type
	budget?: RangeObjectVlance; // Object defining a range for budget
	delivery_time?: RangeObjectVlance; // Object defining a range for delivery time
	no_of_applicants?: RangeObjectVlance; // Object defining a range for number of applicants
	no_of_reactions?: RangeObjectVlance; // Object defining a range for number of reactions
	no_of_shares?: RangeObjectVlance; // Object defining a range for number of shares
	no_of_endorsements?: RangeObjectVlance; // Object defining a range for number of endorsements
	client_investments?: RangeObjectVlance; // Object defining a range for client investments
	client_hiring_rate?: RangeObjectVlance; // Object defining a range for client hiring rate
	client_preferred_hourly_rate?: RangeObjectVlance; // Object defining a range for client preferred hourly rate
	profile_type_enum?: ProfileTypeEnum[] | null; // Array of ProfileTypeEnum values or null
	client_featured_status?: (boolean | null)[]; // Array of booleans or null
	connection_status?: ConnectionStatusEnum[]; // Array of ConnectionStatusEnum values
	client_city_id?: number[]; // Array of integers
	client_country_id?: number[]; // Array of integers
	client_online_status?: YesNoEnum[] | null; // Array of YesNoEnum values or null
	super_creator_status?: YesNoEnum[] | null; // Array of YesNoEnum values or null
	super_seller_status?: YesNoEnum[] | null; // Array of YesNoEnum values or null
	super_affiliate_status?: YesNoEnum[] | null; // Array of YesNoEnum values or null
	search?: string | null; // Search string or null
	order_by?: OrderByEnum; // Order by enum
	order?: OrderEnum; // Order enum
	[key: string]: any; // Allow additional properties with any type
}

// Define the main request type for VLanceMyAssetsMyProjects
export interface VLanceMyAssetsMyProjects {
	publish_enum?: PublishEnumVlance; // Optional filter based on the publish status
	limit: number | null; // Must be an integer between 1 and 50 inclusive or null
	filters?: VLanceMyProjectsFilters; // Optional filters for the request
	[key: string]: any; // Allow additional properties with any type
}

export interface VLanceServiceRequest {
	limit: number | null;
	filters?: VLanceServiceFilters;

	[key: string]: any;
}
export type VLanceType = 'image' | 'video' | 'album' | 'images' | 'videos' | 'albums';
export interface VLancePortfolioFilters {
	limit: number | null;
	order?: 'asc' | 'desc';
	type?: VLanceType;

	[key: string]: any;
}

export interface VLanceClientsByUserIdRequest {
	userId: any;
	limit: number | null;
	filters?: VLanceServiceFilters;

	[key: string]: any;
}

// Detailed type for filters
export interface VLanceServiceFilters {
	category_ids?: number[] | null; // Array of integers or null
	sub_category_ids?: number[] | null; // Array of integers or null
	level_ids?: number[] | null; // Array of integers or null
	privacy_enum?: (string | null)[] | null; // Array of strings or null, allowed values: 'public', 'connections', 'selected_users', 'only_me'
	publishing_date?: VLDateRange | null; // Object or null
	delivery_time?: VLRange<number> | null; // Object or null
	no_of_reactions?: VLRange<number> | null; // Object or null
	no_of_shares?: VLRange<number> | null; // Object or null
	no_of_endorsements?: VLRange<number> | null; // Object or null
	client_investments?: VLRange<number> | null; // Object or null
	client_preferred_hourly_rate?: VLRange<number> | null; // Object or null
	profile_type_enum?: (string | null)[] | null; // Array of strings or null, allowed values: 'personal', 'business'
	client_featured_status?: (boolean | null)[] | null; // Array of booleans or null
	connection_status?: (string | null)[] | null; // Array of strings or null, allowed values: 'connected', 'not connected'
	client_city_id?: number[] | null; // Array of integers or null
	client_country_id?: number[] | null; // Array of integers or null
	client_online_status?: (string | null)[] | null; // Array of strings or null, allowed values: 'yes', 'no'
	super_creator_status?: (string | null)[] | null; // Array of strings or null, allowed values: 'yes', 'no'
	super_seller_status?: (string | null)[] | null; // Array of strings or null, allowed values: 'yes', 'no'
	super_affiliate_status?: (string | null)[] | null; // Array of strings or null, allowed values: 'yes', 'no'
	search?: string | null; // String or null
	sorting?: string | null; // String or null, allowed values: 'title', 'publishing_date', 'delivery_time', 'no_of_reactions', 'no_of_shares', 'no_of_likes', 'no_of_comments', 'no_of_endorsements', 'client_investments', 'client_hiring_rate', 'client_preferred_hourly_rate', 'created_at'
	order?: string | null; // String or null, allowed values: 'asc', 'desc'
	order_by?: string | null;
}

// Type for date range
export interface VLDateRange {
	min: string | null; // String or null
	max: string | null; // String or null
}

// Generic type for numeric range
export interface VLRange<T extends number> {
	min: T | null; // Integer or null
	max: T | null; // Integer or null
}

export type MySponsorsResponseType = {
	data: SponsorshipResource[];
	meta: PaginationMetaType;
};

export type WishlistRequestType = {
	module_enum: ModuleEnum;
	item_type:
		| 'service'
		| 'product'
		| 'ticket'
		| 'subscription'
		| 'Podcast'
		| 'Book'
		| 'Project'
		| 'VLancer'
		| 'VClient'
		| 'Sponsorship'
		| 'Consultation'
		| 'Course'
		| 'Webinar'
		| 'Episode';
	itemable_id: number[];
	asset_enum?: string;
};

export type WishlistRemoveRequestType = {
	item_type:
		| 'service'
		| 'product'
		| 'ticket'
		| 'subscription'
		| 'Podcast'
		| 'Book'
		| 'Sponsorship'
		| 'Consultation'
		| 'Project'
		| 'Course'
		| 'Webinar'
		| 'Episode';
	itemable_ids: number[];
};

export type SponsorshipBulkActionRequestType = {
	module_enum?: string;
	status: 'archive' | 'publish' | 'remove' | 'like' | 'save' | 'unsave';
	asset_ids: number[];
	asset_enum?: string;
};

export type CollegeBulkActionRequestType = {
	status: 'archive' | 'publish' | 'remove' | 'like' | 'save' | 'unsave';
	asset_ids: number[];
};

export type MySponsorshipsRequestType = {
	status: 'published' | 'draft' | 'scheduled' | 'saved';
	filters?: {
		privacy_enum?: (
			| 'public'
			| 'connections'
			| 'selected_users'
			| 'only_me'
			| 'followers'
			| 'members'
			| 'subscribers'
		)[];
		categories?: number[];
		sub_category?: number[];
		levels?: number[];
		publishing_date?: string;
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: { min?: number; max?: number }[];
		featured_status?: boolean[];
		author_featured_status?: boolean[];
		author_country_id?: number[];
		author_city_id?: number[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: 'personal' | 'business';
		v_author_city?: number;
		v_author_country?: number;
		v_author_connection_status?: 'connected' | 'not_connected';
		v_author_is_online?: 'yes' | 'no';
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
	};
	search?: string;
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?:
		| 'title'
		| 'publishing_date'
		| 'sales'
		| 'customers'
		| 'reaction'
		| 'shares'
		| 'rating'
		| 'date'
		| 'reviews';
};

export type BlockAllUsersType = {
	user_ids: (number | string)[];
};

export type UnSaveUsersType = {
	module_enum: ModuleEnum;
	asset_enum?: AssetEnum;
	user_ids: (number | string)[];
};

export type AllCastAuthorsResposne = {
	data: ConnectedUserResource[];
};

export type GetEventDetailsByIdResponse = {
	data: EventResource;
};

export type GetSponsorShipsTop10Response = {
	data: SponsorshipResource[];
};

export type GetCoursesTop10Response = {
	data: CourseResource[];
};

export type GetConsultationTop10Response = {
	data: ConsultationResource[];
};

export type MyBooksResponse = {
	data: BookPostType[];
	meta: PaginationMetaType;
};

export type CreateEventRequestType = {
	id?: string;
	title: string;
	description: string;
	location_longitude?: number;
	location_latitude?: number;
	notes?: string;
	start_date?: string;
	end_date?: string;
	allow_buying_tickets_until?: string;
	status_enum: 'draft' | 'published' | 'archived' | 'expired' | 'scheduled';
	privacy_enum: 'public' | 'connections' | 'selected_users' | 'only_me' | 'followers' | 'members' | 'subscribers';
	is_featured: boolean;
	is_exclusive: boolean;
	is_scheduled: boolean;
	scheduled_at?: string;
	priority_type?: string;
	category?: string;
	is_comment_enabled: boolean;
	sources_copy_right?: string;
	viewable_by?: number[];
	hashtags: any[];
	speaker_ids?: number[];
	deleted_speakers_ids?: number[];
	banner?: Record<string, any>[];
	gallery?: Record<string, any>[];
	files?: Record<string, any>[];
	category_ids?: number[];
	sub_category_ids?: number[];
	level_ids?: number[];
	tickets: {
		id: number;
		name: string;
		validity_start_date?: string;
		no_of_available_tickets: number;
		validity_end_date?: string;
		offer_type_enum?: 'BOGOF' | '3For2' | '4For2' | '5For3' | 'no_offer';
		payment_type_enum: 'free' | 'paid';
		price_type_enum: 'regular' | 'discounted';
		price: number;
		discounted_price?: number;
		description?: string;
		pdf_name: boolean;
		pdf_start_date: boolean;
		pdf_end_date: boolean;
		pdf_organizer_contact: boolean;
		pdf_venue_name: boolean;
		pdf_venue_location: boolean;
		pdf_venue_maps_link: boolean;
		pdf_venue_contact: boolean;
		qr_code: boolean;
		schedule: boolean;
	}[];
	event_schedule_day: EventScheduleDayResource[];
	venue: {
		id?: number;
		venue_type_enum: 'inside_v_verse' | 'outside_v_verse';
		inside_v_verse_id?: number;
		outside_profile?: {
			id?: number;
			name: string;
			business_type_id: number;
			establishment_date: string;
			head_office_country_id: number;
			head_office_city_id: number;
			no_of_employees?: number;
			no_of_employees_inside?: number;
			no_of_employees_outside?: number;
			about: string;
			logo: number;
			address?: string;
			maps_url?: string;
			website?: string;
			phone?: string;
			landline?: string;
			whatsapp?: string;
			email: string;
			social_links?: {
				id: number;
				platform: string;
				link: string;
			}[];
		};
	};
	co_organizers?: {
		id: number;
		organizer_type_enum: 'inside_v_verse' | 'outside_v_verse' | 'outside_v_verse_personal';
		user_id: number;
		outside_profile?: {
			id?: number;
			name: string;
			business_type_id: number;
			establishment_date: string;
			head_office_country_id: number;
			head_office_city_id: number;
			no_of_employees?: number;
			no_of_employees_inside?: number;
			no_of_employees_outside?: number;
			about: string;
			logo: number;
			address?: string;
			maps_url?: string;
			website?: string;
			phone?: string;
			landline?: string;
			whatsapp?: string;
			email: string;
			social_links?: {
				id: number;
				platform: string;
				link: string;
			}[];
		};
		outside_personal?: {
			id: number;
			name: string;
			nationality_country_id?: number;
			country_id?: number;
			city_id?: number;
			website?: string;
			whatsapp?: string;
			image?: number;
			email: string;
			phone?: string;
			title: string;
			employer?: string;
			about: string;
			gender?: 'male' | 'female';
			social_links?: {
				id: number;
				platform: string;
				link: string;
			}[];
		};
	}[];
	sponsors?: {
		id: number;
		sponsor_type_enum: 'inside_v_verse' | 'outside_v_verse' | 'outside_v_verse_personal';
		user_id: number;
		outside_profile?: {
			id?: number;
			name: string;
			business_type_id: number;
			establishment_date: string;
			head_office_country_id: number;
			head_office_city_id: number;
			no_of_employees?: number;
			no_of_employees_inside?: number;
			no_of_employees_outside?: number;
			about: string;
			logo: number;
			address?: string;
			maps_url?: string;
			website?: string;
			phone?: string;
			landline?: string;
			whatsapp?: string;
			email: string;
			social_links?: {
				id: number;
				platform: string;
				link: string;
			}[];
		};
		outside_personal?: {
			id: number;
			name: string;
			nationality_country_id?: number;
			country_id?: number;
			city_id?: number;
			website?: string;
			whatsapp?: string;
			image?: number;
			email: string;
			phone?: string;
			title: string;
			employer?: string;
			about: string;
			gender?: 'male' | 'female';
			social_links?: {
				id: number;
				platform: string;
				link: string;
			}[];
		};
	}[];
	faqs?: FAQType[];
};

export type CreateEventResponseType = {
	data: EventResource[];
};

export type CreateEventSpeakerResponseType = {
	data: EventSpeakerResource[];
};

export type CreateEventSpeakerRequestType = {
	speakers: {
		id: number;
		speaker_type_enum: 'inside_v_verse' | 'outside_v_verse' | 'outside_v_verse_personal';
		inside_v_verse_id?: number;
		outside_profile?: OutSideProfileResource;
		outside_personal?: Record<any, any>;
	}[];
};

export type StoreVExpertRequestType = {
	title: string;
	details: string;
	publish_enum: 'draft' | 'published';
	is_scheduled: boolean;
	scheduled_date: string;
	comments_enabled: boolean;
	privacy_enum: 'public' | 'connections' | 'selected_users' | 'only_me' | 'followers' | 'members' | 'subscribers';
	is_featured: boolean;
	is_exclusive: boolean;
	sources_copy_right: string;
	duration: number;
	payment_type: 'free' | 'paid';
	regular_price: number | null;
	discounted_price: number;
	viewable_by: number[];
	categories: number[];
	coupons: { id?: number; coupon_code: string; coupon_discount: string }[];
	sub_categories: number[];
	levels: number[];
	max_no_of_attendees: number;
	hashtags: any[];
	files: any[];
	files_delete_ids: number[];
	gallery: any[];
	galleries_delete_ids: number[];
	banners: any[];
	banners_delete_ids: number[];
	// priority_type: string;
	planer_praiority: string;
	// planer_priority: string;
	deleted_attachments: number[];
	planer_category: string;
};

export type GetConsultationServiceResponseType = {
	data: ConsultationServiceResource;
};

export type CreateServiceRequestType = {
	title: string;
	description: string;
	privacy_enum: 'public' | 'connections' | 'selected_users' | 'only_me' | 'followers' | 'members' | 'subscribers';
	comments_enabled: boolean;
	is_exclusive: boolean;
	publish_enum: 'published' | 'draft' | 'scheduled';
	is_scheduled: boolean;
	scheduled_at?: string;
	is_featured: boolean;
	sources_copy_right: string;
	viewable_by: number[];
	service_add_ons?: {
		name: string;
		description: string;
		delivery_time: number;
		delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
		price: number;
	}[];
	service_faqs?: {
		id?: number;
		question: string;
		answer: string;
	}[];
	service_categories?: number[];
	service_sub_categories?: number[];
	service_levels?: number[];
	service_hashtags?: any;
	gallery?: any[];
	banners?: any[];
	files?: any[];
	service_tiers: {
		id?: number;
		name: 'silver_tier' | 'gold_tier' | 'platinum_tier' | string;
		description: string;
		delivery_time: number;
		delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
		price: number;
		discount: number;
		add_ons?: {
			id?: number;
			tier_id: string;
			name: string;
			description: string;
			delivery_time: number;
			delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
			price: number;
		}[];
		features?: {
			id: number;
			title: string;
			description: string;
			price: number;
		}[];
		details?: {
			lance_service_tier_id: string;
			tier_detail_name: string;
			price: string;
		}[];
	}[];
};

export type CreateServiceResponseType = {
	data: FreelancingServiceResource[];
};

export type ConnectionRequestsRequestType = {
	type: 'received' | 'sent';
	filters?: {
		sorting?: {
			name?:
				| 'By Name'
				| 'By Joining Date'
				| 'By Location'
				| 'By Featured'
				| 'By No Of Assets'
				| 'By Sales'
				| 'By Investment'
				| 'By Likes'
				| 'By Shares'
				| 'By No Of Endorsements';
			order?: 'asc' | 'desc';
		};
	};
	limit?: number;
	page?: number;
};

export type ConnectionRequestsResponseType = {
	data: ConnectionsType[];
	meta: PaginationMetaType;
};

export type SuggestionsResponseType = {
	data: ConnectedUserResource[];
	meta: PaginationMetaType;
};

export type SuggestionsRequestType = {
	filters?: {
		sorting?: {
			name?:
				| 'By Name'
				| 'By Joining Date'
				| 'By Location'
				| 'By Featured'
				| 'By No Of Assets'
				| 'By Sales'
				| 'By Investment'
				| 'By Likes'
				| 'By Shares'
				| 'By No Of Endorsements';
			order?: 'asc' | 'desc';
		};
	};
	limit?: number;
	page?: number;
};

export type BirthdaysResponseType = {
	data: any[];
	meta: PaginationMetaType;
};

export type BirthdaysRequestType = {
	filters?: (
		| 'All'
		| 'Today'
		| 'Recent(Last 2 Weeks)'
		| 'Upcoming(Next 2 Weeks)'
		| 'January'
		| 'February'
		| 'March'
		| 'April'
		| 'May'
		| 'June'
		| 'July'
		| 'August'
		| 'September'
		| 'October'
		| 'November'
		| 'December'
	)[];
	sorting?: {
		name?: 'anniversary_date';
		order?: 'asc' | 'desc';
	};
	limit?: number;
	page?: number;
};

export type AnniversariesRequestType = {
	filters?: (
		| 'All'
		| 'Today'
		| 'Recent(Last 2 Weeks)'
		| 'Upcoming(Next 2 Weeks)'
		| 'January'
		| 'February'
		| 'March'
		| 'April'
		| 'May'
		| 'June'
		| 'July'
		| 'August'
		| 'September'
		| 'October'
		| 'November'
		| 'December'
	)[];
	limit?: number;
	page?: number;
	sorting?: {
		name?: 'anniversary_date';
		order?: 'asc' | 'desc';
	};
};

export type AnniversariesResponseType = {
	data: any[];
	meta: PaginationMetaType;
};

export type GetWislistResponseType = {
	data: WislistResource[];
	current_page: number;
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: {
		url: string | null;
		label: string;
		active: boolean;
	}[];
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
	meta: PaginationMetaType;
};

export type GetWislistRequestType = {
	user_id: number | string;
	limit?: number;
	page?: number;
};

export type AddWishlistRequestType = {
	name: string;
	is_default?: boolean;
};

export type UpdateWishlistRequestType = {
	id: string;
	name?: string;
	is_default?: boolean;
};

export type RemoveWishlistRequestType = {
	id: string;
};

export type ChangeDefaultWishlistRequestType = {
	id: number;
};

export type RemoveBulkWishlistRequestType = {
	item_type:
		| 'service'
		| 'product'
		| 'ticket'
		| 'subscription'
		| 'Podcast'
		| 'Book'
		| 'Sponsorship'
		| 'Consultation'
		| 'Project'
		| 'Course'
		| 'Webinar'
		| 'Episode';
	itemable_ids?: number[];
};

export type moveBulkWishlistRequestType = {
	source_wish_list_id: number;
	destination_wish_list_id: number;
	item_ids: number[];
};

export type GetCartSummaryResponseType = {
	payable_items: number;
	free_items: number;
	initial_value: number;
	direct_discounts: number;
	vendor_coupons: number;
	vverse_savings: number;
	sub_total_value: number;
	shipping_fees: number;
	vverse_fees: number;
	vat: number;
	other_taxes: number;
	final_value: number;
	cart: any;
};

export type AddToCartResponseType = {
	message: string;
	cart: CartResource[];
};

export type AddToCartRequestType = {
	module_enum: ModuleEnum;
	item_type:
		| 'service'
		| 'product'
		| 'ticket'
		| 'subscription'
		| 'Podcast'
		| 'Book'
		| 'Sponsorship'
		| 'Episode'
		| 'Project'
		| 'Course'
		| 'Webinar';
	item_ids: number[];
};

export type RemoveFromCartResponseType = {
	message: string;
	cart: CartResource[];
};

export type RemoveFromCartRequestType = {
	module_enum: ModuleEnum;
	item_type:
		| 'service'
		| 'product'
		| 'ticket'
		| 'subscription'
		| 'Podcast'
		| 'Book'
		| 'Sponsorship'
		| 'Episode'
		| 'Project'
		| 'Course'
		| 'Webinar';
	item_id: string;
};

export type UpdateQuantityResourceType = {
	message: string;
	cart: CartResource[];
};

export type UpdateQuantityRequestType = {
	module_enum: ModuleEnum;
	item_type: CartItemType;
	item_id: string;
	quantity: number;
};

export type ShippingAddress = {
	id: string;
	title: string;
	address: string;
	maps_link: string;
	delivery_time: string;
	delivery_time_end: string;
	module_enum: string;
	created_at: string;
	updated_at: string;
};

export type GetShippingAddressResponse = {
	data: ShippingAddress[];
};

export type ApplyCouponResponseType = {
	message: string;
	cart: CartResource[];
};

export type ApplyCouponRequestType = {
	coupon_code: string;
	coupon_type: 'vendor' | 'vverse';
};

export type CheckoutResponseType = {
	message: string;
	order: string;
};

export type CheckoutRequestType = {
	payment_method: string;
};
// END

// Common Types
export type CartResource = {
	id: string;
	user_id: string;
	total_price: string;
	total_quantity: string;
	coupon_history: string;
	items: CartItemResource[];
};

export type basic_shipping_option = {
	id: string;
	title: string;
};

export type CartItemResource = {
	id: string;
	cart_id: string;
	itemable_id: string;
	itemable_type: string;
	quantity: string;
	price: string | null;
	total: string;
	module_enum: ModuleEnum;
	item_type: CartItemType;
	price_type: string;
	discount: string;
	discounted_price?: string | null;
	offer_type: string;
	shipping_type: string;
	fee_type: string;
	shipping_fee: string;
	delivery_date: string;
	preferred_shipping?: basic_shipping_option;
	shipping_address?: basic_shipping_option;
	sold_by: string;
	title: string;
	ticket_name?: string;
	item_type_enum?: string;
	item: string;
	duration?: number;
};

export type WislistResource = {
	id: number;
	user_id: number;
	name: string;
	is_default: number;
	created_at: string;
	updated_at: string;
	wish_lists: WishListItem[];
};

export type WishListItem = {
	id: number;
	user_id: number;
	group_id: number;
	itemable_type: string;
	itemable_id: number;
	module_enum: string;
	created_at: string | null;
	updated_at: string | null;
	item_type: string;
	item: WebinarType;
};

// WEBINAR TYPES
export type WebinarType = {
	id: number;
	user_id: number;
	title: string;
	description: string;
	webinar_type: string;
	co_hosts_inside: number[];
	tags: string | null;
	sources_copy_right: string | null;
	privacy_enum: string;
	is_scheduled: boolean;
	scheduled_at: string;
	viewable_by: number[];
	vv_exclusive: boolean;
	comments_enabled: boolean;
	is_reply_enabled: boolean;
	starting_date: string;
	ending_date: string;
	payment_type: string;
	regular_price: string;
	price: string;
	discount_price: string;
	discounted_price: string;
	priority_type: string;
	category: string;
	publish_enum: string;
	duration_in_seconds: number;
	created_at: string;
	updated_at: string;
	is_discount: boolean;
	is_in_cart: boolean;
	user: WebinarUserType;
	offers: WebinarOfferType[];
	polls: WebinarPollType[];
	handouts: WebinarHandoutType[];
};

export type WebinarSettingsType = {
	id: number;
	webinar_id: number;
	enable_chat: boolean;
	allow_private_messages: boolean;
	allow_raising_hands: boolean;
	is_default: boolean;
};

export type LiveWebinarType = {
	join_link: string | null;
	status: string;
	starting_date: string;
};

type WebinarBadgeType = {
	id: string;
	name: string;
	description: string;
	reward_description: string;
	is_active_by_default: string;
	is_redemable: string;
	is_redemable_inside: string;
	plan_id: string;
	is_redeemed: string;
	is_active: string;
};

type WebinarUserType = {
	plan_name?: string;
	id: string;
	full_name: string;
	role: string;
	is_online: boolean;
	badges: WebinarBadgeType[];
	is_online_profile_state: string;
	current_title: string;
	custom_id: string;
	is_featured: string;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: 'approved' | 'pending' | 'rejected';
	is_hide_welcome: string;
	current_company: string;
	gender: string;
	banner_tags: string;
	country: string;
	businessType: string;
	age: string;
	no_of_employees: string;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	joining_date: string;
	is_connected: boolean;
	connection_status: 'connected' | 'not_connected' | 'pending';
	profile_type: string;
	profile_id: string;
	image: string;
	counts_text: string;
	counts: string;
	is_reported: string;
	is_blocked: string;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
	is_my_account: string;
	is_editable: string;
	is_saved: string;
	is_liked: string;
};

export type CountdownTime = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
};

export type WebinarChatMessage = {
	id: string;
	webinarId: string;
	senderId: string;
	content: string;
	createdAt: Timestamp;
	updatedAt: Timestamp;
	reactions?: {
		[key: string]: MessageReaction[]; // emoji as key, array of users who reacted
	};
	isEdited: boolean;
};

export type WebinarPollType = {
	id: number;
	question: string;
	answers: WebinarPollAnswerType[];
	share_results_option: ShareResultsOptionType;
	total_profile_voted: number;
	publish_enum: WebinarPublishEnum;
	is_scheduled?: boolean;
	scheduled_at_hours?: number;
	scheduled_at_minutes?: number;
	scheduled_at_seconds?: number;
	voted_answer_id?: number;
};

export type BuilderWebinarPollType = {
	id: number;
	question: string;
	answers: string[];
	share_results_option: ShareResultsOptionType;
	total_profile_voted: number;
	publish_enum: WebinarPublishEnum;
	is_scheduled?: boolean;
	scheduled_at_hours?: string;
	scheduled_at_minutes?: string;
	scheduled_at_seconds?: string;
};

export type BuilderWebinarType = {
	id?: number;
	_title: string;
	webinar_type: string;
	title: string;
	description: string;
	co_hosts_inside: any[];
	co_hosts_entity_outside_profiles: any[];
	co_hosts_personal_outside_profiles: any[];
	user_id?: string;
	files: any[];
	files_delete_ids: number[];
	gallery: any[];
	galleries_delete_ids: number[];
	banners: any[];
	banners_delete_ids: number[];
	hashtags: any[];
	deleted_attachments: any[];
	privacy_enum: string;
	viewable_by: any[];
	comments_enabled: boolean;
	vv_exclusive: boolean;
	publish_enum: string;
	is_scheduled: boolean;
	scheduled_at: string;
	starting_date: string;
	ending_date: string;

	polls?: BuilderWebinarPollType[];
	offers?: WebinarOfferType[];
	handouts?: WebinarHandoutType[];

	sources_copy_right: string;

	payment_type: string;
	regular_price: number;
	discounted_price: number;
	priority_type: string;
	category: string;
	coupons: any[];
	categories: any[];
	sub_categories: any[];
	levels: any[];
	video: File | null;
	is_reply_enabled: boolean;
};

export type PlannerConsultationType = {
	id?: number;
	_title: string;
	webinar_type: string;
	title: string;
	description: string;
	co_hosts_inside: any[];
	co_hosts_entity_outside_profiles: any[];
	co_hosts_personal_outside_profiles: any[];
	user_id?: string;
	files: any[];
	files_delete_ids: number[];
	gallery: any[];
	galleries_delete_ids: number[];
	banners: any[];
	banners_delete_ids: number[];
	hashtags: any[];
	deleted_attachments: any[];
	privacy_enum: string;
	viewable_by: any[];
	comments_enabled: boolean;
	vv_exclusive: boolean;
	publish_enum: string;
	is_scheduled: boolean;
	scheduled_at: string;
	start_time: string;
	end_time: string;
	is_online: boolean;

	sources_copy_right: string;

	payment_type: string;
	regular_price: number;
	discounted_price: number;
	priority: string;
	category: string;
	coupons: any[];
	categories: any[];
	sub_categories: any[];
	levels: any[];
	video: File | null;
	is_reply_enabled: boolean;
	meeting_link: string;
};

export type WebinarPollAnswerType = {
	id: string;
	answer: string;
	answer_enum: string;
	votes: string;
	votes_percentage: string;
	is_voted: string;
};

export type WebinarOfferType = {
	id: string;
	title: string;
	description: string;
	offer_image?: number | WebinarOfferImageType;
	button_url: string;
	is_scheduled?: boolean;
	scheduled_at_hours?: number;
	scheduled_at_minutes?: number;
	scheduled_at_seconds?: number;
	publish_enum?: WebinarPublishEnum;
};

type WebinarOfferImageType = {
	id: number;
	folder_id: number;
	user_id: number;
	my_account_id: number;
	file_size: number;
	file_name: string;
	file_extension_enum: string;
	file_path: string;
	url: string;
	is_put_in_trash: boolean;
	created_at: string;
	updated_at: string;
	last_used_at: string;
};

export type WebinarHandoutType = {
	id: string;
	title: string;
	description: string;
	handout_file?: number | WebinarOfferImageType;
	is_scheduled?: boolean;
	scheduled_at_hours?: number;
	scheduled_at_minutes?: number;
	scheduled_at_seconds?: number;
	publish_enum?: WebinarPublishEnum;
};

export type WebinarPublishEnum = 'draft' | 'published';

export type ShareResultsOptionType = 'number_only' | 'percentage_only' | 'both';

export type ConnectedUserPersonalFilters = {
	sorting?: {
		name?:
			| 'By Name'
			| 'By Joining Date'
			| 'By Location'
			| 'By Featured'
			| 'By No Of Assets'
			| 'By Sales'
			| 'By Investment'
			| 'By Likes'
			| 'By Shares'
			| 'By No Of Endorsements'
			| 'By No Of Customers'
			| 'By No Of Orders'
			| 'By Rating'
			| 'By Reviews'
			| 'No Of Podcasts'
			| 'No Of Episodes'
			| 'No VCast Of Subscribers'
			| 'No Of Books'
			| 'Vlance No Of Orders'
			| 'Vlance No of Customers'
			| 'created_at'
			| 'no_of_channels'
			| 'no_of_videos'
			| 'no_of_views'
			| 'no_of_subscribers'
			| 'By No Of Sponsorships';
		order?: 'asc' | 'desc';
	};
	connection_status?: Array<'connected' | 'not connected' | null>;
	search?: string;
	earning?: Array<{
		min?: number;
		max?: number;
	}>;
	is_open_to_work?: Array<boolean | null>;
	languages?: Array<{
		language_id: number;
		language_level_id: number;
	}>;
	no_vcast_of_subscribers?: {
		min?: number;
		max?: number;
	};
	no_of_episodes?: {
		min?: number;
		max?: number;
	};
	no_of_podcasts?: {
		min?: number;
		max?: number;
	};
	no_of_books?: {
		min?: number;
		max?: number;
	};
	no_of_orders?: {
		min?: number;
		max?: number;
	};
	sales?: Array<{
		min?: number;
		max?: number;
	}>;
	investment?: Array<{
		min?: number;
		max?: number;
	}>;
	points?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_assets?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_sponsorships?: {
		min?: number;
		max?: number;
	};
	likes?: Array<{
		min?: number;
		max?: number;
	}>;
	shares?: Array<{
		min?: number;
		max?: number;
	}>;
	comments?: Array<{
		min?: number;
		max?: number;
	}>;
	reviews?: Array<{
		min?: number;
		max?: number;
	}>;
	rating?: Array<{
		min?: number;
		max?: number;
	}>;
	age?: Array<{
		min?: number;
		max?: number;
	}>;
	joining_date?: {
		min?: string;
		max?: string;
	};
	gender?: Array<'male' | 'female'>;
	nationality_country_id?: Array<number>;
	residence_country_id?: Array<number>;
	residence_city_id?: Array<number>;
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	employment_status?: Array<'Employed' | 'Unemployed' | 'Self-Employed'>;
	current_title?: Array<string>;
	current_employer?: Array<string>;
	total_years_of_experience?: Array<number>;
	notice_period?: Array<number>;
	driving_license_enum?: Array<'yes' | 'no'>;
	no_of_connections?: Array<{
		min?: number;
		max?: number;
	}>;
	total_customers?: Array<{
		min?: number;
		max?: number;
	}>;
	service_rate_per_hour?: Array<number>;
	social_status_enum?: Array<'single' | 'married' | 'engaged' | 'not disclosed'>;
	military_status_enum?: Array<'completed' | 'exempted' | 'expecting'>;
	excepected_gross_monthly_salary?: {
		min?: number;
		max?: number;
	};
	is_online?: Array<'online' | 'offline'>;
	is_featured?: Array<boolean>;
	profile_message?: Array<string>;
	driving_license_issuing_country_id?: Array<number>;
	total_endorsements?: Array<{
		min?: number;
		max?: number;
	}>;
	social_groups_members?: Array<{
		min?: number;
		max?: number;
	}>;
	social_pages_followers?: Array<{
		min?: number;
		max?: number;
	}>;
	video_channel_subscribers?: Array<{
		min?: number;
		max?: number;
	}>;
	vv_activities?: Array<string>;
	super_creator_status?: Array<'yes' | 'no' | null>;
	super_seller_status?: Array<'yes' | 'no' | null>;
	super_affiliate_status?: Array<'yes' | 'no' | null>;
	hiring_rate?: {
		min?: number;
		max?: number;
	};
	vacancies_range?: {
		min?: number;
		max?: number;
	};
	no_of_hiring?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_event?: {
		min?: number;
		max?: number;
	};
	no_of_vlance_services?: Array<{
		min?: number;
		max?: number;
	}> | null;
	weekly_availability?: Array<{
		min?: number;
		max?: number;
	}> | null;
	hourly_rate?: Array<{
		min?: number;
		max?: number;
	}> | null;
	vlance_success_rate?: Array<{
		min?: number;
		max?: number;
	}> | null;
	no_of_authors_channels?: {
		min?: number;
		max?: number;
	};
	no_of_authors_videos?: {
		min?: number;
		max?: number;
	};
	no_of_authors_channels_subscribers?: {
		min?: number;
		max?: number;
	};
	no_of_authors_channels_views?: {
		min?: number;
		max?: number;
	};
};

export type ConnectedUserBusinessFilters = {
	sorting?: {
		name?:
			| 'By Name'
			| 'By Joining Date'
			| 'By Location'
			| 'By Featured'
			| 'By No Of Assets'
			| 'By Sales'
			| 'By Investment'
			| 'By Likes'
			| 'By Shares'
			| 'By No Of Endorsements'
			| 'By No Of Sponsorships'
			| 'By No Of Customers'
			| 'By No Of Orders'
			| 'By Rating'
			| 'By Reviews'
			| 'No Of Podcasts'
			| 'No Of Episodes'
			| 'No VCast Of Subscribers'
			| 'No Of Books'
			| 'Vlance No Of Orders'
			| 'Vlance No of Customers'
			| 'created_at'
			| 'no_of_channels'
			| 'no_of_videos'
			| 'no_of_views'
			| 'no_of_subscribers';
		order?: 'asc' | 'desc';
	};
	search?: string;
	is_online?: Array<boolean>;
	connection_status?: Array<'connected' | 'not connected' | null>;
	joining_date?: {
		min?: string;
		max?: string;
	};
	profile_type?: Array<'individual' | 'business'>;
	total_sales?: Array<{
		min?: number;
		max?: number;
	}>;
	total_earnings?: Array<{
		min?: number;
		max?: number;
	}>;
	total_investments?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_connections?: Array<{
		min?: number;
		max?: number;
	}>;
	total_points?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_assets?: Array<{
		min?: number;
		max?: number;
	}>;
	languages?: Array<{
		language_id: number;
		language_level_id: number;
	}>;
	total_likes?: Array<{
		min?: number;
		max?: number;
	}>;
	total_reviews?: Array<{
		min?: number;
		max?: number;
	}>;
	total_shares?: Array<{
		min?: number;
		max?: number;
	}>;
	total_comments?: Array<{
		min?: number;
		max?: number;
	}>;
	total_rating?: Array<{
		min?: number;
		max?: number;
	}>;
	total_endorsements?: Array<{
		min?: number;
		max?: number;
	}>;
	vv_activities?: Array<string>;
	no_of_employees_inside_vv?: {
		min?: number;
		max?: number;
	};
	no_of_employees_outside_vv?: {
		min?: number;
		max?: number;
	};
	no_of_sponsorships?: {
		min?: number;
		max?: number;
	};
	no_vcast_of_subscribers?: {
		min?: number;
		max?: number;
	};
	no_of_episodes?: {
		min?: number;
		max?: number;
	};
	no_of_podcasts?: {
		min?: number;
		max?: number;
	};
	no_of_books?: {
		min?: number;
		max?: number;
	};
	social_pages_followers?: Array<{
		min?: number;
		max?: number;
	}>;
	social_groups_members?: Array<{
		min?: number;
		max?: number;
	}>;
	video_channel_subscribers?: Array<{
		min?: number;
		max?: number;
	}>;
	total_customers?: Array<{
		min?: number;
		max?: number;
	}>;
	nationality_country_id?: Array<number>;
	residence_country_id?: Array<number>;
	residence_city_id?: Array<number>;
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	total_years_of_experience?: Array<{
		min?: number;
		max?: number;
	}>;
	no_of_employees?: Array<{
		min?: number;
		max?: number;
	}>;
	opening_status?: Array<'open' | 'closed'>;
	super_creator_status?: Array<'yes' | 'no' | null>;
	super_seller_status?: Array<'yes' | 'no' | null>;
	super_affiliate_status?: Array<'yes' | 'no' | 'null'>;
	hiring_rate?: {
		min?: number;
		max?: number;
	};
	vacancies_range?: {
		min?: number;
		max?: number;
	};
	no_of_hiring?: Array<{
		min?: number;
		max?: number;
	}>;
	business_type_id?: Array<number>;
	featured_status?: Array<boolean>;
	no_of_event?: {
		min?: number;
		max?: number;
	};
	no_of_orders?: {
		min?: number;
		max?: number;
	};
	no_of_vlance_services?: Array<{
		min?: number;
		max?: number;
	}> | null;
	weekly_availability?: Array<{
		min?: number;
		max?: number;
	}> | null;
	hourly_rate?: Array<{
		min?: number;
		max?: number;
	}> | null;
	vlance_success_rate?: Array<{
		min?: number;
		max?: number;
	}> | null;
	no_of_authors_channels?: {
		min?: number;
		max?: number;
	};
	no_of_authors_videos?: {
		min?: number;
		max?: number;
	};
	no_of_authors_channels_subscribers?: {
		min?: number;
		max?: number;
	};
	no_of_authors_channels_views?: {
		min?: number;
		max?: number;
	};
};

export type ConnectionsType = {
	id: string;
	sender: ConnectedUserResource;
	receiver: ConnectedUserResource;
	connection_status: string;
	created_at: string;
	updated_at: string;
};

export type FreelancingServiceResource = {
	id: string;
	title: string;
	service_name: string;
	description: string;
	service_description: string;
	comments_enabled: boolean;
	viewable_by: number[];
	is_exclusive: boolean;
	publish_enum: 'published' | 'draft' | 'scheduled';
	is_scheduled: boolean;
	scheduled_at: string;
	is_featured: boolean;
	privacy_enum: 'public' | 'connections' | 'selected_users' | 'only_me' | 'followers' | 'members' | 'subscribers';
	custom_id: string;
	user_id: string;
	image: string;
	sources_copy_right: string;
	service_categories: any[];
	service_sub_categories: any[];
	service_levels: any[];
	rate: string;
	service_hashtags: string[];
	created_at: string;
	updated_at: string;
	is_liked: string;
	like_type: string;
	is_saved: string;
	is_rated: string;
	rating_id: string;
	is_reported: string;
	likes_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	price_type: string;
	is_discount: string;
	discount_percentage: string;
	regular_price: string;
	discount_price: string;
	customers_count: string;
	service_order_count: string;
	sales: string;
	tier_price: string;
	tier_discounted_price: string;
	is_in_wishlist: string;
	is_in_cart: string;

	gallery?: any[];
	banners?: any[];
	files?: any[];

	category_tree: {
		service_categories: {
			id: string;
			name: string;
			service_sub_categories: {
				id: string;
				name: string;
				category_id: string;
				levels: {
					id: string;
					name: string;
					sub_category_id: string;
				}[];
			}[];
		}[];
		service_sub_categories: {
			id: string;
			name: string;
			category_id: string;
		}[];
		service_levels: {
			id: string;
			name: string;
			sub_category_id: string;
		}[];
	};

	category_id: string;
	sub_category_id: string;
	user: ConnectedUserResource;
	hashtags: string[];
	service_tiers: {
		id?: number;
		name: 'silver_tier' | 'gold_tier' | 'platinum_tier';
		description: string;
		delivery_time: number;
		delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
		price: number;
		discount: number;
		addOns?: {
			id?: number;
			tier_id: string;
			name: string;
			description: string;
			delivery_time: number;
			delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
			price: number;
		}[];
		features?: {
			id: number;
			title: string;
			description: string;
			price: number;
		}[];
		details?: {
			lance_service_tier_id: string;
			tier_detail_name: string;
			price: string;
		}[];
	}[];
	service_faqs: {
		id: number;
		question: string;
		answer: string;
	}[];
	service_add_ons: {
		id: number;
		name: string;
		add_on_name: string;
		description: string;
		delivery_time: number;
		delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
		price: number;
	}[];
};

export type ConsultationServiceResource = {
	category: string;
	priority_type: string;
	id: string;
	custom_id: string;
	image: string;
	publish_enum: 'draft' | 'published';
	is_scheduled: boolean;
	scheduled_date: string;
	comments_enabled: boolean;
	privacy_enum: 'public' | 'connections' | 'selected_users' | 'only_me' | 'followers' | 'members' | 'subscribers';
	is_private: string;
	title: string;
	is_featured: string;
	is_exclusive: string;
	details: string;
	category_tree: CategoryTreeResource;
	likes_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	hashtags: any;
	sources_copy_right: string;
	files: any[];
	gallery: any[];
	banners: any[];
	viewable_by: string;
	regular_price: string;
	discounted_price: string;
	payment_type: 'free' | 'paid';
	price_type: string;
	is_discount: string;
	discount_percentage: string;
	user: ConnectedUserResource;
	coupons: {
		id?: number;
		code: string;
		coupon_code: string;
		discount: string;
		coupon_discount: string;
	}[];
	duration: string;
	is_rated: string;
	is_liked: string;
	is_saved: string;
	is_reported: string;
	is_in_wishlist: string;
	is_in_cart: string;
	order_count: string;
	customers_count: string;
	sales: string;
	is_booked: string;
	booking_id: string;
};

interface CategoryTreeResource {
	categories: CategoryResource[];
	sub_categories: SubCategoryResource[];
	levels: LevelResource[];
}

export type ProfileType = {
	profile_message?: string;
};

export type CountsData = {
	courses_count: string | number;
	podcasts_count: string | number;
	episodes_count: string | number;
	book_count: string | number;
	applicants_count: string | number;
	channels_count: string | number;
	comments_count: string | number;
	connections_count: string | number;
	consultations_count: string | number;
	customers_count: string;
	digital_products_count: string | number;
	dislikes_count: string | number;
	earned_sum: string | number;
	endorsements_count: string;
	events_count: string | number;
	followers_count: string | number;
	hirings_count: string | number;
	invested_sum: string;
	jobs_count: string | number;
	likes_count: string;
	members_count: string | number;
	no_of_assets: string | number;
	orders_count: string;
	physical_products_count: string | number;
	points_count: string;
	posts_count: string | number;
	profile_likes_count: string | number;
	profile_shares_count: string | number;
	projects_count: string | number;
	rating_avg: string | number;
	rating_count: string;
	rating_sum: string | number;
	reviews_count: string | number;
	sales_sum: string;
	services_count: string;
	shares_count: string;
	social_groups_count: string | number;
	social_pages_count: string | number;
	stores_count: string | number;
	subscribers_count: string;
	videos_count: string | number;
	views_count: string | number;
	webinars_count: string | number;
	hourly_rate: string;
	success_rate: string | number;
	hiring_rate: string | number;
	sponsorships_count: string | number;
};

export type UserType = {
	plan_name: string;
	id: string;
	full_name: string;
	official_full_name?: string;
	email: string;
	phone: string;
	response_date: string;
	is_online: boolean;
	is_online_profile_state: boolean;
	custom_id: string;
	is_featured: string | boolean;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: string | number;
	current_title: string;
	current_company: string;
	counts: CountsData | null;
	joining_date: string;
	gender: string;
	banner_tags: string | string[];
	country: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: string;
	connection_status: string;
	profile_type: string;
	profile_type_enum?: string;
	profile_id: string | number;
	businessType: string;
	profile?: any;
	image: string;
	counts_text?: string;
	is_my_account?: string | boolean;
	is_editable?: string | boolean;
	v_guide_is_liked: string;
	v_blog_is_liked: string;
	v_jobs_is_liked: string;
	is_reported: string;
	v_guide_is_saved: string;
	no_of_employees: string | number;
	is_blocked: string | boolean;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
	is_saved?: string | boolean;
	is_liked?: string | boolean;
	is_v_jobs_profile_built?: boolean;
	is_v_lance_profile_built?: boolean;
	module_enum?: string;
	blog_posts_count?: string;
	badges: any[];
	context_text?: string;
	v_lance_is_liked: string;
	v_nation_is_liked: string;
	v_verse_is_liked: string;
	v_tube_is_liked: string;
	v_blog_is_saved: string;
};

export type UserPlanBadgeType = {
	id: string;
	name: string;
	description: string;
	reward_description: string;
	is_active_by_default: string;
	is_redemable: string;
	is_redemable_inside: string;
	plan_id: string;
	is_redeemed: string;
	is_active: string;
};

export type BlogPostType = {
	id: string;
	custom_id: string;
	date: string;
	image: string;
	is_private: string;
	comments_enabled: string;
	title: string;
	publish_enum: string;
	is_featured: string;
	is_exclusive: string;
	details: string;
	category_tree: BlogPostCategoryTreeType;
	likes_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	hashtags: string;
	sources_copy_right: string;
	files: BlogPostAttachmentType[];
	gallery: BlogPostAttachmentType[];
	user: UserType;
	is_liked: string;
	is_saved: string;
	co_authors_entity_outside: OutSideProfileType[];
	co_authors_personal_outside: PersonalProfileOutSideType[];
	co_authors_inside: string;
	is_my_account: string;
	is_locked?: number | boolean;
};

type CouponsType = {
	id: string;
	code: string;
	discount: string;
};

export type BookPostType = {
	id: string;
	custom_id: string;
	user_id: string;
	title: string;
	details: string;
	is_scheduled: string;
	scheduled_at: string;
	created_at: string;
	is_exclusive: string;
	is_featured: string;
	comments_enabled: string;
	sources_copy_right: string;
	publish_enum: string;
	privacy_enum: string;
	book_type_enum: string;
	payment_type_enum: string;
	viewable_by: string;
	regular_price: string;
	discount_price: string;
	discount_percentage: string;
	coupons: CouponsType[];
	user: UserType;
	likes_count: string;
	order_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	hashtags: string;
	category_tree: BookCategoryTreeType;
	files: BookAttachmentType[];
	gallery: BookAttachmentType[];
	pdf_downloadable: BookAttachmentType[];
	banners: BookAttachmentType[];
	is_liked: string;
	is_saved: string;
	is_rated: string;
	rated_id: string;
	is_commented: string;
	commented_id: string;
	customers_count: string;
	min_coupon_discounted_price: string;
	sales: string;
	is_reported: string;
	co_authors_entity_outside: OutSideProfileType[];
	co_authors_personal_outside: PersonalProfileOutSideType[];
	co_authors_inside: string;
	is_locked?: number | boolean;
};

export type BookCategoryTreeType = {
	categories: BlogPostCategoryType[];
	sub_categories: BlogPostSubCategoryType[];
	levels: BlogPostLevelType[];
};

export type BlogPostCategoryTreeType = {
	categories: BlogPostCategoryType[];
	sub_categories: BlogPostSubCategoryType[];
	levels: BlogPostLevelType[];
};

export type BlogPostCategoryType = {
	id: string;
	name: string;
};

export type BlogPostSubCategoryType = {
	id: string;
	name: string;
	catrgory_id: string;
};

export type BlogPostLevelType = {
	id: string;
	name: string;
	sub_category_id: string;
};

export type BlogPostAttachmentType = {
	id: string;
	blog_post_id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
};

export type BookAttachmentType = {
	id: string;
	book_id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	url: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
	storage_file_id: string;
};

export type BlogUserType = {
	id: number;
	custom_id: string;
	full_name: string;
	email?: string;
	is_connected: boolean;
	connection_status: string;
	counts?: string[];
	is_featured: string;
	email_verified_at?: string;
	phone_verified_at?: string;
	document_verified_at?: string;
	id_verified_at?: string;
	current_title: string;
	current_company: string;
	last_seen_at?: string;
	businessType: string;
	joining_date?: object;
	gender: string;
	country: string;
	residency: string;
	image: string;
	blog_posts_count: string;
	profile_type_enum: string;
	profile_id: string;
	v_blog_is_liked: string;
	is_blocked: string;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
};

export type UserPersonalProfileSocialMediaLinkType = {
	id: string;
	platform: 'instagram' | 'facebook' | 'twitter' | 'linkedin' | 'tiktok' | 'youtube' | 'snapchat' | 'pinterest';
	link: string;
};

export type PersonalProfileOutSideType = {
	id: string;
	name: string;
	nationality_country_id: string;
	nationality_city_id: string;
	phone: string;
	email: string;
	image: string;
	title: string;
	employer: string;
	about: string;
	gender: string;
	nationalityCountry: CountryType;
	nationalityCity: CityType;
	socialLinks: UserPersonalProfileSocialMediaLinkType[];
};

export type CountryType = {
	id: string;
	name: string;
};

export type CityType = {
	id: string;
	name: string;
	country_id: string;
};

export type PrivacyEnumTypes = 'public' | 'connections' | 'selected_users' | 'only_me' | '';

export type StatmentType = {
	id: string;
	wallet_id: string;
	date: string;
	transaction_type: string;
	description: string;
	fees: string;
	net_value: string;
	module: string;
	status: string;
	amount: string;
};

export type UserPersonalProfileType = {
	id: string;
	legal_name: string;
	pubic_name: string;
	date_of_birth: string;
	is_custom_tagline: string;
	custom_tagline: string;
	age: string;
	gender: string;
	nationality: string;
	nationality_country_id: string;
	residence_country: string;
	residence_city: string;
	residence_country_id: string;
	residence_city_id: string;
	hobbies: string;
	location_longitude: string;
	location_latitude: string;
	employment_status: string;
	address: string;
	current_title: string;
	current_employer: string;
	joining_date: string;
	maps_url: string;
	total_years_of_experience: string;
	notice_period: string;
	driving_license_enum: 'yes' | 'no';
	service_rate_per_hour: string;
	social_status_enum: string;
	military_status_enum: string;
	expected_gross_monthly_salary: string;
	phone: string;
	whatsapp: string;
	email: string;
	website: string;
	is_featured: string;
	is_online: string;
	profile_message: string;
	weekly_hours_availability: string;
	hourly_rate: string;
	driving_license_issuing_country: string;
	attachments: ProfileAttachmentType[];
	social_media_links: UserPersonalProfileSocialMediaLinkType[];
	languages: UserPersonalProfileLanguageType[];
	resume: ResumeType;
	hiring_preferences: HiringPreferenceType;
	user_id: string;
	related_entity_ids: string;
	related_entity_profiles_inside: ConnectedUserType[];
	profile_outside: OutSideProfileType;
	residency: string;
	v_guide_is_liked: string;
	v_blog_is_liked: string;
	v_jobs_is_liked: string;
};

export type UserBusinessProfileType = {
	id: string;
	user_id: string;
	official_full_name: string;
	public_name: string;
	business_type_id: string;
	establishment_date: string;
	is_featured: string;
	no_of_employees: string;
	no_of_employees_inside_vv: string;
	no_of_employees_outside_vv: string;
	is_online: string;
	custom_tagline: string;
	hourly_rate: string;
	weekly_hours_availability: string;
	whatsapp: string;
	social_media_links: UserPersonalProfileSocialMediaLinkType[];
	total_years_of_experience: string;
	head_office_country_id: string;
	head_office_city_id: string;
	attachments: ProfileAttachmentType[];
	teamMembers: TeamMemberType[];
	branches: BranchType[];
	languages: UserPersonalProfileLanguageType[];
	hiring_preferences: {
		id: string;
		module_enum: string;
		hiring_status: string;
		gender_hiring_preference: 'all' | 'male' | 'female';
		education_level_hiring_preference:
			| 'all'
			| 'High School Degree Holders'
			| 'University Degree Holders'
			| 'MBA Degree Holders'
			| 'PhD Degree Holders'
			| 'Any Degree Holders';
		nationality_hiring_preference: string;
		residence_country_hiring_preference: CountryType;
		residence_city_hiring_preference: CityType;
		prefered_hourly_rate: string;
		hiring_for_enum: string;
		out_side_related_profile: OutsideProfileType;
		in_side_related_profile: ConnectedUserType;
	};
	created_at: string;
	updated_at: string;
	managedBy: ConnectedUserType;
	v_guide_is_liked: string;
	v_blog_is_liked: string;
	v_jobs_is_liked: string;
};

export type BranchType = {
	id: string;
	official_full_name: string;
	public_name: string;
	business_type_id: string;
	establishment_date: string;
	total_years_of_experience: string;
	country_id: string;
	city_id: string;
	is_head_office: string;
	phone_number: string;
	email: string;
	address: string;
	website: string;
	maps_url: string;
	location_longitude: string;
	whatsapp: string;
	landline: string;
	location_latitude: string;
	logo: string;
	cover_image: string;
	description: string;
	business_type: {
		id: string;
		name: string;
	};
	country: {
		id: string;
		name: string;
		'timezone-name': string;
		utc_offset: string;
	};
	city: CityType;
	social_media_links: UserPersonalProfileSocialMediaLinkType[];
	opening_hours: {
		branch_id: string;
		day: string;
		starting_time: string;
		ending_time: string;
	}[];
	products: string[];
	created_at: string;
	updated_at: string;
};

export type TeamMemberType = {
	id: string;
	profile_id: string;
	member_type: string;
	name: string;
	title: string;
	email: string;
	phone: string;
	image: string;
	user_id: string;
	social_media_links: UserPersonalProfileSocialMediaLinkType[];
	counts: string;
	created_at: string;
	updated_at: string;
};

export type ProfileAttachmentType = {
	id: string;
	attachment_type_enum: string;
	file_size: string;
	file_name: string;
	file_extension_enum: string;
	file_path: string;
	url: string;
};

export type UserPersonalProfileLanguageType = {
	id: string;
	language_id: string;
	language_level_id: string;
	language: string;
	language_level: string;
};

export type ResumeType = {
	id: string;
	cover_letter: string;
	experience: WorkExperienceType[];
	education: EducationType[];
	achievements: AchievementType[];
	skill_sets: UserSkillSetType[];
};

export type WorkExperienceType = {
	id: string;
	title: string;
	company_name: string;
	description: string;
	start_date: string;
	end_date: string;
	job_department: string;
	job_level: string;
	country_id: string;
	city_id: string;
	is_current: string;
	reference_name: string;
	reference_company: string;
	reference_email: string;
	reference_mobile: string;
	attachments: string[];
	out_side_related_profile: OutSideProfileType;
	in_side_related_entity: ConnectedUserType;
	inside_related_entity_id: string;
};

export type EducationType = {
	id: string;
	institution_name: string;
	location: string;
	description: string;
	start_date: string;
	end_date: string;
	grade: string;
	gpa: string;
	out_of_gpa: string;
	out_side_related_profile: OutSideProfileType;
	in_side_related_entity: ConnectedUserType;
};

export type OutSideProfileType = {
	id: string;
	name: string;
	user_id: string;
	business_type_id: string;
	business_type: string;
	establishment_date: string;
	head_office_country_id: string;
	head_office_city_id: string;
	head_office_country: string;
	head_office_city: string;
	no_of_employees: number;
	no_of_employees_inside: string;
	no_of_employees_outside: string;
	address: string;
	maps_url: string;
	website: string;
	phone: string;
	landline: string;
	whatsapp: string;
	email: string;
	logo: string;
	about: string;
	profileable_id: string;
	profileable_type: string;
	social_links: UserPersonalProfileSocialMediaLinkType[];
};

export type ConnectedUserType = {
	id: string;
	full_name: string;
	email: string;
	phone: string;
	is_online: boolean;
	is_online_profile_state: boolean;
	response_date: string;
	custom_id: string;
	is_featured: string;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: string;
	current_title: string;
	current_company: string;
	joining_date: string;
	gender: string;
	banner_tags: string;
	country: string;
	businessType: string;
	age: string;
	no_of_employees: string;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: string;
	connection_status: string;
	profile_type: 'business' | 'personal';
	profile_id: string;
	image: string;
	counts_text: string;
	counts: {
		sales_sum: number;
		earned_sum: number;
		jobs_count: number;
		rating_avg: number;
		rating_sum: number;
		likes_count: number;
		posts_count: number;
		views_count: number;
		events_count: number;
		invested_sum: number;
		no_of_assets: number;
		orders_count: number;
		points_count: number;
		rating_count: number;
		shares_count: number;
		stores_count: number;
		videos_count: number;
		courses_count: number;
		hirings_count: number;
		members_count: number;
		reviews_count: number;
		channels_count: number;
		comments_count: number;
		dislikes_count: number;
		projects_count: number;
		services_count: number;
		webinars_count: number;
		customers_count: number;
		followers_count: number;
		applicants_count: number;
		connections_count: number;
		subscribers_count: number;
		endorsements_count: number;
		social_pages_count: number;
		consultations_count: number;
		profile_likes_count: number;
		social_groups_count: number;
		profile_shares_count: number;
		digital_products_count: number;
		physical_products_count: number;
	};
	v_guide_is_liked: string;
	v_blog_is_liked: string;
	v_jobs_is_liked: string;
	is_reported: string;
	v_guide_is_saved: string;
	is_blocked: string;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
	is_my_account: string;
};

export type AchievementType = {
	id: string;
	company_name: string;
	location: string;
	description: string;
	date: string;
	out_side_related_profile: OutSideProfileType;
	in_side_related_entity: ConnectedUserType;
};

export type UserSkillSetType = {
	id: string;
	skill_set_name: string;
	skills: UserSkillType[];
	attachments: string[];
};

export type UserSkillType = {
	id: string;
	skill_name: string;
	percentage: string;
};

export type HiringPreferenceType = {
	id: string;
	module_enum: string;
	hiring_status: string;
	gender_hiring_preference: 'all' | 'male' | 'female';
	education_level_hiring_preference: (
		| 'all'
		| 'High School Degree Holders'
		| 'University Degree Holders'
		| 'MBA Degree Holders'
		| 'PhD Degree Holders'
		| 'Any Degree Holders'
	)[];
	nationality_hiring_preference: string;
	residence_country_hiring_preference: CountryType[];
	residence_city_hiring_preference: CityType[];
	prefered_hourly_rate: string;
	hiring_for_enum: string;
	out_side_related_profile: OutSideProfileType;
	in_side_related_entity: ConnectedUserType;
};
export type NotificationDataType = {
	id: number;
	name: string;
};

export type NotificationType = {
	id: number;
	user_id: number;
	description: string;
	data: NotificationDataType;
	asset: string;
	notification_status_enum: string;
	module_enum: string;
	is_seen: boolean;
	created_at: string;
	updated_at: string;
};

export type NotificationLinkType = {
	url: string | null;
	label: string;
	active: boolean;
};

export type NotificationsResponse = {
	current_page: number;
	data: NotificationType[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: NotificationLinkType[];
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
};

export type NotificationResponseType = {
	notifications: NotificationType[];
	unread_count: CountType;
	pagination: PaginationMetaType;
};

export type JobPostingType = {
	id: string;
	is_saved?: boolean;
	custom_id: string;
	user_id: string;
	title: string;
	last_application_date: string;
	applicants_count: number;
	description: string;
	image: string;
	publishing_date: string;
	contract_type_enum: string;
	work_type_enum: string;
	payment_cycle_enum: string;
	department: any;
	job_branch_id: string;
	job_branch: string;
	hashtags: string;
	sources_copy_right: string;
	longitude: string;
	latitude: string;
	website: string;
	address: string;
	email: string;
	requiring_cross_border_travel: string;
	is_featured: string;
	is_exclusive: string;
	hiring_status_enum: string;
	application_deadline: string;
	job_skills_requirements: string;
	job_education_enum: string;
	gender_requirement_enum: string;
	job_benefits: string;
	required_languages: {
		language_id: string;
		language_level_id: string;
		language: string;
		language_level: string;
	}[];
	salary: string;
	job_type_enum: string;
	job_salary_is_disclosed: string;
	job_hiring_date: string;
	privacy_enum: string;
	viewable_by: string;
	publishing_status: string;
	likes_count: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	aplicants_count: string;
	hired_candidates: string;
	rejected_candidates: string;
	interviewing_candidates: string;
	categoryTree: {
		categories: {
			id: string;
			name: string;
		}[];
		sub_categories: {
			id: string;
			name: string;
			category_id: string;
		}[];
		levels: {
			id: string;
			name: string;
			sub_category_id: string;
		}[];
	};
	age_from: string;
	age_to: string;
	military_status_enum: string;
	martal_status_enum: string;
	nationality_country_ids: string;
	residence_country_ids: string;
	residence_city_ids: string;
	car_ownership: string;
	driving_license: string;
	vacancies_count: string;
	role_level: {
		id: string;
		name: string;
		department_id: string;
	};
	user: ConnectedUserType;
	country: {
		id: string;
		name: string;
	};
	banner: {
		id: string;
		file_name: string;
		file_path: string;
		file_size: string;
		file_extension_enum: string;
		attachment_type_enum: string;
		url: string;
		created_at: string;
		updated_at: string;
	};
	files: {
		id: string;
		file_name: string;
		file_path: string;
		file_size: string;
		file_extension_enum: string;
		attachment_type_enum: string;
		url: string;
		created_at: string;
		updated_at: string;
	}[];
	gallery: {
		id: string;
		file_name: string;
		file_path: string;
		file_size: string;
		file_extension_enum: string;
		attachment_type_enum: string;
		url: string;
		created_at: string;
		updated_at: string;
	}[];
	screening_questions: {
		id: string;
		question: string;
		is_required: string;
		question_type_enum: 'text' | 'multiple_choice';
		bullets: {
			id: string;
			bullet: string;
		}[];
	}[];
	is_liked: string;
	your_status: string;
	your_job_candidate_id: string;
};

export type JobPostType = {
	id: string;
	custom_id: string;
	date: string;
	image: string;
	is_private: string;
	comments_enabled: string;
	title: string;
	publish_enum: string;
	is_featured: string;
	is_exclusive: string;
	details: string;
	category_tree: JobPostCategoryTreeType;
	hashtags: string;
	sources_copy_right: string;
	files: JobPostAttachmentType[];
	gallery: JobPostAttachmentType[];
};

// v-tube/main-page/home
export type FeaturedChannelsResponseType = {
	data: ChannelsByHashtagVTube[];
};

export type FeaturedVidoesResponseType = {
	data: VideoType[];
};

export type FeaturedShortsResponseType = {
	data: VideoType[];
};

export type NomninationsVideos = {
	data: VideoType[];
};

export type ExclusiveChannelsResponseType = {
	data: ChannelsByHashtagVTube[];
};

export type LiveVideosListResponseType = {
	data: VideoType[];
};

export type StreamedVideosListResponseType = {
	data: VideoType[];
};

// v-tube/main-page/hashtags
export type HashtagPerModuleResponseType = {
	data: HashtagResource[];
};

export type TrendingHashtagResourceType = {
	data: TrendingHashtagResource[];
};

export type ChannelsByHashtagVTubeResourceType = {
	data: ChannelsByHashtagVTube[];
};

export type VideosResourceType = {
	data: VideoType[];
};

export type ShortsByHashtagResourceType = {
	data: VideoType[];
};

// v-tube/my-profile/engagements/subsctiptions

export type SubscriptionsResourceType = {
	data: SubscriptionsDataType[];
};

// v-tube/my-profile/engagements/queued
export type QueuedDataResourceType = {
	data: QueuedDataType[];
};

// v-tube/my-profile/engagements/watch-later
export type WatchLaterVideosResourceType = {
	data: VideoType[];
};

// v-tube/my-profile/engagements/watch-tracker
export type WatchTrackerVideosResourceType = {
	data: VideoType[];
};

// v-tube/my-profile/engagements/subscribed-channels
export type SubscribedChannelsResourceType = {
	data: ChannelsByHashtagVTube[];
};

// v-tube/my-profile/saved
export type AllChannelsResourceType = {
	data: ChannelsByHashtagVTube[];
};

// v-tube/my-profile/saved/shorts
export type AllShortsResourceType = {
	data: VideoType[];
};

// vv-search channels [id] videos
export type VideosByChannelIdResourceType = {
	data: VideoType[];
};

export type ChannelInteractionsUsingChannelIdResourceType = {
	data: ChannelInteractionType[];
};

export type VidoeInteractionResoruceType = {
	data: VideoInteractionType[];
};

export type VTubeCategoryResourceType = {
	data: VTubeCategory[];
};

export type VTubeAuthorResourceType = {
	data: ConnectedUserResource[];
};

export type JobPostCategoryTreeType = {
	categories: JobPostCategoryType[];
	sub_categories: JobPostSubCategoryType[];
	levels: JobPostLevelType[];
};

export type JobPostCategoryType = {
	id: string;
	name: string;
};

export type JobPostSubCategoryType = {
	id: string;
	name: string;
	catrgory_id: string;
};

export type JobPostLevelType = {
	id: string;
	name: string;
	sub_category_id: string;
};

export type JobPostAttachmentType = {
	id: string;
	blog_post_id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
};

export type HistoryResponseData = {
	id: number;
	user_id: number;
	activity: string;
	module_enum: string;
	activity_type: string;
	created_at: string;
	updated_at: string;
};

export type HistoryLinkType = {
	url?: string;
	label: string;
	active: boolean;
};

export type HistoryResponse = {
	current_page: number;
	data: HistoryResponseData[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: HistoryLinkType[];
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
};

export type CountType = {
	General: number;
	'V-Verse': number;
	'V-Guide': number;
	'V-Blog': number;
	'V-Nation': number;
	'V-Tube': number;
	'V-Jobs': number;
	'V-College': number;
	'V-Market': number;
	'V-Lance': number;
	'V-Events': number;
	'V-Marketing': number;
	'V-Agency': number;
	'V-Webinars': number;
	'V-Experts': number;
	'V-Buzz': number;
};

export type NotificationCountType = {
	General: number;
	'V-Guide': number;
	'V-Blog': number;
	'V-Nation': number;
	'V-Tube': number;
	'V-Jobs': number;
	'V-College': number;
	'V-Market': number;
	'V-Lance': number;
	'V-Events': number;
	'V-Marketing': number;
	'V-Agency': number;
	'V-Webinars': number;
	'V-Experts': number;
	'V-Buzz': number;
	'V-Cast': number;
	'V-Books': number;
	'V-Support': number;
};

export type HistoryResponseType = {
	history: HistoryResponse;
	count: CountType;
};

export type FolderResponseType = {
	id: string;
	user_id: string;
	folder_name: string;
	parent_folder_id: string;
	parent_folder_name: string;
	type: string;
	nested_folders_count: number;
	files_count: number;
	size: string;
	created_at: string;
	updated_at: string;
};

export type RecentlyUsedFoldersResponseType = {
	data: FolderResponseType[];
};

export type FolderRequestType = {
	sorting: {
		order: SortingOrderType;
		order_by: SortingOrderByType;
	};
	type?: StorageFileType[];
	limit: number;
	search?: string;
};

export type FolderFileResponseType = {
	folders: FolderResponseType[];
	files: FileResponseType[];
};

export type FolderRawResponseType = {
	data: FolderResponseType[];
	meta: PaginationMetaType;
	links: LinkType;
};

export type MetaLinkType = {
	url?: string;
	label: string;
	active: boolean;
};

export type LinkType = {
	first?: string;
	last: string;
	next: string | null;
	prev: string | null;
};

export type PaginationMetaType = {
	current_page: number;
	from: number;
	last_page: number;
	links: MetaLinkType[];
	path: string;
	per_page: number;
	to: number;
	total: number;
};

export type FileResponseType = {
	id: string;
	folder_id: string;
	url: string;
	file_name: string;
	file_path: string;
	file_type: string;
	file_size: string;
	file_extension: string;
	is_put_in_trash: string;
	created_at: string;
	updated_at: string;
	files_count: number;
	type?: string;
	file_id?: string;
};
export type StorageFileType = 'Images' | 'Videos' | 'Audios' | 'Documents' | 'Others' | 'Trash' | null | undefined;
export type MultipleStorageFileType = [StorageFileType];
export type FolderFileRequestType = {
	id?: number | null;
	sorting: {
		order: SortingOrderType;
		order_by: SortingOrderByType;
	};
	type?: StorageFileType[] | StorageFileType;
	limit: number;
	search?: string;
};

export type FolderCreateRequestType = {
	folder_name: string;
	parent_folder_id?: number;
};
export type FolderDeleteRequestType = {
	id: number;
};
export type FolderUpdateRequestType = {
	folder_id: number | undefined;
	folder_name?: string;
	parent_folder_id?: number;
};
export type FolderCreateResponseType = {
	folder_name: string;
	parent_folder_id?: number;
};

export type SortingOrderType = 'asc' | 'desc';
export type SortingOrderByType =
	| 'name'
	| 'type'
	| 'created_at'
	| 'updated_at'
	| 'last_used_at'
	| 'parent_folder_name'
	| 'nested_folders_count'
	| 'files_count'
	| 'size';

export type Attachment = {
	id: string;
	note_id: string;
	file_name: string;
	file_extension_enum: string;
	file_size: string;
	file_path: string;
	url: string;
};

export type AttachmentNote = {
	id: string;
	note_id: string;
	file_name: string;
	file_extension_enum: string;
	file_size: string;
	file_path: string;
	url: string;
};

export type Note = {
	id: number;
	user_id: number;
	title: string;
	note_details: string;
	note_date: string;
	attachments: AttachmentNote[];
};

export type Links = {
	first: string;
	last: string;
	prev: string | null;
	next: string | null;
};

export type MetaLinks = {
	url: string | null;
	label: string;
	active: boolean;
};

export type Meta = {
	current_page: number;
	from: number;
	last_page: number;
	links: MetaLinks[];
	path: string;
	per_page: number;
	to: number;
	total: number;
};

export type GetNotesResponseType = {
	data: Note[];
	links: Links;
	meta: Meta;
};

export type UpdateNoteRequestType = {
	id: string;
	title: string;
	note_details: string;
	note_date: string;
	attachments: number[];
};

export interface Connection {
	id: number;
	user_id: number;
	connection_id: number;
	connection_status: string;
	created_at: string;
	updated_at: string;
	user: UserType;
}

export type NoteResponse = {
	id: number;
	user_id: number;
	title: string;
	note_details: string;
	note_date: string;
};

export type UpdateNoteResponseType = {
	data: NoteResponse;
};

export type NewNoteResponseType = {
	data: NoteResponse;
};

export type NewNoteRequestType = {
	title: string;
	note_details: string;
	note_date: string;
	attachments: number[];
};

export type DeleteNoteResponseType = {
	message: string;
};

export type DeleteNoteRequestType = {
	id: string;
};

export type UploadFolderFileRequestType = {
	folder_id: number | undefined;
	files: any;
};

export type NewsItem = {
	id: number;
	title: string;
	details: string;
	published_at: string;
};

export type NewsResponseType = {
	data: NewsItem[];
	links: {
		first: string;
		last: string;
		prev: string | null;
		next: string | null;
	};
	meta: {
		current_page: number;
		from: number;
		last_page: number;
		links: {
			url: string | null;
			label: string;
			active: boolean;
		}[];
		path: string;
		per_page: number;
		to: number;
		total: number;
	};
};
export type DeleteNewsRequestType = {
	id: string;
};

export type AdsResponseType = {
	data: [];
	pagination: any;
};

export type AdType = {
	id: string;
	name: string;
	location: string;
	content_type: string;
	Number_of_slots: string;
	max_video_size: string;
	max_image_size: string;
	allowed_formats: string;
	aspect_ratio: string;
	price_per_slot: string;
	attachments: string[];
};

export type AdRequestType = {
	id: number;
	user_id: number;
	ad_spot_id: number;
	ad_slot_id: number;
	order_id: number | null;
	from: string;
	to: string;
	cost: string;
	status: string;
	acceptance_status: string;
	clicks: number;
	views: number;
	created_at: string;
	updated_at: string;
	artwork: artwork;
};

export type AdRequestsResponseType = {
	meta: any;
	data: AdRequestType[];
};

export type AdManagementType = {
	sorting: {
		order: 'asc' | 'desc';
		order_by: string;
	};
	limit: number;
};

export type AdRequestRequestType = {
	sorting_order: 'asc' | 'desc';
	sorting_order_by: string;
	limit: number;
};

// Add AdManageRequestType for fix build error
export type AdDataManageRequestType = {
	limit: number;
	sorting: {
		order: 'asc' | 'desc';
		order_by: string;
	};
};

export type AdRequestforAdmin = {
	id: string;
	user_id: string;
	ad_spot_id: string;
	ad_slot_id: string;
	order_id: string;
	custom_id: string;
	from: string;
	to: string;
	cost: string;
	status: string;
	acceptance_status: string;
	clicks: string;
	views: string;
	created_at: string;
	updated_at: string;
	request_date: string;
	artwork: {
		id: string;
		file_name: string;
		file_path: string;
		file_size: string;
		attachment_type_enum: string;
		url: string;
		file_extension_enum: string;
		created_at: string;
		updated_at: string;
		storage_file_id: string;
	};
};

export type AdDataManageType = {
	id: string;
	user_id: string;
	ad_spot_id: string;
	ad_slot_id: string;
	order_id: string;
	from: string;
	to: string;
	cost: string;
	status: string;
	acceptance_status: string;
	clicks: string;
	views: string;
	created_at: string;
	updated_at: string;
	artwork: artwork;
};

type artwork = {
	id: number;
	file_name: string;
	file_path: string;
	file_size: number;
	attachment_type_enum: 'artwork' | 'document' | 'image' | 'video'; // Example types, adjust as needed
	url: string;
	file_extension_enum: 'png' | 'jpg' | 'jpeg' | 'gif'; // Example extensions, adjust as needed
	created_at: string; // ISO string representation of the date
	updated_at: string; // ISO string representation of the date
	storage_file_id: string | null; // Nullable field
};

export type AdDataManageResponseType = {
	data: AdDataManageType[];
};

export type DeleteAdRequestType = {
	id: string;
};

export type UpdateAdResponseType = {
	data: {
		id: string;
		user_id: string;
		ad_spot_id: string;
		ad_slot_id: string;
		order_id: string;
		from: string;
		to: string;
		cost: string;
		status: string;
		acceptance_status: string;
		clicks: string;
		views: string;
		created_at: string;
		updated_at: string;
		artwork: string;
	};
};

export type UpdateAdRequestType = {
	id: string;
	status: string;
};

export type VerifyUserIdResponseType = {
	message: string;
};

export type VerifyUserIdRequestType = {
	country_id: number;
	official_full_name: string;
	gender: string;
	date_of_birth: string;
	national_id_number: string;
	email: string;
	attachments: string[];
};

export type RoomType = {
	id: string;
	name: string;
	tag_line: string;
	users: ConnectedUserType[];
	image: string;
	last_message: RoomMessageType;
	attachments: MessageAttachmentType[];
	participants_count: string;
	messages_count: string;
	documents_count: string;
	pivot: {
		label: string;
		is_hidden: number;
		is_favorite: number;
		is_muted: number;
		note: string;
	};
	created_at: string;
	updated_at: string;
};

export type RoomMessageType = {
	id: string;
	sender_id: string;
	message: string;
	event_type: string;
	event_id: string;
	event: ConversationMeetingType;
	date: string;
	conversation_id: string;
	attachments: MessageAttachmentType[];
	sender: ConnectedUserType;
	sender_is_online: boolean;
	created_at: string;
	updated_at: string;
	sender_name: string;
};

export type ConversationMeetingType = {
	id: string;
	start_time: string;
	end_time: string;
	meeting_link: string;
	is_online: string;
	priority: string;
	category: string;
	timezone: string;
	organizer_id: string;
	address: string;
	maps_link: string;
	status: string;
	attachments: MessageAttachmentType[];
	created_at: string;
	updated_at: string;
};

export type MessageAttachmentType = {
	id: string;
	message_id: string;
	file_name: string;
	file_extension_enum: string;
	file_size: string;
	file_path: string;
	url: string;
	created_at: string;
	updated_at: string;
};

export type UserConversationType = {
	id: string;
	other_user_id: string;
	other_user_name: string;
	other_user_image: string;
	other_user_title: string;
	other_user_local_time: string;
	other_profile_type_enum: string;
	is_online: boolean;
	last_message: MessageType;
	status: string;
	read_at: string;
	unread_count: string;
	pivot: {
		label: string;
		is_hidden: number;
		is_favorite: number;
		is_muted: number;
		note: string;
	};
	attachments_count: string;
	audio_calls_count: string;
	video_calls_count: string;
	created_at: string;
};

export type MessageType = {
	id: string;
	sender_name: string;
	sender_image: string;
	sender_title: string;
	sender_local_time: string;
	sender_is_online: string;
	sender_id: string;
	receiver_id: string;
	message: string;
	message_type: string;
	event_type: string;
	event_id: string;
	event: CallType;
	date: string;
	conversation_id: string;
	created_at: string;
	updated_at: string;
	label: string;
};

export type CallType = {
	id: string;
	call_type: string;
	status: string;
	sender_id: string;
};

export type MetaType = {
	current_page: number;
	from: number;
	last_page: number;
	links: {
		url: string | null;
		label: string;
		active: boolean;
	}[];
	path: string;
	per_page: number;
	to: number;
	total: number;
};

//meet state types
export type meetStateType = {
	is_audio_enable: boolean;
	is_video_enable: boolean;
	is_decline_call: boolean;
	accepted_at: any;
	caller: any;
	roomUsers: any;
};

//--

export type conversationClearOrDelete = Record<any, any>;

export type InviteUsersRequestType = {
	asset_id: number;
	body: string;
	type: string;
	user_id: number;
};

export type InviteUsersResponseType = {
	message: string;
};

export type getPersonalOrEntityUsersRequestType = {
	userId: string;
	profile_type_enum: 'personal' | 'business' | 'all';
};

export type getPersonalOrEntityUsersResponseType = {
	data: ConnectedUserType[];
};
//path parameters
export type getConnectedUsersIdsRequestType = {
	userId: string;
};

//query parameters
export type getConnectedUsersIdsResponseType = {
	data: ConnectedUserType[];
};

export type getConnectedUsersRequestType = {
	profile_type_enum: 'personal' | 'business' | 'all';
	page?: number;
	filters?: {
		personal?: ConnectedUserPersonalFilters;
		business?: ConnectedUserBusinessFilters;
	};
	limit?: number;
};
export type getConnectedUsersResponseType = {
	data: ConnectedUserResource[];
	pagination: PaginationInfo;
};

export type getUsersCountRequestType = {
	id: string;
};
export type getUsersCountResponseType = {
	data: ConnectedUserType[];
};

export type getAuthenticatedUserDetailsRequestType = {
	module_enum: [
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz',
	];
};

export type getAuthenticatedUserDetailsResponseType = {
	data: ConnectedUserType[];
};

export type getUsersFullDetailsRequestType = {
	user_id: number;
	module_enum: [
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz',
	];
};

export type getUsersFullDetailsResponseType = {
	data: ConnectedUserType[];
};

export type updateUserOnlineStatusRequestType = {
	status: boolean;
};

export type updateUserOnlineStatusResponseType = {
	message: string;
};

export type UserDetailsRequestType = {
	user_id: number;
	module_enum: string;
};

type UserCounts = {
	sales_sum: number;
	earned_sum: number;
	jobs_count: number;
	rating_avg: number;
	rating_sum: number;
	likes_count: number;
	posts_count: number;
	views_count: number;
	events_count: number;
	invested_sum: number;
	no_of_assets: number;
	orders_count: number;
	points_count: number;
	rating_count: number;
	shares_count: number;
	stores_count: number;
	videos_count: number;
	hirings_count: number;
	members_count: number;
	reviews_count: number;
	channels_count: number;
	comments_count: number;
	dislikes_count: number;
	projects_count: number;
	services_count: number;
	webinars_count: number;
	customers_count: number;
	followers_count: number;
	applicants_count: number;
	connections_count: number;
	subscribers_count: number;
	endorsements_count: number;
	social_pages_count: number;
	consultations_count: number;
	profile_likes_count: number;
	social_groups_count: number;
	profile_shares_count: number;
	digital_products_count: number;
	physical_products_count: number;
};

type SocialMediaLink = {
	id: number;
	platform: string;
	link: string;
};

type Language = {
	id: number;
	language_id: number;
	language_level_id: number;
	language: string;
	language_level: string;
	module_enum: string;
};

type Profile = {
	id: number;
	legal_name: string;
	public_name: string;
	date_of_birth: string;
	is_custom_tagline: boolean;
	custom_tagline: string;
	age: number;
	gender: string;
	nationality: string;
	nationality_country_id: number;
	residence_country: string;
	residence_city: string;
	residence_country_id: number;
	residence_city_id: number;
	hobbies: string;
	location_longitude: string;
	location_latitude: string;
	employment_status: string;
	address: string;
	current_title: string;
	current_employer: string;
	maps_url: string;
	total_years_of_experience: number;
	notice_period: number;
	driving_license_enum: string;
	service_rate_per_hour: string;
	social_status_enum: string;
	military_status_enum: string;
	excepected_gross_monthly_salary: string;
	phone: string;
	whatsapp: string;
	email: string;
	website: string;
	is_featured: boolean;
	is_online: boolean;
	profile_message: string;
	weekly_hours_availability: number;
	hourly_rate: string;
	driving_license_issuing_country: string;
	attachments: any[];
	social_media_links: SocialMediaLink[];
	languages: Language[];
	resume: any;
	hiring_preferences: any;
	user_id: number;
	related_entity_ids: number[];
	related_entity_profiles_inside: RelatedEntityProfile[];
	profile_outside: any;
	residency: string;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
};

type RelatedEntityProfile = {
	id: number;
	full_name: string;
	email: string;
	phone: string;
	is_online: boolean;
	response_date: string;
	custom_id: string;
	is_featured: boolean;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: number;
	current_title: string;
	current_company: string;
	joining_date: string;
	gender: string;
	banner_tags: string[];
	country: string;
	businessType: string;
	age: number;
	no_of_employees: number;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: boolean;
	connection_status: string;
	profile_type: string;
	profile_id: number;
	image: any;
	counts_text: string;
	counts: UserCounts;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
	is_reported: boolean;
	v_guide_is_saved: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
	is_my_account: boolean;
};

type UserData = {
	id: number;
	full_name: string;
	email: string;
	phone: string;
	response_date: string;
	is_online: boolean;
	is_online_profile_state: boolean;
	custom_id: string;
	is_featured: boolean;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string | null;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: number;
	current_title: string;
	current_company: string;
	counts: UserCounts;
	joining_date: string;
	gender: string;
	banner_tags: string[];
	country: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: boolean;
	connection_status: string;
	profile_type: string;
	profile_id: number;
	businessType: string | null;
	profile: Profile;
	image: any;
	badges: any[];
	counts_text: string;
	is_my_account: boolean;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
	v_lance_is_liked: boolean;
	v_nation_is_liked: boolean;
	v_verse_is_liked: boolean;
	v_guide_is_saved: boolean;
	v_tube_is_liked: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_reported: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
	v_blog_is_saved: boolean;
};

export type UserDetailsResponseType = {
	data: UserData;
	module_enum: string;
};

export type GetAllEndorsmentsRequestType = {
	module: ModuleEnum;
	// | 'General'
	// | 'V-Verse'
	// | 'V-Guide'
	// | 'V-Blog'
	// | 'V-Nation'
	// | 'V-Tube'
	// | 'V-Jobs'
	// | 'V-College'
	// | 'V-Market'
	// | 'V-Lance'
	// | 'V-Events'
	// | 'V-Marketing'
	// | 'V-Agency'
	// | 'V-Webinars'
	// | 'V-Experts'
	// | 'V-Buzz'
	// | 'V-Book'
	// | 'V-Cast';
	asset?: 'profile' | 'user' | 'freelancer' | 'employer' | 'candidate' | 'client';
	filters?:
		| ('all' | 'connected_personal' | 'connected_business' | 'not_connected_personal' | 'not_connected_business')[]
		| null;
	per_page?: number | null;
	search?: string | null;
	sorting?: {
		name?: 'By Name' | 'By Date' | 'By Approval Status' | null;
		order?: 'asc' | 'desc' | null;
	};
	status?: 'sent' | 'received' | null;
	page?: string | number;
	user_id?: string | number;
};

export type EndorsmentResource = {
	id: string;
	description: string;
	date: string;
	endorser: UserType;
	user: UserType;
	status_enum: string;
	created_at: string;
	updated_at: string;
};

export type PaginationInfo = {
	current_page: number;
	total_pages: number;
	per_page: number;
	total: number;
};

export type GetAllEndorsmentsResponseType = {
	meta: any;
	data: EndorsmentResource[];
	pagination: PaginationInfo;
	user_ids: string;
};

export type EndorsmentByIdRequestType = {
	userId: string;
};

export type DeleteEndorsmentByIdRequestType = {
	id: string;
};

export type GetUserEndorsmentsRequestType = {
	asset: 'profile' | 'user' | 'freelancer' | 'client' | 'employer' | 'candidate';
	module:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz'
		| 'V-Cast';
	user_id: number;
	filters?:
		| (
				| 'all'
				| 'connected_personal'
				| 'connected_business'
				| 'not_connected_personal'
				| 'not_connected_business'
				| 'connected_standard_personal'
				| 'not_connected_standard_personal'
				| 'connected_vendor_entity'
				| 'not_connected_vendor_entity'
				| 'connected_vendor_personal'
				| 'not_connected_vendor_personal'
		  )[]
		| null;
	per_page?: number | null;
	search?: string | null;
	sorting?: {
		name?: 'By Name' | 'By Date' | null;
		order?: 'asc' | 'desc' | null;
	};
	page?: number | string;
};

export type CreateEndorsementRequestType = {
	description: string;
	user_id: number;
	module:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz';
	endorser_id?: number; // This field is mentioned in the validation rules but not in the body description
	asset?: 'profile' | 'user' | 'freelancer' | 'employer' | 'candidate' | 'client';
};

export type ApproveDeclineEndorsementRequestType = {
	status_enum: 'approved' | 'declined';
	endorsment_id: number;
};

export type ApproveDeclineEndorsementResponseType = {
	message: string;
};

export type UpdateEndorsmentsResponseType = {
	data: EndorsmentResource[];
};

export type GetUserProfileInteractionsParams = {
	interaction_type_enum: 'like' | 'share' | 'save' | 'Downloads';
	module_enum:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz'
		| 'V-Book'
		| 'V-Cast';
	user_id?: number;
	asset_enum?: 'user' | 'freelancer' | 'client' | 'employer' | 'candidate' | 'profile' | null;
	filters?: (
		| 'all'
		| 'connected_personal'
		| 'connected_business'
		| 'not_connected_personal'
		| 'not_connected_business'
	)[];
	limit?: number;
	order?: 'asc' | 'desc';
	search?: string | null;
	page?: number;
};

export type GetMyDownloadsParams = {
	limit: number;
	product_type_enum?: 'digital' | 'physical';
	status: 'published' | 'draft' | 'scheduled' | 'archived' | 'saved' | 'Downloads';
	filters?: any;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type ProfileInteractionResource = {
	id: string;
	details: string;
	review: string;
	rating: string;
	module_enum: string;
	asset_enum: string;
	created_at: string;
	updated_at: string;
	interaction_type_enum: string;
	user: UserType;
};

export type GetUserProfileInteractionsResponse = {
	meta: any;
	data: ProfileInteractionResource[];
	pagination: PaginationInfo;
	user_ids: string;
};

export type GetTop10UsersLikeRequestType = {
	module_enum:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz';
	asset_enum?: 'user' | 'freelancer' | 'client' | 'employer' | 'candidate' | 'profile' | null;
	profile_type_enum?: 'personal' | 'business';
};

export type GetTop10UsersLikeResponseType = {
	data: UserType[];
	module_enum: string;
};

export type GetAuthUserProfileInteractionsRequestType = {
	interaction_type_enum: 'like' | 'share' | 'save' | 'Downloads';
	module_enum:
		| 'General'
		| 'V-Verse'
		| 'V-Guide'
		| 'V-Blog'
		| 'V-Nation'
		| 'V-Tube'
		| 'V-Jobs'
		| 'V-College'
		| 'V-Market'
		| 'V-Lance'
		| 'V-Events'
		| 'V-Marketing'
		| 'V-Agency'
		| 'V-Webinars'
		| 'V-Experts'
		| 'V-Buzz'
		| 'V-Book'
		| 'V-Cast';
	asset_enum?: 'user' | 'freelancer' | 'client' | 'employer' | 'candidate' | 'profile' | null;
	filters?: (
		| 'all'
		| 'connected_personal'
		| 'connected_business'
		| 'not_connected_personal'
		| 'not_connected_business'
	)[];
	limit?: number;
	order?: 'asc' | 'desc';
	search?: string | null;
	page?: number;
};

// Define the ConnectedUserResource type
export type ConnectedUserResource = {
	date: any;
	id: string;
	full_name: string;
	email: string;
	role: string;
	phone: string;
	is_online: boolean;
	is_online_profile_state: string;
	current_title: string;
	custom_id: string;
	is_featured: string;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: string;
	current_company: string;
	joining_date: string;
	gender: string;
	banner_tags: string;
	country: string;
	businessType: string;
	age: string;
	no_of_employees: string;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: string;
	connection_status: string;
	profile_type: string;
	profile_id: string;
	image: string;
	counts_text: string;
	counts: CountsData | null;
	v_guide_is_liked: string;
	v_blog_is_liked: string;
	v_jobs_is_liked: string;
	is_reported: string;
	v_guide_is_saved: string;
	is_blocked: string;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
	is_my_account: string;
	is_saved: boolean | string;
	is_liked: string;
};

// Define the Pagination type
type Pagination = {
	current_page: string;
	total_pages: string;
	per_page: string;
	total: string;
};

// Define the main response type
export type GetSavedUsersByModuleAssetNameResponse = {
	data: ConnectedUserResource[];
	pagination: Pagination;
	user_ids: string;
};

export type ModuleEnum =
	| 'General'
	| 'V-Verse'
	| 'V-Guide'
	| 'V-Blog'
	| 'V-Nation'
	| 'V-Tube'
	| 'V-Jobs'
	| 'V-College'
	| 'V-Market'
	| 'V-Lance'
	| 'V-Events'
	| 'V-Marketing'
	| 'V-Agency'
	| 'V-Webinars'
	| 'V-Experts'
	| 'V-Buzz'
	| 'V-Cast'
	| 'V-Book'
	| 'V-Mood'
	| 'V-Client';

export type AssetEnum = 'user' | 'freelancer' | 'client' | 'employer' | 'candidate' | 'profile';

export type VideoEnum = 'video' | 'short' | 'live' | 'stream';

type ProfileTypeEnum = 'personal' | 'business';

export type SortingName =
	| 'By Name'
	| 'By Joining Date'
	| 'By Location'
	| 'By Featured'
	| 'By No Of Assets'
	| 'By Sales'
	| 'By Investment'
	| 'By Likes'
	| 'By Shares'
	| 'By No Of Endorsements'
	| 'By Rank';

export type SortOrder = 'asc' | 'desc';

type EmploymentStatus = 'Empoyed' | 'Self Employed' | 'Unemployed';

type DrivingLicenseEnum = 'yes' | 'no';

type SocialStatusEnum = 'single' | 'married' | 'engaged' | 'not disclosed';

type MilitaryStatusEnum = 'completed' | 'exempted' | 'expecting';

type OnlineStatus = 'online' | 'offline';

type ConnectionStatus = 'connected' | 'not connected';

type VVActivities = string; // You might want to specify the allowed values if known

type OpeningStatus = 'open' | 'closed';

type RangeObject<T> = {
	min: T;
	max: T;
};

export type PersonalFilters = {
	sorting?: {
		name?: SortingName;
		order?: SortOrder;
	};
	search?: string;
	earning?: RangeObject<number>[];
	sales?: RangeObject<number>[];
	investment?: RangeObject<number>[];
	points?: RangeObject<number>[];
	connection_status?: ConnectionStatus;
	no_of_assets?: RangeObject<number>[];
	likes?: RangeObject<number>[];
	shares?: RangeObject<number>[];
	age?: RangeObject<number>[];
	joining_date?: {
		min: string;
		max: string;
	};
	gender?: Array<'male' | 'female'>;
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	employment_status?: EmploymentStatus[];
	current_title?: string[];
	current_employer?: string[];
	total_years_of_experience?: number[];
	notice_period?: number[];
	driving_license_enum?: DrivingLicenseEnum[];
	service_rate_per_hour?: number[];
	social_status_enum?: SocialStatusEnum[];
	military_status_enum?: MilitaryStatusEnum[];
	excepected_gross_monthly_salary?: RangeObject<number>[];
	is_online?: OnlineStatus;
	is_featured?: boolean[];
	profile_message?: string[];
	driving_license_issuing_country_id?: number[];
	total_endorsements?: RangeObject<number>[];
	social_groups_members?: RangeObject<number>[];
	social_pages_followers?: RangeObject<number>[];
	video_channel_subscribers?: RangeObject<number>[];
	plan?: string[];
};

export type BusinessFilters = {
	sorting?: {
		name?: 'By Name' | 'By Rank' | 'By Sales' | 'By Joining Date' | null;
		order?: SortOrder;
	};
	search?: string;
	is_online?: boolean;
	personal?: {
		plan?: string[];
	};
	connection_status?: ConnectionStatus;
	joining_date?: RangeObject<string>[];
	profile_type?: Array<'individual' | 'business'>;
	total_sales?: RangeObject<number>[];
	total_earnings?: RangeObject<number>[];
	total_investments?: RangeObject<number>[];
	total_points?: RangeObject<number>[];
	no_of_assets?: RangeObject<number>[];
	total_likes?: RangeObject<number>[];
	total_endorsements?: RangeObject<number>[];
	vv_activities?: VVActivities[];
	total_shares?: RangeObject<number>[];
	social_pages_followers?: RangeObject<number>[];
	social_groups_members?: RangeObject<number>[];
	video_channel_subscribers?: RangeObject<number>[];
	total_customers?: RangeObject<number>[];
	nationality_country_id?: number[];
	residence_country_id?: number[];
	residence_city_id?: number[];
	location_latitude?: number;
	location_longitude?: number;
	max_distance?: number;
	total_years_of_experience?: RangeObject<number>[];
	no_of_employees?: RangeObject<number>[];
	opening_status?: OpeningStatus[];
	business_type_id?: number[];
};

export type BlogAuthorFilters = {
	profile_type?: ProfileTypeEnum;
	country?: Array<number | null>;
	no_of_posts?: RangeObject<number>[];
	total_posts_points?: RangeObject<number>[];
	total_post_reviews?: RangeObject<number>[];
	total_post_shares?: RangeObject<number>[];
	total_post_ratings?: RangeObject<number>[];
	is_online?: 'yes' | 'no' | null;
	connection_status?: ConnectionStatus;
	gender?: 'male' | 'female' | null;
	online_status?: OnlineStatus;
	featured_status?: boolean | null;
	plan?: string[] | null;
	order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
	order?: 'asc' | 'desc';
};

export type GetSavedUsersByModuleAssetNameRequestType = {
	module_enum: ModuleEnum;
	asset_enum?: AssetEnum;
	profile_type_enum: ProfileTypeEnum;
	limit: number;
	page: number | string;
	filters: {
		order: string;
		order_by: string;
		personal?: PersonalFilters;
		business?: BusinessFilters;
		blog_author?: BlogAuthorFilters; // You might want to specify this structure if known
	};
};

type Counts = {
	sales_sum?: number;
	earned_sum?: number;
	rating_avg?: number;
	rating_sum?: number;
	likes_count?: number;
	posts_count?: number;
	invested_sum?: number;
	orders_count?: number;
	points_count?: number;
	rating_count?: number;
	shares_count?: number;
	members_count?: number;
	reviews_count?: number;
	comments_count?: number;
	followers_count?: number;
	endorsements_count?: number;
	social_pages_count?: number;
	profile_likes_count?: number;
	social_groups_count?: number;
	profile_shares_count?: number;
	views_count?: number;
	courses_count?: number;
	hirings_count?: number;
	channels_count?: number;
	dislikes_count?: number;
	projects_count?: number;
	services_count?: number;
	webinars_count?: number;
	customers_count?: number;
	applicants_count?: number;
	subscribers_count?: number;
	no_of_assets?: number;
	stores_count?: number;
	videos_count?: number;
	events_count?: number;
	digital_products_count?: number;
	physical_products_count?: number;
	sponsorships_count?: number;
	consultations_count?: number;
	hiring_rate?: number;
	hourly_rate?: number;
	success_rate?: number;
	freelancer_likes_count?: number;
	freelancer_orders_count?: number;
	freelancer_points_count?: number;
	freelancer_shares_count?: number;
	client_likes_count?: number;
	client_orders_count?: number;
	client_points_count?: number;
	client_shares_count?: number;
	client_endorsements_count?: number;
	freelancer_endorsements_count?: number;
	employer_likes_count?: number;
	candidate_likes_count?: number;
	employer_points_count?: number;
	candidate_points_count?: number;
	employer_shares_count?: number;
	candidate_shares_count?: number;
	employer_endorsements_count?: number;
	candidate_endorsements_count?: number;
	jobs_count?: number;
};

type VVerseProfile = {
	user_id: number;
	custom_id: string;
	banner_tags: string[];
	is_connected: boolean;
	connection_status: string;
	v_nation_counts: Counts;
	v_college_counts: Counts;
	v_tube_counts: Counts;
	v_lance_counts: Counts;
	v_jobs_counts: Counts;
	v_webinars_counts: Counts;
	v_events_counts: Counts;
	v_experts_counts: Counts;
	v_market_counts: Counts;
	v_marketing_counts: Counts;
	v_cast_counts: Counts;
	v_books_counts: Counts;
	v_mood_counts: Counts;
	v_client_counts: Counts;
	v_blog_counts: Counts;
	v_verse_counts: Counts;
	v_buzz_counts: Counts;
	profile_type_enum: string;
	current_title: string;
	current_company: string;
	businessType: string;
	last_seen_at: string;
	profile_id: number;
	country: string;
	residency: string;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
};

export type GetAllUserProfilesResponseType = {
	data: VVerseProfile;
};

export type GetAllUserProfileRequestType = {
	id: string;
};

export type User = {
	id: number;
	full_name: string;
	public_name?: string;
	email: string;
	role: string;
	phone: string;
	is_online: boolean;
	is_online_profile_state: boolean;
	current_title: string;
	custom_id: string;
	is_featured: boolean;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	verification_status: string;
	is_hide_welcome: number;
	current_company: string;
	joining_date: string;
	gender: string;
	banner_tags: any[];
	country: string;
	businessType: null | string;
	age: number;
	no_of_employees: string;
	establishment_date: string;
	residency: string;
	time_zone: string;
	last_seen_at: string;
	is_connected: boolean;
	connection_status: string;
	profile_type: string;
	profile_id: number;
	image: null | string;
	counts_text: string;
	counts: Counts;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
	v_book_is_liked: boolean;
	is_reported: boolean;
	v_guide_is_saved: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
	is_my_account: boolean;
};

export type GetTop10UserResponseType = {
	data: User[];
	module_enum: string;
};

export type GetTop10AuthorResponseType = {
	data: User[];
};

export type GetTop10BookResponseType = {
	data: BookPostType[];
};

export type GetTop10UserRequestType = {
	module_enum: ModuleEnum;
	asset_enum?: AssetEnum;
	profile_type_enum?: ProfileTypeEnum;
};

export type AssesType = 'profile' | 'freelancer' | 'employer' | 'candidate' | 'client' | 'user';

export class moduleEnumType {}

export type VBlogPostsFilterOptions = {
	post_category?: string[];
	post_sub_category?: string[];
	post_level?: string[];
	post_privacy?: string[];
	post_publishing_date?: RangeObject<string>[];
	comments?: RangeObject<number>[];
	reactions?: RangeObject<number>[];
	shares?: RangeObject<number>[];
	ratings?: RangeObject<number>[];
	reviews?: RangeObject<number>[];
	post_featured_status?: boolean | null;
	author_profile_type?: string[];
	author_connection_status?: boolean | null;
	author_online_status?: boolean | null;
	author_featured_status?: boolean | null;
	author_country?: string[];
	author_city?: string[];
	super_creator_status?: string;
	super_seller_status?: string;
};

export type VMyBlogPostsFilterOptions = {
	privacy_enum?: Array<'public' | 'connections' | 'selected_users' | 'only_me' | null> | null;
	categories?: Array<number>;
	sub_category?: Array<number>;
	levels?: Array<number>;
	publishing_date?: string;
	comments?: RangeObject<string>[];
	likes?: RangeObject<string>[];
	shares?: RangeObject<string>[];
	ratings?: RangeObject<string>[];
	featured_status?: Array<boolean | null>;
	author_featured_status?: Array<boolean | null>;
	author_country_id?: Array<number>;
	author_city_id?: Array<number>;
	vv_exclusive_status?: Array<boolean | null>;
	v_author_profile_type?: string | null;
	v_author_city?: number | null;
	v_author_country?: number | null;
	v_author_connection_status?: string | null;
	v_author_is_online?: string | null;
	super_creator_status?: 'yes' | 'no' | null;
	super_seller_status?: 'yes' | 'no' | null;
	super_affiliate_status?: 'yes' | 'no' | null;
	search?: string | null;
};

export type VBlogPersonalProfilesAuthorFilterOptionsType = {
	gender?: string[];
	nationality?: string[];
	country?: string[];
	city?: string[];
	distance?: string | number[];
	connection_status?: boolean | null;
	online_status?: boolean | null;
	featured_status?: boolean | null;
	joining_date?: RangeObject<string>[];
	activities?: string[];
	age?: RangeObject<number>[];
	authors_published_posts?: RangeObject<number>[];
	published_posts_comments?: RangeObject<number>[];
	authors_posts_total_rating?: RangeObject<number>[];
	authors_posts_reviews?: RangeObject<number>[];
	authors_profile_likes?: RangeObject<number>[];
	authors_profile_shares?: RangeObject<number>[];
	super_creator_status?: string;
	super_seller_status?: string;
};

export type VBlogEntityProfilesAuthorsFilterOptionsType = {
	entity_type?: string[];
	country?: string[];
	city?: string[];
	distance?: string | number[];
	connection_status?: boolean | null;
	online_status?: boolean | null;
	featured_status?: boolean | null;
	joining_date?: RangeObject<string>[];
	activities?: string[];
	total_employees?: RangeObject<number> | undefined | string;
	employees_inside_vv?: RangeObject<number> | undefined | string;
	employees_outside_vv?: RangeObject<number> | undefined | string;
	opening_status?: boolean | null;
	authors_published_post_no?: RangeObject<number>[];
	published_posts_comments?: RangeObject<number>[];
	authors_posts_total_rating?: RangeObject<number>[];
	authors_posts_no_of_reviews?: RangeObject<number>[];
	no_of_authors_profile_likes?: RangeObject<number>[];
	no_of_authors_profile_shares?: RangeObject<number>[];
	super_creator_status?: string;
	super_seller_status?: string;
};

export type SocialPagesFilterOptionsType = {
	category?: string[];
	sub_category?: string[];
	level?: string[];
	privacy?: string[];
	publishing_data?: RangeObject<string>[];
	social_posts?: RangeObject<number>[];
	followers?: RangeObject<number>[];
	reactions?: RangeObject<number>[];
	shares?: RangeObject<number>[];
	ratings?: RangeObject<number>[];
	reviews?: RangeObject<number>[];
	featured_status?: boolean | null;
	author_connection_status?: boolean | null;
	author_online_status?: boolean | null;
	following_status?: string[];
	author_profile_type?: string[];
	author_country?: string[];
	author_city?: string[];
};

export type SocialGroupsFilterOptionsType = {
	category?: string[];
	sub_category?: string[];
	level?: string[];
	privacy?: string[];
	author_connection_status?: boolean | null;
	author_online_status?: boolean | null;
	author_joining_date?: RangeObject<string>[];
	joining_status?: boolean | null;
	author_profile_type?: string[];
	author_country?: string[];
	author_city?: string[];
	shares?: RangeObject<number>[];
	ratings?: RangeObject<number>[];
	reviews?: RangeObject<number>[];
	featured_status?: boolean | null;
	publishing_date?: RangeObject<string>[];
	social_posts?: RangeObject<number>[];
	members?: RangeObject<number>[];
	reactions?: RangeObject<number>[];
};

export type VNationAuthorSFilterModalTypes = {
	profile_type?: string[];
	country?: string[];
	city?: string[];
	connection_status?: boolean | null;
	online_status?: boolean | null;
	featured_status?: boolean | null;
	joining_date?: RangeObject<string>[];
	published_pages_no?: RangeObject<number>[];
	published_social_groups?: RangeObject<number>[];
	members_total?: RangeObject<number>[];
	followers_total?: RangeObject<number>[];
	social_posts_total?: RangeObject<number>[];
	authors_profile_likes?: RangeObject<number>[];
	authors_profile_shares?: RangeObject<number>[];
	authors_assests_total_rating?: RangeObject<number>[];
	authors_assests_reviews_no?: RangeObject<number>[];
	authors_profile_endorsements_no?: RangeObject<number>[];
};

export type SponsorshipResource = {
	id: string;
	custom_id: string;
	user_id: string;
	title: string;
	description: string;
	is_featured: string;
	is_exclusive: string;
	is_scheduled: string;
	scheduled_at: string;
	comments_enabled: string;
	sources_copy_right: string;
	publish_enum: string;
	privacy_enum: string;
	viewable_by: string;
	regular_price: string;
	discount_price: string;
	payment_type_enum: string;
	user: ConnectedUserResource;
	tiers: SponsorshipTierResource[];
	faqs: SponsorshipFaqResource[];
	add_ons: SponsorshipAddOnResource[];
	files: SponsorshipAttachmentResource[];
	gallery: SponsorshipAttachmentResource[];
	banners: SponsorshipAttachmentResource[];
	category_tree: SponsorshipCategoryTreeResource;
	is_liked: string;
	like_type: string;
	is_saved: string;
	is_rated: string;
	rated_id: string;
	is_commented: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	likes_count: string;
	rating: string;
	customers_count: string;
	min_tier_price: string;
	min_tier_discounted_price: string;
	sales: string;
	created_at: string;
	updated_at: string;
	is_reported: string;
	sponsorship_order_count: string;
};

// BUILDER SPONSORSHIP TYPES
export type BuilderSponsorshipType = {
	title: string;
	description: string;
	files: number[];
	files_delete_ids: number[];
	gallery: number[];
	galleries_delete_ids: number[];
	banners: number[];
	banners_delete_ids: number[];
	hashtags: string[];
	privacy_enum: 'public' | 'connection' | 'selected_users' | 'only_me';
	viewable_by: number[];
	comments_enabled: boolean;
	is_exclusive: boolean;
	is_featured: boolean;
	publish_enum: 'published' | 'draft' | 'scheduled';
	is_scheduled: boolean;
	scheduled_at: string;
	start_date: string;
	end_date: string;
	sources_copy_right: string;
	regular_price: number;
	discount_price: number;
	categories: number[];
	sub_categories: number[];
	levels: number[];
	tiers: BuilderSponsorshipTierType[];
	faqs: BuilderSponsorshipFAQTier[] | null;
	add_ons: BuilderSponsorshipAddOnType[] | null;
	image: string;
};

type BuilderSponsorshipTierDetailType = {
	id?: number;
	description: string;
	tier_detail_name: string;
};

type BuilderSponsorshipTierType = {
	id?: number;
	tier_name: string;
	delivery_time: number;
	delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
	description: string;
	price: number;
	discount_price: number;
	details: BuilderSponsorshipTierDetailType[];
	add_ons?: BuilderSponsorshipAddOnType[] | null;
};

type BuilderSponsorshipAddOnType = {
	id?: number;
	add_on_name: string;
	price: number;
	description: string;
	delivery_time: number;
	delivery_time_unit: 'hours' | 'days' | 'weeks' | 'months';
};

type BuilderSponsorshipFAQTier = {
	question: string;
	answer: string;
};

export type CourseResource = {
	id: string;
	custom_id: string;
	user_id: string;
	title: string;
	description: string;
	is_featured: string;
	is_exclusive: string;
	is_scheduled: string;
	scheduled_at: string;
	comments_enabled: string;
	sources_copy_right: string;
	publish_enum: string;
	privacy_enum: string;
	viewable_by: string;
	regular_price: string;
	discount_price: string;
	payment_type_enum: string;
	user: ConnectedUserResource;
	tiers: SponsorshipTierResource[];
	faqs: SponsorshipFaqResource[];
	add_ons: SponsorshipAddOnResource[];
	files: SponsorshipAttachmentResource[];
	gallery: SponsorshipAttachmentResource[];
	banners: SponsorshipAttachmentResource[];
	category_tree: SponsorshipCategoryTreeResource;
	admins: ConnectedUserResource[];
	moderators: ConnectedUserResource[];
	coupons: string;
	units: string;
	exams: string;
	announcements: string;
	enrollments: string;
	knowledge: string;
	is_certificate: string;
	is_liked: string;
	like_type: string;
	is_saved: string;
	is_rated: string;
	rated_id: string;
	is_commented: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	likes_count: string;
	rating: string;
	customers_count: string;
	min_tier_price: string;
	min_tier_discounted_price: string;
	sales: string;
	created_at: string;
	updated_at: string;
	is_reported: string;
	sponsorship_order_count: string;
};

export type getServiceRequestType = {
	filters?: {
		search?: string;
		category_ids?: number[];
		sub_category_ids?: number[];
		level_ids?: number[];
		privacy_enum?: 'public' | 'connection' | 'selected_users' | 'only_me'; //channel privacy
		publishing_date?: {
			min?: string;
			max?: string;
		};
		start_price?: {
			min?: number;
			max?: number;
		};
		delivery_time?: {
			min?: number;
			max?: number;
		};
		sales?: {
			min?: number;
			max?: number;
		};
		orders?: {
			min?: number;
			max?: number;
		};
		customers?: {
			min?: number;
			max?: number;
		};
		reactions?: {
			min?: number;
			max?: number;
		};
		shares?: {
			min?: number;
			max?: number;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		reviews?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		price_range?: {
			min?: number;
			max?: number;
		};
		client_country_id?: string[];
		client_city_id?: string[];
		profile_type_enum?: string[];
		connection_status?: string[];
		featured_status?: string[];
		vv_exclusive_status?: string[];
		v_author_profile_type?: string[];
		v_author_country?: string[];
		v_author_city?: string[];
		v_author_connection_status?: string[];
		v_author_is_online?: string[];
		sorting?: {
			order_by?: string;
			order?: string;
		};
	};
	limit?: number;
	page?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
};

export type ConsultationResource = {
	id: string;
	custom_id: string;
	user_id: string;
	title: string;
	description: string;
	is_featured: string;
	is_exclusive: string;
	is_scheduled: string;
	scheduled_at: string;
	comments_enabled: string;
	sources_copy_right: string;
	publish_enum: string;
	privacy_enum: string;
	viewable_by: string;
	regular_price: string;
	discount_price: string;
	payment_type_enum: string;
	user: ConnectedUserResource;
	tiers: SponsorshipTierResource[];
	faqs: SponsorshipFaqResource[];
	add_ons: SponsorshipAddOnResource[];
	files: SponsorshipAttachmentResource[];
	gallery: SponsorshipAttachmentResource[];
	banners: SponsorshipAttachmentResource[];
	category_tree: SponsorshipCategoryTreeResource;
	is_liked: string;
	like_type: string;
	is_saved: string;
	is_rated: string;
	rated_id: string;
	is_commented: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	likes_count: string;
	rating: string;
	customers_count: string;
	min_tier_price: string;
	min_tier_discounted_price: string;
	sales: string;
	created_at: string;
	updated_at: string;
	is_reported: string;
	order_count: string;
};

export type SponsorshipTierResource = {
	id: string;
	tier_name: string;
	description: string;
	price: string;
	discount_price: string;
	delivery_time: string;
	delivery_time_unit: string;
	details: SponsorshipTierDetailResource[];
};

export type SponsorshipTierDetailResource = {
	id: string;
	tier_detail_name: string;
	description: string;
};

export type SponsorshipFaqResource = {
	id: string;
	question: string;
	answer: string;
};

export type SponsorshipAddOnResource = {
	id: string;
	add_on_name: string;
	description: string;
	price: string;
	delivery_time: string;
	delivery_time_unit: string;
};

export type SponsorshipAttachmentResource = {
	id: string;
	file_path: string;
	file_name: string;
	file_size: string;
	file_type: string;
	url: string;
	attachment_type_enum: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
	file_id: string;
};

export type SponsorshipCategoryTreeResource = {
	categories: CategoryResource[];
	sub_categories: SubCategoryResource[];
	levels: LevelResource[];
};

export type CategoryResource = {
	id: string;
	name: string;
};

export type SubCategoryResource = {
	id: string;
	name: string;
	category_id: string;
};

export type LevelResource = {
	id: string;
	name: string;
	sub_category_id: string;
};

export type CartItemType =
	| 'service'
	| 'product'
	| 'ticket'
	| 'subscription'
	| 'Podcast'
	| 'Book'
	| 'Sponsorship'
	| 'Episode'
	| 'Project'
	| 'Course'
	| 'Webinar'
	| 'Sponsorship'
	| 'Consultation';
export type AddCartRequest = {
	module_enum: ModuleEnum;
	item_type: CartItemType;
	item_ids: any[];
};
export type removeCartRequest = {
	module_enum: ModuleEnum;
	item_type: CartItemType;
	item_id: any;
};

export type updateShippingAddressRequest = {
	item_id: string;
	shipping_address_id: string;
};
export type updateItemShippingOptionRequest = {
	item_id: string;
	shipping_option_id: string;
};

export type bookBulkInteractionRequestType = {
	status: 'archive' | 'publish' | 'remove' | 'like' | 'save' | 'unsave';
	asset_ids: number[];
};

export type consultationBulkInteractionRequestType = {
	status: 'archive' | 'publish' | 'remove' | 'like' | 'save' | 'unsave';
	asset_ids: number[];
};

export type EventResource = {
	id: string;
	custom_id: string;
	user_id: string;
	title: string;
	description: string;
	is_featured: string;
	is_scheduled: string;
	scheduled_at: string;
	privacy_enum: string;
	viewable_by: string;
	sources_copy_right: string;
	duration_in_hours: string;
	location_longitude: string;
	location_latitude: string;
	is_comment_enabled: string;
	is_exclusive: string;
	notes: string;
	date: string;
	start_date: string;
	end_date: string;
	duration: string;
	status_enum: string;
	type_enum: string;
	venue: EventVenueResource;
	faqs: EventFaqResource[];
	user: ConnectedUserResource;
	sponsors: EventSponserResource[];
	coOrganizers: EventOrganizerResource[];
	gallery: string[];
	files: string[];
	banner: string[];
	speakers: EventSpeakerResource[];
	schedule: EventScheduleDayResource[];
	tickets: EventTicketResource[];
	event_status_enum: string;
	is_in_wishlist: string;
	is_in_cart: string;
	categoryTree: EventCategoryTreeResource;
	hashtags: string[];
	is_liked: string;
	is_saved: string;
	is_rated: string;
	rated_id: string;
	likes_count: string;
	like_type: string;
	is_commented: string;
	comments_count: string;
	shares_count: string;
	reviews_count: string;
	rating: string;
	offer_type_enum: string;
	payment_type_enum: string;
	price: string;
	discounted_price: string;
	price_type: string;
	is_discount: string;
	min_ticket_price: string;
	customers_count: string;
	orders_count: string;
	sales: string;
	total_tickets: string;
	remaining_tickets: string;
	location: string;
	created_at: string;
	updated_at: string;
	is_reported: string;
	is_my_account: boolean;
};

export type EventVenueResource = {
	id: string;
	event_id: string;
	venue_type_enum: string;
	inside_v_verse_id: string;
	insideProfile: ConnectedUserResource;
	outside_profile: OutSideProfileResource;
	outside_personal: Record<any, any>;
	created_at: string;
	updated_at: string;
};

export type EventFaqResource = {
	id: string;
	question: string;
	answer: string;
	event_id: string;
	created_at: string;
	updated_at: string;
};

export type EventSponserResource = {
	id: string;
	user_id: string;
	event_id: string;
	sponser_type_enum: string;
	inside_profile: ConnectedUserResource;
	outside_profile: OutSideProfileResource;
	outside_personal: Record<any, any>;
	created_at: string;
	updated_at: string;
};

export type EventOrganizerResource = {
	event_id: string;
	organizer_type_enum: string;
	inside_v_verse_id: ConnectedUserResource;
	outside_v_verse_id: OutSideProfileResource;
	outside_personal: Record<any, any>;
	created_at: string;
	updated_at: string;
};

export type EventSpeakerResource = {
	id: string;
	speaker_type_enum: string;
	speaker_name?: string;
	event_id: string;
	inside_v_verse_user: ConnectedUserResource;
	outsideProfile: OutSideProfileResource;
	outsidePersonal: Record<any, any>;
	created_at: string;
	updated_at: string;
};

export type EventScheduleDayResource = {
	id: string;
	event_id?: string;
	name: string;
	start_date: string;
	end_date: string;
	activities: ScheduleActivityResource[];
};

export type ScheduleActivityResource = {
	id?: string;
	schedule_id?: string;
	speaker_ids?: number[];
	name: string;
	start_date: string;
	end_date: string;
	description: string;
	speakers?: EventSpeakerResource[];
};

export type EventTicketResource = {
	id: string;
	event_id: string;
	user_id: string;
	name: string;
	validity_start_date: string;
	validity_end_date: string;
	offer_type_enum: string;
	no_of_available_tickets: string;
	quantity_sold: string;
	total: string;
	price_type_enum: string;
	price: string;
	discounted_price: string;
	description: string;
	pdf_name: string;
	pdf_start_date: string;
	pdf_end_date: string;
	pdf_organizer_contact: string;
	pdf_venue_name: string;
	pdf_venue_location: string;
	pdf_venue_maps_link: string;
	pdf_venue_contact: string;
	qr_code: string;
	schedule: string;
	is_discounted: string;
	discount_percentage: string;
	venue: EventVenueResource;
	created_at: string;
	updated_at: string;
};

export type EventCategoryTreeResource = {
	categories: CategoryResource[];
	sub_categories: SubCategoryResource[];
	levels: LevelResource[];
};

export type HashtagResource = {
	id: string;
	name: string;
	module_enum: ModuleEnum;
	created_at: string;
	updated_at: string;
};

export type TrendingHashtagResource = {
	name: string;
	module_enum: ModuleEnum;
	group_posts_count: number;
	page_posts_count: number;
	timeline_posts_count: number;
	video_channels_count: number;
	videos_count: number;
	blog_posts_count: number;
	total_count: number;
};

export type ChannelsByHashtagVTube = {
	id: string;
	custom_id: string;
	title: string;
	description: string;
	user_id: string;
	likes_count: number;
	dislikes_count: number;
	comments_count: number;
	shares_count: number;
	reviews_count: number;
	views_count: number;
	rating: number;
	publish_status: string;
	hash_tags: string;
	categoryTree?: BlogPostCategoryTreeType;
	attachments: Attachment[];
	is_liked: boolean;
	is_disliked: boolean;
	is_reported: boolean;
	is_featured: boolean;
};

export type VideoType = {
	id: string;
	title: string;
	description: string;
	user_id: string;
	video_type_enum: VideoEnum;
	privacy_enum: string;
	publish_status: string;
	tagged_user_ids: string;
	sources_copy_right: string;
	viewable_by: string;
	is_scheduled: string;
	scheduled_at: string;
	comments_enabled: boolean;
	is_exclusive: boolean;
	is_featured: boolean;
	is_private: boolean;
	categoryTree: BlogPostCategoryTreeType;
	likes_count: number;
	dislikes_count: number;
	comments_count: number;
	shares_count: number;
	reviews_count: number;
	rating: number;
	views_count: number;
	hash_tags: string;
	channel: ChannelsByHashtagVTube;
	attachments: Attachment[];
	media: Media;
	thumbnail: Thumbnail;
	is_liked: string;
	is_disliked: string;
	is_reported: string;
	created_at: string;
	updated_at: string;
	upload_date: string;
	user?: ConnectedUserResource;
};

interface Thumbnail {
	id: number;
	user_id: number;
	video_id: number;
	file_size: string; // Keeping it as string since the value "93815KB" is a string
	file_name: string;
	file_path: string;
	url: string;
	file_id: string;
	attachment_type_enum: 'thumbnail'; // Since it's always "thumbnail" in the example
	file_extension_enum: 'jpg' | 'jpeg' | 'png' | 'gif' | string; // Using 'jpg' as specified, but could include other image types
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
}

interface Media {
	id: number;
	user_id: number;
	video_id: number;
	file_size: string; // Since "KB" is a string, we keep it as a string type
	file_name: string;
	file_path: string;
	url: string;
	file_id: string;
	attachment_type_enum: string; // If there are specific types, consider using a string literal union
	file_extension_enum: string; // If there are specific extensions, consider using a string literal union
	created_at: string; // ISO date string
	updated_at: string; // ISO date string
}

export type SubscriptionsDataType = {
	id: string;
	title: string;
	description: string;
	user_id: string;
	video_type_enum: string;
	privacy_enum: string;
	publish_status: string;
	tagged_user_ids: string;
	sources_copy_right: string;
	viewable_by: string;
	is_scheduled: string;
	scheduled_at: string;
	comments_enabled: string;
	is_exclusive: string;
	is_featured: string;
	is_private: string;
	categoryTree: BlogPostCategoryTreeType;
	likes_count: number;
	dislikes_count: number;
	comments_count: number;
	shares_count: number;
	reviews_count: number;
	rating: number;
	views_count: number;
	hash_tags: string;
	channel: ChannelsByHashtagVTube;
	attatchments: Attachment[];
	is_liked: boolean;
	is_disliked: boolean;
	is_reported: boolean;
	created_at: string;
	updated_at: string;
	upload_date: string;
};

export type QueuedDataType = {
	detail_ids: string | null | string[];
	current_video: VideoType;
	prev_video: VideoType;
	next_video: VideoType | null;
};

// getVideoChannelInteractions
export type ChannelInteractionType = {
	id: string;
	user_id: string;
	channel_id: string;
	parent_interaction_id: string;
	details: string;
	review: string;
	rating: string;
	interaction_type_enum: string;
	created_at: string;
	updated_at: string;
	attachments: Attachment[];
	childInteraction: string;
	reaction_counts: number;
	reaction_user_ids: string;
	reply_counts: number;
	reply_user_ids: string;
	reaction_type: string;
};

// video Interaction data type
export type VideoInteractionType = {
	id: string;
	user_id: string;
	video_id: string;
	parent_interaction_id: string;
	details: string;
	review?: string;
	rating: string;
	interaction_type_enum: string;
	created_at: string;
	updated_at: string;
	attachments: Attachment[];
	childInteraction: string;
	reaction_counts: string;
	reaction_user_ids: string;
	reply_counts: string;
	reply_user_ids: string;
	reaction_type: string;
};

export type storeVideoInteractionRequestType = {
	video_id?: number | string;
	parent_interaction_id?: number;
	details?: string | null;
	review?: string | null;
	rating?: number | null;
	interaction_type_enum: 'comment' | 'like' | 'dislike' | 'share' | 'review' | 'save';
	voice_note?: string | null;
	attachments?: {
		id: string;
		file_size: string;
		file_extension: 'jpg' | 'jpeg' | 'png' | 'gif' | string;
		file_path: string;
	}[];
};

interface VTubeLevel {
	id: string;
	name: string;
	sub_category_id: string;
}

export interface VTubeSubcategory {
	id: string;
	name: string;
	levels: VTubeLevel[];
	category_id: string;
}

export interface VTubeCategory {
	id: string;
	name: string;
	subcategories: VTubeSubcategory[];
	assets_count: number;
}

export interface VTubeCategoryDataResource {
	data: VTubeCategory[];
}

export interface VTubeAuthor {
	id: number;
	custom_id: string;
	full_name: string;
	email: string;
	is_connected: string;
	connection_status: string;
	counts: CountsData | null;
	is_featured: string;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	image: string;
	joining_date: Record<string, any>;
	gender: string;
	country: string;
	profile_type_enum: string;
	profile_id: number;
	v_tube_is_liked: string;
	is_blocked: string;
	is_follower: string;
	is_member: string;
	is_subscriber: string;
	is_candidate: string;
	is_applicant: string;
	is_client: string;
}

export interface VTubeUserType {
	id: number;
	custom_id: string;
	full_name: string;
	email: string;
	is_connected: boolean;
	connection_status: string;
	counts: Counts;
	is_featured: boolean;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	image: string;
	joining_date: Record<string, any>; // Adjust as per the actual structure of joining_date
	gender: string;
	country: string;
	profile_type_enum: string;
	profile_id: number;
	v_tube_is_liked: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
}

export type VTubeAuthroRequestType = {
	profile_type_enum?: 'personal' | 'business';
	filters?: {
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		search?: string;
		gender?: 'male' | 'female';
		nationality?: number[];
		country?: number[];
		city?: number[];
		max_distance?: number;
		connection_status?: 'connected' | 'not connected';
		is_online?: 'yes' | 'no';
		featured_status?: 'yes' | 'no';
		joining_date?: {
			min?: string;
			max?: string;
		};
		activities?: string[];
		no_of_authors_channels?: {
			min?: number;
			max?: number;
		};
		no_of_authors_videos?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_subscribers?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_views?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_comments?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_likes?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_shares?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_endorsements?: {
			min?: number;
			max?: number;
		};
		total_rating?: {
			min?: number;
			max?: number;
		};
		no_of_reviews?: {
			min?: number;
			max?: number;
		};
	};
};

export type VTubeAuthroEntityRequestType = {
	profile_type_enum?: 'personal' | 'business';
	filters?: {
		order_by?: 'created_at' | 'full_name' | 'likes' | 'shares' | 'comments' | 'reviews' | 'rating';
		order?: 'asc' | 'desc';
		search?: string;
		entity_type?: string[];
		country?: number[];
		city?: number[];
		max_distance?: number;
		connection_status?: 'connected' | 'not connected';
		is_online?: 'yes' | 'no';
		featured_status?: 'yes' | 'no';
		joining_date?: {
			min?: string;
			max?: string;
		};
		activities?: string[];
		no_of_authors_channels?: {
			min?: number;
			max?: number;
		};
		no_of_authors_videos?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_subscribers?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_views?: {
			min?: number;
			max?: number;
		};
		no_of_authors_channel_comments?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_likes?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_shares?: {
			min?: number;
			max?: number;
		};
		no_of_authors_profile_endorsements?: {
			min?: number;
			max?: number;
		};
		total_rating?: {
			min?: number;
			max?: number;
		};
		no_of_reviews?: {
			min?: number;
			max?: number;
		};
	};
};

export interface PodcastFilterModalProps {
	filters?: {
		categories?: number[];
		publishing_date?: {
			min: string;
			max: string;
		};
		ratings?: {
			min?: number;
			max?: number;
		};
		v_author_profile_type?: 'personal' | 'business';
		v_author_country?: number[];
		v_author_city?: number[];
		v_author_connection_status?: 'connected' | 'not_connected';
		no_of_sales_form?: number;
		no_of_sales_to?: number;
		no_of_subscribe_form?: number;
		no_of_subscribe_to?: number;
	};
}

export interface EpisodeFilterModalProps {
	filters?: {
		categories?: number[];
		publishing_date?: string;
		ratings?: {
			min?: number;
			max?: number;
		};
		v_author_profile_type?: 'personal' | 'business';
		v_author_country?: number[];
		v_author_city?: number[];
		v_author_connection_status?: 'connected' | 'not_connected';
		no_of_sales_form?: number;
		no_of_sales_to?: number;
		no_of_subscribe_form?: number;
		no_of_subscribe_to?: number;
		no_of_plays_form?: number;
		no_of_plays_to?: number;
		reviews?: {
			min?: number;
			max?: number;
		};
	};
}

export type SavedStoreAllRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		no_of_stores?: {
			min?: number;
			max?: number;
		};
		no_of_start_price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		no_of_sales?: {
			min?: number;
			max?: number;
		};
		no_of_order?: {
			min?: number;
			max?: number;
		};
		no_of_customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type SavedPhysicalProductsAllRequestType = {
	filters?: {
		categories?: number[];
		sub_category?: number[];
		privacy_enum?: ('public' | 'connections' | 'selected_users' | 'only_me')[];
		levels?: number[];
		search?: string;
		publishing_date?: { min?: string; max?: string };
		comments?: { min?: number; max?: number }[];
		likes?: { min?: number; max?: number }[];
		shares?: { min?: number; max?: number }[];
		ratings?: {
			min?: number;
			max?: number;
		};
		featured_status?: boolean[];
		vv_exclusive_status?: boolean[];
		v_author_profile_type?: ('personal' | 'business')[];
		v_author_city?: number[];
		v_author_country?: number[];
		v_author_connection_status?: ('connected' | 'not_connected')[];
		v_author_is_online?: ('yes' | 'no')[];
		super_creator_status?: ('yes' | 'no')[];
		super_seller_status?: ('yes' | 'no')[];
		super_affiliate_status?: ('yes' | 'no')[];
		no_of_stores?: {
			min?: number;
			max?: number;
		};
		price?: {
			min?: number;
			max?: number;
		};
		no_of_delivery_time?: {
			min?: number;
			max?: number;
		};
		sales?: {
			min?: number;
			max?: number;
		};
		orders?: {
			min?: number;
			max?: number;
		};
		customer?: {
			min?: number;
			max?: number;
		};
		no_of_reaction?: {
			min?: number;
			max?: number;
		};
		no_of_review?: {
			min?: number;
			max?: number;
		};
		no_of_shares?: {
			min?: number;
			max?: number;
		};
		no_of_rating?: {
			min?: number;
			max?: number;
		};
		no_of_endorsement?: {
			min?: number;
			max?: number;
		};
	};
	limit?: number;
	order?: 'asc' | 'desc';
	order_by?: 'title' | 'date' | 'comments' | 'likes' | 'shares' | 'rating' | 'reviews';
	page?: number;
};

export type VNationMenu3DotsTypes = 'home' | 'my-profile' | 'my-assets' | 'my-social-page' | 'my-social-group';

export type StripePaymentResponse = {
	payment_type_enum: 'card' | 'bank' | 'online_wallet'; // Assuming these are potential payment types; update as necessary
	payment_logo: string;
	card_number: string;
	card_expiry_date: string;
	bank_name: string | null;
	bank_account_number: string | null;
	online_wallet_account_number: string | null;
	payment_method_id: string;
	wallet_id: number;
	updated_at: string; // ISO date string
	created_at: string; // ISO date string
	id: number;
};
export type WALLETS_ENUM_TYPE = 'payment' | 'payout';
export type Buyer = {
	id: number;
	image: string | null;
	profile_type: string;
	name: string;
	custom_tagline: string;
	is_online: boolean;
	created_at: string;
	updated_at: string;
};

export type OrderState = {
	id: number;
	code: string;
	date: string;
	buyer: Buyer;
	total_no_of_items: number;
	no_of_paid_items: number;
	no_of_free_items: number;
	no_of_digital_items: number;
	no_of_physical_items: number;
	gross_value: string;
	discount_value: string;
	shipping_fees: string;
	sub_total_value: string;
	net_paid_value: string;
	vv_fees_value: string;
	net_received_value: string;
	vat_value: string;
	vat_percentage: string;
	other_tax_value: string;
	other_tax_percentage: string;
	no_of_in_progress_items: number;
	no_of_on_the_way_items: number;
	no_of_delivered_items: number;
	no_of_cancelled_items: number;
	no_of_returned_items: number;
	status: string;
};

export type BillingInterval = 'monthly' | 'annually';

export interface PlanFeature {
	name: string;
	value: string | number | boolean;
}

export interface SubscriptionPlansResponse {
	plans: Plan[]; // Assuming 'data' is an array of Plan objects
	vv_vat: number;
	vv_tax: number;
}

export type PricingToggleProps = {
	billingInterval: 'monthly' | 'annually';
	setBillingInterval: (interval: 'monthly' | 'annually') => void;
};

export interface Plan {
	is_active: any;
	id: string;
	name: string;
	price_in_annually: number;
	price_in_monthly: number;
	discount_in_monthly: number;
	discount_in_annually: number;
	is_subscribed?: boolean;
	description: string;
	features: {
		id: number;
		name: string;
		description: string | null;
		module_enum: string;
		type: string;
		quantity: number;
	}[];
	isPopular?: boolean;
}

export interface PricingCardProps {
	plan: Plan;
	billingInterval: BillingInterval;
	all?: boolean;
	vv_vat: number;
	vv_tax: number;
	refetch?: () => void;
	// price?: number;
	// discount: number;
}
export interface SubscribtionProps {
	plan: Plan;
	billingInterval: BillingInterval;
	all?: boolean;
	price: number;
	discount: number;
	vv_vat: number;
	vv_tax: number;
	vWebinarPriceChecked: boolean;
	vWebinarPrice: number;
	refetch?: () => void;
}

export interface Feature {
	name: string;
	quantity: number;
	module_enum: string;
}

export interface PricingPlansProps {
	plans: Plan[];
	vv_tax: number;
	vv_vat: number;
	refetch?: () => void;
}

export interface VWebinarsSwitchProps {
	checked?: boolean;
	onCheckedChange?: (checked: boolean) => void;
	disabled?: boolean;
}

export type AdSpotType = {
	id: number;
	name: string;
	location: string;
	content_type: string;
	number_of_slots: number;
	max_video_size: number;
	max_image_size: number;
	allowed_formats: string;
	aspect_ratio: string;
	price_per_slot: string;
	attachments: any[];
};

export type Slot = {
	ad_spot_id: number;
	available_from: string;
	created_at: string;
	id: number;
	name: string;
	status: string;
	updated_at: string;
	value: string;
};
export type PrivacyOptions = {
	id: number;
	name: string;
	description: string;
	icon: JSX.Element;
	value: string;
};
export type PrivacyOptionsProps = {
	onChange?: (item: any) => void;
	onProfilesUpdated?: (item: any) => void;
	onProfilesRemoved?: (item: any) => void;
	profiles?: any;
	isChannelSubscriber?: boolean;
	defaultSelected?: string;
	error?: any;
	options?: PrivacyOptions[];
	customClass?: string;
	required?: boolean;
};

export type Plans = {
	type: string;
	id: number;
	title: string;
	description: string;
	tags: string[];
	startTime: string;
	endTime: string;
	timezone: string;
	files: { type: string; name: string }[];
	participants: { name: string; avatar: JSX.Element }[];
	address: string;
	googleMapLink: string;
	planableId?: number;
	webinarType?: string;
};

export type OrderItem = {
	id: number;
	seller_name: string;
	buyer_name: string;
	title: string;
	ticket_name: string;
	sold_by: string;
	cart_id: number | null;
	itemable_id: number;
	itemable_type: string;
	quantity: number;
	price: string; // Assuming price and discounted_price are strings to match the JSON structure
	discounted_price: string;
	total: number;
	item_type_enum: 'digital' | 'physical' | string; // specify known values and allow others
	module_enum: string;
	item_type: 'product' | 'service' | string;
	price_type: 'Discounted' | 'Standard' | string;
	discount: number;
	offer_type: 'no_offer' | 'special_offer' | string;
	shipping_type: 'standard' | 'express' | string;
	fee_type: 'fixed' | 'variable' | string;
	shipping_fee: number;
	delivery_date: string | null; // ISO format string if applicable, or null
	preferred_shipping: Record<string, unknown>; // If more detail is available, adjust type
	shipping_address: Record<string, unknown>; // If more detail is available, adjust type
	order_status: 'declined' | 'approved' | 'pending' | string;
};

export enum AssetType {
	PERSONAL = 'personal_profiles',
	ENTITY = 'entity_profiles',
	BLOG_POST = 'blog_post',
	SOCIAL_POST = 'social_posts',
	HIGHLIGHT = 'highlights',
	REEL = 'reels',
	STORY = 'stories',
	SHORT = 'shorts',
	SOCIAL_PAGE = 'social_pages',
	SOCIAL_GROUP = 'social_groups',
	CHANNEL = 'channels',
	VIDEO = 'videos',
	JOB = 'jobs',
	SERVICE = 'services',
	PROJECT = 'projects',
	COURSE = 'courses',
	STORE = 'stores',
	PHYSICAL_PRODUCT = 'physical_products',
	DIGITAL_PRODUCT = 'digital_products',
	EVENT = 'events',
	WEBINAR = 'webinars',
	CONSULTATION = 'consultations',
	SPONSORSHIP = 'sponsorships',
	PODCAST = 'podcasts',
	EPISODE = 'episodes',
	HARDCOVER_BOOK = 'hardcover_books',
	E_BOOK = 'e_books',
}

export type TableProps = {
	records: any;
	rowClick?: (id: number | object) => void;
	onSelected?: (links: any[]) => void;
	renderSlot?: (key: string, value: any, row: any, index: any) => ReactNode;
	sortBy?: (sortBy: SortingOrderByType, sortKey: SortingOrderType) => void;
	defaultSortKey?: string;
	defaultSortBy?: string;
	tdBorders?: boolean;
	isSticky?: boolean;
	theadClasses?: string;
	loadMore?: () => void;
	hasNextPage?: boolean;
	isLoading?: boolean;
	selected?: string[];
	setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
	creditsData?: { id: number; no_of_credit: number }[];
	setCreditsData?: React.Dispatch<React.SetStateAction<{ id: number; no_of_credit: number }[]>>;
	activeTab?: string;
	refetch?: () => void;
};

type AttachmentAdminReport = {
	id: number;
	file_name: string;
	file_path: string;
	file_size: number;
	url: string;
	file_extension_enum: string;
	created_at: string;
	updated_at: string;
	storage_file_id: number | null;
};

type Reportable = {
	user: any;
	legal_name: string;
	public_name: string;
	custom_id: string;
	profile_type: string;
	id: number;
	user_id: number;
	title: string;
	description: string;
	privacy_enum: string;
	viewable_by: number[];
	comments_enabled: boolean;
	is_exclusive: boolean;
	is_featured: boolean;
	publish_enum: string;
	is_scheduled: boolean;
	scheduled_at: string;
	budget_enum: string;
	budget_value: string;
	work_type_enum: string;
	delivery_time: number;
	delivery_time_unit: string;
	deadline: string;
	nationalities: string | null;
	countries: string | null;
	cities: string | null;
	gender_enum: string;
	level_enum: string;
	min_experience_years: number;
	max_experience_years: number;
	min_hourly_rate: string;
	max_hourly_rate: string;
	min_endorsements: number;
	max_endorsements: number;
	min_earnings: string;
	max_earnings: string;
	min_success_rate: number;
	max_success_rate: number;
	min_weekly_hours: number;
	max_weekly_hours: number;
	sources_copy_right: string;
	status_enum: string;
	skills: string | null;
	is_locked: number;
	created_at: string;
	updated_at: string;
};

type UserCountsAdminReport = {
	sales_sum: number;
	book_count: number;
	earned_sum: number;
	jobs_count: number;
	rating_avg: number;
	rating_sum: number;
	likes_count: number;
	posts_count: number;
	views_count: number;
	events_count: number;
	invested_sum: number;
	no_of_assets: number;
	orders_count: number;
	points_count: number;
	rating_count: number;
	shares_count: number;
	stores_count: number;
	videos_count: number;
	courses_count: number;
	hirings_count: number;
	members_count: number;
	reviews_count: number;
	channels_count: number;
	comments_count: number;
	dislikes_count: number;
	episodes_count: number;
	notifies_count: number;
	podcasts_count: number;
	projects_count: number;
	services_count: number;
	webinars_count: number;
	customers_count: number;
	followers_count: number;
	applicants_count: number;
	connections_count: number;
	subscribers_count: number;
	endorsements_count: number;
	social_pages_count: number;
	sponsorships_count: number;
	consultations_count: number;
	profile_likes_count: number;
	social_groups_count: number;
	profile_shares_count: number;
	digital_products_count: number;
	physical_products_count: number;
};

type UserAdminReport = {
	id: number;
	full_name: string;
	image: string | null;
	current_title: string | null;
	counts_text: string;
	counts: UserCountsAdminReport;
	created_at: string;
	updated_at: string;
	is_locked: number;
};

export type AdminReport = {
	id: number;
	user_id: number;
	description: string;
	module_enum: string;
	status: string;
	reportable_id: number;
	reportable_type: string;
	asset_name: string;
	created_at: string;
	updated_at: string;
	attachments: AttachmentAdminReport[];
	reportable: Reportable;
	user: UserAdminReport;
};

// CONTRACTS

// SERVICE CONTRACT TYPES
type ContractUserCountsType = {
	sales_sum: number;
	earned_sum: number;
	rating_avg: number;
	rating_sum: number;
	hiring_rate: number;
	hourly_rate: number;
	likes_count: number;
	posts_count: number;
	invested_sum: number;
	orders_count: number;
	points_count: number;
	rating_count: number;
	shares_count: number;
	success_rate: number;
	reviews_count: number;
	comments_count: number;
	projects_count: number;
	services_count: number;
	customers_count: number;
	client_likes_count: number;
	client_orders_count: number;
	client_points_count: number;
	client_shares_count: number;
	freelancer_likes_count: number;
	freelancer_orders_count: number;
	freelancer_points_count: number;
	freelancer_shares_count: number;
	client_endorsements_count: number;
	freelancer_endorsements_count: number;
};

type ContractUserType = {
	id: number;
	custom_id: string;
	full_name: string;
	email: string;
	is_connected: boolean;
	connection_status: string;
	counts: ContractUserCountsType;
	is_featured: boolean;
	current_title: string | null;
	current_company: string | null;
	email_verified_at: string;
	phone_verified_at: string;
	document_verified_at: string;
	id_verified_at: string;
	last_seen_at: string;
	businessType: string | null;
	joining_date: string;
	gender: string | null;
	banner_tags: string[];
	country: string;
	residency: string;
	image: string | null;
	profile_type_enum: string;
	profile_id: number;
	v_guide_is_liked: boolean;
	v_blog_is_liked: boolean;
	v_jobs_is_liked: boolean;
	is_blocked: boolean;
	is_follower: boolean;
	is_member: boolean;
	is_subscriber: boolean;
	is_candidate: boolean;
	is_applicant: boolean;
	is_client: boolean;
};

type TierFeatureType = {
	id: number;
	tier_id: number;
	title: string;
	description: string;
	price: number;
};

type TierAddOnType = {
	id: number;
	tier_id: number;
	name: string;
	description: string;
	delivery_time: number;
	delivery_time_unit: string;
	price: string;
};

type ContractTierType = {
	id: number;
	name: string;
	delivery_time: string;
	delivery_time_unit: string;
	description: string;
	price: string;
	discount: string | null;
	features: TierFeatureType[];
	addOns: TierAddOnType[];
};

export type ContractTrackerType = {
	id: number;
	title: string;
	created_at: string;
	updated_at: string;
};

export type ServiceContractType = {
	id: string;
	customer: ContractUserType;
	seller: ContractUserType;
	tier: ContractTierType;
	trackers: ContractTrackerType[];
	deliveries: ContractDeliveryType[];
	status_enum: StatusEnumType;
	addon_ids: number[];
	created_at: string;
	updated_at: string;
	delivery_date: string;
	price: string;
	service_title: string;
};

export type ContractDeliveryType = {
	id: number;
	status_enum: StatusEnumType;
	title: string;
	description: string;
	attachments: ContractDeliveryAttachment[];
	created_at: string;
	updated_at: string;
};

type ContractDeliveryAttachment = {
	id: number;
	file_path: string;
	file_name: string;
	file_size: number;
	file_type: string | null;
	url: string;
	file_extension_enum:
		| 'pdf'
		| 'jpg'
		| 'jpeg'
		| 'png'
		| 'doc'
		| 'docx'
		| 'ppt'
		| 'pptx'
		| 'xls'
		| 'xlsx'
		| 'mp4'
		| 'avi'
		| 'mov'
		| 'mp3'
		| 'wav'
		| 'txt'
		| 'other-files';
	created_at: string;
	updated_at: string;
	storage_file_id: number | null;
};

export type ServiceContractSummaryType = {
	orderId: string;
	tier: string;
	soldBy: string;
	soldTo: string;
	price: string;
	deliveryDate: string;
	contractStatus: StatusEnumType;
	created_at: string;
	service_title: string;
};

export type StatusEnumType = 'pending' | 'approved' | 'declined' | 'cancelled';

// SPONSORSHIP CONTRACT TYPES
export type SponsorshipContractType = {
	id: string;
	customer: ContractUserType;
	seller: ContractUserType;
	tier: SponsorshipContractTierType;
	trackers: ContractTrackerType[];
	deliveries: ContractDeliveryType[];
	status_enum: StatusEnumType | 'finished';
	addon_ids: number[];
	created_at: string;
	updated_at: string;
	sponsorship_title: string;
	price: string;
	delivery_date: string;
};

type SponsorshipContractTierType = {
	id: number;
	tier_name: string;
	description: string;
	price: string;
	discount_price: string;
	delivery_time: string;
	delivery_time_unit: string;
	details: SponsorshipTierDetailType[];
	add_ons: SponsorshipTierAddOnType[];
};

type SponsorshipTierDetailType = {
	id: number;
	tier_detail_name: string;
	description: string;
};

type SponsorshipTierAddOnType = {
	id: string;
	add_on_name: string;
	description: string;
	price: string;
	delivery_time: string;
	delivery_time_unit: string;
};

export type SponsorshipContractSummaryType = {
	orderId: string;
	tier: string;
	soldBy: string;
	soldTo: string;
	price: string;
	deliveryDate: string;
	contractStatus: StatusEnumType | 'finished';
	created_at: string;
	sponsorship_title: string;
};

// PROJECT CONTRACT TYPES
export type ProjectContractType = {
	id: string;
	customer: ContractUserType;
	seller: ContractUserType;
	tier: ContractTierType;
	trackers: ContractTrackerType[];
	deliveries: ContractDeliveryType[];
	status_enum: StatusEnumType;
	addon_ids: number[];
	created_at: string;
	updated_at: string;
	project_title: string;
	delivery_date: string;
	price: string;
	application: application;
};

export type ProjectContractSummaryType = {
	orderId: string;
	vLancer: string;
	vClient: string;
	budgetType: string;
	budgetValue: string;
	deliveryDate: string;
	contractStatus: StatusEnumType;
	created_at: string;
	project_title: string;
};

export type ProjectContractMilestoneType = {
	id: number;
	contract_id: number;
	title: string;
	description: string;
	amount: string;
	due_date: string;
	deliveryDate: string;
	status_enum: MilestoneStatusEnumType;
	attachments: ContractDeliveryAttachment[];
	created_at: string;
};

// CONTRACTS ANALYSIS TYPES
export type ContractsAnalysisType = {
	lancer_influencer: ContractsAnalysisLancerInfluencerType;
	client_customer: ContractsAnalysisClientCustomerType;
};

type ContractsAnalysisLancerInfluencerType = {
	total: number;
	canceled: number;
	delivered: number;
	rate: number;
};

type ContractsAnalysisClientCustomerType = {
	total: number;
	canceled: number;
	delivered: number;
};

// V-TUBE TYPES
export type ShortVideo = {
	id: number;
	title: string;
	description: string;
	user_id: number;
	image: string;
	video_type_enum: string;
	privacy_enum: string;
	publish_status: string;
	tagged_user_ids: number[];
	sources_copy_right: string;
	viewable_by: any[];
	is_scheduled: boolean;
	scheduled_at: string | null;
	comments_enabled: boolean;
	is_exclusive: boolean;
	is_featured: boolean;
	is_private: boolean;
	categoryTree: CategoryTreeResource;
	likes_count: number;
	dislikes_count: number;
	comments_count: number;
	shares_count: number;
	reviews_count: number;
	rating: number;
	views_count: number;
	hash_tags: string[][];
	channel: ChannelsByHashtagVTube;
	user: UserType;
	media: AttachmentResource;
	thumbnail: AttachmentResource;
	files: AttachmentResource[];
	playlist_ids: number[];
	duration_in_seconds: number;
	duration: string;
	attachments: Attachment[];
	is_liked: boolean;
	is_disliked: boolean;
	like_type: string;
	is_saved: boolean;
	is_reported: boolean;
	created_at: string;
	updated_at: string;
	upload_date: string;
	is_locked: number;
};

type application = {
	budget_enum: string;
	budget_value: string;
};

export type MilestoneStatusEnumType = 'funded' | 'pending';

// ------------------------------------------ New chat-(UI) ---------------------------------------------
export type ChatViewProps = {
	SelectedChatId: string;
	chat: ChatWithUser;
	onclick?: () => void;
};

// ------------------------------------------ New chat-(Schema) ---------------------------------------------

// Base Types
type Timestamp = firestore.Timestamp;

// Utility Types
interface Timestamped {
	createdAt: Timestamp;
	updatedAt: Timestamp;
}

// Chat Related Types

interface ChatNotepad {
	userId: string;
	content: string;
	updatedAt: Timestamp;
}
interface ChatTagInfo {
	userId: string;
	tag: ChatTag;
	createdAt: Timestamp;
}
interface ChatTags {
	[userId: string]: ChatTagInfo;
}
export type ChatTag =
	| 'personal'
	| 'business'
	| 'social'
	| 'interviews'
	| 'pre_orders'
	| 'open_orders'
	| 'post_orders'
	| '';

export interface Chat extends Timestamped {
	id: string;
	type: ChatType;
	lastMessage?: LastMessage;
	metadata: {
		name: string;
		photo?: string;
		description?: string;
		memberCount: number;
		tagline?: string;
	};
	isGroup: boolean;
	participantIds: string[]; // For direct chats, always 2
	ownerId?: string; // For group chats
	isActive: boolean;
	isArchived: boolean;
	deletedFor: string[]; // Add this field to track who deleted the chat
	isDeleted?: boolean; // Add this for group chats
	tags?: ChatTags;
	preferences?: {
		[userId: string]: ChatPreferences;
	};
	unreadCount?: {
		[userId: string]: number;
	};
	notepads?: {
		[userId: string]: ChatNotepad;
	};
	muted?: {
		[userId: string]: boolean;
	};
	isBlocked?: boolean;
	blockedBy?: string;
	webinarId?: string;
	webinarChatType?: 'public' | 'private';
}

export type ChatFilter = 'all' | 'favourites' | 'unread' | 'hidden' | 'visible';
export interface ChatPreferences {
	userId: string;
	isFavourite?: boolean;
	isHidden?: boolean;
	lastReadMessageId?: string;
	updatedAt: Timestamp;
}
export interface ChatWithUser extends Chat {
	otherUser: otherUser;
}
export type ChatType = 'all' | 'direct' | 'group' | 'webinar' | 'private_webinar';

// Group Chat Related Types
export interface CreateGroupChatParams {
	name: string;
	description?: string;
	photo?: string;
	tagline?: string;
	participantIds: string[];
	createdBy: string; // this is an ID.
}
export interface GroupChatWithUsers extends Chat {
	participants: {
		id: string;
		displayName: string;
		photoURL: string;
		isOnline: boolean;
	}[];
	owner?: {
		id: string;
		displayName: string;
		photoURL: string;
	};
}
export interface GroupParticipant {
	id: string;
	displayName: string;
	photoURL: string;
	role: 'owner' | 'admin' | 'member';
	joinedAt: Timestamp;
	isOnline: boolean;
}
export type DeleteChatOptions = {
	chatId: string;
	userId: string;
	isGroup?: boolean;
};

// Message Related Types
export interface Message {
	id: string;
	chatId: string;
	senderId: string;
	sender: ChatUser;
	type: MessageTypeChat;
	content: string;
	status: MessageStatus;
	createdAt: Timestamp;
	updatedAt: Timestamp;
	seenBy: string[]; // Array of userIds who have seen the message
	deliveredTo: string[];
	contentLower: string;
	searchTerms: string[];
	attachments: MessageAttachment[];
	reactions?: {
		[key: string]: MessageReaction[]; // emoji as key, array of users who reacted
	};
	replyTo?: Message;
	deletedFor: string[]; // Add this field to track who deleted the chat
	isDeletedForAll?: boolean;
	isEdited: boolean;
	participantIds: string[];
	webinarPrivateChat?: boolean;
	isHost?: boolean;
}

export type MessageAttachment = {
	actionable?: boolean;
	active?: boolean;
	created_at?: Timestamp;
	file_extension?: string;
	file_name?: string;
	file_path?: string;
	file_size?: number;
	file_type?: string;
	folder_id?: number;
	folder_name?: string;
	id?: number;
	is_put_in_trash?: boolean;
	last_used_at?: Timestamp;
	updated_at?: Timestamp;
	url: string;
};
export type MessageTypeChat =
	| 'text'
	| 'image'
	| 'video'
	| 'audio'
	| 'file'
	| 'system'
	| 'document'
	| 'link'
	| 'raise_hand'
	| 'reaction';
export type MessageStatus = 'sent' | 'delivered' | 'seen';
export interface LastMessage {
	id: string;
	content: string;
	type: MessageTypeChat;
	senderId: string;
	sender: ChatUser;
	createdAt: Timestamp;
	seenCount: number;
	preview?: string;
	seenBy: string[];
	deliveredTo: string[];
}
export type MessageReaction = {
	emoji: string;
	userId: string;
	timestamp: Timestamp;
};

// User Related Types
export interface ChatUser extends Timestamped {
	id: string;
	displayName: string;
	email: string;
	photoURL: string;
	activeChats: number; // Denormalized counter
	unreadMessages: number; // Denormalized counter
	blockedUsers: string[]; // Array of blocked user IDs
	blockedBy: string[];
}
export type UserRole = 'owner' | 'admin' | 'member';
export type otherUser = {
	id: string;
	displayName: string;
	photoURL: string;
	isOnline: boolean; // You'll need to implement online status separately
};

// ------------------------------------------ New chat-(Functions params) ---------------------------------------------
export interface EditMessageParams {
	messageId: string;
	chatId: string;
	content: string;
	userId: string;
}

export interface DeleteMessageForAllParams {
	messageId: string;
	chatId: string;
	userId: string;
}

export interface ToggleReactionParams {
	messageId: string;
	emoji: string;
	userId: string;
	chatId: string;
}

export interface DeleteAllMessagesOptions {
	chatId: string;
	userId: string;
}

export interface DeleteMessageParams {
	messageId: string;
	chatId: string;
	userId: string;
}

export interface BlockUserResult {
	success: boolean;
	chatId?: string; // If users have an existing chat
}

export interface SearchUsersResult {
	users: UserType[];
}

export interface MediaOptions {
	chatId: string;
	types?: MessageTypeChat[];
	startAfter?: DocumentSnapshot;
	limit?: number;
}

export interface MediaResult {
	items: Message[];
	lastVisible: DocumentSnapshot | null;
	hasMore: boolean;
}

export interface AddUsersToGroupParams {
	chatId: string;
	users: UserType[];
	addedBy: string;
}

// Related types
export interface GetChatsOptions {
	userId: string;
	type?: ChatType;
	filter?: ChatFilter;
	searchTerm?: string;
	limit?: number;
	lastChat?: DocumentSnapshot;
	tag?: ChatTag;
}

export interface ChatsResult {
	chats: ChatWithUser[];
	lastVisible: DocumentSnapshot | null;
	hasMore: boolean;
}

type mediaToggle = {
	videoToggled?: boolean;
	audioToggled?: boolean;
};

export type callStatus = 'initiated' | 'ongoing' | 'ended';

export interface Call {
	id: string;
	offer?: any;
	answer?: any;
	status: callStatus;
	chatId: string;
	callerId: string;
	createdAt: Timestamp;
	participantIds: string[];
	participantMedia: {
		[userId: string]: mediaToggle;
	};
	userJoined: {
		[userId: string]: boolean;
	};
}

export type AgoraCallStatus = 'initiated' | 'joined' | 'left' | 'declined';
export type AgoraCallTypes = 'one to one' | 'group';

export interface AgoraCall {
	id: string;
	chatId: string;
	hostId: string;
	type: 'one to one' | 'group';
	createdAt: Timestamp;
	participantIds: string[];
	participantStatus: {
		[participantId: string]: AgoraCallStatus;
	};
}

export type CheckBoxArrItem = {
	id: number | string;
	user_id: number | string;
	checked: boolean;
};

export type FAQType = {
	id?: number;
	question: string;
	answer: string;
};

export type EndorsementItem = {
	id?: string;
	date?: string;
	endorser?: UserType;
	user?: UserType;
	type: string;
	active: boolean;
	isUser: boolean;
	status_enum?: string;
	created_at?: string;
	updated_at?: string;
	connectionDetails: {
		name: string;
		title: string;
		timestamp: string;
	};
	description: string;
};

// V-COLLEGE TYPES
export type SelectedQuizAnswerType = {
	question_id: number;
	answer_id: number;
};

export type CourseType = {
	user: User;
	completed_date: string;
	title: string;
	co_instructor_inside: User[];
	exams: CourseExamType[];
	curriculum: CourseCurriculumType;
	is_certificate: boolean;
	is_completed: boolean;
	comments_enabled: boolean;
	announcement: CourseAnnouncementType[];
	description: string;
	co_authors_personal_outside: any;
	co_authors_entity_outside: any;
	admins: any;
	moderators: any;
	categoryTree: any;
	files: any;
	gallery: any;
	sources_copy_right: string;
	hashtags: any;
	comments_count: number;
};

export type CourseExamType = {
	id: number;
	title: string;
	no_of_questions: number;
	total_students: number;
	total_passed_students: number;
	total_failed_students: number;
	avg_score: number;
	passing_score: number;
	pressing_score: number;
	previous_score: number;
	is_taken: boolean;
	is_show: boolean;
};

type CourseCurriculumType = {
	no_of_lessons: number;
	no_of_quizzes: number;
	no_of_units: number;
	total_of_duration: string;
	units: any;
};

type CourseAnnouncementType = {
	title: string;
	description: string;
};

export type CourseLessonType = {
	id: number;
	media: { url: string }[];
	title?: string;
	description?: string;
	files?: any[];
	downtime_in_seconds: number;
	is_completed: boolean;
};

export type MediaPostType = 'reel' | 'highlight' | 'story';

// ADs TYPES
export type ReceivedAdType = {
	id: string;
	user_id: string;
	ad_spot_id: string;
	ad_slot_id: string;
	order_id: string;
	from: string;
	to: string;
	cost: string;
	status: string;
	acceptance_status: string;
	clicks: string;
	views: string;
	created_at: string;
	updated_at: string;
	url?: string;
	ad_content_type: 'video' | 'image';
	artwork: AttachmentResource;
};

export type BreakPointCounts = {
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	'2xl'?: number;
};
