import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VBlogAuthor = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.0013 9.33301H5.33464C4.62739 9.33301 3.94911 9.61396 3.44902 10.1141C2.94892 10.6142 2.66797 11.2924 2.66797 11.9997V14.9997"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0346 9.55583C12.1053 9.48519 12.1891 9.42915 12.2814 9.39092C12.3737 9.35269 12.4727 9.33301 12.5726 9.33301C12.6725 9.33301 12.7714 9.35269 12.8637 9.39092C12.956 9.42915 13.0399 9.48519 13.1105 9.55583C13.1812 9.62648 13.2372 9.71034 13.2754 9.80265C13.3137 9.89495 13.3333 9.99388 13.3333 10.0938C13.3333 10.1937 13.3137 10.2926 13.2754 10.3849C13.2372 10.4772 13.1812 10.5611 13.1105 10.6317L9.47936 14.2629L8 14.6663L8.40346 13.187L12.0346 9.55583Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.9987 7.33236C9.47146 7.33236 10.6654 6.13845 10.6654 4.66569C10.6654 3.19293 9.47146 1.99902 7.9987 1.99902C6.52594 1.99902 5.33203 3.19293 5.33203 4.66569C5.33203 6.13845 6.52594 7.33236 7.9987 7.33236Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default VBlogAuthor;
