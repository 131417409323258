import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Assets = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M8.231.563C8.17.625 8.17.666 8.17 4.375c0 3.71 0 3.75.062 3.813.063.062.103.062 3.688.062 3.584 0 3.625 0 3.687-.062.063-.063.063-.103.063-3.813s0-3.75-.063-3.812C15.544.5 15.503.5 11.92.5 8.334.5 8.294.5 8.23.563Zm6.938 3.812V7.75h-6.5V1h6.5v3.375Z" />
			<path d="m13.277 1.763-1 .256-.7.353-.7.353-.806-.115c-.444-.063-.825-.11-.847-.104-.053.02-.122.494-.075.51.084.025 1.64.234 1.747.234.087 0 .306-.097.865-.375l.747-.375.963-.237c.531-.132.975-.25.99-.263.013-.015 0-.131-.03-.262-.041-.175-.07-.238-.104-.235-.028 0-.5.119-1.05.26ZM13.669 3.625V4h-.188c-.312 0-.312.003-.312.75s0 .75.312.75h.188v.75h.5V5.5h.187c.313 0 .313-.003.313-.75s0-.75-.313-.75h-.187v-.75h-.5v.375Zm.5 1.125V5h-.5v-.5h.5v.25ZM11.669 4.125V4.5h-.188c-.322 0-.312-.031-.312 1s-.01 1 .312 1h.188v.75h.5V6.5h.187c.322 0 .313.031.313-1s.009-1-.313-1h-.187v-.75h-.5v.375Zm.5 1.375V6h-.5V5h.5v.5ZM9.669 4.375v.375H9.48c-.312 0-.312.003-.312.75s0 .75.312.75h.188v1h.5v-1h.187c.313 0 .313-.003.313-.75s0-.75-.313-.75h-.187V4h-.5v.375Zm.5 1.125v.25h-.5v-.5h.5v.25ZM5.903 2l-.184.188.278.28.281.282H2.419v.5H4.73c2.272 0 2.313 0 2.375-.062.044-.044.063-.103.063-.194 0-.128-.013-.144-.522-.656a12.752 12.752 0 0 0-.54-.525c-.01 0-.1.084-.204.187ZM2.481 4.063c-.044.043-.062.103-.062.193 0 .129.012.144.53.663l.532.534.194-.193.197-.197-.281-.282-.282-.28h3.86V4H4.856c-2.272 0-2.312 0-2.375.062ZM1.981 6.313c-.062.062-.062.103-.062 2.187 0 2.085 0 2.125.062 2.188.063.062.103.062 2.688.062 2.584 0 2.625 0 2.687-.062.063-.063.063-.104.063-2.188s0-2.125-.063-2.187c-.062-.063-.103-.063-2.687-.063-2.585 0-2.625 0-2.688.063Zm3.95.475c.144.459.413.759.806.9l.166.056V9.26l-.14.04c-.376.11-.704.472-.832.91-.01.03-.287.04-1.262.04-.975 0-1.253-.01-1.263-.04-.128-.438-.456-.8-.831-.91l-.14-.04V7.743l.165-.055c.394-.138.678-.46.806-.9.01-.028.288-.038 1.263-.038.975 0 1.253.01 1.262.038Z" />
			<path d="M4.419 7.135c0 .112-.013.143-.075.171a.896.896 0 0 0-.366.366.948.948 0 0 0 0 .656c.11.244.325.385.669.438.065.01.147.047.181.081.078.078.075.231-.003.31-.116.115-.375.04-.375-.11C4.45 9.01 4.4 9 4.184 9H3.92v.1c.003.213.203.494.425.594.062.028.075.06.075.172V10h.5l.003-.134c0-.122.01-.138.147-.225.228-.147.33-.319.343-.581.016-.282-.046-.45-.228-.62-.131-.118-.243-.168-.493-.205C4.54 8.21 4.45 8.122 4.45 8c0-.119.1-.219.219-.219.1 0 .218.094.218.172 0 .038.05.047.266.047h.266v-.103c-.003-.21-.203-.49-.425-.59-.063-.029-.075-.06-.075-.172V7h-.5v.135ZM7.8 11.46c-.04.006-.64.25-1.331.537l-1.257.522-.496-.325c-.744-.481-.597-.444-1.647-.444-.878 0-.9 0-.9-.062a.216.216 0 0 0-.063-.125c-.06-.06-.103-.063-.687-.063-.585 0-.628.003-.688.063-.062.062-.062.103-.062 1.812 0 1.71 0 1.75.062 1.813.06.059.103.062.688.062.772 0 .75.01.75-.39v-.266h.684l.91.453c.89.444.915.453 1.112.453a.945.945 0 0 0 .303-.044c.06-.021.84-.534 1.744-1.134 1.725-1.153 1.853-1.25 1.947-1.531.065-.19.062-.522-.003-.716-.072-.212-.347-.484-.557-.553-.178-.056-.403-.084-.51-.063Zm.378.593c.188.097.269.397.163.606-.063.12-3.294 2.285-3.441 2.304-.075.009-.294-.088-1.006-.444l-.913-.456H2.17V12.25h.78c.592 0 .81.01.885.044.107.044 1.25.772 1.344.856.122.11.019.35-.153.35-.044 0-.275-.119-.531-.269a4.234 4.234 0 0 0-.463-.259l-.137.228-.128.219.412.247c.55.331.625.365.85.365.31 0 .572-.171.684-.443a.918.918 0 0 0 .007-.572l-.044-.103.3-.129c.166-.068.669-.28 1.116-.468.853-.357.887-.366 1.087-.263Zm-6.51 1.322v1.375h-.5V12h.5v1.375Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.167 0h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Assets;
