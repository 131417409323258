import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;
const Document = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} stroke={color} fill="none" {...props}>
			<path
				stroke="#fff"
				strokeLinecap="round"
				d="M12 6v2m0 0v2m0-2h-2m2 0h2M8 14h1m7 0h-4M9 18h6M3 14v-4c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h2c3.771 0 5.657 0 6.828 1.172.654.653.943 1.528 1.07 2.828M21 10v4c0 3.771 0 5.657-1.172 6.828C18.657 22 16.771 22 13 22h-2c-3.771 0-5.657 0-6.828-1.172-.654-.653-.943-1.528-1.07-2.828"
			/>
		</svg>
	);
};
export default Document;
