const LikeEmoji = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="8.00019" cy="8.17402" r="7.80488" fill="url(#paint0_linear_5738_233601)" />
			<g clipPath="url(#clip0_5738_233601)">
				<path
					d="M6.36626 8.10106L7.92723 4.58887C8.23773 4.58887 8.53551 4.71221 8.75507 4.93177C8.97462 5.15132 9.09797 5.4491 9.09797 5.7596V7.32057H11.3067C11.4199 7.31929 11.5319 7.34262 11.6352 7.38895C11.7384 7.43528 11.8303 7.5035 11.9045 7.58887C11.9788 7.67425 12.0336 7.77475 12.0651 7.8834C12.0967 7.99206 12.1042 8.10627 12.0872 8.21814L11.5487 11.7303C11.5205 11.9164 11.4259 12.0861 11.2825 12.208C11.1391 12.3299 10.9564 12.3959 10.7682 12.3937H6.36626M6.36626 8.10106V12.3937M6.36626 8.10106H5.19553C4.98853 8.10106 4.79001 8.18329 4.64364 8.32966C4.49727 8.47603 4.41504 8.67455 4.41504 8.88155V11.6133C4.41504 11.8203 4.49727 12.0188 4.64364 12.1651C4.79001 12.3115 4.98853 12.3937 5.19553 12.3937H6.36626"
					stroke="white"
					strokeWidth="0.390244"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_5738_233601"
					x1="6.30191"
					y1="15.2102"
					x2="18.9271"
					y2="8.63088"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<clipPath id="clip0_5738_233601">
					<rect width="9.36585" height="9.36585" fill="white" transform="translate(3.31738 3.49121)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LikeEmoji;
