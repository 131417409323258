import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const SocialGroup = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M7.417 2H2.75v4.667h4.667V2ZM14.75 2h-4.667v4.667h4.667V2ZM14.75 9.333h-4.667V14h4.667V9.333ZM7.417 9.333H2.75V14h4.667V9.333Z"
		/>
	</svg>
);
export default SocialGroup;
