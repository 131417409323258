import * as React from 'react';

type Props = {
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Image = (props: Props) => {
	const { width = 24, height = 24, ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.57822 2.02031C4.99697 2.13281 4.43447 2.59219 4.15791 3.17344L4.00791 3.49219L3.99384 9.88594C3.98447 15.6516 3.98916 16.3078 4.05947 16.5422C4.23291 17.1375 4.56103 17.5359 5.10009 17.7984L5.45634 17.9766H14.011H22.5704L22.8517 17.8406C23.2407 17.6484 23.6251 17.2687 23.8173 16.8844L23.9767 16.5703V10.0078V3.44531L23.8173 3.11719C23.5407 2.55469 23.0204 2.15156 22.4157 2.02031C22.111 1.95937 5.90634 1.95469 5.57822 2.02031ZM22.4298 3.14531C22.5704 3.21562 22.7204 3.34219 22.8048 3.46406L22.9454 3.66562L22.9595 8.35781C22.9688 12.9562 22.9688 13.05 22.8845 12.9609C22.8329 12.9094 21.8438 11.7609 20.686 10.4062C19.5282 9.05156 18.5345 7.90781 18.4876 7.86562C18.436 7.82344 18.272 7.72969 18.122 7.65937C17.8782 7.54688 17.8079 7.5375 17.4423 7.55625C16.7298 7.58906 16.8188 7.50937 14.5642 10.2C13.486 11.4844 12.5767 12.5812 12.5392 12.6328C12.4735 12.7172 12.4313 12.6844 11.686 11.9437C11.236 11.5031 10.8095 11.1187 10.6876 11.0578C10.5048 10.9594 10.411 10.9453 10.0079 10.9453C9.60947 10.9453 9.50634 10.9641 9.30478 11.0578C9.12666 11.1469 8.60166 11.6391 7.04541 13.1906L5.01572 15.2062V9.5625C5.01572 5.82187 5.03447 3.85312 5.06259 3.74062C5.15634 3.41719 5.47978 3.11719 5.82197 3.05156C5.93447 3.02812 9.66103 3.01406 14.1095 3.01875L22.2001 3.02344L22.4298 3.14531ZM17.836 8.72812C17.9204 8.8125 19.1157 10.1906 20.4798 11.7891L22.9688 14.6953V15.4359C22.9688 16.2422 22.9313 16.4062 22.697 16.6594C22.6267 16.7344 22.486 16.8328 22.3829 16.8797C22.2048 16.9641 21.8767 16.9688 13.997 16.9688C5.87822 16.9688 5.79384 16.9688 5.60166 16.875C5.49384 16.8234 5.36259 16.7344 5.30634 16.6734L5.20322 16.5656L7.51416 14.2594C9.77353 12.0047 9.82509 11.9531 10.0079 11.9531C10.1813 11.9531 10.2282 11.9953 11.2267 12.9844C12.2532 14.0016 12.2673 14.0156 12.4642 14.0156C12.572 14.0156 12.7032 13.9922 12.7548 13.9641C12.8063 13.9406 13.8048 12.7641 14.9813 11.3531C16.1532 9.94219 17.1751 8.74219 17.2595 8.68125C17.4657 8.53125 17.6392 8.55 17.836 8.72812Z"
				fill={rest?.fill ?? 'white'}
			/>
			<path
				d="M8.57902 5.02031C8.00246 5.13281 7.43058 5.59219 7.16339 6.16406C7.05558 6.40313 7.01808 6.55781 6.99933 6.85781C6.94308 7.72031 7.34621 8.42813 8.10089 8.79844C8.45246 8.97188 8.46652 8.97656 9.00089 8.97656C9.53527 8.97656 9.54933 8.97188 9.90089 8.79844C10.6556 8.42813 11.0587 7.72031 11.0025 6.85781C10.9275 5.63906 9.79777 4.78125 8.57902 5.02031ZM9.54933 6.21563C10.0368 6.60938 10.1118 7.1625 9.73683 7.61719C9.35714 8.08125 8.63996 8.07656 8.25558 7.6125C7.94152 7.22813 7.95089 6.74063 8.27902 6.37969C8.51808 6.1125 8.68683 6.04219 9.05714 6.05625C9.32433 6.07031 9.39464 6.09375 9.54933 6.21563Z"
				fill={rest?.fill ?? 'white'}
			/>
			<path
				d="M2.39062 9.69844C2.25469 9.73125 2.15625 9.79219 2.07656 9.89531C2.04375 9.93281 1.57031 11.6203 1.02188 13.6453L0.028125 17.3203L0.0234375 17.8594C0.0234375 18.3703 0.0328125 18.4125 0.173438 18.7172C0.351562 19.0922 0.721875 19.4859 1.05938 19.6547C1.19063 19.7203 4.6125 20.6578 9.1875 21.8859C16.5234 23.85 17.1141 24 17.4656 24C17.8781 24 18.2297 23.9063 18.5438 23.7047C18.8391 23.5219 19.2 23.0906 19.3078 22.8047C19.4578 22.4063 20.2031 19.6078 20.2031 19.4484C20.2031 19.2375 19.9406 18.9844 19.7156 18.9844C19.4953 18.9844 19.3172 19.0688 19.2422 19.2188C19.2094 19.2844 18.9984 20.0109 18.7734 20.8359C18.5531 21.6563 18.3422 22.3828 18.3047 22.4484C18.1547 22.7344 17.7797 22.9688 17.4703 22.9688C17.3813 22.9688 14.0766 22.1016 10.1203 21.0422C0.9 18.5766 1.59375 18.7688 1.39219 18.6047C1.19063 18.4359 1.03125 18.1219 1.03125 17.8875C1.03125 17.7938 1.47188 16.0594 2.01563 14.0391C2.55938 12.0234 3 10.2844 3 10.1813C3 9.8625 2.70469 9.62813 2.39062 9.69844Z"
				fill={rest?.fill ?? 'white'}
			/>
		</svg>
	);
};

export default Image;
