import { SVGProps } from 'react';

const DislikeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="8.00019" cy="9.008" r="7.80488" fill="#FA0101" />
		<g clipPath="url(#clip0_1_21785)">
			<path
				d="M11.645 6.31651C11.4457 6.11709 11.209 5.9589 10.9486 5.85098C10.6881 5.74305 10.4089 5.6875 10.127 5.6875C9.84501 5.6875 9.56583 5.74305 9.30536 5.85098C9.04489 5.9589 8.80823 6.11709 8.60891 6.31651L8.19525 6.73016L7.78159 6.31651C7.37898 5.91389 6.83292 5.68771 6.26354 5.68771C5.69417 5.68771 5.14811 5.91389 4.7455 6.31651C4.34288 6.71912 4.1167 7.26518 4.1167 7.83455C4.1167 8.40393 4.34288 8.94999 4.7455 9.3526L5.15915 9.76626L8.19525 12.8024L11.2313 9.76626L11.645 9.3526C11.8444 9.15328 12.0026 8.91663 12.1105 8.65616C12.2185 8.39568 12.274 8.1165 12.274 7.83455C12.274 7.55261 12.2185 7.27342 12.1105 7.01295C12.0026 6.75248 11.8444 6.51582 11.645 6.31651Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1_21785">
				<rect width="9.36585" height="9.36585" fill="white" transform="translate(3.51221 4.51562)" />
			</clipPath>
		</defs>
	</svg>
);
export default DislikeIcon;
