import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VInfluencer = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_54_3811)">
			<path
				d="M7.17487 0.871883C5.57487 1.08751 4.13425 1.78438 2.978 2.9C2.31238 3.54063 1.83426 4.19375 1.44051 4.99375C0.449884 7.01562 0.409259 9.30937 1.33426 11.3937C1.47801 11.7219 1.80301 12.3031 1.96863 12.5312C2.13738 12.7625 2.43426 12.7781 2.61238 12.5687C2.74675 12.4062 2.72488 12.2625 2.49676 11.9C1.40613 10.1375 1.16238 8.12187 1.80301 6.19687C2.61238 3.76563 4.73425 2.00626 7.28425 1.65001C7.75925 1.58438 8.62799 1.58438 9.08112 1.65001C11.3624 1.97813 13.2749 3.375 14.2561 5.43125C14.6749 6.30625 14.8655 7.10312 14.8999 8.09062C14.928 8.93749 14.8374 9.59062 14.578 10.3844C14.4311 10.8406 14.103 11.5344 13.8499 11.9281C13.7624 12.0656 13.6811 12.2219 13.6717 12.2781C13.6499 12.4031 13.728 12.5781 13.8405 12.6531C13.9499 12.725 14.1561 12.7281 14.2655 12.6562C14.4592 12.5281 14.9905 11.5687 15.2342 10.9125C15.3811 10.5094 15.5499 9.85624 15.6186 9.41562C15.6967 8.90937 15.6967 7.7 15.6186 7.2C15.3311 5.375 14.453 3.7875 13.0811 2.60938C11.9749 1.65938 10.628 1.06251 9.14362 0.868758C8.69362 0.809383 7.61237 0.812509 7.17487 0.871883Z"
				fill={props.stroke || 'white'}
				strokeWidth={0.1}
			/>
			<path
				d="M7.42423 3.10666C5.44298 3.4129 3.82111 4.7754 3.18674 6.66602C2.83049 7.73165 2.83361 8.90977 3.19611 9.99102C3.37424 10.5254 3.50549 10.6973 3.73361 10.6973C3.93049 10.6973 4.12736 10.5004 4.12736 10.3098C4.12736 10.266 4.08674 10.1285 4.03674 10.0035C3.59924 8.90039 3.58986 7.7879 4.00549 6.7129C4.49299 5.4504 5.54611 4.4629 6.84611 4.04728C7.28673 3.90665 7.69923 3.84728 8.20548 3.8504C8.90548 3.8504 9.4711 3.97853 10.0867 4.2754C11.2023 4.80978 12.043 5.75353 12.4242 6.89415C12.5805 7.36915 12.6305 7.63477 12.6492 8.13477C12.6742 8.80352 12.5773 9.38164 12.3492 9.94727C12.1961 10.3191 12.2305 10.541 12.4586 10.6504C12.6773 10.7535 12.8867 10.6691 13.0023 10.4254C13.368 9.65352 13.5242 8.51914 13.393 7.59727C13.1117 5.60978 11.7773 3.99103 9.8836 3.34103C9.30548 3.14103 9.03673 3.09728 8.2836 3.08791C7.91485 3.08166 7.52736 3.08791 7.42423 3.10666Z"
				fill={props.stroke || 'white'}
				strokeWidth={0.1}
			/>
			<path
				d="M7.81548 5.32193C7.3561 5.37818 6.92485 5.54068 6.52485 5.80005C4.91861 6.84693 4.70298 9.15317 6.08735 10.4813C6.18735 10.5782 6.3061 10.6844 6.35298 10.7188L6.43735 10.7813L6.19673 10.8844C5.11548 11.3375 4.23423 12.2938 3.88111 13.4C3.72798 13.8719 3.68736 14.1625 3.66861 14.8563C3.65298 15.4125 3.65611 15.5125 3.70298 15.6063C3.81236 15.8407 3.44048 15.8219 8.20298 15.8219H12.4717L12.5717 15.7313L12.6748 15.6407V14.8782C12.6748 14.3719 12.6592 14.0563 12.6342 13.9313C12.3811 12.775 11.7686 11.8563 10.8373 11.2407C10.6217 11.1 10.2186 10.8907 10.028 10.825L9.92797 10.7907L10.0498 10.6907C10.5498 10.2938 10.9373 9.69692 11.0998 9.08755C11.1936 8.7313 11.2155 8.1063 11.1467 7.76255C10.8623 6.36255 9.74047 5.38443 8.3311 5.31255C8.15922 5.30318 7.92798 5.3063 7.81548 5.32193ZM8.74985 6.1688C9.47172 6.35943 10.053 6.91255 10.3061 7.6438C10.4155 7.9563 10.4373 8.43755 10.3623 8.77817C10.1748 9.62817 9.50297 10.3094 8.67485 10.4938C8.3186 10.575 7.8186 10.5469 7.47485 10.4313C6.76548 10.1907 6.21548 9.5938 6.01548 8.85005C5.9436 8.5813 5.9436 8.06255 6.01548 7.79067C6.20923 7.05005 6.75298 6.45943 7.46548 6.20943C7.8811 6.06568 8.3061 6.05318 8.74985 6.1688ZM8.8186 11.3532C9.6561 11.4313 10.3498 11.7563 10.928 12.3344C11.5436 12.95 11.8655 13.6969 11.8998 14.5938L11.9186 15.0407H8.17798H4.44048V14.8282C4.44048 14.1063 4.64673 13.4 5.02486 12.8313C5.19673 12.5719 5.62486 12.1344 5.8936 11.95C6.3561 11.6313 6.94985 11.4063 7.47173 11.3563C7.85923 11.3188 8.44047 11.3188 8.8186 11.3532Z"
				fill={props.stroke || 'white'}
				strokeWidth={0.1}
			/>
		</g>
		<defs>
			<clipPath id="clip0_54_3811">
				<rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);
export default VInfluencer;
