import { SVGProps } from 'react';

const BorderedPPT = (props: SVGProps<SVGSVGElement>) => (
	<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.492188" width="45" height="45" rx="22.5" stroke="url(#paint0_linear_872_83900)" />
		<path
			d="M24.902 12.7344V14.8816C27.452 14.8959 30.4198 14.8554 32.9675 14.8936C33.449 14.8936 33.47 15.3196 33.476 15.6916C33.5165 20.1481 33.4662 24.7329 33.5 29.1916C33.491 29.3671 33.4955 30.0166 33.4902 30.2011C33.4737 30.8184 33.1647 30.8454 32.5475 30.8641C32.4485 30.8641 32.1575 30.8686 32.0615 30.8731C29.6758 30.8611 27.29 30.8664 24.902 30.8664V33.2529L12.5 31.3441C12.5 25.7771 12.5 20.2119 12.5 14.6484L24.902 12.7344Z"
			fill="#D33922"
		/>
		<path
			d="M24.9007 15.5938H32.7757V30.1437H24.9007V28.2402H30.6262V27.284H24.9007V26.0915H30.6262V25.1375H24.9029C24.9029 24.6695 24.9029 24.2022 24.8984 23.735C25.3669 23.904 25.8671 23.9664 26.3627 23.9178C26.8583 23.8691 27.3368 23.7106 27.7634 23.4537C28.1983 23.168 28.5585 22.7824 28.8141 22.3292C29.0697 21.876 29.2132 21.3682 29.2327 20.8482C28.1827 20.8408 27.1379 20.8437 26.0932 20.8437C26.0932 19.8057 26.1052 18.7685 26.0714 17.7327C25.6799 17.8077 25.2892 17.8902 24.8999 17.9735V15.5945"
			fill="white"
		/>
		<path
			d="M26.5726 17.2344C27.3861 17.2854 28.1533 17.6306 28.731 18.2057C29.3087 18.7807 29.6574 19.5463 29.7121 20.3596C28.6621 20.3716 27.6173 20.3671 26.5703 20.3671C26.5703 19.3224 26.5703 18.2769 26.5703 17.2344"
			fill="#D33922"
		/>
		<path
			d="M16.3253 19.035C17.6205 19.0972 19.1903 18.522 20.2643 19.4932C21.2925 20.7412 21.0203 23.0362 19.4963 23.7825C18.9422 24.0019 18.3414 24.0772 17.7503 24.0015C17.7503 24.9345 17.7458 25.8675 17.7503 26.7997C17.2778 26.7592 16.803 26.7187 16.3305 26.6805C16.3088 24.1305 16.3043 21.5805 16.3305 19.0305"
			fill="white"
		/>
		<path
			d="M17.7445 20.3258C18.2147 20.3041 18.7945 20.2186 19.114 20.6596C19.2417 20.8997 19.3111 21.1666 19.3164 21.4385C19.3218 21.7105 19.2629 21.9799 19.1447 22.2248C18.8725 22.7161 18.2575 22.6688 17.7827 22.7258C17.7325 21.9263 17.7377 21.1276 17.7445 20.3258Z"
			fill="#D33922"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_872_83900"
				x1="17.9954"
				y1="43.727"
				x2="55.2003"
				y2="24.3385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default BorderedPPT;
