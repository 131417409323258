import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Instagram = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_80_3863)">
			<path
				d="M7.01565 0.0510197C5.00939 0.158833 3.48127 0.702583 2.37033 1.69165C0.884389 3.01821 0.232826 4.55571 0.0922009 7.07759C-0.0343617 9.3604 -0.00154915 16.4385 0.143763 17.7182C0.340639 19.5088 0.950014 20.9057 1.98127 21.9464C2.81564 22.7854 3.62189 23.2589 4.80314 23.601C6.06877 23.9667 7.00627 24.0135 12.725 23.9854C17.3188 23.9667 17.6891 23.9526 18.6313 23.7604C21.3969 23.2026 23.2719 21.3135 23.8063 18.5526C23.9938 17.587 24.0032 17.076 23.9891 11.5542L23.9704 6.25727L23.8625 5.67134C23.5813 4.13852 23.0282 3.00883 22.1094 2.07133C20.811 0.74477 19.3625 0.186958 16.8735 0.0463314C15.8657 -0.00991821 8.05627 -0.00522995 7.01565 0.0510197ZM17.2953 2.29634C18.6922 2.43227 19.6578 2.80259 20.375 3.48227C20.9094 3.98384 21.2516 4.54165 21.486 5.29165C21.6641 5.85415 21.7438 6.37446 21.7953 7.3354C21.8563 8.42759 21.8563 15.6182 21.7953 16.6917C21.6875 18.637 21.3313 19.5979 20.3985 20.4932C19.6578 21.1964 18.8047 21.5573 17.5063 21.7026C16.3438 21.8339 7.67658 21.8339 6.5844 21.7026C5.67502 21.5948 4.92502 21.3698 4.37658 21.0417C4.06252 20.8589 3.44377 20.287 3.21408 19.9729C2.84377 19.4713 2.55314 18.712 2.4172 17.9057C2.29064 17.1463 2.24845 15.6276 2.24845 11.976C2.24845 8.3854 2.29064 6.90884 2.41252 6.16352C2.61877 4.87915 3.10158 3.97446 3.92189 3.31352C4.35314 2.96665 4.66721 2.7979 5.24846 2.60571C5.86252 2.40415 6.55158 2.30102 7.64846 2.24946C8.89534 2.19321 16.6344 2.23071 17.2953 2.29634Z"
				fill="white"
			/>
			<path
				d="M18.0356 4.18545C17.3325 4.38701 16.9059 5.08545 17.0231 5.84014C17.0747 6.16826 17.1872 6.39326 17.4216 6.62295C17.7075 6.91357 17.9137 6.99795 18.3872 7.02139C18.7762 7.04014 18.8137 7.03545 19.0997 6.89482C19.4231 6.73545 19.6247 6.5292 19.7934 6.18701C19.9341 5.8917 19.9387 5.31514 19.7981 5.01045C19.4887 4.34482 18.7341 3.99326 18.0356 4.18545Z"
				fill="white"
			/>
			<path
				d="M11.2299 5.86347C10.2877 5.99003 9.35486 6.35097 8.52986 6.90409C8.08924 7.1994 7.21736 8.07128 6.92205 8.51659C6.45798 9.21972 6.10642 10.0728 5.94705 10.8978C5.84861 11.39 5.86267 12.7166 5.97048 13.2088C6.50955 15.7119 8.3283 17.5354 10.8361 18.0791C11.3799 18.1963 12.7111 18.1963 13.2549 18.0791C14.5205 17.8025 15.4767 17.2869 16.3861 16.3822C17.3096 15.4682 17.8486 14.4697 18.1252 13.1713C18.2424 12.6182 18.2424 11.3807 18.1252 10.8275C17.5908 8.31034 15.7346 6.44472 13.2408 5.92909C12.7955 5.83534 11.6846 5.79784 11.2299 5.86347ZM13.0111 8.15565C14.4221 8.51659 15.5096 9.60409 15.8939 11.0385C15.9736 11.3291 15.9971 11.5494 15.9971 11.9994C15.9971 12.6744 15.8986 13.1103 15.6314 13.6728C14.7783 15.4728 12.7158 16.3916 10.8736 15.7916C9.52361 15.3557 8.54392 14.301 8.16892 12.89C8.05642 12.4682 8.06111 11.5353 8.17361 11.0853C8.65174 9.22441 10.3486 7.9494 12.2142 8.04315C12.4533 8.05253 12.8096 8.10409 13.0111 8.15565Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_80_3863">
				<rect width="24" height="24" fill="white" transform="translate(-7.62939e-05 -0.000976562)" />
			</clipPath>
		</defs>
	</svg>
);
export default Instagram;
