import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Analytics = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M.941.594a1.436 1.436 0 0 0-.825.747l-.1.206v9.906l.1.206c.181.369.456.616.838.747.175.06.262.063 2.578.063h2.397l-.172 1.03-.172 1.032h-.378c-.319 0-.394.01-.469.06-.144.096-.206.215-.206.409 0 .194.062.312.206.41.087.056.206.059 3.262.059 3.057 0 3.175-.003 3.263-.06.144-.097.206-.215.206-.41 0-.193-.062-.312-.206-.409-.075-.05-.15-.059-.469-.059h-.378l-.172-1.031-.172-1.031 2.457-.007 2.456-.009.219-.106c.287-.14.534-.388.675-.675l.106-.219V1.547l-.106-.219a1.501 1.501 0 0 0-.675-.675l-.22-.106L8.048.54C1.263.534 1.107.534.941.594Zm6.59 1.225c0 .387.032.475.207.59.122.082.403.082.525 0 .175-.115.206-.203.206-.59v-.35l3.119.006 3.116.01.109.068c.06.038.137.116.172.172.062.103.062.16.062 4.775 0 4.616 0 4.672-.062 4.775a.642.642 0 0 1-.172.172l-.11.069H1.298l-.109-.07a.642.642 0 0 1-.172-.171c-.062-.103-.062-.16-.062-4.775V1.828l.068-.11a.456.456 0 0 1 .307-.221c.053-.013 1.468-.022 3.15-.025l3.053-.003v.35Zm1.726 11.68.172 1.032H6.572l.172-1.031.172-1.031h2.169l.172 1.03Z" />
			<path d="M2.238 3.59a.655.655 0 0 0-.147.148c-.056.087-.06.196-.06 2.762s.004 2.675.06 2.763c.031.046.1.115.147.146.087.057.187.06 2.262.06s2.175-.003 2.263-.06a.655.655 0 0 0 .147-.146c.056-.085.059-.166.059-1.263s-.003-1.178-.06-1.262c-.096-.144-.215-.207-.409-.207-.194 0-.312.063-.41.207-.052.084-.058.159-.058.94v.853H4.969V6.928c0-1.515-.003-1.606-.06-1.69-.096-.144-.215-.207-.409-.207-.194 0-.312.063-.41.207-.055.084-.059.175-.059 1.69v1.603H2.97V6.178c0-2.25-.003-2.353-.06-2.44-.096-.144-.215-.207-.409-.207-.115 0-.2.019-.262.06ZM10.722 3.813c-.547.115-.994.359-1.397.762a2.601 2.601 0 0 0-.678 1.081c-.097.29-.1.31-.1.844s.003.553.1.844c.147.434.344.747.678 1.081.335.334.647.531 1.082.675.287.1.312.1.843.1.531 0 .556 0 .844-.1.434-.144.747-.34 1.081-.675.335-.334.531-.647.675-1.081.1-.288.1-.313.1-.844 0-.531 0-.556-.1-.844a2.562 2.562 0 0 0-.675-1.081 2.57 2.57 0 0 0-1.081-.675c-.269-.087-.337-.097-.766-.106a4.25 4.25 0 0 0-.606.019Zm.06 1.728v.725l-.541.54-.544.544-.066-.156a1.753 1.753 0 0 1 .382-1.931c.206-.207.6-.45.725-.45.034 0 .043.134.043.728Zm1.243-.616c.325.156.644.475.8.8.063.128.113.25.113.272 0 .025-.172.034-.61.034h-.609v-.61c0-.437.01-.608.034-.608.022 0 .144.05.272.112Zm.913 2.088a1.864 1.864 0 0 1-1.297 1.206 1.874 1.874 0 0 1-1.085-.1l-.156-.066.544-.544.54-.54h.726c.593 0 .728.01.728.044Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Analytics;
