import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const MainPage = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={0.667}
			d="m2.166 6 6-4.667 6 4.667v7.333a1.333 1.333 0 0 1-1.333 1.334H3.5a1.333 1.333 0 0 1-1.334-1.334V6Z"
		/>
		<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.667} d="M6.166 14.667V8h4v6.667" />
	</svg>
);
export default MainPage;
