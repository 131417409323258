import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Builders = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M10.627.332c-.063.078-.066.125-.066.66v.577l.09.09c.05.05.122.092.16.092.09 0 .237-.082.262-.141a6.62 6.62 0 0 0 .02-.638c0-.571-.004-.587-.073-.653-.11-.103-.31-.093-.393.013ZM8.156.985c-.128.134-.1.234.187.734.144.25.29.478.325.507.16.13.425.009.425-.194 0-.113-.5-1.006-.603-1.078a.26.26 0 0 0-.334.03ZM13.16.96c-.093.081-.6.978-.6 1.066 0 .04.042.115.092.165.106.106.243.119.343.035.103-.088.597-.975.597-1.075 0-.2-.278-.322-.431-.191ZM8.27 3.194c-.122.166-1.24 2.225-1.24 2.288 0 .028.015.081.034.119.053.096 3.422 4.18 3.56 4.312.128.125.215.144.334.078.037-.022.647-.74 1.356-1.6l1.8-2.181c.41-.497.51-.638.51-.713 0-.062-.194-.443-.597-1.178-.329-.597-.625-1.115-.663-1.156l-.062-.069H8.345l-.075.1Zm1.994.625-.353.64a4.353 4.353 0 0 1-.256.448c-.01 0-.172-.29-.366-.64l-.347-.641h1.431l-.109.193Zm2.344.016-.353.644c-.132.234-.247.418-.26.406a29.365 29.365 0 0 1-.684-1.24c0-.013.319-.02.706-.02h.706l-.115.21ZM9.186 5.207c0 .006-.316.012-.703.012-.388 0-.703-.01-.703-.018 0-.013.156-.307.35-.657l.347-.637.356.644c.194.353.353.646.353.656Zm2.025-.61c.175.32.319.591.319.6 0 .013-.32.022-.707.022-.387 0-.7-.012-.693-.025.006-.015.162-.31.353-.653.281-.515.35-.619.378-.575.019.028.175.313.35.631Zm2.34-.025.347.632-.356.009a27.12 27.12 0 0 1-.712 0l-.36-.01.35-.634c.19-.347.353-.631.363-.631.01 0 .175.284.369.634ZM9.837 7.147c.21.766.375 1.4.369 1.407-.007.006-.497-.575-1.088-1.294-.594-.719-1.115-1.353-1.162-1.41l-.088-.1h1.584l.385 1.397Zm1.806-1.343c-.01.03-.184.675-.394 1.43-.206.757-.387 1.4-.4 1.435-.018.04-.13-.334-.378-1.231-.193-.706-.378-1.375-.41-1.487l-.058-.2h.828c.775 0 .828.003.812.053Zm2.006.115c-.35.438-2.196 2.669-2.203 2.663-.006-.006.716-2.672.757-2.794.009-.028.2-.037.796-.037h.788l-.138.168Z" />
			<path d="M5.511 8.922c-.337.072-.637.225-1.843.922l-1.182.681-.109-.19c-.131-.24-.206-.325-.353-.4a.686.686 0 0 0-.478-.063c-.069.02-.344.157-.61.307-.375.215-.5.303-.568.4a.745.745 0 0 0-.116.534c.016.084.422.838 1.169 2.156.631 1.116 1.187 2.09 1.237 2.169.05.078.141.175.203.219.1.069.154.078.37.078h.252l.438-.247c.565-.319.594-.34.694-.51a.557.557 0 0 0 .084-.334c.003-.172-.013-.219-.147-.469-.119-.215-.144-.284-.11-.303.023-.015.126-.075.229-.134l.187-.106 1.844.497c1.016.271 1.956.509 2.094.528.387.05.878-.035 1.216-.21.353-.18 5.34-3.287 5.446-3.39.194-.188.27-.388.285-.744.015-.4-.056-.597-.306-.85a.77.77 0 0 0-.407-.247 1.167 1.167 0 0 0-.512-.05c-.557.031-.722.11-2.663 1.24-1.39.813-1.622.941-1.753.982l-.066.022.06-.16c.072-.193.078-.528.012-.721a1.195 1.195 0 0 0-.43-.544c-.126-.084-.257-.128-.579-.203-.54-.125-.984-.26-1.847-.566a18.183 18.183 0 0 0-.881-.294c-.216-.056-.6-.056-.86 0Zm1.535.791c.84.3 1.365.463 1.9.581.387.088.512.156.61.325.137.244.05.575-.182.697-.213.116-.394.088-1.6-.25-.372-.106-.7-.19-.728-.19-.088 0-.235.171-.235.274 0 .179.072.22.657.385 1.315.372 1.925.49 2.343.453.441-.04.635-.134 2.307-1.11.862-.5 1.656-.952 1.765-1.002.782-.354 1.335-.191 1.335.39 0 .372.162.247-2.688 2.006-1.4.863-2.637 1.62-2.75 1.675-.112.06-.281.125-.375.15-.384.091-.472.075-2.553-.484-1.066-.284-1.984-.519-2.04-.519-.072 0-.2.053-.385.16-.187.109-.287.15-.31.128-.015-.016-.33-.56-.696-1.207L2.755 11l.285-.168C5.405 9.45 5.51 9.397 5.999 9.419c.24.013.34.04 1.047.294Zm-5.275.688c.022.012.575.971 1.228 2.128 1.116 1.968 1.187 2.106 1.147 2.168-.053.082-.828.522-.919.522-.034 0-.078-.018-.094-.04-.097-.12-2.353-4.15-2.353-4.204 0-.08.038-.109.497-.371.41-.235.431-.244.494-.204Z" />
			<path d="M1.358 11.016a.245.245 0 0 0-.078.188c0 .156.047.23.178.284.294.125.54-.21.334-.453-.069-.081-.103-.097-.219-.097-.106 0-.159.019-.215.078Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Builders;
