import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Channel = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<path stroke="#fff" d="M14.75 1.333 9.417 6H8.083L2.75 1.333M2.083 6h13.334v8.667H2.083V6Z" />
	</svg>
);
export default Channel;
