type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Connections = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22" fill="none">
			<path
				d="M12.0273 10.5411C11.782 10.5411 11.5809 10.7422 11.5809 10.9875V13.8029L9.14315 15.2106C8.93224 15.3333 8.85867 15.603 8.98129 15.8189C9.06468 15.9611 9.21182 16.0396 9.36878 16.0396C9.44236 16.0396 9.52084 16.02 9.58951 15.9807L12.0273 14.573L14.465 15.9807C14.5337 16.02 14.6122 16.0396 14.6858 16.0396C14.8378 16.0396 14.9899 15.9611 15.0733 15.8189C15.1959 15.6079 15.1223 15.3333 14.9114 15.2106L12.4736 13.8029V10.9875C12.4687 10.7422 12.2725 10.5411 12.0273 10.5411Z"
				fill="#016DEA"
			/>
			<path
				d="M10.1281 6.30816C9.67687 6.5436 8.68116 7.09786 7.74431 7.86303C7.44511 8.10828 7.27344 8.47615 7.27344 8.86365V10.0654C7.27344 10.3106 7.47454 10.5117 7.71979 10.5117C7.96504 10.5117 8.16614 10.3106 8.16614 10.0654V8.86365C8.16614 8.74102 8.2201 8.62821 8.31329 8.54973C9.36786 7.68645 10.5107 7.10767 10.7265 6.99976C10.908 6.91147 11.0208 6.72999 11.0208 6.52888V4.83667C11.0208 4.68952 10.9473 4.54728 10.8246 4.4688C10.7265 4.40503 10.6677 4.29712 10.6677 4.1794V2.42343C10.6677 1.79559 11.1778 1.28547 11.8056 1.28547H12.1833C12.8111 1.28547 13.3213 1.79559 13.3213 2.42343V4.1794C13.3213 4.29712 13.2624 4.40503 13.1643 4.4688C13.0417 4.55218 12.9681 4.68952 12.9681 4.83667V6.53379C12.9681 6.73489 13.0809 6.91637 13.2624 7.00466C13.4831 7.11257 14.6211 7.68645 15.6756 8.55463C15.7688 8.63311 15.8228 8.74593 15.8228 8.86855V10.0703C15.8228 10.3155 16.0239 10.5166 16.2691 10.5166C16.5144 10.5166 16.7155 10.3155 16.7155 10.0703V8.86855C16.7155 8.48106 16.5438 8.11319 16.2446 7.86794C15.3127 7.10276 14.3121 6.5436 13.8608 6.31306V5.04758C14.0864 4.81705 14.214 4.50804 14.214 4.18431V2.42343C14.214 1.30509 13.3065 0.397675 12.1882 0.397675H11.8105C10.6922 0.397675 9.78478 1.30509 9.78478 2.42343V4.1794C9.78478 4.50804 9.91231 4.81705 10.1379 5.04268V6.30816H10.1281Z"
				fill="#016DEA"
			/>
			<path
				d="M4.52238 12.3707H4.90006C5.5279 12.3707 6.03801 12.8808 6.03801 13.5086V15.2646C6.03801 15.3823 5.97915 15.4902 5.88106 15.554C5.75843 15.6374 5.68486 15.7747 5.68486 15.9219V17.619C5.68486 17.8201 5.79767 18.0016 5.97915 18.0899C6.19988 18.1978 7.33783 18.7717 8.3924 19.6398C8.48559 19.7183 8.53955 19.8311 8.53955 19.9538V21.1555C8.53955 21.4007 8.74065 21.6018 8.9859 21.6018C9.23115 21.6018 9.43225 21.4007 9.43225 21.1555V19.9538C9.43225 19.5663 9.26058 19.1984 8.96137 18.9532C8.02943 18.188 7.02882 17.6288 6.57756 17.3983V16.1328C6.80319 15.9023 6.93072 15.5933 6.93072 15.2695V13.5135C6.93072 12.3952 6.0233 11.4878 4.90497 11.4878H4.52728C3.40895 11.4878 2.50153 12.3952 2.50153 13.5135V15.2646C2.50153 15.5933 2.62906 15.9023 2.85469 16.1279V17.3934C2.40343 17.6288 1.40773 18.1831 0.470877 18.9482C0.171674 19.1935 0 19.5614 0 19.9489V21.1506C0 21.3958 0.201104 21.5969 0.446352 21.5969C0.6916 21.5969 0.892704 21.3958 0.892704 21.1506V19.9489C0.892704 19.8262 0.946659 19.7134 1.03985 19.6349C2.09442 18.7717 3.23728 18.1929 3.4531 18.085C3.63458 17.9967 3.74739 17.8152 3.74739 17.6141V15.9219C3.74739 15.7747 3.67382 15.6325 3.5512 15.554C3.4531 15.4902 3.39424 15.3823 3.39424 15.2646V13.5086C3.38443 12.8808 3.89454 12.3707 4.52238 12.3707Z"
				fill="#016DEA"
			/>
			<path
				d="M15.0334 18.9482C14.7342 19.1935 14.5625 19.5613 14.5625 19.9488V21.1505C14.5625 21.3958 14.7636 21.5969 15.0089 21.5969C15.2541 21.5969 15.4552 21.3958 15.4552 21.1505V19.9488C15.4552 19.8262 15.5092 19.7134 15.6024 19.6349C16.6569 18.7716 17.7998 18.1929 18.0156 18.0849C18.1971 17.9967 18.3099 17.8152 18.3099 17.6141V15.9219C18.3099 15.7747 18.2363 15.6325 18.1137 15.554C18.0156 15.4902 17.9567 15.3823 17.9567 15.2646V13.5086C17.9567 12.8808 18.4669 12.3707 19.0947 12.3707H19.4724C20.1002 12.3707 20.6103 12.8808 20.6103 13.5086V15.2646C20.6103 15.3823 20.5515 15.4902 20.4534 15.554C20.3307 15.6374 20.2572 15.7747 20.2572 15.9219V17.619C20.2572 17.8201 20.37 18.0016 20.5515 18.0898C20.7722 18.1978 21.9101 18.7716 22.9647 19.6398C23.0579 19.7183 23.1119 19.8311 23.1119 19.9537V21.1555C23.1119 21.4007 23.313 21.6018 23.5582 21.6018C23.8035 21.6018 24.0046 21.4007 24.0046 21.1555V19.9537C24.0046 19.5662 23.8329 19.1984 23.5337 18.9531C22.6017 18.1879 21.6011 17.6288 21.1499 17.3982V16.1328C21.3755 15.9022 21.503 15.5932 21.503 15.2695V13.5135C21.503 12.3952 20.5956 11.4878 19.4773 11.4878H19.0996C17.9813 11.4878 17.0738 12.3952 17.0738 13.5135V15.2646C17.0738 15.5932 17.2014 15.9022 17.427 16.1279V17.3933C16.9659 17.6288 15.9702 18.183 15.0334 18.9482Z"
				fill="#016DEA"
			/>
		</svg>
	);
};
export default Connections;
