import { SVGProps } from 'react';

const BorderedLink = (props: SVGProps<SVGSVGElement>) => (
	<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect
			x="0.5"
			y="0.492188"
			width="45"
			height="45"
			rx="22.5"
			stroke={props.stroke || 'url(#paint0_linear_872_83817)'}
		/>
		<path
			d="M20.4912 25.501L33 12.9922M33 12.9922V15.4922M33 12.9922H30.5"
			stroke={props.stroke || 'url(#paint1_linear_872_83817)'}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M33 21.4922V22.9922C33 27.7062 33 30.0633 31.5355 31.5277C30.0711 32.9922 27.714 32.9922 23 32.9922C18.2859 32.9922 15.9289 32.9922 14.4645 31.5277C13 30.0633 13 27.7062 13 22.9922C13 21.8639 13 20.8705 13.0201 19.9922M24.5 12.9922H23C18.2859 12.9922 15.9289 12.9922 14.4645 14.4567C14.0235 14.8976 13.7154 15.4194 13.5 16.0621"
			stroke={props.stroke || 'url(#paint2_linear_872_83817)'}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_872_83817"
				x1="17.9954"
				y1="43.727"
				x2="55.2003"
				y2="24.3385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_872_83817"
				x1="25.3847"
				y1="24.885"
				x2="35.5018"
				y2="19.6127"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_872_83817"
				x1="20.8241"
				y1="32.0073"
				x2="37.0001"
				y2="23.5775"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default BorderedLink;
