import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Blog = (props: SVGProps<SVGSVGElement>) => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x="1.74023" y="1.55664" width="14.0438" height="12.9115" rx="1.34808" stroke="white" strokeWidth="0.75" />
		<path
			d="M3.22656 12.4824L4.02585 13.2817L4.82514 12.4824"
			stroke="white"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.40951 5.2207H3.71891C3.34884 5.2207 3.04883 5.52071 3.04883 5.89079V10.5814C3.04883 10.9515 3.34884 11.2515 3.71891 11.2515H8.40951C8.77959 11.2515 9.0796 10.9515 9.0796 10.5814V5.89079C9.0796 5.52071 8.77959 5.2207 8.40951 5.2207Z"
			stroke="white"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.89084 7.56439C5.16818 7.56439 5.393 7.33956 5.393 7.06223C5.393 6.78489 5.16818 6.56006 4.89084 6.56006C4.6135 6.56006 4.38867 6.78489 4.38867 7.06223C4.38867 7.33956 4.6135 7.56439 4.89084 7.56439Z"
			stroke="white"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.07519 9.23761L7.4013 7.56372L3.71875 11.2463"
			stroke="white"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line x1="12.4238" y1="2.43164" x2="14.2584" y2="2.43164" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<line x1="10.207" y1="5.2373" x2="14.0109" y2="5.2373" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<line x1="10.207" y1="6.71436" x2="14.0109" y2="6.71436" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<line x1="10.207" y1="8.19116" x2="14.0109" y2="8.19116" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<line x1="10.207" y1="12.8684" x2="12.0416" y2="12.8684" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<line x1="6.51562" y1="12.8684" x2="8.35024" y2="12.8684" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
		<circle cx="3.23564" cy="2.80596" r="0.393846" fill="white" />
		<circle cx="4.34306" cy="2.8062" r="0.393846" fill="white" />
		<circle cx="5.45049" cy="2.8062" r="0.393846" fill="white" />
		<line x1="1.36523" y1="3.8833" x2="16.1591" y2="3.8833" stroke="white" strokeWidth="0.75" />
	</svg>
);
export default Blog;
