import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;
const SvgComponent = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} stroke={color} fill="none" {...props}>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2v11Z"
			/>
		</svg>
	);
};
export default SvgComponent;
