'use client';

import Audio from '@/components/icons/Audio';
import BorderedAudio from '@/components/icons/chat/BorderedAudio';
import BorderedLink from '@/components/icons/chat/BorderedLink';
import BorderedPDF from '@/components/icons/chat/BorderedPDF';
import BorderedPPT from '@/components/icons/chat/BorderedPPT';
import BorderedVideo from '@/components/icons/chat/BorderedVideo';
import BorderedWord from '@/components/icons/chat/BorderedWord';
import DislikeEmoji from '@/components/icons/chat/DislikeEmoji';
import ExcelIcon from '@/components/icons/chat/ExcelIcon';
import HeartIcon from '@/components/icons/chat/HeartIcon';
import LikeEmoji from '@/components/icons/chat/LikeEmoji';
import PdfIcon from '@/components/icons/chat/PdfIcon';
import PowerPointIcon from '@/components/icons/chat/PowerPointIcon';
import WordIcon from '@/components/icons/chat/WordIcon';
import Document from '@/components/icons/Document';
import Images from '@/components/icons/files/Image';
import FolderSolid from '@/components/icons/FolderSolid';
import ImageIcon from '@/components/icons/ImageIcon';
import Other from '@/components/icons/Other';
import Summary from '@/components/icons/Summary';
import TrashSolid from '@/components/icons/TrashSolid';
import Video from '@/components/icons/Video';
import { ItemType } from '@/components/NavItem';
import { TAILWIND_BREAKPOINTS } from '@/constants/constants';
import JSZip from 'jszip';
import { FilesIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { BreakPointCounts, ProfileInteractionResource } from './types';

// import { useRouter } from 'next/navigation';

export function CreateRandomID() {
	// Generate a random ID
	return '_' + Math.random().toString(36).substr(2, 9).toLowerCase();
}

export default function formatNumber(num: number, precision = 0) {
	const map = [
		{ suffix: 'T', threshold: 1e12 },
		{ suffix: 'B', threshold: 1e9 },
		{ suffix: 'M', threshold: 1e6 },
		{ suffix: 'K', threshold: 1e3 },
		{ suffix: '', threshold: 1 },
	];

	if (num < 1000) {
		return num;
	}

	const found = map.find((x) => Math.abs(num) >= x.threshold);
	if (found) {
		const formatted = (num / found.threshold).toFixed(precision);
		const result = formatted.split('.')[1];
		if (parseInt(result) == 0 && precision == 0) {
			return parseInt(formatted) + found.suffix;
		}
		return formatted + found.suffix;
	}

	return num;
}

export function convertDateRangeToDateStrings(dateRange: any, isMinMax: boolean = false) {
	if (!dateRange?.from || !dateRange?.to) {
		return {
			from: '',
			to: '',
		};
	}
	const fromDate = new Date(dateRange.from);
	const toDate = new Date(dateRange.to);

	const formatDate = (date: any) => {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString();
		return `${year}-${month}-${day}`;
	};

	const formattedFromDate = formatDate(fromDate);
	const formattedToDate = formatDate(toDate);
	if (isMinMax) {
		return {
			min: formattedFromDate,
			max: formattedToDate,
		};
	}
	return {
		from: formattedFromDate,
		to: formattedToDate,
	};
}

export function formatDate(date: any, useMonthString: boolean = false, useTimeString: boolean = true) {
	if (!date) {
		return;
	}
	const formatDate = (date: any) => {
		const day = date.getDate().toString().padStart(2, '0');
		const month = useMonthString
			? date.toLocaleString('default', { month: 'long' })
			: (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString();
		const timeString = date.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		});
		return useTimeString
			? `${day}-${month}-${year}${useTimeString ? ` ${timeString}` : ''}`
			: `${month}-${day}-${year}`;
	};
	return formatDate(date);
}

export function buildQueryString(params: any, prefix: string = ''): string {
	const queryString = Object.keys(params)
		.map((key) => {
			const value = params[key];
			const prefixedKey = prefix ? `${prefix}[${key}]` : key;
			if (value !== null && typeof value === 'object') {
				return buildQueryString(value, prefixedKey);
			} else {
				return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
			}
		})
		.join('&');
	return queryString;
}

export const convertDateTimeLocal = (originalDate: string) => {
	if (originalDate) {
		return originalDate.substring(0, 16);
	}
	return null;
};

export function DateTimeFormat(date: Date) {
	const _date = new Date(date);
	const options: any = { hour: 'numeric', minute: 'numeric', hour12: true };
	// let options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }
	const timeString = _date.toLocaleTimeString('en-US', options);

	return `${AddZero(_date.getDate())}-${AddZero(_date.getMonth() + 1)}-${_date.getFullYear()} ${timeString}`;
}

export function AddZero(value: string | number) {
	return value.toString().padStart(2, '0');
}

export const StorageItems = (t: (s: string) => string): ItemType[] => [
	{
		icon: <Summary />,
		name: t('myAccount.myStorage.summary.Summary'),
		link: '/my-account/my-storage',
		active: false,
	},
	{
		icon: <FolderSolid />,
		name: t('myAccount.myStorage.folder.Folders'),
		link: '/my-account/my-storage/folder',
		active: false,
	},
	{
		icon: <Images />,
		name: t('myAccount.myStorage.summary.images'),
		link: '/my-account/my-storage/images',
		active: false,
	},
	{
		icon: <Video />,
		name: t('myAccount.myStorage.summary.video'),
		link: '/my-account/my-storage/videos',
		active: false,
	},
	{
		icon: <Audio />,
		name: t('myAccount.myStorage.summary.audio'),
		link: '/my-account/my-storage/audio',
		active: false,
	},
	{
		icon: <Document />,
		name: t('myAccount.myStorage.summary.documents'),
		link: '/my-account/my-storage/documents',
		active: false,
	},
	{
		icon: <Other />,
		name: t('myAccount.myStorage.summary.other'),
		link: '/my-account/my-storage/others',
		active: false,
	},
	{
		icon: <TrashSolid />,
		name: t('myAccount.common.trash'),
		link: '/my-account/my-storage/trash',
		active: false,
	},
];

export const RoomLabels: {
	value: 'personal' | 'business' | 'social' | 'interviews' | 'pre_orders' | 'open_orders' | 'post_orders' | '';
	name: string;
	color: string;
}[] = [
	{ value: 'personal', name: 'Personal', color: '#E600FA' },
	{ value: 'business', name: 'Business', color: '#FA9600' },
	{ value: 'social', name: 'Social', color: '#00BEFA' },
	{ value: 'interviews', name: 'Interviews', color: '#AC9364' },
	{ value: 'pre_orders', name: 'Pre-Orders', color: '#09A198' },
	{ value: 'open_orders', name: 'Open Orders', color: '#00FAEB' },
	{ value: 'post_orders', name: 'Post Orders', color: '#8C00FA' },
];

export function isDateGreaterThan30Minutes(dateString: any = null) {
	return false;
	// if (!dateString) {
	//   return;
	// }

	// const date = new Date(dateString);

	// const currentTime = new Date().getTime();

	// const timeDifference = currentTime - date.getTime();

	// const thirtyMinutesInMillis = 30 * 60 * 1000;

	// return timeDifference > thirtyMinutesInMillis;
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
}

export const getIconByExtension = (extension: any, type = 0) => {
	switch (extension?.toLowerCase()) {
		case 'mp3':
		case 'webm':
		case 'wav':
			return type == 0 ? <Audio /> : <BorderedAudio />;
		case 'mp4':
		case 'avi':
			return type == 0 ? <Video /> : <BorderedVideo />;
		case 'jpg':
		case 'jpeg':
		case 'png':
			return type == 0 ? (
				<ImageIcon color="#000" />
			) : (
				<div className="default-gradient flex h-full w-full items-center justify-center rounded-full">
					<Images />
				</div>
			);
		case 'xls':
		case 'xlsx':
			return type == 0 ? (
				<ExcelIcon />
			) : (
				<div className="default-gradient h-full w-full rounded-full p-[1px]">
					<div className="flex h-full w-full items-center justify-center rounded-full bg-white">
						<ExcelIcon />
					</div>
				</div>
			);
		case 'pdf':
			return type == 0 ? <PdfIcon /> : <BorderedPDF />;
		case 'doc':
		case 'docx':
			return type == 0 ? <WordIcon /> : <BorderedWord />;
		case 'ppt':
		case 'pptx':
			return type == 0 ? <PowerPointIcon /> : <BorderedPPT />;
		default:
			return type == 0 ? <FilesIcon stroke="white" /> : <BorderedLink />;
	}
};

export const documentFiles = ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'ppt', 'pptx'];

export interface CategorizedData {
	business: ProfileInteractionResource[];
	personal: ProfileInteractionResource[];
}

export function categorizeData(data: ProfileInteractionResource[]): CategorizedData {
	return data.reduce(
		(acc: CategorizedData, item: ProfileInteractionResource) => {
			if (item.user.profile_type === 'business' || item.user.profile_type_enum === 'business') {
				acc['business'].push(item);
			} else if (item.user.profile_type === 'personal' || item.user.profile_type_enum === 'personal') {
				acc['personal'].push(item);
			}
			return acc;
		},
		{ business: [], personal: [] }
	);
}

export const iceServers = [
	{
		urls: 'stun:openrelay.metered.ca:80',
	},
	{
		urls: 'turn:openrelay.metered.ca:80',
		username: 'openrelayproject',
		credential: 'openrelayproject',
	},
	{
		urls: 'turn:openrelay.metered.ca:443',
		username: 'openrelayproject',
		credential: 'openrelayproject',
	},
	{
		urls: 'turn:openrelay.metered.ca:443?transport=tcp',
		username: 'openrelayproject',
		credential: 'openrelayproject',
	},
];

export function formatSecondsTime(seconds: number): string {
	seconds = Math.floor(seconds);
	const minutes: number = Math.floor(seconds / 60);
	const remainingSeconds: number = seconds % 60;
	const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString();
	const secondsStr: string = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();
	return `${minutesStr}:${secondsStr}`;
}

export function blobToFile(blob: Blob) {
	if (!blob) {
		return null; // Ensure it returns null if the blob is invalid.
	}
	const generateRandomFileName = (): string => {
		return Math.random().toString(36).substring(2, 10) + '.webm'; // Adding .webm extension
	};
	const fileName = generateRandomFileName();
	return new File([blob], fileName, { type: 'audio/webm' }); // Correct MIME type for WebM
}

export function blobToFilemp3(blob: Blob) {
	if (!blob) {
		return null; // Ensure it returns null if the blob is invalid.
	}

	const generateRandomFileName = (): string => {
		return Math.random().toString(36).substring(2, 10) + '.mp3'; // Adding .mp3 extension
	};

	const fileName = generateRandomFileName(); // Generate random file name with .mp3 extension
	return new File([blob], fileName, { type: 'audio/mpeg' }); // Correct MIME type for MP3
}

export const getEmojiIcon = (type: any) => {
	switch (type) {
		case 'like':
			return <LikeEmoji />;
		case 'dislike':
			return <DislikeEmoji />;
		case 'heart':
			return <HeartIcon />;
		case 'laugh':
			return (
				<img
					className="size-[16px]"
					src="/images/laugh.png"
					alt="image"
					style={{
						objectFit: 'cover',
					}}
				/>
			);
		case 'wow':
			return (
				<img
					className="size-[16px]"
					src="/images/wow.png"
					alt="image"
					style={{
						objectFit: 'cover',
					}}
				/>
			);
		case 'nervous':
			return (
				<img
					className="size-[16px]"
					src="/images/nervous.png"
					alt="image"
					style={{
						objectFit: 'cover',
					}}
				/>
			);
		case 'angry':
			return (
				<img
					className="size-[16px]"
					src="/images/angry.png"
					alt="image"
					style={{
						objectFit: 'cover',
					}}
				/>
			);
		default:
			return <div></div>;
	}
};

export const toggleSelection = (value: any, oldSelected: any) => {
	const _sentSelectedData = [...oldSelected];
	const index = _sentSelectedData.findIndex((_id: number) => _id == value);
	if (index == -1) {
		_sentSelectedData.push(value);
	} else {
		_sentSelectedData.splice(index, 1);
	}
	return _sentSelectedData;
};

export const handleFilterForm = (filters: any, key: string, value: any, arrayIndex?: number) => {
	const keys = key.split('.'); // Split the key by dot notation
	const _filters = { ...filters }; // Clone the existing filters

	let current = _filters; // Start with the root object
	const lastKey = keys.pop(); // Remove the last key from the array

	if (arrayIndex !== undefined && current[keys[0]] !== undefined && current[keys[0]]?.length) {
		current[keys[0]] = current[keys[0]][0];
	}

	// Traverse through the keys, except the last one, to reach the nested object
	keys.forEach((k) => {
		// Clone the object if it exists, otherwise create an empty object
		current[k] = current[k] && typeof current[k] === 'object' && !Array.isArray(current[k]) ? { ...current[k] } : {};
		current = current[k]; // Move deeper into the object
	});

	if (
		value === null ||
		value === undefined ||
		(typeof value === 'object' && !Object.keys(value).length) ||
		(Array.isArray(value) && value.length === 0) ||
		value === ''
	) {
		// If the value is null, undefined, an empty object, an empty array, or an empty string, delete the key
		delete current[lastKey!];

		// Check if the parent object (like age) is now empty, and delete it if it is
		if (Object.keys(current).length === 0) {
			const parentKey = keys.pop(); // Go back one level to the parent object
			if (parentKey && _filters[parentKey]) {
				delete _filters[parentKey];
			}
		}
	} else {
		// Otherwise, set the value at the last key
		current[lastKey!] = value;
	}
	if (arrayIndex !== undefined) {
		if (_filters[keys[0]]) {
			_filters[keys[0]] = [_filters[keys[0]]];
		}
	}
	// Update the form value
	return _filters;
};

export const filterInteractions = (interactions: any, interaction_type_enum = 'comment') => {
	return interactions
		.filter((interaction: any) => interaction.interaction_type_enum === interaction_type_enum)
		.map((interaction: any) => ({
			...interaction,
			childInteraction: interaction.childInteraction
				? filterInteractions(interaction.childInteraction, interaction_type_enum)
				: [],
		}));
};

export const filterChildInteractions = (interaction: any, interaction_type_enum = 'comment'): any => {
	if (!interaction.childInteraction || interaction.childInteraction.length === 0) {
		return interaction;
	}
	const filteredChildren = interaction.childInteraction
		.filter((child: any) => child.interaction_type_enum === interaction_type_enum)
		.map((child: any) => filterChildInteractions(child, interaction_type_enum));
	return {
		...interaction,
		childInteraction: filteredChildren,
	};
};

export const updateInteractions = (
	comment: any,
	secondResponse: any,
	commentId: string,
	interaction_type_enum: string = 'comment'
): any => {
	const updatedInteractionData = filterChildInteractions(secondResponse.data.data, interaction_type_enum);
	const updateNestedInteractions = (item: any): any => {
		if (item.id === commentId) {
			return {
				...updatedInteractionData,
				childInteraction: updatedInteractionData.childInteraction,
			};
		}
		if (item.childInteraction && item.childInteraction.length > 0) {
			const updatedChildInteractions = item.childInteraction.map((child: any) => updateNestedInteractions(child));
			return {
				...item,
				childInteraction: updatedChildInteractions,
			};
		}
		return item;
	};
	return updateNestedInteractions(comment);
};

export const countNestedInteractions = (data: any) => {
	if (!data || !Array.isArray(data)) {
		return 0;
	}

	let count = 0;

	data.forEach((item) => {
		count += 1;
		if (item.childInteraction) {
			count += countNestedInteractions(item.childInteraction);
		}
	});

	return count;
};

export const capitalizeFirstLetterOfEachWord = (phrase: string) => {
	if (phrase == 'N/a' || phrase == 'N/A' || !phrase) return 'N/A';
	return phrase
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const decapitalizeFirstLetterOfEachWord = (phrase: string) => {
	if (phrase == 'N/a' || phrase == 'N/A' || !phrase) return 'N/A';
	return phrase
		.toLowerCase()
		.split('')
		.map((word) => word.charAt(0).toLowerCase() + word.slice(1))
		.join('');
};

export const decapitalizeFirstLetter = (phrase: string) => {
	if (phrase == 'N/a' || phrase == 'N/A' || !phrase) return 'N/A';
	return phrase
		.split('')
		.map((word) => word.charAt(0).toLowerCase() + word.slice(1))
		.join(' ');
};

export const checkOnline = (auth: any, current: any, isBoolean = false) => {
	if (auth.id === current?.id) {
		if (isBoolean) {
			return current.is_online_profile_state;
		}
		return current.is_online_profile_state ? 'online' : 'offline';
	} else {
		if (isBoolean) {
			return current?.is_online;
		}
		return current?.is_online ? 'online' : 'offline';
	}
};

export const downloadFile = async (url: string) => {
	try {
		const filename = url.substring(url.lastIndexOf('/') + 1);
		const response = await fetch(url);
		const blob = await response.blob();
		const urlBlob = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = urlBlob;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(urlBlob);
	} catch (error) {
		console.log('response error', error);
	}
};

export const downloadFilesAsZip = async (urls: string[]) => {
	try {
		const zip = new JSZip();

		// Fetch all files and add them to the ZIP
		await Promise.all(
			urls.map(async (url) => {
				const filename = url.substring(url.lastIndexOf('/') + 1);
				const response = await fetch(url);
				const blob = await response.blob();
				zip.file(filename, blob);
			})
		);

		// Generate the ZIP file as a Blob
		const zipBlob = await zip.generateAsync({ type: 'blob' });

		// Create a URL for the Blob and trigger a download
		const urlBlob = URL.createObjectURL(zipBlob);
		const a = document.createElement('a');
		a.href = urlBlob;
		a.download = 'books.zip'; // Name of the ZIP file
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(urlBlob);
	} catch (error) {
		console.log('response error', error);
	}
};

export const formatEventTime = (minutes: number): string => {
	if (minutes === null || minutes === undefined) {
		return '00 D: 00 H: 00 M: 00 S';
	}
	if (isNaN(minutes)) {
		return '00 D: 00 H: 00 M: 00 S';
	}
	const totalSeconds: number = minutes * 60;
	const days: number = Math.floor(totalSeconds / (24 * 3600));
	const hours: number = Math.floor((totalSeconds % (24 * 3600)) / 3600);
	const mins: number = Math.floor((totalSeconds % 3600) / 60);
	const secs: number = totalSeconds % 60;
	const format = (num: number): string => num.toString().padStart(2, '0');
	return `${format(days)} D: ${format(hours)} H: ${format(mins)} M: ${format(secs)} S`;
};
export const convertToSeconds = (hours: any = 0, minutes: any = 0, seconds: any = 0) => {
	return parseInt(hours * 3600 + minutes * 60 + seconds);
};

export const convertFromSeconds = (totalSeconds: any = 0) => {
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = totalSeconds % 60;

	return { hours, minutes, seconds };
};

// export const navigateBackOrTo = (fallbackRoute: string) => {
//   const router = useRouter();
//   if (window.history.length > 1) {
//     router.back();
//   } else {
//     router.push(fallbackRoute);
//   }
// };

export const highlightSortingOptions = [
	{ name: 'By Name', value: 'name' },
	{ name: 'By Date', value: 'created_at' },
	{ name: 'By No. Of Views', value: 'views' },
	{ name: 'By No. of Comments', value: 'comments' },
	{ name: 'By No. Of Shares', value: 'shares' },
	{ name: 'By No. Of Likes', value: 'likes' },
];

export const myProfileTimelineSortingOptions = [
	{ name: 'By Publishing Date', value: 'created_at' },
	{ name: 'By No. Of Views', value: 'views' },
	{ name: 'By No. of Comments', value: 'comments' },
	{ name: 'By No. Of Reactions', value: 'reactions' },
	{ name: 'By No. Of Shares', value: 'shares' },
];

export const nationHashtagTimelineSortingOptions = [
	{ name: 'By No. Of Views', value: 'views' },
	{ name: 'By No. of Comments', value: 'comments' },
	{ name: 'By No. Of Reactions', value: 'reactions' },
	{ name: 'By No. Of Shares', value: 'shares' },
	{ name: 'By No. Of Rating', value: 'rating' },
	{ name: 'By No. Of Reviews', value: 'reviews' },
];

export const SocialPostsSortingOptions = [
	{ name: 'By Comments', value: 'comments' },
	{ name: 'By Reactions', value: 'reactions' },
	{ name: 'By Shares', value: 'shares' },
];

export const handleViewedBy = (old_viewable_by: any) => {
	let viewable_by: any = [];
	try {
		if (typeof old_viewable_by == 'object') {
			viewable_by = old_viewable_by;
		} else if (old_viewable_by) {
			viewable_by = JSON.parse(old_viewable_by);
		} else {
			viewable_by = [];
		}
	} catch (e) {
		viewable_by = [];
	}
	return viewable_by;
};

export function formatPrivayEnum(privacy_enum: string, t?: (key: string) => string): string {
	switch (privacy_enum) {
		case 'public':
			return t?.('privacy.public') || 'Public';
		case 'only_me':
			return t?.('privacy.only_me') || 'Private';
		case 'connections':
			return t?.('privacy.connections') || 'Connections';
		case 'rebuilding':
			return t?.('privacy.rebuilding') || 'Rebuilding';
		case 'selected_users':
			return t?.('privacy.selected_users') || 'Selected Users';
		case 'subscribers':
			return t?.('privacy.subscribers') || 'Subscribers';
		default:
			return privacy_enum;
	}
}

export function formatSponsorshipTier(tier: string, t?: (key: string) => string): string {
	switch (tier?.toLowerCase()) {
		case 'silver':
			return t?.('vBuzz.common.silver') || 'Silver';
		case 'gold':
			return t?.('vBuzz.common.gold') || 'Gold';
		case 'platinum':
			return t?.('vBuzz.common.platinum') || 'Platinum';
		default:
			return tier;
	}
}

export function formatDeliveryTime(time: string, t?: (key: string) => string): string {
	switch (time?.toLocaleLowerCase()) {
		case 'minutes':
		case 'minute':
			return t?.('common.labels.minutes') || 'Minutes';
		case 'hours':
		case 'hour':
			return t?.('common.labels.hours') || 'Hours';
		case 'days':
		case 'day':
			return t?.('common.labels.days') || 'Days';
		case 'weeks':
		case 'week':
			return t?.('common.labels.weeks') || 'Weeks';
		case 'months':
		case 'month':
			return t?.('common.labels.months') || 'Months';
		default:
			return time;
	}
}

export const videoThumbnailGenerator = (videoUrl: string, onThumbnailGenerated: (thumbnail: string | null) => void) => {
	const video = document.createElement('video');
	video.src = videoUrl;
	video.crossOrigin = 'anonymous';
	video.muted = true;

	video.addEventListener('loadedmetadata', () => {
		// Set currentTime dynamically as 10% of the video's total duration to take the snapshot
		const thumbnailTime = Math.min(video.duration * 0.1, video.duration - 0.1);
		video.currentTime = thumbnailTime;
	});

	video.addEventListener('seeked', () => {
		const canvas = document.createElement('canvas');
		canvas.width = video.videoWidth || 640;
		canvas.height = video.videoHeight || 360;

		const ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
			const dataUrl = canvas.toDataURL('image/png');
			onThumbnailGenerated(dataUrl); // Pass the thumbnail data URL to the callback
		}
		video.remove();
	});

	video.addEventListener('error', () => {
		console.error('Error generating thumbnail');
		onThumbnailGenerated(null);
	});
};

export const getLoadingSkeletonsCount = (dataLength: number, maxCountPerBreakPoint: BreakPointCounts) => {
	const orderedBreakpoints: Array<keyof BreakPointCounts> = ['2xl', 'xl', 'lg', 'md', 'sm'];
	const sortedMaxCountPerBreakPoint: BreakPointCounts = {};

	orderedBreakpoints.forEach((breakpoint: keyof BreakPointCounts) => {
		if (maxCountPerBreakPoint[breakpoint] !== undefined) {
			sortedMaxCountPerBreakPoint[breakpoint] = maxCountPerBreakPoint[breakpoint];
		}
	});

	const getMaxLoadingSkeletons = (maxCountPerBreakPoint: BreakPointCounts) => {
		const screenWidth = window.innerWidth;

		for (const [key, count] of Object.entries(maxCountPerBreakPoint)) {
			const breakpoint = TAILWIND_BREAKPOINTS[key as keyof typeof TAILWIND_BREAKPOINTS];
			if (breakpoint !== undefined && screenWidth >= breakpoint) {
				return count;
			}
		}

		return 3;
	};

	const maxCards = getMaxLoadingSkeletons(sortedMaxCountPerBreakPoint);
	const cardsCount = dataLength % maxCards === 0 ? maxCards : maxCards - (dataLength % maxCards);

	return cardsCount;
};
