import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;
const TrashSolid = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} stroke={color} fill="none" {...props}>
			<path
				stroke="#fff"
				strokeLinecap="round"
				d="M9.17 4a3.001 3.001 0 0 1 5.659 0M20.5 6h-17M18.373 15.4c-.177 2.654-.266 3.981-1.13 4.79-.866.81-2.196.81-4.857.81h-.773c-2.661 0-3.992 0-4.857-.81-.865-.809-.953-2.136-1.13-4.79l-.46-6.9m13.667 0-.2 3M9.5 11l.5 5M14.5 11l-.5 5"
			/>
		</svg>
	);
};
export default TrashSolid;
