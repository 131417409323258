import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Orders = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path
				id="Vector"
				d="M7.27812 0.818751C6.88437 0.856252 6.30625 0.971877 5.8625 1.10938C4.0125 1.66875 2.44062 2.99688 1.5625 4.74063C1.03125 5.79375 0.796875 6.79375 0.796875 8.01563C0.796875 8.94375 0.925 9.71563 1.21562 10.5C1.45312 11.15 1.91875 11.9781 2.3375 12.4969C2.425 12.6063 2.49687 12.7063 2.49687 12.7219C2.49687 12.7625 2.4125 12.7563 2.21562 12.7031C1.97812 12.6406 1.94375 12.6438 1.8375 12.7469C1.76562 12.8219 1.74687 12.8656 1.74687 12.975C1.74687 13.2 1.78437 13.2219 2.72187 13.4688C3.17812 13.5875 3.59062 13.6875 3.6375 13.6875C3.74062 13.6875 3.90312 13.5313 3.90312 13.4313C3.90312 13.3906 3.80312 12.9781 3.68437 12.5156C3.4375 11.5719 3.41875 11.5313 3.18125 11.5313C3.08125 11.5313 3.03437 11.5531 2.95937 11.625C2.85937 11.725 2.85625 11.7594 2.91875 12.0531C2.95937 12.2313 2.86562 12.175 2.66562 11.8969C1.99687 10.9813 1.58125 9.93438 1.43125 8.78125C1.38125 8.40625 1.38437 7.60625 1.43125 7.21875C1.58125 6.04688 2.03437 4.9375 2.74062 4C2.98125 3.68125 3.55 3.10313 3.87187 2.84375C5.4125 1.61563 7.41875 1.13438 9.34062 1.53438C9.90625 1.65 10.6281 1.90938 11.05 2.14375C11.4031 2.34063 11.6531 2.25 11.6531 1.92813C11.6531 1.75 11.45 1.61563 10.7531 1.32813C9.65625 0.878127 8.47187 0.706252 7.27812 0.818751Z"
				fill="white"
			/>
			<path
				id="Vector_2"
				d="M12.2768 2.33352C12.1862 2.3679 12.1205 2.44602 12.1049 2.5429C12.0924 2.60852 12.1612 2.92102 12.308 3.48352C12.5518 4.42727 12.5737 4.4679 12.8112 4.4679C12.908 4.4679 12.958 4.44602 13.0299 4.37727C13.1299 4.27727 13.1393 4.22102 13.0862 4.01165C13.0237 3.77102 13.0924 3.7804 13.283 4.03665C14.7893 6.0429 15.0299 8.69915 13.9112 10.9523C13.0612 12.6616 11.4737 13.9398 9.62054 14.4054C8.13304 14.7804 6.59554 14.6335 5.19866 13.9835C4.86429 13.8273 4.73304 13.8116 4.61116 13.9054C4.50491 13.9898 4.47991 14.121 4.53929 14.2648C4.58304 14.3648 4.62366 14.3929 4.87679 14.5116C5.88929 14.9835 6.84866 15.196 7.96429 15.1991C8.83616 15.2023 9.45179 15.1054 10.2612 14.8398C12.6299 14.0616 14.4362 12.096 15.0112 9.67102C15.508 7.57415 15.0518 5.36165 13.7705 3.66165C13.633 3.47727 13.5112 3.30852 13.5049 3.28665C13.483 3.23665 13.5393 3.23977 13.7268 3.29602C13.958 3.36477 14.0487 3.3554 14.1549 3.25227C14.3112 3.0929 14.2674 2.83352 14.0674 2.7554C13.9424 2.7054 12.4205 2.31165 12.3674 2.31477C12.3424 2.31477 12.3018 2.32415 12.2768 2.33352Z"
				fill="white"
			/>
			<path
				id="Vector_3"
				d="M6.1125 5.26482C4.93437 5.67732 4.28125 5.92107 4.2375 5.96482L4.17188 6.03357L4.18125 7.99294C4.19062 9.80544 4.19687 9.95857 4.24687 10.0304C4.29375 10.0961 4.6125 10.2179 6.09687 10.7398C7.08437 11.0867 7.93437 11.3711 7.98437 11.3711C8.0375 11.3742 8.8875 11.0929 9.875 10.7461C11.2562 10.2648 11.6875 10.1023 11.7437 10.0429L11.8125 9.96482V7.99919V6.03357L11.7437 5.95857C11.6875 5.89919 11.2469 5.73357 9.85937 5.25232C8.8625 4.90544 8.01875 4.62419 7.98437 4.62732C7.95 4.62732 7.10937 4.91482 6.1125 5.26482ZM8.4125 5.39607C8.63125 5.47732 8.80937 5.55857 8.80312 5.57419C8.79687 5.58982 8.2375 5.79607 7.55937 6.03357L6.32812 6.46482L5.92187 6.33044C5.68125 6.25232 5.51562 6.18044 5.51562 6.15544C5.51562 6.13357 5.9875 5.95232 6.75 5.68357C7.42812 5.44607 7.99062 5.25232 7.99687 5.24919C8.00312 5.24919 8.19062 5.31482 8.4125 5.39607ZM10.1437 5.98669C10.4594 6.10232 10.5281 6.13982 10.4937 6.17419C10.4812 6.18357 9.91562 6.38669 9.2375 6.62419L8 7.05857L7.67187 6.94607C7.475 6.88044 7.34687 6.82107 7.35312 6.79919C7.3625 6.77419 9.73125 5.88357 9.81562 5.87419C9.825 5.87419 9.975 5.92419 10.1437 5.98669ZM5.32812 6.78044C5.58437 6.86482 5.80312 6.95232 5.81875 6.97419C5.83125 6.99607 5.84375 7.34294 5.84375 7.74294V8.47107L5.92187 8.54607C6.03125 8.65857 6.25312 8.65544 6.35937 8.54294C6.4375 8.45857 6.4375 8.45232 6.4375 7.83982C6.4375 7.49919 6.45 7.21482 6.46562 7.20544C6.4875 7.19294 7.4125 7.48669 7.63437 7.57732C7.68437 7.59919 7.6875 7.70232 7.6875 9.11169C7.6875 10.4992 7.68437 10.6242 7.63437 10.6242C7.59062 10.6242 5.14062 9.77419 4.88437 9.66794L4.78125 9.62732V8.12732C4.78125 6.92107 4.79062 6.62419 4.82187 6.62419C4.84375 6.62419 5.07187 6.69294 5.32812 6.78044ZM11.2125 8.13044L11.2031 9.63357L9.89062 10.0992C8.2625 10.6742 8.34687 10.6461 8.30937 10.5898C8.29375 10.5648 8.28125 9.90544 8.28125 9.08669C8.28125 7.95232 8.29062 7.62107 8.32187 7.60232C8.3875 7.56169 11.1062 6.62732 11.1656 6.62419C11.2156 6.62419 11.2187 6.70857 11.2125 8.13044Z"
				fill="white"
			/>
			<path
				id="Vector_4"
				d="M9.99305 8.9861C9.72742 9.07985 9.47742 9.18922 9.43992 9.2236C9.34617 9.3111 9.34305 9.52047 9.43055 9.63297C9.53992 9.7736 9.6243 9.76735 10.1555 9.57985C10.7587 9.37047 10.8212 9.3361 10.8618 9.22047C10.9055 9.0986 10.8587 8.9236 10.7649 8.85797C10.6555 8.77985 10.5024 8.80485 9.99305 8.9861Z"
				fill="white"
			/>
		</g>
	</svg>
);
export default Orders;
