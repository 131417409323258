import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Profiles = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g clipPath="url(#a)">
			<path
				fill="#fff"
				stroke="#fff"
				strokeWidth={0.2}
				d="M14.714 13.1h.1V2.9H1.186v10.2h13.528ZM3.682 10.734a.042.042 0 0 1-.04.041h-.785c-.025 0-.041-.02-.04-.041v-.004a2.723 2.723 0 0 1 1.286-2.172l.102-.063-.08-.089a1.9 1.9 0 0 1-.495-1.28c0-1.051.847-1.9 1.89-1.9 1.042 0 1.889.849 1.889 1.9.001.473-.175.93-.494 1.28l-.08.089.102.063a2.725 2.725 0 0 1 1.285 2.172l.1-.005-.1.005c0 .006-.001.012-.003.017l.094.035-.094-.035a.043.043 0 0 1-.04.028h-.784a.042.042 0 0 1-.042-.04v-.001a1.848 1.848 0 0 0-1.835-1.709c-.968 0-1.764.756-1.836 1.709Zm-3.11-8.92h14.857c.26 0 .471.211.471.472v11.428a.47.47 0 0 1-.471.472H.57a.47.47 0 0 1-.471-.472V2.286c0-.261.21-.472.471-.472Zm11.326 5.443H9.817a.374.374 0 0 1-.003-.043v-.857c0-.016.001-.03.003-.043h2.08a.357.357 0 0 1 .003.043v.857c0 .017 0 .031-.002.043Zm1.26 2.572H9.84c-.003 0-.008-.001-.015-.009a.052.052 0 0 1-.012-.034v-.857c0-.015.006-.027.012-.035.007-.007.012-.008.015-.008h3.316c.003 0 .008.001.015.008.006.008.012.02.012.035v.857a.052.052 0 0 1-.012.034c-.007.008-.012.009-.015.009ZM6.54 7.125c0-.567-.458-1.028-1.023-1.028s-1.023.46-1.023 1.028c0 .567.458 1.029 1.023 1.029S6.54 7.692 6.54 7.125Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Profiles;
