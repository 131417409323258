import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const ShoppingCart = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.333}
			d="M4.2 3.333H14L12.667 8h-7.75m8.416 2.667h-8L4 2H2m4 11.334a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm7.333 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
		/>
	</svg>
);
export default ShoppingCart;
