import { SVGProps } from 'react';

const WordIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.5 1.5H7.5C6.67157 1.5 6 2.17157 6 3V21C6 21.8284 6.67157 22.5 7.5 22.5H22.5C23.3284 22.5 24 21.8284 24 21V3C24 2.17157 23.3284 1.5 22.5 1.5Z"
			fill="url(#paint0_linear_327_25751)"
		/>
		<path
			d="M6 17.25H24V21C24 21.8285 23.3284 22.5 22.5 22.5H7.5C6.67157 22.5 6 21.8285 6 21V17.25Z"
			fill="url(#paint1_linear_327_25751)"
		/>
		<path d="M24 12H6V17.25H24V12Z" fill="url(#paint2_linear_327_25751)" />
		<path d="M24 6.75H6V12H24V6.75Z" fill="url(#paint3_linear_327_25751)" />
		<path
			d="M6 9C6 7.75732 7.00736 6.75 8.25 6.75H12.75C13.9927 6.75 15 7.75732 15 9V18C15 19.2427 13.9927 20.25 12.75 20.25H6V9Z"
			fill="black"
			fillOpacity="0.3"
		/>
		<path
			d="M12 5.25H1.5C0.671573 5.25 0 5.92157 0 6.75V17.25C0 18.0784 0.671573 18.75 1.5 18.75H12C12.8284 18.75 13.5 18.0784 13.5 17.25V6.75C13.5 5.92157 12.8284 5.25 12 5.25Z"
			fill="url(#paint4_linear_327_25751)"
		/>
		<path
			d="M11.25 8.26065H9.78923L8.64217 13.1542L7.38725 8.25H6.15196L4.88725 13.1542L3.75 8.26065H2.25L4.20098 15.75H5.4951L6.75 11.0159L8.0049 15.75H9.29902L11.25 8.26065Z"
			fill="white"
		/>
		<defs>
			<linearGradient id="paint0_linear_327_25751" x1="6" y1="5" x2="24" y2="5" gradientUnits="userSpaceOnUse">
				<stop stopColor="#2B78B1" />
				<stop offset="1" stopColor="#338ACD" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_327_25751"
				x1="6"
				y1="20.5312"
				x2="24"
				y2="20.5312"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#1B366F" />
				<stop offset="1" stopColor="#2657B0" />
			</linearGradient>
			<linearGradient id="paint2_linear_327_25751" x1="13.875" y1="15" x2="24" y2="15" gradientUnits="userSpaceOnUse">
				<stop stopColor="#20478B" />
				<stop offset="1" stopColor="#2D6FD1" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_327_25751"
				x1="13.875"
				y1="9.75"
				x2="24"
				y2="9.75"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#215295" />
				<stop offset="1" stopColor="#2E84D3" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_327_25751"
				x1="2.48353e-08"
				y1="12.75"
				x2="14.25"
				y2="12.75"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#223E74" />
				<stop offset="1" stopColor="#215091" />
			</linearGradient>
		</defs>
	</svg>
);
export default WordIcon;
