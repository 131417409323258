import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const AdsManager = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g fill="#fff" clipPath="url(#a)">
			<path d="m7.77.081-.08.085v1.847l.084.087c.069.072.11.088.228.088.119 0 .16-.016.228-.088l.084-.087V.166L8.234.08c-.066-.065-.113-.08-.232-.08-.119 0-.166.015-.231.08ZM4.355.65a.405.405 0 0 0-.106.153c-.063.188-.006.269.64.91l.604.6h.15c.125 0 .159-.016.237-.097a.286.286 0 0 0 .09-.225c0-.125-.015-.144-.634-.763L4.702.594h-.138c-.084 0-.168.022-.209.056ZM10.668 1.228c-.616.616-.635.64-.635.763 0 .215.188.369.407.328.103-.019 1.24-1.14 1.303-1.284.093-.222-.053-.441-.3-.441h-.144l-.631.634ZM2.499 3.325c-.31.081-.572.303-.719.597l-.075.156-.01 5.097-.006 5.097.079.078c.087.088.19.119.312.094.044-.006.538-.403 1.1-.878l1.025-.863 4.656-.015 4.657-.016.171-.081c.22-.103.425-.31.529-.528l.08-.172V4.078l-.09-.181a1.162 1.162 0 0 0-.543-.516l-.179-.084-3.78-.01c-2.81-.006-3.804.004-3.87.029-.118.05-.178.144-.178.287 0 .144.04.225.135.278.053.032.865.044 3.825.057l3.756.015.087.066a.73.73 0 0 1 .15.172c.063.103.063.172.072 3.362l.007 3.26H11.468l-.075.075a.32.32 0 0 0 0 .475l.075.075h2.225l-.022.146a.818.818 0 0 1-.044.197.593.593 0 0 1-.35.253c-.075.013-2.206.025-4.734.029H3.939l-.303.256c-.168.14-.528.444-.797.672-.271.228-.5.415-.509.415-.01 0-.012-2.05-.01-4.553.01-4.493.01-4.556.073-4.662.125-.216.187-.235.712-.253.597-.02.678-.06.678-.328 0-.279-.11-.329-.694-.325-.25 0-.509.018-.59.04Z" />
			<path d="M4.558 3.331c-.112.06-.15.135-.15.294 0 .094.019.147.075.203.21.21.55.085.55-.203 0-.162-.04-.237-.156-.297a.3.3 0 0 0-.319.003ZM6.149 6.385c-.088.084-1.022 2.837-1.022 3.003 0 .228.244.378.46.278.109-.05.143-.116.274-.503l.091-.26.419.01.422.009.112.325c.125.366.206.456.394.45.153-.003.256-.081.3-.222.034-.115.012-.19-.453-1.572-.54-1.606-.535-1.59-.788-1.59-.103 0-.16.018-.21.072ZM8.505 6.378a.497.497 0 0 0-.1.144c-.016.044-.028.684-.028 1.488 0 1.609-.003 1.593.234 1.675.191.062 1.003.028 1.241-.057.481-.168.834-.531.975-1.003.069-.231.069-1 0-1.237a1.512 1.512 0 0 0-1.04-1.025c-.129-.038-.304-.05-.691-.05-.485 0-.525.003-.59.065Zm1.216.657a.865.865 0 0 1 .425.418c.065.138.075.2.075.547 0 .325-.013.413-.066.531-.16.354-.484.532-.972.532h-.15V6.969h.275c.206 0 .306.016.413.066ZM10.146 10.91c-.113.134-.113.321.003.44.069.072.11.088.231.088.203 0 .31-.107.31-.313 0-.21-.107-.312-.313-.312-.131 0-.163.012-.231.096ZM10.261 13.694c-.137.034-.228.16-.228.316 0 .121.019.146.635.762l.63.634h.145c.247 0 .393-.218.3-.44-.063-.144-1.2-1.266-1.303-1.285a.512.512 0 0 0-.179.013ZM4.89 14.288c-.65.64-.704.718-.638.912.044.138.15.206.319.206.131 0 .14-.009.765-.634.62-.619.635-.637.635-.762a.286.286 0 0 0-.09-.225c-.079-.082-.113-.097-.238-.097h-.15l-.604.6ZM7.774 13.9l-.085.088v1.847l.082.084a.352.352 0 0 0 .462 0l.081-.084V13.98l-.087-.084a.326.326 0 0 0-.453.003Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default AdsManager;
