import { SVGProps } from 'react';

const BorderedWord = (props: SVGProps<SVGSVGElement>) => (
	<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.492188" width="45" height="45" rx="22.5" stroke="url(#paint0_linear_872_83860)" />
		<path
			d="M33.5 12.4922H18.5C17.6716 12.4922 17 13.1638 17 13.9922V31.9922C17 32.8206 17.6716 33.4922 18.5 33.4922H33.5C34.3284 33.4922 35 32.8206 35 31.9922V13.9922C35 13.1638 34.3284 12.4922 33.5 12.4922Z"
			fill="url(#paint1_linear_872_83860)"
		/>
		<path
			d="M17 28.2422H35V31.9922C35 32.8206 34.3284 33.4922 33.5 33.4922H18.5C17.6716 33.4922 17 32.8206 17 31.9922V28.2422Z"
			fill="url(#paint2_linear_872_83860)"
		/>
		<path d="M35 22.9922H17V28.2422H35V22.9922Z" fill="url(#paint3_linear_872_83860)" />
		<path d="M35 17.7422H17V22.9922H35V17.7422Z" fill="url(#paint4_linear_872_83860)" />
		<path
			d="M17 19.9922C17 18.7495 18.0074 17.7422 19.25 17.7422H23.75C24.9927 17.7422 26 18.7495 26 19.9922V28.9922C26 30.2349 24.9927 31.2422 23.75 31.2422H17V19.9922Z"
			fill="black"
			fillOpacity="0.3"
		/>
		<path
			d="M23 16.2422H12.5C11.6716 16.2422 11 16.9138 11 17.7422V28.2422C11 29.0706 11.6716 29.7422 12.5 29.7422H23C23.8284 29.7422 24.5 29.0706 24.5 28.2422V17.7422C24.5 16.9138 23.8284 16.2422 23 16.2422Z"
			fill="url(#paint5_linear_872_83860)"
		/>
		<path
			d="M22.25 19.2528H20.7892L19.6422 24.1464L18.3873 19.2422H17.152L15.8873 24.1464L14.75 19.2528H13.25L15.201 26.7422H16.4951L17.75 22.0081L19.0049 26.7422H20.299L22.25 19.2528Z"
			fill="white"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_872_83860"
				x1="17.9954"
				y1="43.727"
				x2="55.2003"
				y2="24.3385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_872_83860"
				x1="17"
				y1="15.9922"
				x2="35"
				y2="15.9922"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#2B78B1" />
				<stop offset="1" stopColor="#338ACD" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_872_83860"
				x1="17"
				y1="31.5234"
				x2="35"
				y2="31.5234"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#1B366F" />
				<stop offset="1" stopColor="#2657B0" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_872_83860"
				x1="24.875"
				y1="25.9922"
				x2="35"
				y2="25.9922"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#20478B" />
				<stop offset="1" stopColor="#2D6FD1" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_872_83860"
				x1="24.875"
				y1="20.7422"
				x2="35"
				y2="20.7422"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#215295" />
				<stop offset="1" stopColor="#2E84D3" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_872_83860"
				x1="11"
				y1="23.7422"
				x2="25.25"
				y2="23.7422"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#223E74" />
				<stop offset="1" stopColor="#215091" />
			</linearGradient>
		</defs>
	</svg>
);
export default BorderedWord;
