import { SVGProps } from 'react';

const PowerPointIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#D33922"
			d="M13.902 1.74v2.148c2.55.014 5.518-.026 8.066.012.481 0 .502.426.508.798.04 4.456-.01 9.041.024 13.5-.009.175-.005.825-.01 1.01-.016.617-.325.644-.942.663-.1 0-.39.004-.486.009-2.386-.012-4.772-.007-7.16-.007v2.386L1.5 20.35V3.655L13.902 1.74Z"
		/>
		<path
			fill="#fff"
			d="M13.9 4.604h7.876v14.55H13.9V17.25h5.725v-.956h-5.725V15.1h5.725v-.954h-5.723c0-.468 0-.935-.005-1.402a3.351 3.351 0 0 0 2.865-.281 3.264 3.264 0 0 0 1.47-2.606c-1.05-.007-2.095-.004-3.14-.004 0-1.038.012-2.076-.022-3.111-.391.075-.782.157-1.171.24V4.604"
		/>
		<path fill="#D33922" d="M15.573 6.243a3.357 3.357 0 0 1 3.14 3.125c-1.05.012-2.096.008-3.143.008V6.243" />
		<path
			fill="#fff"
			d="M5.325 8.044c1.296.063 2.865-.513 3.94.458 1.027 1.248.755 3.543-.769 4.29a3.524 3.524 0 0 1-1.746.219c0 .933-.004 1.866 0 2.798l-1.42-.12c-.021-2.55-.026-5.1 0-7.65"
		/>
		<path
			fill="#D33922"
			d="M6.744 9.335c.47-.022 1.05-.108 1.37.333a1.731 1.731 0 0 1 .03 1.566c-.272.49-.887.444-1.361.5-.05-.799-.045-1.598-.039-2.4Z"
		/>
	</svg>
);
export default PowerPointIcon;
