import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VTubeAuthor = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.3333 8.34338H5.66667C4.95942 8.34338 4.28115 8.62434 3.78105 9.12443C3.28095 9.62453 3 10.3028 3 11.0101V14.0101"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M13.4839 10.3004L11.3627 11.8156L13.4839 13.3307V10.3004Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M10.7566 9.69373H7.42326C7.08854 9.69373 6.8172 9.96507 6.8172 10.2998V13.3301C6.8172 13.6648 7.08854 13.9361 7.42326 13.9361H10.7566C11.0913 13.9361 11.3627 13.6648 11.3627 13.3301V10.2998C11.3627 9.96507 11.0913 9.69373 10.7566 9.69373Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M8.33073 6.34212C9.80349 6.34212 10.9974 5.14822 10.9974 3.67546C10.9974 2.2027 9.80349 1.00879 8.33073 1.00879C6.85797 1.00879 5.66406 2.2027 5.66406 3.67546C5.66406 5.14822 6.85797 6.34212 8.33073 6.34212Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
	</svg>
);
export default VTubeAuthor;
