const DislikeEmoji = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="7.9997" cy="8.17402" r="7.80488" fill="url(#paint0_linear_5738_233598)" />
			<path
				d="M10.0758 8.57896L8.51479 12.0912C8.20429 12.0912 7.90651 11.9678 7.68695 11.7483C7.4674 11.5287 7.34405 11.2309 7.34405 10.9204V9.35944H5.13527C5.02214 9.36073 4.91008 9.33739 4.80685 9.29107C4.70363 9.24474 4.61172 9.17652 4.53747 9.09115C4.46323 9.00577 4.40844 8.90527 4.3769 8.79661C4.34535 8.68796 4.33781 8.57374 4.35479 8.46188L4.89332 4.94969C4.92155 4.76358 5.01608 4.59394 5.15951 4.47203C5.30293 4.35012 5.48558 4.28415 5.67381 4.28627H10.0758M10.0758 8.57896V4.28627M10.0758 8.57896H11.1177C11.3386 8.58286 11.5532 8.50554 11.7208 8.36166C11.8884 8.21778 11.9974 8.01737 12.027 7.79847V5.06676C11.9974 4.84786 11.8884 4.64745 11.7208 4.50357C11.5532 4.35969 11.3386 4.28237 11.1177 4.28627H10.0758"
				stroke="white"
				stroke-width="0.390244"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_5738_233598"
					x1="0.194824"
					y1="8.17402"
					x2="15.8046"
					y2="8.17402"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FA0101" />
					<stop offset="1" stop-color="#016DEA" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default DislikeEmoji;
