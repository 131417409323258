import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Webinars = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#fff"
				d="M2.977 9.072h11.546a.303.303 0 0 0 .303-.303V1.728a.303.303 0 0 0-.303-.303H2.977a.303.303 0 0 0-.303.303v7.041c0 .167.136.303.303.303Zm.304-7.04h10.938v6.434H3.281V2.03Zm6.077 9.85H8.142c-.996 0-1.807.81-1.807 1.806v1.761c0 .168.136.303.303.303h4.224a.303.303 0 0 0 .303-.303v-1.76c0-.997-.81-1.807-1.807-1.807Zm1.2 3.264H6.943v-1.458c0-.661.538-1.2 1.2-1.2h1.216c.662 0 1.2.539 1.2 1.2v1.458ZM7.623.55c0-.168.136-.304.303-.304h1.65a.303.303 0 0 1 0 .607h-1.65a.303.303 0 0 1-.303-.303ZM.75 11.007c0-.167.136-.303.303-.303H2.12v-.02c0-.167.135-.302.303-.302h1.65a.303.303 0 0 1 .302.322h3.248l-.001-.02c0-.167.136-.302.303-.302h1.65a.303.303 0 0 1 .302.322h3.248a.303.303 0 0 1 .303-.322h1.65a.303.303 0 0 1 .302.322h1.067a.303.303 0 0 1 0 .606H1.053a.303.303 0 0 1-.303-.303Zm3.106.875H2.639c-.996 0-1.806.81-1.806 1.806v1.761c0 .168.136.303.303.303h4.223a.303.303 0 0 0 .303-.303v-1.76c0-.997-.81-1.807-1.806-1.807Zm1.2 3.264H1.439v-1.458c0-.661.538-1.2 1.2-1.2h1.217c.662 0 1.2.539 1.2 1.2v1.458Zm9.805-3.264h-1.217c-.996 0-1.806.81-1.806 1.806v1.761c0 .168.135.303.303.303h4.223a.303.303 0 0 0 .303-.303v-1.76c0-.997-.81-1.807-1.806-1.807Zm1.2 3.264h-3.617v-1.458c0-.661.538-1.2 1.2-1.2h1.217c.662 0 1.2.539 1.2 1.2v1.458Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75-.001h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Webinars;
