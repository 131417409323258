import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Episodes = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M3.11.715a.937.937 0 0 0-.616.518c-.063.141-.072.213-.081.735l-.013.581-.14.075a.978.978 0 0 0-.244.197c-.175.203-.203.337-.203.96v.55l-.157.04c-.378.097-.722.431-.856.831-.047.147-.05.531-.044 4.672l.01 4.516.09.181c.182.363.5.613.882.694.325.069 13.7.069 14.025 0 .39-.082.671-.303.871-.688l.1-.187.01-4.516c.006-4.14.003-4.525-.044-4.672-.131-.39-.466-.722-.837-.828l-.144-.04V3.78c0-.43-.013-.584-.053-.703-.06-.181-.29-.425-.453-.484l-.113-.04-.012-.582c-.01-.522-.02-.597-.082-.74a.932.932 0 0 0-.46-.457l-.155-.072-4.5-.01C6.716.688 5.366.697 5.309.722a.235.235 0 0 0-.075.387l.075.079h4.463c4.187 0 4.469.003 4.569.056.215.11.237.169.247.74l.012.513-.71.01c-.702.009-.709.009-.768.084-.078.093-.075.225.006.328l.063.081h.843c.938 0 1.01.013 1.122.194.057.094.063.156.063.61v.509l-5.394.006-5.394.01-.06.074c-.077.094-.074.225.007.328l.063.082h5.553c3.684 0 5.59.012 5.669.03.212.06.406.22.509.42l.094.187v4.344c0 3.49-.01 4.369-.04 4.462a.828.828 0 0 1-.385.447l-.17.094H1.829l-.169-.094a.827.827 0 0 1-.384-.447c-.031-.093-.04-.972-.04-4.462V5.449l.093-.187c.103-.2.297-.36.51-.42.065-.015.471-.03.934-.03h.819l.062-.082c.081-.103.085-.234.006-.328-.059-.075-.071-.075-.703-.084l-.643-.01v-.506c0-.453.006-.519.062-.61C2.5 2.988 2.165 3 7.497 3h4.844l.062-.081c.081-.103.085-.235.006-.328l-.059-.075-4.725-.01L2.9 2.5l.013-.512c.009-.463.015-.522.075-.604.128-.175.218-.196.859-.196.566 0 .581-.004.647-.072a.264.264 0 0 0 .01-.338c-.06-.075-.07-.075-.682-.081-.34-.003-.66.006-.713.019Z" />
			<path d="M8.284 5.674a2.01 2.01 0 0 0-.803.466c-.21.206-.343.428-.456.734-.069.2-.072.228-.072 1.36 0 1.287.003 1.318.225 1.74.213.406.713.785 1.156.878l.166.035v.518h-.09c-.304 0-.91-.293-1.235-.596-.284-.266-.381-.297-.519-.16-.056.06-.093.128-.093.178 0 .12.296.42.634.641.353.234.65.344 1.234.456.066.013.07.028.07.278v.263l-.873.01c-.975.009-.96.005-1.122.246-.068.1-.072.128-.062.544.01.394.015.447.078.534.147.207.081.2 2.228.2 2.147 0 2.081.006 2.228-.2.063-.087.069-.14.078-.534.01-.416.007-.444-.062-.544-.163-.24-.147-.237-1.122-.247L9 12.464v-.262c0-.25.003-.265.072-.278.319-.06.597-.134.781-.21.297-.121.688-.39.906-.615.21-.222.225-.306.085-.45-.138-.137-.235-.106-.519.16-.325.303-.931.597-1.234.597H9v-.52l.165-.034c.443-.093.943-.472 1.156-.878.222-.422.225-.453.225-1.74 0-1.132-.003-1.16-.072-1.36-.225-.631-.666-1.04-1.29-1.203a2.296 2.296 0 0 0-.9.003Zm1.007.569c.243.112.497.353.606.572l.069.137-.4.016c-.338.013-.413.025-.47.075-.087.081-.09.275-.002.362.056.057.103.063.515.063h.454v.531h-.435c-.39 0-.44.006-.515.066-.104.081-.116.275-.02.372.057.056.104.062.516.062h.454v.525l-.45.013c-.391.01-.46.018-.516.068-.088.082-.09.275-.003.363.056.056.103.063.469.063.225 0 .406.009.406.021 0 .072-.2.357-.335.485a1.3 1.3 0 0 1-.678.34c-.384.072-.78-.053-1.09-.34-.135-.128-.335-.413-.335-.485 0-.012.185-.021.407-.021.365 0 .412-.007.468-.063.088-.088.085-.281-.003-.363-.056-.05-.125-.059-.515-.068l-.45-.013V8.5h.453c.412 0 .459-.006.515-.062.097-.097.085-.29-.018-.372-.075-.06-.125-.066-.516-.066h-.434v-.531h.446c.407 0 .454-.006.516-.063.103-.096.094-.287-.012-.371-.075-.06-.125-.066-.472-.066H7.53l.082-.16c.096-.196.33-.434.515-.53.244-.126.375-.154.669-.144.24.006.312.021.494.109Zm1.272 6.99v.266H6.938v-.531h3.625v.266Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75-.001h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Episodes;
