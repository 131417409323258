import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Wallet = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<g fill="#fff" clipPath="url(#a)">
			<path d="M4.769 1.047c-2.094.762-2.91 1.075-3.013 1.15-.175.125-.334.35-.397.562-.08.272-.062.463.091.904l.137.4h-.084c-.144 0-.394.08-.625.203-.381.2-.694.593-.828 1.043-.044.154-.05.625-.05 4.725 0 4.438.003 4.56.063 4.75a1.762 1.762 0 0 0 1.218 1.17c.147.04 1.038.046 6.247.046 6.71 0 6.24.012 6.644-.197.24-.128.566-.453.694-.697.187-.35.197-.44.197-1.656v-1.1l.103-.022c.293-.062.578-.297.743-.61l.075-.14.01-1.534c.01-1.516.006-1.538-.06-1.719a1.128 1.128 0 0 0-.825-.669c-.04 0-.046-.153-.046-1.087 0-.91-.01-1.116-.05-1.26a1.676 1.676 0 0 0-.447-.747c-.297-.296-.594-.446-.972-.493l-.172-.022-.016-.734c-.015-.7-.019-.741-.094-.891a1.231 1.231 0 0 0-.578-.55c-.187-.075-.19-.075-1.809-.09l-1.619-.016-.21-.578C8.876.572 8.817.45 8.65.29 8.456.106 8.238.019 7.922.009L7.64-.003l-2.872 1.05Zm3.25.031c.025.063.09.244.15.403l.103.294-.219.022c-.325.031-.537.122-.725.31a1.372 1.372 0 0 0-.234.315c-.075.15-.078.184-.088.884l-.01.725-2.19-.006-2.19-.01-.17-.45a5.767 5.767 0 0 1-.165-.487c0-.022.022-.06.05-.081.075-.06 5.45-2.022 5.553-2.025.072-.003.097.016.135.106Zm4.375 1.716c.053.04.06.094.069.64l.009.597H7.934l.01-.597c.01-.546.015-.6.068-.64.091-.066 4.291-.066 4.382 0Zm1.234 2.272a.892.892 0 0 1 .406.397c.072.143.075.171.085 1.15l.012 1.006-1.043.012c-.938.013-1.066.022-1.285.081a2.362 2.362 0 0 0-1.506 1.241c-.197.41-.244.644-.228 1.131.025.678.21 1.104.681 1.572.306.303.512.435.897.575l.275.097 1.103.01 1.103.009-.01 1.037c-.009.913-.015 1.053-.065 1.16a.746.746 0 0 1-.372.406l-.162.081H1.544l-.163-.081a.746.746 0 0 1-.372-.406c-.053-.116-.056-.375-.065-4.388-.006-2.687.003-4.34.022-4.465a.765.765 0 0 1 .559-.647c.116-.031 1.372-.038 6.069-.035 5.39.007 5.931.01 6.034.057Zm1.36 3.571c.056.044.059.1.068 1.316.007 1.11.003 1.285-.04 1.347l-.047.075h-1.403c-1.32 0-1.41-.003-1.582-.063a1.41 1.41 0 0 1-.837-.746c-.094-.197-.1-.225-.1-.57 0-.352.003-.365.115-.593.197-.397.604-.706 1.016-.778.081-.013.734-.025 1.45-.028 1.075-.003 1.31.003 1.36.04Z" />
			<path d="m12.234 9.547-.13.047a1.12 1.12 0 0 0-.088.115c-.063.094-.079.157-.079.3 0 .166.01.191.122.313.122.131.125.131.347.131.222 0 .225 0 .347-.131.113-.122.122-.147.122-.31 0-.203-.063-.33-.203-.418a.495.495 0 0 0-.438-.047Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default Wallet;
