import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Videos = (props: SVGProps<SVGSVGElement>) => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_9744_16658)">
			<path
				d="M16.0827 4.66675L11.416 8.00008L16.0827 11.3334V4.66675Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0827 3.33325H2.74935C2.01297 3.33325 1.41602 3.93021 1.41602 4.66659V11.3333C1.41602 12.0696 2.01297 12.6666 2.74935 12.6666H10.0827C10.8191 12.6666 11.416 12.0696 11.416 11.3333V4.66659C11.416 3.93021 10.8191 3.33325 10.0827 3.33325Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_9744_16658">
				<rect width="16" height="16" fill="white" transform="translate(0.75)" />
			</clipPath>
		</defs>
	</svg>
);
export default Videos;
