import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Video = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			stroke={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M23 7L16 12L23 17V7Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default Video;
