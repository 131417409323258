import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const LinkedIn = (props: Props) => {
	const { width = 25, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.43081 5.85916C5.05054 5.85916 6.36358 4.54754 6.36358 2.92958C6.36358 1.31162 5.05054 0 3.43081 0C1.81109 0 0.498047 1.31162 0.498047 2.92958C0.498047 4.54754 1.81109 5.85916 3.43081 5.85916ZM8.73242 7.74641V23.9997H13.7843V15.9621C13.7843 13.8412 14.1838 11.7873 16.8163 11.7873C19.4126 11.7873 19.4448 14.2121 19.4448 16.096V24.001H24.4994V15.0878C24.4994 10.7095 23.5558 7.34473 18.4328 7.34473C15.9732 7.34473 14.3245 8.69303 13.6503 9.96903H13.5819V7.74641H8.73242ZM0.5 7.74609H5.55996V23.9994H0.5V7.74609Z"
				fill={color}
			/>
		</svg>
	);
};

export default LinkedIn;
