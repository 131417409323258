import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const TikTok = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M22.4939 9.83953C20.4302 9.84447 18.4172 9.20088 16.7391 7.99969V16.3772C16.7385 17.9288 16.2642 19.4432 15.3797 20.718C14.4952 21.9928 13.2426 22.9672 11.7893 23.5109C10.3361 24.0545 8.75152 24.1416 7.24748 23.7604C5.74343 23.3791 4.39162 22.5478 3.37279 21.3776C2.35396 20.2074 1.71668 18.754 1.54616 17.2118C1.37564 15.6696 1.68001 14.112 2.41858 12.7475C3.15714 11.3829 4.29469 10.2764 5.67913 9.5758C7.06356 8.87521 8.62888 8.61398 10.1658 8.82703V13.0406C9.4625 12.8194 8.70729 12.8261 8.00801 13.0597C7.30873 13.2933 6.70114 13.7418 6.27202 14.3413C5.84289 14.9408 5.61416 15.6606 5.6185 16.3979C5.62285 17.1351 5.86003 17.8521 6.2962 18.4466C6.73236 19.041 7.34518 19.4824 8.04717 19.7077C8.74915 19.933 9.50438 19.9308 10.205 19.7013C10.9057 19.4718 11.5159 19.0268 11.9485 18.4298C12.3811 17.8329 12.6141 17.1145 12.6141 16.3772V0H16.7391C16.7362 0.348347 16.7654 0.696219 16.8263 1.03922C16.9696 1.80491 17.2676 2.53332 17.7021 3.17988C18.1366 3.82643 18.6985 4.37756 19.3533 4.79953C20.2849 5.41551 21.3771 5.74383 22.4939 5.74359V9.83953Z"
				fill={color}
			/>
		</svg>
	);
};

export default TikTok;
