import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const X = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
		<path
			fill="#fff"
			d="M19.202 3h3.31l-7.229 8.26 8.503 11.24H17.13l-5.217-6.816L5.95 22.5H2.636l7.73-8.836L2.214 3H9.04l4.711 6.23L19.202 3Zm-1.163 17.522h1.833L8.041 4.875H6.072L18.04 20.522Z"
		/>
	</svg>
);
export default X;
