type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const OnlyMe = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
			<path
				d="M19 10.9998H5C3.89543 10.9998 3 11.8952 3 12.9998V19.9998C3 21.1043 3.89543 21.9998 5 21.9998H19C20.1046 21.9998 21 21.1043 21 19.9998V12.9998C21 11.8952 20.1046 10.9998 19 10.9998Z"
				stroke="#016DEA"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7 10.9998V6.99976C7 5.67367 7.52678 4.4019 8.46447 3.46422C9.40215 2.52654 10.6739 1.99976 12 1.99976C13.3261 1.99976 14.5979 2.52654 15.5355 3.46422C16.4732 4.4019 17 5.67367 17 6.99976V10.9998"
				stroke="#016DEA"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default OnlyMe;
