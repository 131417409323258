import { SVGProps } from 'react';

const BorderedAudio = (props: SVGProps<SVGSVGElement>) => (
	<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.492188" width="45" height="45" rx="22.5" stroke="url(#paint0_linear_872_83876)" />
		<path
			d="M33 25.9922V19.9922C33 14.9922 31 12.9922 26 12.9922H20C15 12.9922 13 14.9922 13 19.9922V25.9922C13 30.9922 15 32.9922 20 32.9922H26C31 32.9922 33 30.9922 33 25.9922Z"
			stroke="url(#paint1_linear_872_83876)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.62 28.2946C21.7908 28.2946 22.74 27.3455 22.74 26.1747C22.74 25.0038 21.7908 24.0547 20.62 24.0547C19.4491 24.0547 18.5 25.0038 18.5 26.1747C18.5 27.3455 19.4491 28.2946 20.62 28.2946Z"
			stroke="url(#paint2_linear_872_83876)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.74 26.1756V18.7656"
			stroke="url(#paint3_linear_872_83876)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M24.13 17.7603L26.47 18.5403C27.04 18.7303 27.5 19.3703 27.5 19.9703V20.5903C27.5 21.4003 26.87 21.8503 26.11 21.5903L23.77 20.8103C23.2 20.6203 22.74 19.9803 22.74 19.3803V18.7603C22.74 17.9603 23.36 17.5003 24.13 17.7603Z"
			stroke="url(#paint4_linear_872_83876)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_872_83876"
				x1="17.9954"
				y1="43.727"
				x2="55.2003"
				y2="24.3385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_872_83876"
				x1="20.8241"
				y1="32.0073"
				x2="37.0001"
				y2="23.5775"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_872_83876"
				x1="20.1587"
				y1="28.0858"
				x2="23.588"
				y2="26.2987"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_872_83876"
				x1="23.1312"
				y1="25.8107"
				x2="24.1546"
				y2="25.7387"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_872_83876"
				x1="24.6021"
				y1="21.4682"
				x2="28.1262"
				y2="19.2699"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default BorderedAudio;
