import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const WishList = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M13.893 3.075a3.666 3.666 0 0 0-5.186 0L8 3.78l-.707-.706A3.667 3.667 0 1 0 2.107 8.26l.706.707L8 14.155l5.187-5.187.706-.707a3.666 3.666 0 0 0 0-5.186Z"
		/>
	</svg>
);
export default WishList;
