import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VCastAuthor = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10.3333 8.84277H5.66667C4.95942 8.84277 4.28115 9.12373 3.78105 9.62382C3.28095 10.1239 3 10.8022 3 11.5094V14.5094"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M8.33073 6.84212C9.80349 6.84212 10.9974 5.64822 10.9974 4.17546C10.9974 2.7027 9.80349 1.50879 8.33073 1.50879C6.85797 1.50879 5.66406 2.7027 5.66406 4.17546C5.66406 5.64822 6.85797 6.84212 8.33073 6.84212Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M12.2478 7C12.0489 7 11.8581 7.07902 11.7175 7.21967C11.5768 7.36032 11.4978 7.55109 11.4978 7.75V9.75C11.4978 9.94891 11.5768 10.1397 11.7175 10.2803C11.8581 10.421 12.0489 10.5 12.2478 10.5C12.4467 10.5 12.6375 10.421 12.7781 10.2803C12.9188 10.1397 12.9978 9.94891 12.9978 9.75V7.75C12.9978 7.55109 12.9188 7.36032 12.7781 7.21967C12.6375 7.07902 12.4467 7 12.2478 7Z"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M13.9978 10.5V11C13.9978 11.4641 13.8134 11.9092 13.4852 12.2374C13.1571 12.5656 12.7119 12.75 12.2478 12.75C11.7837 12.75 11.3386 12.5656 11.0104 12.2374C10.6822 11.9092 10.4978 11.4641 10.4978 11V10.5"
			stroke={props.stroke || 'white'}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M12.2478 13.0576V14.0576"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
		<path
			d="M11.2478 14.0576H13.2478"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={'0.66667'}
		/>
	</svg>
);
export default VCastAuthor;
