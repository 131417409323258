import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const VOrganizer = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.66797 9.99902H5.33464C4.62739 9.99902 3.94911 10.28 3.44902 10.7801C2.94892 11.2802 2.66797 11.9584 2.66797 12.6657V13.999"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.8161 9.19922H9.18649C8.90012 9.19922 8.66797 9.438 8.66797 9.73255V13.4659C8.66797 13.7604 8.90012 13.9992 9.18649 13.9992H12.8161C13.1025 13.9992 13.3346 13.7604 13.3346 13.4659V9.73255C13.3346 9.438 13.1025 9.19922 12.8161 9.19922Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0391 8.66602V9.73268"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.96484 8.66602V9.73268"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.66797 10.7998H13.3346"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.9987 7.33236C9.47146 7.33236 10.6654 6.13845 10.6654 4.66569C10.6654 3.19293 9.47146 1.99902 7.9987 1.99902C6.52594 1.99902 5.33203 3.19293 5.33203 4.66569C5.33203 6.13845 6.52594 7.33236 7.9987 7.33236Z"
			stroke={props.stroke || 'white'}
			strokeWidth="0.666667"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default VOrganizer;
