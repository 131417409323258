import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Planner = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M2 6h12m-8 4 1.333 1.334L10 8.667M4.667 2v1.333M11.333 2v1.333M4.133 14h7.734c.746 0 1.12 0 1.405-.145.25-.128.455-.332.583-.583.145-.285.145-.658.145-1.405v-6.4c0-.747 0-1.12-.145-1.405a1.333 1.333 0 0 0-.583-.583c-.285-.146-.659-.146-1.405-.146H4.133c-.746 0-1.12 0-1.405.146-.25.128-.455.332-.583.583C2 4.347 2 4.72 2 5.467v6.4c0 .747 0 1.12.145 1.405.128.251.332.455.583.583.285.145.659.145 1.405.145Z"
		/>
	</svg>
);
export default Planner;
