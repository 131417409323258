import { SVGProps } from 'react';

interface Props {
	disabled?: boolean;
}

const VLancer = (props: Props & SVGProps<SVGSVGElement>) => (
	<>
		{props.disabled ? (
			<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#AAA9AD" />
				<rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#AAA9AD" />
				<path
					d="M15.668 14.333H10.3346C9.62739 14.333 8.94911 14.614 8.44902 15.1141C7.94892 15.6142 7.66797 16.2924 7.66797 16.9997V19.9997"
					stroke="white"
					stroke-width="0.666667"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M15.6048 14.999V16.6354M15.332 15.8172H12.332V19.9081H18.332V16.0899H16.6957"
					stroke="white"
					stroke-width="0.666667"
					stroke-linejoin="round"
				/>
				<path d="M13.332 17.666H15.9987" stroke="white" stroke-width="0.666667" stroke-linejoin="round" />
				<path
					d="M12.9987 12.3324C14.4715 12.3324 15.6654 11.1384 15.6654 9.66569C15.6654 8.19293 14.4715 6.99902 12.9987 6.99902C11.5259 6.99902 10.332 8.19293 10.332 9.66569C10.332 11.1384 11.5259 12.3324 12.9987 12.3324Z"
					stroke="white"
					stroke-width="0.666667"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		) : (
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<path
					d="M10.668 9.33301H5.33464C4.62739 9.33301 3.94911 9.61396 3.44902 10.1141C2.94892 10.6142 2.66797 11.2924 2.66797 11.9997V14.9997"
					stroke={props.color || props.stroke || 'white'}
					strokeWidth="0.666667"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.6048 9.99902V11.6354M10.332 10.8172H7.33203V14.9081H13.332V11.0899H11.6957"
					stroke={props.color || props.stroke || 'white'}
					strokeWidth="0.666667"
					strokeLinejoin="round"
				/>
				<path
					d="M8.33203 12.666H10.9987"
					stroke={props.color || props.stroke || 'white'}
					strokeWidth="0.666667"
					strokeLinejoin="round"
				/>
				<path
					d="M7.9987 7.33236C9.47146 7.33236 10.6654 6.13845 10.6654 4.66569C10.6654 3.19293 9.47146 1.99902 7.9987 1.99902C6.52594 1.99902 5.33203 3.19293 5.33203 4.66569C5.33203 6.13845 6.52594 7.33236 7.9987 7.33236Z"
					stroke={props.color || props.stroke || 'white'}
					strokeWidth="0.666667"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		)}
	</>
);
export default VLancer;
