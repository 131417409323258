import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Books = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 17} // Default width
		height={props.height || 16} // Default height
		fill="none"
		{...props}
	>
		<g stroke="#fff" clipPath="url(#a)">
			<path d="M14.015 10.666h-8c-.62 0-.93 0-1.184.068a2 2 0 0 0-1.414 1.414" />
			<path
				strokeLinecap="round"
				d="M5.417 10.666v-9M7.417 14.666c-1.886 0-2.829 0-3.415-.586-.585-.586-.585-1.529-.585-3.414V5.332c0-1.885 0-2.828.585-3.414.586-.586 1.529-.586 3.415-.586h2.666c1.886 0 2.829 0 3.415.586.585.586.585 1.529.585 3.414m-4 9.334c1.886 0 2.829 0 3.415-.586.585-.586.585-1.529.585-3.414V7.999"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M.75-.001h16v16h-16z" />
			</clipPath>
		</defs>
	</svg>
);
export default Books;
